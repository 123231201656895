

import React from 'react';
import PersonaList from '../screens/PersonaList';
import PizarraList from '../screens/PizarraList';
import InvitadoList from '../screens/InvitadoList';
import UsuarioList from '../screens/UsuarioList';
import EspacioList from '../screens/EspacioList';
import ReservaEspacioList from '../screens/ResarvaEspacioList';
import CobroList from '../screens/CobroList';
import ControlIngresoList from '../screens/ControlIngresoList';
import NotificacionList from '../screens/NotificacionList';
import CategoriasComercioList from '../screens/CategoriasComercioList';
import ComerciosList from '../screens/ComerciosList';
import BuzonList from '../screens/BuzonList';
import SolicitudReclamoList from '../screens/SolicitudReclamoList';
import ControlIngresoListReport from '../screens/ControlIngresoListReport';

// Private routes.
const Residentes = () => <PersonaList />;
const Pizarra = () => <PizarraList/>;
const Invitados = () => <InvitadoList/>;
const Usuarios = () => <UsuarioList />;
const Cobros = () => <CobroList />;
const Notificacion = () => <NotificacionList />;
const Espacios = () => <EspacioList/>;
const Comercios = () => <ComerciosList />;
const EspaciosReserva = () => <ReservaEspacioList />;
const CategoriasComercio = () => <CategoriasComercioList />;
const ControlInvitados = () => <ControlIngresoList />;

//el nuevo componente
const ControlInvitadosR = () => <ControlIngresoListReport />;

const SolicitudReclamos = () => <SolicitudReclamoList />;
const Home = () => <BuzonList />;


export {
	Residentes,
	Pizarra,
	Invitados,
	Usuarios,
	Cobros,
	Notificacion,
	Espacios,
    Comercios,
    EspaciosReserva,
    CategoriasComercio,
	ControlInvitados,
	SolicitudReclamos,
	ControlInvitadosR,
    Home
};
