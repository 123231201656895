export default class ComercioModel {
    constructor(
        nombre,
        estado,
        id,
        fotos,
        horario,
        direccion,
        detalle,
        idCategoria,
        usuario_ingreso,
        usuario_modificacion,
        telefono
    ) {
        this.nombre = nombre;
        this.horario = horario;
        this.estado = estado;
        this.id = id;
        this.fotos = fotos;
        this.direccion = direccion;
        this.detalle = detalle;
        this.idCategoria = idCategoria;
        this.usuario_ingreso = usuario_ingreso;
        this.usuario_modificacion = usuario_modificacion;
        this.telefono = telefono;
    }

    route() {
        return "comercio";
    }
}