export default class ReservaModel {
    constructor(
        id,
        espacioNombre,
        espacio,
        detalle,
        persona,
        fechaInicio,
        fechaFin,
        ubicacion,
        estado,
        usuario_ingreso,
        fechaIngreso,
        usuario_modificacion,
        valor,
        idCobro
    ) {
        this.id = id;
        this.espacioNombre = espacioNombre;
        this.espacio = espacio;
        this.detalle = detalle;
        this.persona = persona;
        this.fechaInicio = fechaInicio;
        this.fechaFin = fechaFin;
        this.ubicacion = ubicacion;
        this.estado = estado;
        this.usuario_ingreso = usuario_ingreso;
        this.fechaIngreso=fechaIngreso;
        this.usuario_modificacion = usuario_modificacion;
        this.valor=valor;
        this.idCobro=idCobro;
    }

    route() {
        return "reserva";
    }
}