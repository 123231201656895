import React from 'react';
import Base from '../../components/Base';
import GridTable from '../../components/GridTable';
import Button from '../../components/Button';

import GeneralServices from '../../services/GeneralServices.js';
import './style.css';
import matchSorter from 'match-sorter';
import { Link } from 'react-router-dom';



export default class UsuarioList extends Base {

    

    constructor(props) {
        super(props);
        this.UsuarioService = new GeneralServices();

        let cmbTipo = this.getOpcion("RolesAdmin");
        var tipodata; 
        if (this.getEtapa()!=='null')
        {
            tipodata= cmbTipo === "" ? [] : JSON.parse(cmbTipo);
        }
        else{
            tipodata=JSON.parse(localStorage.getItem('etapas'));
        }
        

        var columns = [
            {
                Header: this.getText("titUsuarios"),
                headerStyle: { textAlign: "left" },
                columns: [this.ColumnaId(),
                {
                    Header: this.getText("lblNombres"),
                    id: "nombres",
                    width: 200,
                    accessor: d => d.nombres,
                    filterMethod: (filter, rows) =>
                        matchSorter(rows, filter.value, { keys: ["nombres"] }),
                    filterAll: true
                },
                {
                    Header: this.getText("lblUsuario"),
                    id: "usuario",
                    width: 300,
                    accessor: d => d.usuario,
                    filterMethod: (filter, rows) =>
                        matchSorter(rows, filter.value, { keys: ["usuario"] }),
                    filterAll: true
                },
                {
                    Header: this.getText("lblFechaRegistro"),
                    accessor: "fecha",
                    style: { textAlign: "center" }
                },
                {
                    Header: this.getText("lblEstado"),
                    accessor: "estado",
                    width: 80,
                },
                {
                    Header: this.getText(this.getEtapa()!=='null'?"lblTipo":"lblUrbanizacion"),
                    accessor: "tipo",
                    id: "tipo",
                    filterMethod: (filter, row) => {
                        if (filter.value === "all") {
                            return true;
                        }
                        else {
                            return row[filter.id] === filter.value;
                        }

                    },
                    Filter: ({ filter, onChange }) =>
                        <select
                            onChange={event => onChange(event.target.value)}
                            style={{ width: "100%" }}
                            value={filter ? filter.value : "all"}
                        >
                            <option value="all">{this.getText("lblTodos")}</option>
                            {
                                tipodata.map(k => {
                                    return <option key={k.key} value={this.getEtapa()!=='null'?k.key:k.value}>{k.value}</option>
                                })
                            }
                        </select>
                }
                ]
            },
            {
                Header: <Button className="tertiaryColorB" onClick={(e) => this.New(e)} value={this.getText("lblNuevo")} />,
                columns: [
                    {
                        Header: "",
                        accessor: "buscar",
                        filterable: false,
                        sortable: false,
                        style: { textAlign: "center" }
                    }]
            }
        ];

        this.state = {
            dataGridRows: [],
            dataGridColumns: columns
        }
    };

    setValue(rows) {
        this.setState({
            dataGridRows: rows
        });
    };

    New(e) {
        e.preventDefault();
        window.location.href="/usuario/id/0";
        //this.props.history.push("/usuario/id/0");
    }

    componentDidMount() {
        let  etapa = this.getEtapa();

        this.UsuarioService.List((etapa!=='null')?etapa:0, `usuario/roletapa`)
            .then(result => {
                if (result.codigo !== "0") {
                    alert(result.mensaje);
                    if (result.codigo === 401) {
                        this.CerarSesion();
                    }
                }
                else {

                    var rows = [];
                   // console.log(result.data);
                    result.data.map(Usuario =>
                        rows.push({
                            id: Usuario[0],
                            usuario: Usuario[1],
                            fecha: Usuario[2] ? new Date(Usuario[2]).toLocaleDateString() : "",
                            estado: Usuario[3],
                            tipo: (etapa!=='null')?Usuario[6]:Usuario[5],
                            nombres: Usuario[7] + ' ' + Usuario[8],
                            buscar: this.link(Usuario[4])
                        }),

                    )
                    this.setValue(rows);
                }
            }
            )
            .catch(error => {
                console.log(error);
            });
    }

    //Fucion para mostrar icono y Link
    link(id) {
        var link = [];
        link.push(
            <Link key={id} to={"/Usuario/id/" + id}>
                <h6>{this.getText("lblEditar")}</h6>
            </Link>)
        return link[0]
    }

    render() {

        return (
            <div>
                <div className="mainContent">
                    <div className="contentBlock">
                        <GridTable
                            data={this.state.dataGridRows}
                            columns={this.state.dataGridColumns} />
                    </div>
                </div>
            </div>
        )
    }

}

