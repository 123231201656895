import React from 'react';
import Base from '../../components/Base';
import {
    Col, Form, FormGroup, Label, Input,
    ModalHeader, Modal, ModalBody,
    ModalFooter
} from 'reactstrap';

import Button from '../../components/Button';
import Text from "../../components/Text";
import './style.css';
import GeneralServices from '../../services/GeneralServices.js';
import Loader from '../../components/Loader';
const Compress = require('compress.js');


export default class ControlIngresoFotoReport extends Base {

    constructor(props) {
        super(props);

        this.ControlIngresoFotoService = new GeneralServices();
        this.iniciarCamara = this.iniciarCamara.bind(this);
        this.handleTakePicture = this.handleTakePicture.bind(this);
        this.Service = new GeneralServices();

        this.state =
        {
            iniciado: false

        };
    }


    componentDidMount() {
        // this.iniciarCamara();
    }

    handleTakePicture(event) {
        event.preventDefault();


        var arrXml = [],
            szInfo = "";

        arrXml.push("<LocalConfigInfo>");
        arrXml.push('<DownloadPath>C:\\wamp64\\www</DownloadPath>');
        arrXml.push("<CapturePath>C:\\wamp64\\www</CapturePath>");
        ///arrXml.push("<CapturePath>\\\localhost\\apps</CapturePath>");
        arrXml.push("</LocalConfigInfo>");


        var iRet = window.WebVideoCtrl.I_SetLocalCfg(arrXml.join(""));
        if (0 == iRet) {
            szInfo = "local configuration success！";
        } else {
            szInfo = "local configuration failed！";
        }

        console.log(szInfo);

        let archivo;


        let ok = window.WebVideoCtrl.I_CapturePic("test", { bDateDir: false });

        fetch("http://localhost/test.jpg")
            .then(res => res.blob()) // Gets the response and returns it as a blob
            .then(blob => {
                // Here's where you get access to the blob
                // And you can use it for whatever you want
                // Like calling ref().put(blob)

                // Here, I use it to make an image appear on the page
                // let objectURL = URL.createObjectURL(blob);
                // let myImage = new Image();
                // myImage.src = objectURL;
                // console.log(myImage.src);
                // console.log(objectURL);
                // archivo = this.blobToFile(blob, "prueba.jpg");
                // console.log(myImage.outerHTML);



                // let compress = new Compress();

                // compress.compress(blob, {
                //     size: 8, // the max size in MB, defaults to 2MB
                //     quality: 0.40, // the quality of the image, max is 1,
                // }).then((results) => {
                //     console.log(results);

                //     let img1 = results[0];
                //     let base64str = img1.data;
                //     let imgExt = img1.ext;
                //     let name = img1.alt;
                //     let file = Compress.convertBase64ToFile(base64str, imgExt);
                //     let archivo = new File([file], name,{ type: file.type, });
                //    console.log(archivo);
                // });

                // var width = 1920;
                // var height = 1080;


                // var canvas = document.createElement('canvas');  // Dynamically Create a Canvas Element
                // canvas.width = width;  // Set the width of the Canvas
                // canvas.height = height;  // Set the height of the Canvas
                // var ctx = canvas.getContext("2d");  // Get the "context" of the canvas 
                // //var img = document.getElementById("myimage");  // The id of your image container
                // ctx.drawImage(myImage, 0, 0, width, height);  // Draw your image to the canvas


                //var jpegFile = canvas.toDataURL("image/jpeg");


                let formData = new FormData();
                formData.append('file', blob);
                this.Service.Upload(formData, 'archivo/subir')
                    .then(result => {
                        console.log(result);
                        if (result.codigo !== "0") {
                            alert(result.mensaje);
                            if (result.codigo === 401) {
                                this.CerarSesion();
                            }
                            this.setState({ loading: false });
                        }
                        else {
                            console.log(result.data.url);

                            let server = this.getOpcion('fileServer');
                            let url = server + result.data.url;

                            this.setState({ foto: url });
                        }
                    }
                    )
                    .catch(error => {
                        console.log(error);


                        this.setState({ loading: false });
                    });


            });

        // const qrcode = new Decoder();


        // qrcode.scan("http://localhost/test.jpg")
        //     .then(result => {
        //         alert(result.data);
        //     })
        //     .catch(error => {
        //         alert(error.data);
        //     });



        this.forceUpdate();


        alert(ok);
    }

    blobToFile(theBlob, fileName) {
        //A Blob() is almost a File() - it's just missing the two properties below which we will add
        theBlob.lastModifiedDate = new Date();
        theBlob.name = fileName;
        return theBlob;
    }

    iniciarCamara() {

        console.log(document.body);
        // if (!this.state.iniciado) {
        const script = document.createElement("script");
        script.src = "/scripts/codebase/webVideoCtrl2.js";
        script.async = true;
        script.id = 'del'
        script.onload = () => this.createPluginCamera();
        document.body.appendChild(script);
        console.log(script);
        //this.setState({iniciado:true,script:script});

        // }
        // else
        // {
        //     document.body.appendChild(this.state.script);
        // }

    }

    createPluginCamera(e) {

        console.log("Creado", e)
        var iRet = window.WebVideoCtrl.I_CheckPluginInstall();
        console.log(iRet)

        if (-1 === iRet) {
            alert("If the plugin is uninstalled, please install the WebComponentsKit.exe!");
            return;
        }

        var oPlugin = {
            iWidth: 750,             // plugin width
            iHeight: 500             // plugin height
        };

        var oLiveView = {
            iProtocol: 1,            // protocol 1��http, 2:https
            szIP: "192.168.0.54",    // protocol ip
            szPort: "80",            // protocol port
            szUsername: "admin",     // device username
            szPassword: "abcorp", // device password
            iStreamType: 1,          // stream 1��main stream  2��sub-stream  3��third stream  4��transcode stream
            iChannelID: 1,           // channel no
            bZeroChannel: false      // zero channel
        };
        console.log("Camara 00")


        window.WebVideoCtrl.I_InitPlugin(oPlugin.iWidth, oPlugin.iHeight, {
            bWndFull: true,//Wether support doule clicking to switch the full-screen mode: it's supported by default; true:support, false:not support
            iWndowType: 1,
            cbInitPluginComplete: function () {
                window.WebVideoCtrl.I_InsertOBJECTPlugin("divPluginC");
                console.log("Camara 01");


                // login
                var ret = window.WebVideoCtrl.I_Login(oLiveView.szIP, oLiveView.iProtocol, oLiveView.szPort, oLiveView.szUsername, oLiveView.szPassword, {
                    success: function (xmlDoc) {
                        // strat real play
                        var szDeviceIdentify = oLiveView.szIP + "_" + oLiveView.szPort;
                        setTimeout(function () {
                            window.WebVideoCtrl.I_StartRealPlay(szDeviceIdentify, {
                                iStreamType: oLiveView.iStreamType,
                                iChannelID: oLiveView.iChannelID,
                                bZeroChannel: oLiveView.bZeroChannel
                            });
                        }, 1000);
                    }
                });

                console.log("Camara 1.02")
                console.log(ret);

                console.log("Camara 02")
            }
        });

    }

    render() {


        console.log("inicia");
        console.log(this.props);

        if (this.props.open) {
            this.iniciarCamara();
        }


        const closeBtn = <button className="close" onClick={this.props.Close}>&times;</button>;

        return (
            <Modal size='lg' isOpen={this.props.open} centered >
                <ModalHeader close={closeBtn}> {this.getText("titVehiculo")}</ModalHeader>
                <ModalBody>
                    <Form  >

                        <div>

                            <div ref={(ref) => this.video = ref} id="divPluginC" key="divPluginC" className="plugin"></div>

                            <img src={this.state.foto} width="235" height="189"></img>

                            <Button type="button"
                                onClick={this.handleTakePicture}
                                className="tertiaryColorB"
                                value={this.getText("lblFotos")} />

                            {/* <Button type="submit"
                                onClick={this.handleSubmit}
                                className="tertiaryColorB"
                                value={this.getText("lblGuardar")} /> */}

                            {/* <QR></QR> */}


                        </div>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={this.handleAgregar}>{this.getText("lblAceptar")}</Button>
                </ModalFooter>
            </Modal>
        )


    }
}