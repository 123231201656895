export default class PizarraModel {
    constructor(
        asunto,
        tipo,
        estado,
        detalle,
        idEtapa,        
        id,
        manzana,
        villa,
        departamento,
        usuario_ingreso,
        usuario_modificacion,
        attachments
    ) {
        this.asunto = asunto;
        this.detalle = detalle;
        this.estado = estado;
        this.id = id;
        this.idEtapa = idEtapa;
        this.tipo = tipo;
        this.manzana=manzana;
        this.villa=villa;
        this.departamento=departamento;
        this.usuario_modificacion=usuario_modificacion;
        this.usuario_ingreso=usuario_ingreso;
        this.attachments = attachments;
    }

    route() {
        return "notificacion";
    }
}