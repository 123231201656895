// Componente base
import React from 'react';
import PropTypes from 'prop-types';
import Base from '../Base';
import { Button } from "reactstrap";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// Otros Componentes
import './style.css';

export default class Boton extends Base {

    constructor(props) {
        super(props);
        this.state = {
            value: this.props.value
        };

    };

    render() {

        const { className, onClick, size, disabled, icono, outline,colorButton,colorIcon } = this.props;
        return (
            outline ?
                <Button
                    value={this.state.value}
                    onClick={onClick}
                    disabled={disabled}
                    size={size ? size : "md"}
                    outline color={colorButton}
                >
                    {this.state.value}
                    {icono ?
                        <span >{" "}
                            <FontAwesomeIcon
                                icon={icono}
                                color={colorButton?colorButton:colorIcon}
                            />
                        </span> : <div></div>

                    }
                </Button> :
                icono?
                <Button
                    value={this.state.value}
                    onClick={onClick}
                    disabled={disabled}
                    className={className}
                    size={size ? size : "md"}
                >
                    {this.state.value}
                    {icono ?
                        <span >{" "}
                            <FontAwesomeIcon
                                icon={icono}
                            />
                        </span> : <div></div>

                    }
                </Button>:

                <Button
                    value={this.state.value}
                    className={className}
                    onClick={onClick}
                    disabled={disabled}
                    size={size ? size : "md"}
                >{this.state.value}</Button>


        )
    }
}
// Seccion de Props
Button.propTypes = Button.propTypes || {
    value: PropTypes.string,
    onClick: PropTypes.func,
    type: PropTypes.string.isRequired
}
