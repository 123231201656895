import React from 'react';
import { Col, Form, FormGroup, Label, Input, CustomInput } from 'reactstrap';
import Base from '../../components/Base';
import Text from '../../components/Text';
import Ubicacion from "../../components/Ubicacion";
import './style.css';
import SolicitudModel from '../../models/SolicitudModel.js';
import GeneralServices from '../../services/GeneralServices.js';
import Loader from '../../components/Loader';
import Button from '../../components/Button';
import Return from '../../components/Return';
import Dropdown from "../../components/Dropdown";
import Alert from '../../components/Alert';

const Compress = require('compress.js');

export default class SolicitudReclamo extends Base {
    constructor(props) {
        super(props);

        this.handleAgregar = this.handleAgregar.bind(this);
        this.solicitudService = new GeneralServices();
        this.ChangeEstado = this.ChangeEstado.bind(this);
        this.state = {
            estado: 'A',
            files: [],
            fotos: [],
            loading: false,
            alerta: false,
            mensaje: '',
            error: false
        };
    }

    ChangeEstado(event) {
        this.setState({
            estado: event.target.value
        });
    }

    ShowAlert(mensaje, error) {
        this.setState({ alerta: true, mensaje: mensaje, error: error }, () => {
            window.setTimeout(() => {
                this.setState({ alerta: false, mensaje: '', error: false })
            }, 5000)
        });
    }


    handleAgregar(event) {

        event.preventDefault();

        let solicitud = new SolicitudModel();
        let formData = new FormData();
        var imagedata = this.state.files;
        let validar = this.ValidateForm();

        if (validar) {
            this.ShowAlert(validar, true);
            return;
        }

        let ubicacion = this.getRef('cmbUbicacion');

        this.setState({ loading: true });

        solicitud.titulo = this.getRef('txt_titulo').getValue();
        solicitud.detalle = this.getRef('txt_detalle').getValue();;
        solicitud.id = this.state.solicitud.id;
        solicitud.idEtapa = this.state.solicitud.idEtapa;
        solicitud.tipo = this.getRef("cmbTipo").getValue();
        solicitud.estado = this.state.estado;
        solicitud.categoria = this.getRef("cmbCategoria").getValue();
        //solicitud.fechaIngreso = result.data.fechaIngreso;
        solicitud.persona = ubicacion.state.persona ? ubicacion.state.persona : null;
        solicitud.ubicacion = ubicacion.state.ubicacion.id;
        solicitud.usuario_ingreso = this.getUsuario();


        for (const file of imagedata) {
            formData.append('files', file);
        }

        this.solicitudService.Upload(formData, 'archivo/subirvarios')
            .then(result => {
                if (result.codigo !== "0") {
                    alert(result.mensaje);
                    if (result.codigo === 401) {
                        this.CerarSesion();
                    }
                }
                else {
                    var archivos = [];
                    var fotoServer = [];

                    fotoServer = [...this.state.fotos, ...result.data];
                    fotoServer.map((foto, i) => { foto.orden = i + 1 });
                    solicitud.fotos = JSON.stringify(fotoServer);

                    this.solicitudService.save(solicitud, solicitud.route())
                        .then(result2 => {
                            if (result2.codigo !== "0") {
                                alert(result2.mensaje);
                            }
                            else {
                                this.setState({ estado: result2.data.estado, solicitud: result2.data, files: archivos, fotos: fotoServer, loading: false });
                                this.ShowAlert(this.getText("msgGuardadoOk"), false);
                                //this.props.history.push('/Pizarra');
                            }
                            this.setState({ loading: false });
                        }
                        )
                        .catch(error => {
                            console.log(error);
                            this.setState({ loading: false });
                        });
                }
            }
            )
            .catch(error => {
                console.log(error);
                this.setState({ loading: false });
            });
    }

    fileSelectedHandler = e => {
        let imagen = [...e.target.files];
        if (this.validaImagenes(imagen[0])) {
            let compress = new Compress();

            compress.compress(imagen, {
                size: 8, // the max size in MB, defaults to 2MB
                quality: 0.40, // the quality of the image, max is 1,
            }).then((results) => {

                let img1 = results[0];
                let base64str = img1.data;
                let imgExt = img1.ext;
                let name = img1.alt;
                let file = Compress.convertBase64ToFile(base64str, imgExt);
                let archivo = new File([file], name, { type: file.type, });
                this.setState({ files: [...this.state.files, archivo] });

            });
        }
        e.target.value = null;
    }

    deleteRow(orden, e) {
        e.preventDefault();
        var nuevofile = []
        var nuevofotos = this.state.fotos.filter((foto) =>
            foto.orden !== orden);
        nuevofile = this.state.files;
        nuevofile.splice(orden, 1);
        this.setState({ files: nuevofile });
        this.setState({ fotos: nuevofotos });

    }

    validaImagenes = (file) => {
        const CantImagen = parseInt(this.getOpcion("NoImgPizarra"));
        const types = ['image/png', 'image/jpeg'];

        if (types.every(type => file.type !== type)) {
            this.ShowAlert(this.getText("msgImagenNoPermitido"), true);
            window.scrollTo(0, 0);
            return false;
        }

        let CantRegistro = this.state.files.length + this.state.fotos.length;

        if (CantRegistro >= CantImagen) {
            this.ShowAlert(this.getText("msgImagenMaximo") + CantImagen, true);
            window.scrollTo(0, 0);
            return false;
        }
        return true;
    }


    cargarDatos() {
        const id = this.props.match.params.id;

        let solicitud = new SolicitudModel();
        solicitud.id = id;

        if (id > 0) {

            this.setState({ loading: true });
            this.solicitudService.Id(solicitud.id, solicitud.route())
                .then(result => {

                    this.setState({ loading: false });
                    if (result.codigo !== "0") {
                        alert(result.mensaje);
                        if (result.codigo === 401) {
                            this.CerarSesion();
                        }
                    }
                    else {
                        console.log(result.data);
                        var fotos = [];

                       /* solicitud.estado = result.data.estado;
                        solicitud.fechaIngreso = result.data.fechaIngreso;
                        solicitud.persona = result.data.persona;
                        solicitud.ubicacion = result.data.ubicacion;
                        solicitud.detalle = result.data.detalle;
                        solicitud.fechaInicio = result.data.fechaInicio;
                        solicitud.fechaFin = result.data.fechaFin;
                        solicitud.usuario_ingreso = result.data.usuario_ingreso;
                        solicitud.titulo = result.data.titulo;
                        solicitud.tipo = result.data.tipo;
                        solicitud.idEtapa = result.data.idEtapa;
                        solicitud.categoria=result.data.categoria;*/
                        if (result.data.fotos != null) {
                            fotos = JSON.parse(result.data.fotos);
                        }
                        //console.log(solicitud);
                        this.setState({ estado: result.data.estado, solicitud:  result.data,fotos: fotos });
                        this.getRef("cmbTipo").updateValue(result.data.tipo );
                        this.getRef("cmbCategoria").updateValue(result.data.categoria );

                    }
                }
                )
                .catch(error => {
                    this.setState({ loading: false });
                    console.log(error);
                });
        }
        else {
            solicitud.estado = 'N';
            solicitud.idEtapa=this.getEtapa();
            solicitud.titulo="";
            this.setState({ solicitud: solicitud,estado:solicitud.estado });

        }


    }

    componentDidMount() {
        this.cargarDatos();
    }
    render() {
        const val = this.state.solicitud;

        if (val) {
            return (
                <div className='formTitulo' >

                    <Alert
                        open={this.state.alerta}
                        mensaje={this.state.mensaje}
                        error={this.state.error}
                    />
                    <FormGroup row >
                        <Col md={9}>
                            <h5><Return onClick={() => this.props.history.go(-1)} />
                                {this.getText("titSolicitudReclamos")}</h5>
                        </Col>
                        <Col md={3} className="text-right">
                            <Button type="submit"
                                onClick={this.handleAgregar}
                                className="tertiaryColorB"
                                value={this.getText("lblGuardar")} />
                        </Col>

                    </FormGroup>

                    <div className='form'>
                        <Form >

                            <FormGroup row>
                                <Col >
                                    <Label for="estados" className='inputNormal' >{this.getText("lblEstado")}</Label>
                                    <FormGroup check inline id="estados" >
                                        <Label check>
                                            <Input
                                                type='radio'
                                                name="estado"
                                                value='N'
                                                checked={this.state.estado === 'N'}
                                                onChange={this.ChangeEstado}
                                            />{this.getText("lblNuevo")}</Label>

                                    </FormGroup>
                                    <FormGroup check inline  >
                                        <Label check>
                                            <Input
                                                type='radio'
                                                name="estado"
                                                value='A'
                                                checked={this.state.estado === 'A'}
                                                onChange={this.ChangeEstado}
                                            />{this.getText("lblAsigando")}</Label>
                                    </FormGroup>
                                    <FormGroup check inline  >
                                        <Label check>
                                            <Input
                                                type='radio'
                                                name="estado"
                                                value='T'
                                                checked={this.state.estado === 'T'}
                                                onChange={this.ChangeEstado}
                                            />{this.getText("lblTerminado")}</Label>
                                    </FormGroup>
                                </Col>
                            </FormGroup>

                            <Ubicacion
                                ref={(ref) => this.setRef('cmbUbicacion', ref)}
                                residente={true}
                                persona={val.persona}
                                ubicacion={val.ubicacion}

                            />

                            <FormGroup row >
                                <Col >
                                    <Dropdown
                                        id="cmbTipo"
                                        ref={(ref) => this.setRef('cmbTipo', ref)}
                                        label={this.getText("lblTipo")}
                                        value={JSON.parse(this.getOpcion("CasoTipo"))}
                                    />
                                </Col>
                                <Col >
                                    <Dropdown
                                        id="cmbCategoria"
                                        ref={(ref) => this.setRef('cmbCategoria', ref)}
                                        label={this.getText("lblCategoria")}
                                        value={JSON.parse(this.getOpcion("CasoCategoria"))}
                                    />
                                </Col>
                            </FormGroup>

                            <FormGroup row>
                                <Col >
                                    <Text
                                        label={this.getText("lblTitulo")}
                                        id={'txt_titulo'}
                                        value={val.titulo}
                                        type={'text'}
                                        ref={(ref) => this.setRef('txt_titulo', ref)}
                                        isRequired={true}
                                        menssage={`${this.getText("msgCampoRequerido")} ${this.getText("lblTitulo")}`}
                                    />
                                </Col>
                            </FormGroup>


                            <FormGroup row>
                                <Col >
                                    <Text
                                        label={this.getText("lblDetalle")}
                                        id={'txt_detalle'}
                                        value={val.detalle}
                                        type={'textarea'}
                                        height="150px"
                                        ref={(ref) => this.setRef('txt_detalle', ref)}

                                    />
                                </Col>
                            </FormGroup>

                            <FormGroup>
                                <Label for="files"  >{this.getText("lblMultimedia")}</Label>
                                <CustomInput type="file"
                                    id="files"
                                    name="files"
                                    label={this.getText("lblSeleccionarImages")}
                                    accept="image/*"
                                    onChange={this.fileSelectedHandler}
                                    disabled={this.state.loading} />
                            </FormGroup>
                            <br></br>
                            <ul>
                                {
                                    this.state.fotos.map((repo, index) => {
                                        let server = this.getOpcion('fileServer');
                                        let url = server + repo.url;
                                        return (
                                            <FormGroup row key={index}  >
                                                <Col md={3}>
                                                    <img alt={index} src={url} height="42" width="42" />
                                                </Col>
                                                <Col md={7}>
                                                    <a href={url} target="_blank" > {this.getText("lblImagen") + " " + repo.orden}</a>
                                                </Col>
                                                <Col md={2}>
                                                    <Button
                                                        onClick={(e) => this.deleteRow(repo.orden, e)}
                                                        size="sm"
                                                        className="secondaryColorB"
                                                        value="X" />
                                                </Col>
                                            </FormGroup>
                                        )
                                    })
                                }
                                {
                                    this.state.files.map((repo, index) => {
                                        let orden = index;
                                        let url = URL.createObjectURL(repo);
                                        return (
                                            <FormGroup row key={orden}  >
                                                <Col md={3}>
                                                    <img alt={orden} src={url} height="42" width="42" />
                                                </Col>
                                                <Col md={7}>
                                                    <a href={url} target="_blank" >
                                                        {this.getText("lblImagen") + " " + (this.state.fotos.length + index + 1)}</a>
                                                </Col>
                                                <Col md={2}>
                                                    <Button
                                                        onClick={(e) => this.deleteRow(repo.orden, e)}
                                                        size="sm"
                                                        className="secondaryColorB"
                                                        value="X" />
                                                </Col>
                                            </FormGroup>
                                        )
                                    })
                                }
                            </ul>

                        </Form>

                    </div>
                    {(this.state.loading) ? <Loader>{this.getText("lblCargando")}</Loader> : <span></span>}

                </div>
            );
        }
        else {
            return (
                <div>
                    <Loader />
                </div>);
        }
    }

}