// Componente base
import React from 'react';
import Base from '../../components/Base';
import { Link } from 'react-router-dom';
import matchSorter from 'match-sorter';
import Button from '../../components/Button';

import GridTable from '../../components/GridTable';
import GeneralServices from '../../services/GeneralServices.js';
import './styles.css';

export default class CategoriasComercioList extends Base {

    constructor(props) {
        super(props);
        this.servicio = new GeneralServices();

        var columns = [
            {
                Header: this.getText("titCategoriasComercio"),
                headerStyle: { textAlign: "left" },
                columns: [
                    this.ColumnaId(),

                    {
                        Header: this.getText("lblFechaIngreso"),
                        accessor: "fecha",
                        style: { textAlign: "center" }
                    },
                    {
                        Header: this.getText("lblDescripcion"),
                        id: "nombre",
                        width: 200,
                        accessor: d => d.nombre,
                        filterMethod: (filter, rows) =>
                            matchSorter(rows, filter.value, { keys: ["nombre"] }),
                        filterAll: true
                    },
                    {
                        Header: this.getText("lblEstado"),
                        accessor: "estado",
                        width: 80,
                    }

                ]
            },
            {
                Header: <Button className="tertiaryColorB" onClick={(e) => this.New(e)} value={this.getText("lblNuevo")} />,
                columns: [
                    {
                        Header: "",
                        accessor: "buscar",
                        filterable: false,
                        sortable: false,
                        style: { textAlign: "center" }
                    }]
            }
        ];

        this.state = {
            dataGridRows: [],
            dataGridColumns: columns
        }
    };


    setValue(rows) {
        this.setState({
            dataGridRows: rows
        });
    };

    New(e) {
        e.preventDefault();
        window.location.href="/CategoriaComercio/id/0";
        //this.props.history.push("/CategoriaComercio/id/0");
    }

    componentDidMount() {
         this.servicio.List("", 'categoriacomercio')
            .then(result => {
                if (result.codigo !== "0") {
                    alert(result.mensaje);
                    if (result.codigo === 401) {
                        this.CerarSesion();
                    }
                }
                else {

                    //console.log(result.data);
                    var rows = [];
                    for (let i = 0; i < result.data.length; i++) {
                        rows.push({
                            id: result.data[i].id,
                            fecha: result.data[i].fechaIngreso ? new Date(result.data[i].fechaIngreso).toLocaleDateString() : "",
                            nombre: result.data[i].nombre,
                            estado: result.data[i].estado,
                            buscar: this.link(result.data[i].id)

                        });
                    }
                    this.setValue(rows);
                }

            }
            )
            .catch(error => {
                console.log(error);
            });
    }
    //Fucion para mostrar icono y Link
    link(id) {
        var link = [];
        link.push(
            <Link key={id} to={"/CategoriaComercio/id/" + id}>
                <h6>{this.getText("lblEditar")}</h6>
            </Link>)
        return link[0]
    }

    render() {
        return (
            <div>
                <div className="mainContent">
                    <div className="contentBlock">
                        <GridTable
                            data={this.state.dataGridRows}
                            columns={this.state.dataGridColumns} />
                    </div>
                </div>
            </div>
        )
    }
}
