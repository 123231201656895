// Componente base
import React from 'react';
import Base from '../../components/Base';
import Alert from '../../components/Alert';
import { Link } from 'react-router-dom';
import matchSorter from 'match-sorter';
import { Input, FormGroup, Label, Col, Card, CardHeader, CardBody, Row, Form, UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import GridTable from '../../components/GridTable';
import GeneralServices from '../../services/GeneralServices.js';
import './style.css';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import Button from '../../components/Button';
import ExportExcel from '../../components/ExportExcel';

export default class ControlIngresoListReport extends Base {

    constructor(props) {
        super(props);
        this.servicio = new GeneralServices();


        var columns = [
            {
                columns: [

                    {
                        Header: this.getText("lblManzana"),
                        id: "manzana",
                        width: 50,
                        accessor: d => d.manzana,
                        filterMethod: (filter, rows) =>
                            matchSorter(rows, filter.value, { keys: ["manzana"] }),
                        filterAll: true,
                        style: { textAlign: "right" }
                    },

                    {
                        Header: this.getText("lblLote"),
                        id: "villa",
                        accessor: d => d.villa,
                        filterMethod: (filter, rows) =>
                            matchSorter(rows, filter.value, { keys: ["villa"] }),
                        filterAll: true,
                        width: 80,
                        style: { textAlign: "right" }
                    },

                    {
                        Header: this.getText("lblDepartamento"),
                        id: "departamento",
                        accessor: d => d.departamento,
                        filterMethod: (filter, rows) =>
                            matchSorter(rows, filter.value, { keys: ["departamento"] }),
                        filterAll: true,
                        width: 90,
                        style: { textAlign: "right" }
                    },

                    {
                        Header: this.getText("lblHoraIngreso"),
                        id: "fecha_inicio",
                        accessor: d => d.fecha_inicio,
                        filterMethod: (filter, rows) =>
                            matchSorter(rows, filter.value, { keys: ["horaDesde"] }),
                        width: 190,
                        filterAll: true,
                        style: { textAlign: "right" }
                    },

                    {
                        Header: this.getText("lblHoraSalida"),
                        id: "fecha_fin",
                        accessor: d => d.fecha_fin,
                        filterMethod: (filter, rows) =>
                            matchSorter(rows, filter.value, { keys: ["horaHasta"] }),
                        width: 190,
                        filterAll: true,
                        style: { textAlign: "right" }
                    },
                    {
                        Header: this.getText("lblNombres"),
                        id: "nombre_residente",
                        accessor: d => d.nombre_residente,
                        filterMethod: (filter, rows) =>
                            matchSorter(rows, filter.value, { keys: ["nombre_residente"] }),
                        filterAll: true,
                        style: { textAlign: "right" }
                    },
                    {
                        Header: this.getText("lblMotivo"),
                        id: "nombre",
                        accessor: d => d.nombre,
                        filterMethod: (filter, rows) =>
                            matchSorter(rows, filter.value, { keys: ["nombre"] }),
                        filterAll: true,
                        style: { textAlign: "right" }
                    },
                    {
                        Header: this.getText("lblPlaca"),
                        id: "placa",
                        accessor: d => d.placa,
                        filterMethod: (filter, rows) =>
                            matchSorter(rows, filter.value, { keys: ["placa"] }),
                        filterAll: true,
                        style: { textAlign: "right" }
                    },
                    {
                        Header: this.getText("lblTipo"),
                        id: "tipo",
                        accessor: d => d.tipo,
                        filterMethod: (filter, rows) =>
                            matchSorter(rows, filter.value, { keys: ["tipo"] }),
                        filterAll: true,
                        style: { textAlign: "right" }
                    },

                ]
            },
            {
                Header: "",
                columns: [
                    {
                        Header: "",
                        accessor: "buscar",
                        filterable: false,
                        sortable: false,
                        style: { textAlign: "center" }
                    }
                ]
            },
            // {
            // Header: "",
            // columns: [
            //     {
            //         Header: "",
            //         accessor: "salida",
            //         filterable: false,
            //         sortable: false,
            //         style: { textAlign: "center" }
            //     }
            // ]
            // }
        ];

        this.state = {
            dataGridRows: [],
            dataGridColumns: columns,
            manzanas: [],
            estado: '',
            loading: false,
            alerta: false,
            mensaje: '',
            error: false
        }
    };

    setValue(rows) {
        this.setState({ dataGridRows: rows });
    };

    New(e) {
        e.preventDefault();
        window.location.href = "/ControlIngreso/id/0";
        //this.props.history.push("/comercio/id/0");
    }

    Parametros(e) {
        e.preventDefault();
        window.location.href = "/parametrosCamaras";
    }

    Buscar(e) {

        if (e) {
            e.preventDefault();
        }


        var fecha = this.fecha.value
        var fecha_hasta = this.fecha_hasta.value

        if (fecha === "" || fecha_hasta === "") {
            alert(this.getText("msgValidaFechaVacia"));
            return;
        }
        let _fecha = moment(fecha);
        let _fecha_hasta = moment(fecha_hasta);

        if (_fecha > _fecha_hasta) {
            alert(this.getText("msgValidaFechas"));
            return;
        }

        var dias_maximos = _fecha_hasta.diff(_fecha, 'days');
        // console.log("dias: ", dias_maximos);
        if (dias_maximos > 31) {
            this.ShowAlert(this.getText("msgDiasFecha"), true);
            return;
        }

        let etapa = this.getEtapa();

        this.setState({ loading: true });
        this.servicio.List("", `control/etapa/repo/${etapa}/${_fecha.toDate().getTime()}/${_fecha_hasta.toDate().getTime()}`)
            .then(result => {
                if (result.codigo !== "0") {
                    alert(result.mensaje);
                    if (result.codigo === 401) {
                        this.CerarSesion();
                    }
                }
                else {
                    var rows = [];
                    var vigencia = JSON.parse(this.getOpcion("vigencia_tipo"));
                    var tipoTransporte = JSON.parse(this.getOpcion("TipoTransporte"));
                    var categoriaTransporte = JSON.parse(this.getOpcion("CategoriaTransporte"));
                    var categoriaIngreso = JSON.parse(this.getOpcion("CategoriaIngreso"));
                    // console.log(vigencia);
                    // console.log(categoriaTransporte);
                    const auditoria = result.data.auditoria;
                    // return;
                    for (let i = 0; i < auditoria.length; i++) {
                        let invitacion = auditoria[i];
                        //console.log(invitacion);
                        let dir;
                        if (invitacion.direccion != null) {
                            dir = JSON.parse(invitacion.direccion);
                        }
                        rows.push({
                            id: invitacion.id ? invitacion.id : "",
                            data: invitacion.data ? invitacion.data : "",
                            detalle: invitacion.detalle ? invitacion.detalle : "",
                            fecha_inicio: invitacion.fecha_inicio ? new Date(invitacion.fecha_inicio.replace("+0000", "Z")).toLocaleString() : "",
                            fecha_fin: invitacion.fecha_fin ? new Date(invitacion.fecha_fin.replace("+0000", "Z")).toLocaleString() : "",

                            manzana: dir ? dir.mz ? dir.mz : dir.manzana : "",
                            villa: dir ? dir.vl ? dir.vl : dir.villa : "",
                            departamento: dir ? dir.dp ? dir.dp : dir.departamento : "",

                            buscar: this.link(invitacion.id),
                            // salida: this.linkSalida(invitacion[0]),
                            nombre: invitacion.nombre ? invitacion.nombre : "",
                            tipo: invitacion.tipo ? vigencia.filter(v => v.key === invitacion.tipo)[0].value : "",
                            residente: invitacion.residente ? invitacion.residente : "",
                            nombre_residente: invitacion.nombre_residente ? invitacion.nombre_residente : "",
                            id_etapa: invitacion.id_etapa ? invitacion.id_etapa : "",
                            id_invitacion: invitacion.id_invitacion ? invitacion.id_invitacion : "",
                            fotos_ingreso: invitacion.fotos_ingreso ? invitacion.fotos_ingreso : "",
                            fotos_salida: invitacion.fotos_salida ? invitacion.fotos_salida : "",
                            placa: invitacion.placa ? invitacion.placa : "",
                            categoria_transporte: invitacion.categoria_transporte ? categoriaTransporte.filter(cat => cat.key === invitacion.categoria_transporte)[0].value : "",
                            nombre_conductor: invitacion.nombre_conductor ? invitacion.nombre_conductor : "",
                            novedad: invitacion.novedad ? invitacion.novedad : "",
                            tipo_transporte: invitacion.tipo_transporte ? tipoTransporte.filter(tip => tip.key === invitacion.tipo_transporte)[0].value : "",
                            categoria_ingreso: invitacion.categoria_ingreso ? categoriaIngreso.filter(c => c.key === invitacion.categoria_ingreso)[0].value : "",
                            usuario_ingreso: invitacion.usuario_ingreso ? invitacion.usuario_ingreso : "",
                            nombre_usuario_ingreso: invitacion.nombre_usuario_ingreso ? invitacion.nombre_usuario_ingreso : "",
                            usuario_salida: invitacion.usuario_salida ? invitacion.usuario_salida : "",
                            nombre_usuario_salida: invitacion.nombre_usuario_salida ? invitacion.nombre_usuario_salida : ""
                        });
                    }

                    this.setValue(rows);
                }
                this.setState({ loading: false });
                // console.log("lo que se guarda: ", this.state.dataGridRows);
            }
            )
            .catch(error => {
                console.log(error);
                this.setState({ loading: false });
            });

    }

    componentDidMount() {
        this.Buscar();
    }

    //Fucion para mostrar icono y Link
    link(id) {
        var link = [];
        link.push(
            <Link key={id} to={"/controlInvitadoR/id/" + id}>
                <h6>{this.getText("lblVer")}</h6>
            </Link>)
        return link[0]
    }

    //Fucion para mostrar icono y Link
    linkSalida(id) {
        var link = [];
        link.push(
            <Link key={id} to={"#"} onClick={(e) => this.registrarSalida(id, e)}>
                <h6>{this.getText("lblRegistrarSalida")}</h6>
            </Link>);

        return link[0]
    }

    registrarSalida = (id, e) => {
        e.preventDefault();

        var InvitadoControl;


        InvitadoControl = {
            id: id,
            usuario_modificacion: this.getUsuario()
        }

        this.servicio.save(InvitadoControl, "control/fechasalida")
            .then(result3 => {
                if (result3.codigo !== "0") {
                    alert(result3.mensaje);
                }
                else {
                    alert(this.getText("msgGuardadoOk"));
                    this.Buscar();
                }
                this.setState({ loading: false });
            }
            )
            .catch(error => {
                console.log(error);
                this.setState({ loading: false });
            });
    }

    DatosExport() {

        let headerXls = [
            this.getText("lblid"),
            this.getText("lblManzana"),
            this.getText("lblLote"),
            this.getText("lblDepartamento"),
            this.getText("lblData"),
            this.getText("lblDetalle"),
            this.getText("lblHoraIngreso"),
            this.getText("lblHoraSalida"),
            this.getText("lblIdResidente"),
            this.getText("lblNombres"),
            this.getText("lblMotivo"),
            this.getText("lblPlaca"),
            this.getText("lblTipo"),
            this.getText("lblEtapa"),
            this.getText("titInvitacion"),
            this.getText("lblFotosIngreso"),
            this.getText("lblFotosSalida"),
            this.getText("lblCategoriaTransporte"),
            this.getText("lblNombreConductor"),
            this.getText("lblNovedad"),
            this.getText("lblTipoTransporte"),
            this.getText("lblCategoriaIngreso"),
            this.getText("lblIdUsuarioIngreso"),
            this.getText("lblNombreUsuarioIngreso"),
            this.getText("lblIdUsuarioSalida"),
            this.getText("lblNombreUsuarioSalida"),
        ];
        const exportXlsFil = [], exportXls = [];

        exportXlsFil.push(headerXls);

        exportXls.push(headerXls);

        const dataexport = this.state.dataGridRows;


        dataexport.forEach((data) => {
            const dataArray = [
                data.id,
                data.manzana,
                data.villa,
                data.departamento,
                data.data,
                data.detalle,
                data.fecha_inicio,
                data.fecha_fin,
                data.residente,
                data.nombre_residente,
                data.nombre,
                data.placa,
                data.tipo,
                data.id_etapa,
                data.id_invitacion,
                data.fotos_ingreso,
                data.fotos_salida,
                data.categoria_transporte,
                data.nombre_conductor,
                data.novedad,
                data.tipo_transporte,
                data.categoria_ingreso,
                data.usuario_ingreso,
                data.nombre_usuario_ingreso,
                data.usuario_salida,
                data.nombre_usuario_salida,
            ]
            exportXls.push(dataArray)
        });

        const datosfitradoS = this.reactTable.gridTable.getResolvedState().sortedData;

        datosfitradoS.forEach((data) => {
            // console.log(data._original);
            const dataArrayf = [
                data._original.id,
                data._original.manzana,
                data._original.villa,
                data._original.departamento,
                data._original.data,
                data._original.detalle,
                data._original.fecha_inicio,
                data._original.fecha_fin,
                data._original.residente,
                data._original.nombre_residente,
                data._original.nombre,
                data._original.placa,
                data._original.tipo,
                data._original.id_etapa,
                data._original.id_invitacion,
                data._original.fotos_salida,
                data._original.fotos_ingreso,
                data._original.categoria_transporte,
                data._original.nombre_conductor,
                data._original.novedad,
                data._original.tipo_transporte,
                data._original.categoria_ingreso,
                data._original.usuario_ingreso,
                data._original.nombre_usuario_ingreso,
                data._original.usuario_salida,
                data._original.nombre_usuario_salida,
            ]
            exportXlsFil.push(dataArrayf)
        });
        this.setState({ filtrado: exportXlsFil, exportXLS: exportXls });

    }
    ShowAlert(mensaje, error) {
        this.setState({ alerta: true, mensaje: mensaje, error: error }, () => {
            window.setTimeout(() => {
                this.setState({ alerta: false, mensaje: '', error: false })
            }, 3000)
        });
    }

    render() {

        var date = moment().format('YYYY-MM-DD')

        //console.log(date);
        return (
            <div id="PantallaTableReport">
                <div className="mainContent">
                    <div className="contentBlock">

                        <Card >
                            <Alert
                                open={this.state.alerta}
                                mensaje={this.state.mensaje}
                                error={this.state.error}
                            />
                            <CardHeader tag="h5">
                                <Row  >
                                    <Col sm={8}>
                                        {this.getText("titControlInvitadosR")}
                                    </Col>

                                    {/* <Col >
                                        <Button className="float-right"
                                            onClick={(e) => this.New(e)}
                                            disabled={this.state.loading}
                                            value={this.getText("lblExportar")} />
                                    </Col> */}
                                    <Col >
                                        <UncontrolledButtonDropdown size='sm'
                                            onClick={(e) => this.DatosExport()}
                                        >
                                            <DropdownToggle caret>
                                                {this.getText("lblExportar")}
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                <ExportExcel
                                                    data={this.state.exportXLS}
                                                    filename={this.getText("titReporteCons")}
                                                    title={this.getText("lblTodos")}>

                                                </ExportExcel>
                                                <DropdownItem divider />
                                                <ExportExcel
                                                    data={this.state.filtrado ? this.state.filtrado : this.state.exportXLS}
                                                    filename={this.getText("titReporteCons")}
                                                    title={this.getText("lblDatosfiltrados")}>

                                                </ExportExcel>

                                            </DropdownMenu>
                                        </UncontrolledButtonDropdown>
                                    </Col>
                                    {/* <Col sm={2} >
                                        <Button className="float-right"
                                            onClick={(e) => this.Parametros(e)}
                                            disabled={this.state.loading}
                                            value={this.getText("lblParametros")} />
                                    </Col> */}


                                </Row>

                            </CardHeader>
                            <CardBody>
                                <Form inline>
                                    <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                        <Label for="dtpfecha" className="mr-sm-2">{this.getText("lblFechaDesde")}</Label>
                                        <Input
                                            type="date"
                                            name="date"
                                            id="dtpfecha"
                                            defaultValue={date}
                                            min="2000-01-01"
                                            max="2500-01-01"
                                            innerRef={(c) => this.fecha = c}
                                        />
                                    </FormGroup>
                                    <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                        <Label for="dtpfecha2" className="mr-sm-2">{this.getText("lblFechaHasta")}</Label>
                                        <Input
                                            type="date"
                                            name="date2"
                                            id="dtpfecha2"
                                            defaultValue={date}
                                            min="2000-01-01"
                                            max="2500-01-01"
                                            innerRef={(c) => this.fecha_hasta = c}
                                        />
                                    </FormGroup>
                                    <span className="css_buscar">
                                        <FontAwesomeIcon icon={faSearch} size="lg" onClick={(e) => this.Buscar(e)} />
                                    </span>

                                </Form>
                            </CardBody>
                        </Card>
                        <GridTable
                            ref={(r) => this.reactTable = r}
                            data={this.state.dataGridRows}
                            columns={this.state.dataGridColumns}
                            loading={this.state.loading} />
                    </div>
                </div>
            </div >
        )
    }
}
