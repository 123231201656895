import React from 'react';
import Base from '../../components/Base';
import { Col, Form, FormGroup, Table, Collapse, Modal, ModalHeader, ModalBody, } from 'reactstrap';
import Dropdown from "../../components/Dropdown";
import Text from "../../components/Text";
import './style.css';
import GeneralServices from '../../services/GeneralServices.js';
import Loader from '../../components/Loader';
import Return from '../../components/Return';
import { Link } from 'react-router-dom';
import Button from '../../components/Button';
import Img from '../../components/Img/Img';
import Carrusel from '../../components/Carrusel';

export default class Invitado extends Base {
    constructor(props) {
        super(props);
        this.InvitadoService = new GeneralServices();

        this.state =
        {
            loading: false,
            modal: false,
            index: 0
        };
        this.toggle = this.toggle.bind(this);
    }

    componentDidMount() {
        const id = this.props.match.params.id;
        this.InvitadoService.Id(id, 'invitacion/id')
            .then(result => {
                // console.log("resultado de lña consulta en invitados: ", result);
                if (result.codigo !== "0") {
                    alert(result.mensaje);
                    if (result.codigo === 401) {
                        this.CerarSesion();
                    }
                }
                else {
                    if (result.data === null) {
                        alert(this.getText("lblNoHayDatosId") + id);
                        window.location.href = "/Invitados";
                        //this.props.history.push('/Invitados');
                        return;
                    }
                    //console.log(result.data);
                    this.setState({ Invitado: result.data });
                    this.setState({ Invitados: this.state.Invitado.invitados })
                    this.getRef("cmbTipo").updateValue(result.data.tipo)
                }
            }
            )
            .catch(error => {
                console.log(error);
            });
    }

    toggle() {
        this.setState({
            modal: !this.state.modal,
            ControlIngreso: []
        });

    }

    index(i) {
        this.setState({
            index: i,
            ControlIngreso: []
        })
    }

    loadControlInvitacion(idInvitado) {
        // console.log("fuera");
        const id = this.props.match.params.id;
        this.InvitadoService.List("", `control/invitacion/${id}/${idInvitado}`).then(result => {
            // this.InvitadoService.List("", `control/invitacion/47/29`).then(result => {
            // console.log("resultado de invitacion, invitado: ", result.data);

            if (result.codigo !== "0") {
                return alert(result.mensaje);
            }
            if (result.data === null) {
                alert(this.getText("lblNoHayDatosId") + id);
                return;
            }

            this.setState({ ControlIngreso: result.data });


        }).catch(error => {
            console.log(error);
        });
    }

    render() {

        const roles = JSON.parse(localStorage.getItem("roles"));

        if (this.state.Invitado) {
            // let server = this.getOpcion('fileServer');
            // let url = server + this.state.Invitado.qr;

            let dir;
            if (this.state.Invitado.direccion != null) {
                dir = JSON.parse(this.state.Invitado.direccion);
            }
            return (
                <div className='formTitulo' >
                    <FormGroup row >
                        <Col md={10}>
                            <h5><Return onClick={() => this.props.history.go(-1)} />{this.getText("titInvitacion")}</h5>
                        </Col>
                    </FormGroup>
                    <div className='form'>
                        <Form >
                            <FormGroup row>
                                <Col >
                                    <Text
                                        label={this.getText("lblNombre")}
                                        id={'txt_nombres'}
                                        value={this.state.Invitado.nombre}
                                        type={'text'}
                                        disabled={true}
                                        ref={(ref) => this.setRef('txt_nombres', ref)}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Col>
                                    <Text
                                        label={this.getText("lblDetalle")}
                                        id={'txt_detalle'}
                                        value={this.state.Invitado.detalle}
                                        type={'text'}
                                        disabled={true}
                                        ref={(ref) => this.setRef('txt_detalle', ref)}
                                    />
                                </Col>
                            </FormGroup>

                            <FormGroup row >
                                <Col>
                                    <Dropdown
                                        id="cmbTipo"
                                        ref={(ref) => this.setRef('cmbTipo', ref)}
                                        label={this.getText("lblTipo")}
                                        disabled={true}
                                        value={JSON.parse(this.getOpcion("vigencia_tipo"))}
                                    />
                                </Col>
                            </FormGroup>

                            <FormGroup row >
                                <Col>
                                    <Text
                                        label={this.getText("lblFechaInicio")}
                                        id={'txt_fecha_inicio'}
                                        value={new Date(this.state.Invitado.fecha_inicio).toLocaleString()}
                                        type={'text'}
                                        disabled={true}
                                        ref={(ref) => this.setRef('txt_fecha_inicio', ref)}
                                    />
                                </Col>

                                <Col >
                                    <Text
                                        label={this.getText("lblFechaFin")}
                                        id={'txt_fecha_fin'}
                                        value={new Date(this.state.Invitado.fecha_fin).toLocaleString()}
                                        type={'text'}
                                        disabled={true}
                                        ref={(ref) => this.setRef('txt_fecha_fin', ref)}
                                    />
                                </Col>
                            </FormGroup>

                            <FormGroup row >
                                <Col >
                                    <Text
                                        label={this.getText("lblManzana")}
                                        id={'txt_mz'}
                                        value={dir ? dir.mz : ""}
                                        type={'text'}
                                        disabled={true}
                                        ref={(ref) => this.setRef('txt_mz', ref)}
                                    />
                                </Col>

                                <Col >
                                    <Text
                                        label={this.getText("lblLote")}
                                        id={'txt_vl'}
                                        value={dir ? dir.vl : ""}
                                        type={'text'}
                                        disabled={true}
                                        ref={(ref) => this.setRef('txt_vl', ref)}
                                    />
                                </Col>
                                <Col >
                                    <Text
                                        label={this.getText("lblDepartamento")}
                                        id={'txt_departamento'}
                                        value={dir ? dir.dp : ""}
                                        type={'text'}
                                        disabled={true}
                                        ref={(ref) => this.setRef('txt_departamento', ref)}
                                    />
                                </Col>
                            </FormGroup >
                            <Collapse isOpen={this.state.Invitado.invitados.length > 0 ? true : false}>
                                <FormGroup row >
                                    <Col md={10}>
                                        <h6>{this.getText("titInvitadosDetalle")}</h6>
                                    </Col>
                                </FormGroup>
                                <FormGroup row >
                                    <Col>
                                        <Table size="sm" responsive  >
                                            <thead>
                                                <tr>
                                                    <th>{this.getText("lblNombres")}</th>
                                                    <th>{this.getText("lblApellidos")}</th>
                                                    {(roles && roles[0].nombre != "GARITA") &&
                                                        <th>{this.getText("lblEmail")}</th>
                                                    }
                                                    <th>{this.getText("lblIdentificacion")}</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.Invitado.invitados.map((repo, index) => {
                                                        return (
                                                            <tr key={index} >
                                                                <td>{repo.nombres}</td>
                                                                <td>{repo.apellidos}</td>
                                                                {(roles && roles[0].nombre != "GARITA") &&
                                                                    <td>{repo.correo}</td>
                                                                }
                                                                <td>{repo.identificacion}</td>
                                                                <td>

                                                                    <Button
                                                                        className="tertiaryColorB"
                                                                        value="ver"
                                                                        onClick={() => {
                                                                            this.index(index);
                                                                            this.toggle();
                                                                            this.loadControlInvitacion(repo.id)
                                                                        }}
                                                                    />
                                                                </td>

                                                            </tr>
                                                        )
                                                    })

                                                }
                                            </tbody>
                                        </Table>
                                    </Col>
                                </FormGroup >
                            </Collapse>
                        </Form>
                    </div>
                    {(this.state.loading) ? <Loader /> : <span />}


                    {this.state.modal &&
                        <Modal isOpen={this.state.modal} toggle={this.toggle}>
                            <ModalHeader toggle={this.toggle}>{this.getText("lblTituloModalInvitados")}</ModalHeader>
                            <ModalBody>
                                {/* {this.body(this.state.Invitados, this.state.index)} */}
                                <div>
                                    <FormGroup row >
                                        {(this.state.Invitados) &&

                                            <Col >
                                                <Text
                                                    label={this.getText("lblNombres")}
                                                    id={'txt_nombresIn'}
                                                    value={this.state.Invitados[this.state.index].nombres + " " + this.state.Invitados[this.state.index].apellidos}
                                                    type={'text'}
                                                    disabled={true}
                                                    ref={(ref) => this.setRef('txt_nombresIn', ref)}
                                                />
                                                {(roles && roles[0].nombre != "GARITA") &&
                                                    <Text
                                                        label={this.getText("lblEmail")}
                                                        id={'txt_correo'}
                                                        value={this.state.Invitados[this.state.index].correo}
                                                        type={'text'}
                                                        disabled={true}
                                                        ref={(ref) => this.setRef('txt_correo', ref)}
                                                    />
                                                }
                                                <Text
                                                    label={this.getText("lblIdentificacion")}
                                                    id={'txt_identificacion'}
                                                    value={this.state.Invitados[this.state.index].identificacion}
                                                    type={'text'}
                                                    disabled={true}
                                                    ref={(ref) => this.setRef('txt_identificacion', ref)}
                                                />
                                            </Col>
                                        }
                                    </FormGroup >

                                    {this.state.ControlIngreso &&
                                        this.state.ControlIngreso.map((ingreso, i) => {

                                            const fotosIngreso = ingreso.fotosIngreso === null ? [] : JSON.parse(ingreso.fotosIngreso);

                                            const fotosSalida = ingreso.fotosSalida === null ? [] : JSON.parse(ingreso.fotosSalida);
                                            return (
                                                <div key={i}>

                                                    <h5>{this.getText("lblRegistro")} {i + 1}</h5>

                                                    <FormGroup row>
                                                        <Col>
                                                            <Text
                                                                label={this.getText("lblFechaIngresoI")}
                                                                id={'txt_fechaIngreso'}
                                                                value={ingreso.fechaIngreso ? new Date(ingreso.fechaIngreso).toLocaleString() : ""}
                                                                type={'text'}
                                                                disabled={true}
                                                                ref={(ref) => this.setRef('txt_fechaIngreso', ref)}
                                                            />
                                                        </Col>
                                                        <Col>
                                                            <Text
                                                                label={this.getText("lblFechaSalidaI")}
                                                                id={'txt_fechaSalida'}
                                                                value={ingreso.fechaSalida ? new Date(ingreso.fechaSalida).toLocaleString() : ""}
                                                                type={'text'}
                                                                disabled={true}
                                                                ref={(ref) => this.setRef('txt_fechaSalida', ref)}
                                                            />
                                                        </Col>
                                                    </FormGroup>
                                                    <h5>{this.getText("lblFotos")}</h5>
                                                    <FormGroup row >
                                                        <Col>
                                                            <p>{this.getText("lblFotosIngreso")}</p>
                                                            <Carrusel value={fotosIngreso} />
                                                            {
                                                                // fotosIngreso.map((fotos, i) => {
                                                                //     return (
                                                                //         // <Img key={i}
                                                                //         //     src={"https://cde.laprensa.e3.pe/ima/0/0/2/3/8/238082.jpg"}
                                                                //         //     alt={""}
                                                                //         // />
                                                                //         <Card key={i}>
                                                                //             <CardImg bottom width="100%" src={fotos.url} alt="Card image cap" />
                                                                //         </Card>
                                                                //     )
                                                                // })
                                                            }
                                                        </Col>

                                                        <Col>
                                                            <p>{this.getText("lblFotosSalida")}</p>
                                                            <Carrusel value={fotosSalida} />
                                                            {
                                                                // fotosSalida.map((fotos, i) => {
                                                                //     return (
                                                                //         // <Img key={i}
                                                                //         //     src={this.getOpcion("fileServer") + fotos.url}
                                                                //         //     alt={""}
                                                                //         // />
                                                                //         <Card key={i}>
                                                                //             <CardImg bottom width="100%" src={fotos.url} alt="Card image cap" />
                                                                //         </Card>
                                                                //     )
                                                                // })
                                                            }
                                                        </Col>

                                                    </FormGroup>
                                                </div>
                                            )
                                        })
                                    }

                                </div>
                            </ModalBody>

                        </Modal>

                    }
                </div>
            );
        }
        else {
            return (
                <div>
                    <Loader />
                </div>);
        }
    }
    // }
}