export default class BuzonModel {
    constructor(
        titulo,        
        estado,    
        id,
        idEtapa,
        detalle,
        tipo,
        url,    
        usuario_ingreso,
        usuario_modificacion
    ) {
        this.titulo = titulo;
        this.idEtapa = idEtapa;
        this.estado = estado;
        this.id = id;
        this.usuario_modificacion=usuario_modificacion;
        this.usuario_ingreso=usuario_ingreso;
        this.detalle = detalle;
        this.tipo = tipo;
        this.url = url;
    }

    route() {
        return "buzon";
    }
}