export default class SolicitudModel {
    constructor(
        id,
        titulo,
        tipo,
        detalle,
        categoria,
        fotos,
        estado,
        ubicacion,
        usuario_ingreso,
        fechaIngreso,
        fechaModificacion,
        usuario_modificacion,
        persona,
        idEtapa
    ) {
        this.id = id;
        this.titulo = titulo;
        this.tipo = tipo;
        this.detalle = detalle;
        this.persona = persona;
        this.categoria = categoria;
        this.fotos = fotos;
        this.ubicacion = ubicacion;
        this.estado = estado;
        this.usuario_ingreso = usuario_ingreso;
        this.fechaIngreso=fechaIngreso;
        this.usuario_modificacion = usuario_modificacion;
        this.fechaModificacion=fechaModificacion;
        this.idEtapa=idEtapa;
    }

    route() {
        return "caso";
    }
}