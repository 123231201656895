import React from 'react';
import Base from '../../components/Base';
import '../../config/global';
import { Spinner, FormGroup } from 'reactstrap';
import Home from "../Home";
import { Form } from 'reactstrap';
import Button from '../../components/Button';
import { imagenes } from '../../config/images.js';
import Text from "../../components/Text";
import './style.css';
import { validateForms, getText } from '../../libs/TBSUtil/index.js';
import LoginService from '../../services/LoginService.js';
import Usuario from '../../models/Usuario.js';
import CambiarClave from '../CambiarClave';
import cookies from 'react-cookies';


export default class Login extends Base {

    constructor(props) {
        super(props);

        this.login = this.login.bind(this);
        this.Reset = this.Reset.bind(this);
        this.validaRol = this.validaRol.bind(this);

        this.loginService = new LoginService();
        this.state = {
            logged: false,
            isSubmitting: false,
            opciones: false,
            resetPas: false,
            roles: []
        }
    }

    cargarOpciones() {
  
        this.loginService.list('', 'opcion')
            .then(result => {
                if (result.codigo !== "0") {
                    alert(result.mensaje);
                    if (result.codigo === 401) {
                        this.CerarSesion();
                    }
                }
                else {
                    global.opciones = result.data;
                    this.setState({ opciones: global.opciones });
                }
            }
            )
            .catch(error => {
                console.log(error);
            });
    }


    validaRol() {
        var array2 = this.state.roles;
        var array1 = JSON.parse(this.getOpcion("webRoles"));

        var intersection = array2.filter(function (e) {
            var cats = e.nombre.split(' ');
            return cats.filter(function (cat) {

                return array1.indexOf(cat) > -1;
            }).length > 0;

        });

        if (intersection.length <= 0) {
            alert("usuario no permitido");
            this.CerarSesion();
        }
        else {
            //var json_str = JSON.stringify(intersection[0].capacidades);
            cookies.save("capacidades", intersection[0].capacidades, { path: "/", maxAge: 10800 });
            // console.log(json_str);
            this.setState({ logged: true });
        }

    }

    componentWillMount() {
        try {
            let username = cookies.load('usuario');
            //SI LA COOKIE ESTA ACTIVA RUTEA AL HOME
            if (username !== undefined || username === "") {
                this.cargarOpciones();
                this.setState({ logged: true });
            }

        }
        catch (error) {
            console.log(error);
        }
    }

    Reset(event) {
        event.preventDefault();
        this.setState({ resetPas: true });
    }

    login(event) {
        event.preventDefault();
        let validar = validateForms(this.components)
        if (validar) {
            alert(validar);
            return;
        }

        let usuario = new Usuario();
        //Ejecuta Login contra el servidor
        usuario.nombre = this.getRef('txt_usuario').getValue();
        usuario.clave = this.getRef('txt_password').getValue();
        this.setState({ isSubmitting: true });
        this.loginService.login(usuario, `${usuario.route()}/login`)
            .then(result => {
                // console.log("resultado del login: ", result.data);
                if (result.codigo !== "0") {
                    alert(result.mensaje);
                    this.setState({ isSubmitting: false });
                }
                else {
                    // console.log(result.data);
                    let User = result.data.persona.nombres;
                    cookies.save("token", result.data.token.accessToken, { path: "/", maxAge: 10800 });
                    cookies.save("usuario", User, { path: "/", maxAge: 10800 });
                    cookies.save("idusuario", result.data.token.id_usuario, { path: "/", maxAge: 10800 });
                    cookies.save("etapa", result.data.persona.idEtapa, { path: "/", maxAge: 10800 });

                    localStorage.setItem('roles', JSON.stringify(result.data.roles));

                    if (Array.isArray(result.data.empresa)) {
                        localStorage.setItem('empresas', JSON.stringify(result.data.empresa));
                        localStorage.setItem('etapas', JSON.stringify(result.data.etapa));
                    }
                    else {

                        cookies.save("empresaDescripcion", result.data.empresa.nombre, { path: "/", maxAge: 10800 });
                        cookies.save("etapaDescripcion", result.data.etapa.nombre, { path: "/", maxAge: 10800 });
                    }


                    this.cargarOpciones();
                    setTimeout(() => {
                        this.setState({ roles: result.data.roles });
                        this.validaRol();
                        this.setState({ isSubmitting: false });
                    }, 4000);


                }

            }
            )
            .catch(error => {
                console.log(error);
                alert(this.getText("msgNoBE"));
                this.setState({ isSubmitting: false });
            });

    }

    render() {
        if (this.state.resetPas) {
            return (
                <CambiarClave />
            );
        }

        if (this.state.logged) {
            if (this.state.opciones) {
                return (
                    <Home body={this.props.body} />
                );
            }
            else {
                return (
                    <Spinner color="primary" />
                    // <Home body={<Spinner color="primary" />} />
                );
            }
        }
        else {
            return (
                <div className="limiter">
                    <div className="container-login">
                        <div className="wrap-login p-t-85 p-b-20">
                            <Form className="login-form100" onSubmit={this.login} >

                                <img
                                    src={imagenes.logo.logoCity2Live}
                                    alt={'City2Live'}
                                    id={''}
                                    className={'login-logo'}
                                    ref={(ref) => this.setRef('logo_login', ref)}
                                />

                                <FormGroup row>
                                    <Text
                                        label={''}
                                        id={'txt_usuario'}
                                        value=''
                                        type={'text'}
                                        className={'inputLogin'}
                                        placeholder={getText("lblUsuario")}
                                        isRequired={true}
                                        menssage={getText("msgCampoRequerido")}
                                        ref={(ref) => this.setRef('txt_usuario', ref)}
                                    />
                                </FormGroup>
                                <FormGroup row>
                                    <Text
                                        label={''}
                                        id={'txt_password'}
                                        type={'password'}
                                        value={''}
                                        className={'inputLogin'}
                                        placeholder={getText('lblPassword')}
                                        isRequired={true}
                                        menssage={getText("msgCampoRequerido")}
                                        ref={(ref) => this.setRef('txt_password', ref)}
                                    />
                                </FormGroup>
                                <FormGroup row>
                                    <div className="container-login-form-btn">
                                        <Button
                                            id={'btn_login'}
                                            ref={(ref) => this.setRef('btn_login', ref)}
                                            value={getText("lblLogin")}
                                            type={'submit'}
                                            className={'login-btn'}

                                        />
                                    </div>
                                    {
                                        this.state.isSubmitting ? <Spinner color="primary" /> : <span></span>
                                    }
                                </FormGroup>
                            </Form>
                        </div>
                        <a className="forgotPassword" href="#" onClick={this.Reset}>{getText("msgchangePassword")}</a>
                    </div>
                </div>
            )
        }

    }
}



