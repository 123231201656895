export const imagenes = {
    logo: {
      logoCC: require('../res/images/CiudadCeleste_Logo.png'),
      logoCity2Live: require('../res/images/logo-city2live.png'),
      bgLogin: require('../res/images/bgLogin.png'),
      file: require('../res/images/Files.png')
    }
    // },
    // iconos: {
    //     exit: require('../images/icons/exit-sign.png'),
    //     search:require('../images/icons/search_24.png')
    // },
    // avatar: require('../images/avatar.jpg'),
};
