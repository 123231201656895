// Componente base
import React from 'react';
import Base from '../Base';
import {  Label } from "reactstrap";

// Otros Componentes
import './style.css';


const ENTER_KEY = 13;
const COMMA_KEY = 188;
const BACKSPACE_KEY = 8;

export default class Tag extends Base {
  constructor(props) {
    super(props);
    this.state = { tags: this.props.value, value: "", disabled: this.props.disabled };

    this.handleChange = this.handleChange.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  handleChange(e) {
    this.setState({
      value: e.target.value
    });
  }

  handleKeyUp(e) {
    const key = e.keyCode;

    if (key === ENTER_KEY || key === COMMA_KEY) {
      this.addTag();
    }
  }

  handleKeyDown(e) {
    const key = e.keyCode;
    if (key === BACKSPACE_KEY && !this.state.value) {
      this.editPrevTag();
    }
  }

  addTag() {
    const { tags, value } = this.state;
    let tag = value.trim();

    tag = tag.replace(/,/g, "");

    if (!tag) {
      return;
    }

    let existe=tags.filter((dato) => dato === tag);

    if (existe.length>0) {
      alert("dato repetido");
      this.setState({value: ""});
      return;
    }
    
    this.setState({ tags: [...tags, tag], value: ""  });
  }

  getTags() {

    let tag =this.state.tags;

    tag.forEach(element => {
      console.log(element);
    });

    return tag;
}

  editPrevTag() {
    let { tags } = this.state;

    const tag = tags.pop();

    this.setState({ tags, value: tag });
  }

  render() {
    const { tags, value, disabled } = this.state;
    return (
      <div >
      <Label for="contenedor" >{this.props.label?this.props.label:this.getText("lblEtiquetas")}
                </Label>
      <div className="contenedor" id="contenedor" >
        <div className="tags">
          {/* <ul className="select2-selection__rendered"> */}
          <ul>
            {tags.map((tag, i) => (
              <li key={tag + i} className="tag" title= {tag}>
                
                {/* <span class="select2-selection__choice__remove" role="presentation">×</span> */}
                {tag}
              </li>
            ))}
          </ul>
          
        </div>
        <input
            type={this.props.type?this.props.type:"search"}
            placeholder="Agregar"
            value={value}
            onChange={this.handleChange}
            ref="tag"
            className="input"
            onKeyUp={this.handleKeyUp}
            onKeyDown={this.handleKeyDown}
            disabled={disabled}
          />
          <br></br>
        <small>
        Presione ENTER o , para agregar. Presione{" "}<code>retorno</code> para editar o eliminar.
        </small>
      </div>
      <br></br>
      </div>
    );
  }
}

