export default class OpcionModel {
    constructor(
        nombre,
        valor,
        estado,    
        id
    ) {
        this.nombre = nombre;
        this.valor = valor;
        this.estado = estado;
        this.id = id;

    }

    route() {
        return "opcion";
    }
}