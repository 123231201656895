// Componente base
import moment from 'moment';
import React from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Col, FormGroup, Label } from 'reactstrap';
import Base from '../../components/Base';
import Dropdown from "../../components/Dropdown";
import Loader from '../../components/Loader';
import ReservaModel from '../../models/ReservaModel.js';
import GeneralServices from '../../services/GeneralServices.js';
import Espacio from './Espacio.js';
import './styles.css';

const localizer = momentLocalizer(moment);

export default class ReservaEspacioList extends Base {

    constructor(props) {
        super(props);
        this.servicio = new GeneralServices();
        this.changeEspacio = this.changeEspacio.bind(this);
        this.addEvent = this.addEvent.bind(this);
        this.Close = this.Close.bind(this);

        const now = new Date();
        const minTime = now;
        minTime.setHours(0, 0, 0);

        const maxTime = new Date();
        maxTime.setHours(0, 0, 0);


        this.state = {
            culture: 'es',
            events: [],
            espacio: null,
            seleccionar: false,
            view: "month",
            month: now.getMonth(),
            dia: moment().format("ddd").substring(0, 3),
            diaSeleccionado: null,
            hInicio: minTime,
            hFin: maxTime,
            open: false,
            reserva: null,
            cobro: null,
            costo: 0,
            step: 360,
            fechaSeleccionada: null
        }

    };

    changeEspacio(event) {
        
        this.setState({ view: "month" });
        let val = event.target.value;
        this.getRef("cmbEspacio").updateValue(val);
        this.getRef("cmbEspacio").getTexto(val);
        let espacio = this.state.espacio.filter(dato => {
            return dato.key === val;
        }
        );
        let fecha = this.getRef("calendar").props.date;
        this.CargaReservas(val, fecha.getTime());

        let step = this.state.step;
        if (espacio[0].tipoPeriodo === "HOR") {
            step = espacio[0].periodo * 60;
        }
        else {
            step = espacio[0].periodo;
        }
        //console.log(this.state.reserva);
        this.setState({ horario: espacio[0].horario, step: step, costo: espacio[0].valor });

    }

    Close() {
        this.setState(({ open }) => ({ open: !open }));
    }

    componentDidMount() {
        this.servicio.List(this.getEtapa(), 'espacio/etapa')
            .then(result => {
                if (result.codigo !== "0") {
                    alert(result.mensaje);
                    if (result.codigo === 401) {
                        this.CerarSesion();
                    }
                }
                else {
                    //console.log(result.data);
                    var rows = [];
                    for (let i = 0; i < result.data.length; i++) {
                        if (result.data[i].estado !== 'I') {
                            const horario = JSON.parse(result.data[i].horario)
                            rows.push({
                                key: result.data[i].id.toString(),
                                value: result.data[i].nombre,
                                valor: (result.data[i].valor === null ? 0 : result.data[i].valor),
                                // periodo: result.data[i].periodo,
                                periodo: horario.horarios,
                                tipoPeriodo: result.data[i].tipoPeriodo,
                                // horario: JSON.parse(result.data[i].horario)
                                horario: horario
                            });
                        }
                    }
                    if (rows.length > 0) {
                        let _fecha = moment();

                        this.CargaReservas(rows[0].key, _fecha.toDate().getTime());
                        let step = this.state.step;
                        if (rows[0].tipoPeriodo === "HOR") {
                            // step = rows[0].periodo;
                            step = 15;
                        }
                        else {
                            // step = rows[0].periodo;
                            step = rows[0].horario[0].horarios[0].periodo;
                        }

                        this.setState({
                            espacio: rows,
                            costo: (result.data[0].valor === null ? 0 : result.data[0].valor),
                            horario: rows[0].horario,
                            step: step
                        });

                    }
                    else {
                        alert(this.getText("msgCrearEspacio"));
                        window.location.href = "/espacio/id/0";
                        //this.props.history.push("/espacio/id/0");
                        return;
                    }
                }
            }
            )
            .catch(error => {
                console.log(error);
            });
    }

    CargaReservas(id, _fecha) {
        this.setState({ loading: true });

        this.servicio.List('', `reserva/espacio/${id}/${_fecha}`)
            .then(result => {
                if (result.codigo !== "0") {
                    alert(result.mensaje);
                    if (result.codigo === 401) {
                        this.CerarSesion();
                    }
                }
                else {
                    var events = [];
                    for (let i = 0; i < result.data.length; i++) {
                        events.push({
                            id: result.data[i].id,
                            title: result.data[i].detalle,
                            start: new Date(result.data[i].fechaInicio),
                            end: new Date(result.data[i].fechaFin),
                            hexColor1: result.data[i].estado === 'A' ? '#B077FE' : '#E68483',
                            hexColor2: result.data[i].estado === 'A' ? '#8EB7FB' : '#F8C776',
                        });
                    }
                    this.setState({ events: events });
                }
                this.setState({ loading: false });
            }
            )
            .catch(error => {
                console.log(error);
                this.setState({ loading: false });
            });

    }

    eventStyleGetter = (event, start, end, isSelected) => {

        var style = {
            backgroundImage: `linear-gradient(to right, ${event.hexColor1}, ${event.hexColor2})`,
            borderRadius: '0px',
            fontSize: '0.9rem',
            // opacity: 0.8,
            border: '0px',
            width: '101%',
            display: 'block'
        };
        return {
            style: style
        };
    }

    handleView = (view) => {
        this.cambiaDia(view, this.state.dia);
    }

    cambiaDia(view, dia_letra) {

        var selecion = this.state.seleccionar;

        if (view === 'day' && this.state.horario.length > 0) {

            let dia = this.state.horario.filter(dato => {
               
                return dato.id === dia_letra;
            }
            );
            if (dia.length <= 0) {
                return;
            }
            dia[0].horarios.map(hora => {
                var res_ini = hora.ini.split(":");
                if (res_ini.length === 2) {
                    this.state.hInicio.setHours(res_ini[0], res_ini[1], 0, 0);
                }
                else {
                    this.state.hInicio.setHours(0, 0, 0, 0);
                }
            })

            dia[0].horarios.map(hora => {
                var res_fin = hora.fin.split(":");
                if (res_fin.length === 2) {
                    this.state.hFin.setHours(res_fin[0], res_fin[1], 0, 0);
                }
                else {
                    this.state.hFin.setHours(0, 0, 0, 0);
                }
            })
            selecion = dia[0].val;

        }
        else {
            selecion = false;
        }
        this.setState({ diaSeleccionado: dia_letra, seleccionar: selecion, view: view });


    }

    Navegar = (date, view, action) => {

        this.setState({ fechaSeleccionada: date });
        if (this.state.month !== date.getMonth()) {
            this.CargaReservas(this.getRef("cmbEspacio").getValue(), date.getTime());
            this.setState({ month: date.getMonth() });
        }

        if (view === 'day') {
            this.cambiaDia(view, moment(date).format("ddd").substring(0, 3));

        }
        else {
            if (action === 'DATE') {
                this.cambiaDia("day", moment(date).format("ddd").substring(0, 3));
            }

        }
    }

    handleSelecting = (event) => {
        this.consultaEvento(event);

    }

    consultaEvento(event) {
        let reserva = new ReservaModel();
        reserva.id = event.id;
        //await this.getRef("cmbEspacio").forceUpdate();
        //console.log(this.getRef("cmbEspacio"))
        reserva.espacioNombre = this.getRef("cmbEspacio").getDescripcion();
        reserva.espacio = this.getRef("cmbEspacio").getValue();
        reserva.valor = this.state.costo;

        this.servicio.Id(reserva.id, reserva.route() + '/id')
            .then(result => {
                if (result.codigo !== "0") {
                    alert(result.mensaje);
                    if (result.codigo === 401) {
                        this.CerarSesion();
                    }
                }
                else {
                    //console.log(result.data);
                    reserva.estado = result.data.reserva.estado;
                    reserva.fechaIngreso = result.data.reserva.fechaIngreso;
                    reserva.persona = result.data.reserva.persona;
                    reserva.ubicacion = result.data.reserva.ubicacion;
                    reserva.detalle = event.title;
                    reserva.fechaInicio = event.start;
                    reserva.fechaFin = event.end;
                    reserva.usuario_ingreso = result.data.reserva.usuario_ingreso;

                    let cobro = (result.data.cobro === undefined ? null : result.data.cobro);

                    this.setState({ open: true, reserva: reserva, cobro: cobro });
                }
            }
            )
            .catch(error => {
                console.log(error);
            });
    }

    handleSelect = ({ start, end, slots }) => {
        
        //si es el mismo horario no permite seleccionar
        if (Object.is(start.getTime(), end.getTime())) {
            return;
        }

        let eventos = this.state.events.filter(dato => {
            return dato.start.getDate() === start.getDate();
        });

        let espacio = eventos.filter(dato => {
            return Object.is(dato.start.getTime(), start.getTime());
        });
        if (espacio.length > 0) {
            //console.log(espacio[0]);
            this.consultaEvento(espacio[0]);
            return;
        }

        for (var i = 1; i < slots.length; i++) {
            var obj = slots[i];

            for (var j = 0; j < eventos.length; j++) {
                if (Object.is(obj.getTime(), eventos[j].end.getTime())) {
                    return;
                }
            }
        }


        let reserva = new ReservaModel();
        reserva.id = 0;
        // await this.getRef("cmbEspacio").forceUpdate();
        //console.log(this.getRef("cmbEspacio"))
        reserva.espacioNombre = this.getRef("cmbEspacio").getDescripcion();
        reserva.espacio = this.getRef("cmbEspacio").getValue();
        reserva.fechaInicio = start;
        reserva.fechaFin = end;
        reserva.estado = 'A';
        reserva.valor = this.state.costo;
        // usuario_ingreso,
        // usuario_modificacion

        this.setState({ open: true, reserva: reserva });

    }

    addEvent(dato) {

        this.CargaReservas(dato.espacio, dato.fechaInicio.getTime());
        this.Close();
    }

    render() {
        if (this.state.espacio) {
            return (
                <div>
                    <div className="mainContent">
                        <div className="contentBlock" >
                            <FormGroup row >
                                <Col md={3}>
                                    <Label>
                                        {this.getText("titEspaciosReserva")}
                                    </Label>
                                </Col>
                                <Col md={9} >

                                    <Dropdown
                                        id="cmbEspacio"
                                        ref={(ref) => this.setRef('cmbEspacio', ref)}
                                        value={this.state.espacio}
                                        onChange={this.changeEspacio}
                                        disabled={(this.state.view == "day") ? true : false}
                                    />
                                </Col>
                            </FormGroup>
                            <div className={this.state.loading ? 'filtering' : null}>
                                {(this.state.view == 'month') ? (
                                    <FormGroup style={{ height: '450pt' }}>
                                        <Calendar
                                            min={this.state.hInicio}
                                            max={this.state.hFin}
                                            popup
                                            events={this.state.events}
                                            culture={this.state.culture}
                                            defaultDate={new Date()}
                                            localizer={localizer}
                                            step={Number(this.state.step)}
                                            timeslots={1}
                                            selectable={this.state.seleccionar}
                                            views={{ month: true, day: true }}
                                            ref={(ref) => this.setRef('calendar', ref)}
                                            // defaultView={Views.DAY}
                                            onSelectSlot={this.handleSelect}
                                            onNavigate={this.Navegar}
                                            onView={this.handleView}
                                            eventPropGetter={this.eventStyleGetter}
                                            onSelectEvent={this.handleSelecting}
                                            //onSelecting={this.handleOnSelecting}
                                            messages={{
                                                next: this.getText("lblSiguiente"),
                                                previous: this.getText("lblAnterior"),
                                                today: this.getText("lblHoy"),
                                                month: this.getText("lblMes"),
                                                day: this.getText("lblDia"),
                                                showMore: total => `+${total} ${this.getText("lblVer")}`
                                            }}
                                        />
                                    </FormGroup>)
                                    : this.state.horario.map(horari => {
                                        if (horari.id === this.state.diaSeleccionado) {
                                            // console.log("en el componente: ", horari);
                                            return horari.horarios.map(hora => {
                                                var res_Inicio = hora.ini.split(":");
                                                var HInicio = new Date();
                                                if (res_Inicio.length === 2) {
                                                    HInicio.setHours(res_Inicio[0], res_Inicio[1], 0, 0);
                                                }
                                                else {
                                                    HInicio.setHours(0, 0, 0, 0);
                                                }

                                                var res_fin = hora.fin.split(":");
                                                var HFin = new Date();
                                                if (res_fin.length === 2) {
                                                    HFin.setHours(res_fin[0], res_fin[1], 0, 0);
                                                }
                                                else {
                                                    HFin.setHours(0, 0, 0, 0);
                                                }
                                                return (<FormGroup style={{ height: '450pt' }} key={hora.id}>
                                                    <Calendar
                                                        min={HInicio}
                                                        max={HFin}
                                                        popup
                                                        events={this.state.events}
                                                        culture={this.state.culture}
                                                        defaultDate={this.state.fechaSeleccionada}
                                                        localizer={localizer}
                                                        step={Number(hora.periodo)}
                                                        timeslots={1}
                                                        selectable={this.state.seleccionar}
                                                        views={{ month: true, day: true }}
                                                        //ref={(ref) => this.setRef('calendar_' + hora.id, ref)}
                                                        // defaultView={Views.DAY}
                                                        onSelectSlot={this.handleSelect}
                                                        onNavigate={this.Navegar}
                                                        onView={this.handleView}
                                                        eventPropGetter={this.eventStyleGetter}
                                                        onSelectEvent={this.handleSelecting}
                                                        view={this.state.view}
                                                        //onSelecting={this.handleOnSelecting}
                                                        messages={{
                                                            next: this.getText("lblSiguiente"),
                                                            previous: this.getText("lblAnterior"),
                                                            today: this.getText("lblHoy"),
                                                            month: this.getText("lblMes"),
                                                            day: this.getText("lblDia"),
                                                            showMore: total => `+${total} ${this.getText("lblVer")}`
                                                        }}
                                                    />
                                                </FormGroup>
                                                )
                                            })
                                        }
                                    })




                                }

                            </div>
                            <Espacio
                                open={this.state.open}
                                Close={this.Close}
                                addEvent={this.addEvent}
                                value={this.state.reserva}
                                cobro={this.state.cobro}
                            />
                        </div>
                    </div>
                </div>
            )
        }
        else {
            return (
                <div>
                    <Loader />
                </div>);
        }

    }
}
