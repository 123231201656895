import React from 'react';
import Base from '../../components/Base';
import GeneralServices from '../../services/GeneralServices.js';
import Loader from '../../components/Loader'
import {
    Col, Form, FormGroup, Label, CustomInput, Collapse, Table, Row
} from 'reactstrap';
import Button from '../../components/Button';
import Alert from '../../components/Alert';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons'
import './style.css';
import PageContainer from '../../components/PageContainer';
import Return from '../../components/Return';

export default class PersonaImport extends Base {

    constructor(props) {
        super(props);
        this.personaService = new GeneralServices();

        this.state =
        {
            estado: '',
            loading: false,
            open: false,
            alerta: false,
            mensaje: '',
            error: false,
            detalle: [],
            body: '',
            enviaCorreo: false,
            label: this.getText("lblSeleccionar")
        };

    }

    ShowAlert(mensaje, error) {
        this.setState({ alerta: true, mensaje: mensaje, error: error });
    }

    fileSelectedHandler = e => {
        let file = [...e.target.files];
        this.setState({ alerta: false, mensaje: '', error: false, detalle: [], ver: false });
        this.setState({ file: file[0], label: file[0].name });
    }

    Descargar(e) {
        e.preventDefault();
        let server = this.getOpcion('fileServer');

        setTimeout(() => {
            const response = {
                file: server + '/files/plantillas/CargaResidentes.xlsx',
            };
            window.open(response.file);
        }, 70);
    }

    Importar(e) {
        e.preventDefault();
        let formData = new FormData();
        formData.append('file', this.state.file);

        if (this.state.file === undefined) {
            this.ShowAlert(this.getText("lblSeleccionarArchivo"), true);
            return;
        }


        if (this.state.file.name !== "CargaResidentes.xlsx") {
            this.archivo.value = "";
            this.setState({ label: this.getText("lblSeleccionar") });
            this.ShowAlert(this.getText("lblArchivoIncorrecto"), true);
            return;
        }

        let correo = this.getRef('txt_detalle').getValue();

        console.log(this.chkMail.checked);
        console.log(correo);

        if (this.chkMail.checked && correo === "<p></p>") {
            this.ShowAlert(`${this.getText("msgCampoRequerido")} ${this.getText("lblDetalle")}`, true);
            return;
        }
        else {

            if (!this.chkMail.checked) {
                if (!window.confirm(this.getText("msgEnviarCorreo"))) {
                    return;
                }
            }
            this.archivo.value = "";
            this.setState({ label: this.getText("lblSeleccionar") });

            formData.append('mensaje', this.chkMail.checked ? correo : "");
        }


        this.setState({ loading: true });
        this.personaService.Upload(formData, 'persona/import/' + this.getEtapa())
            .then(result => {
                //console.log("el resultado: " + result);
                if (result.codigo !== "0") {
                    alert(result.mensaje);
                    if (result.codigo === 401) {
                        this.CerarSesion();
                    }
                }
                else {

                    if (result.data.error > 0) {

                        this.setState({ detalle: result.data.detalleError, ver: true });
                        let mensaje = this.getText("msgErrorres") + result.data.error + "\n" +
                            this.getText("msgProcesados") + result.data.correcto;
                        this.ShowAlert(mensaje, true);
                    }
                    else {
                        this.ShowAlert(this.getText("msgProcesados") + result.data.correcto, false);

                    }
                    console.log(result);
                }
                this.setState({ loading: false });
            }
            )
            .catch(error => {
                console.log(error);
                this.setState({ loading: false });
            });
    }

    changeCorreo = e => {
        this.setState({ enviaCorreo: e.target.checked, body: '' });
        this.getRef('txt_detalle').reset();
    }

    render() {

        let texto = this.getOpcion("AdvertenciaResidenteMasivo");
        texto = JSON.parse(texto);

        // console.log("el texto: ", texto);
        return (
            <div className='formTitulo' >

                <FormGroup row >
                    <Col md={9}>
                        <h5><Return onClick={() => this.props.history.go(-1)} />
                            {this.getText("titCargarResidente")}</h5>
                    </Col >

                    <Col md={3} className="text-right" >
                        <Button type="submit"
                            onClick={(e) => this.Importar(e)}
                            className="tertiaryColorB"
                            value={this.getText("lblGuardar")} />
                    </Col>
                </FormGroup>
                <div className='form'>
                    {texto &&
                        <>
                            <Label>{texto.titulo}</Label>
                            {
                                texto.contenido.map(text => (
                                    <Label key={text.key}>- {text.value}</Label>
                                ))
                            }
                        </>
                    }

                    <br />
                    <br />
                    <Form encType="multipart/form-data" >
                        <FormGroup row>
                            <Col>
                                <Row>
                                    <Col md={9}>
                                        <Label for="files"  >{this.getText("lblCargarArchivo")}</Label>
                                    </Col>
                                    <Col md={3} className="text-right">
                                        <Button id="descarga"
                                            value={this.getText("lblDescargarArchivo")}
                                            icono={faFileExcel}
                                            outline={true}
                                            size={"sm"}
                                            colorButton="success"
                                            onClick={(e) => this.Descargar(e)}>
                                        </Button>
                                    </Col>
                                </Row>
                                <CustomInput type="file"
                                    id="files"
                                    name="customFile"
                                    label={this.state.label}
                                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                    onChange={this.fileSelectedHandler}
                                    innerRef={(c) => this.archivo = c}
                                    disabled={this.state.loading} />

                            </Col>
                        </FormGroup>
                        <Row>
                            <Col  >
                                <CustomInput
                                    type={"switch"}
                                    id="chk_mail"
                                    innerRef={(c) => this.chkMail = c}
                                    onChange={this.changeCorreo}
                                    label={this.getText("lblEmviarCorreo")} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <PageContainer
                                    value={this.state.body}
                                    id={'txt_detalle'}
                                    ref={(ref) => this.setRef('txt_detalle', ref)}
                                    disabled={!this.state.enviaCorreo}

                                />
                            </Col>
                        </Row>

                        <Alert
                            open={this.state.alerta}
                            mensaje={this.state.mensaje}
                            error={this.state.error}
                        />
                        <Collapse isOpen={this.state.ver}>
                            <FormGroup row >
                                <Col>
                                    <Table size="sm" bordered  >
                                        <thead>
                                            <tr>
                                                <th>{this.getText("lblNombres")}</th>
                                                <th>{this.getText("lblApellidos")}</th>
                                                <th>{this.getText("lblEmail")}</th>
                                                <th>{this.getText("lblIdentificacion")}</th>
                                                <th>{this.getText("lblObservacion")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.detalle.map((det, index) => {
                                                    return (
                                                        <tr key={index} >
                                                            <td>{det.primerNombre}</td>
                                                            <td>{det.primerApellido}</td>
                                                            <td>{det.correoCliente}</td>
                                                            <td>{det.identificacion}</td>
                                                            <td>{det.observacion}</td>
                                                        </tr>
                                                    )
                                                })

                                            }
                                        </tbody>
                                    </Table>
                                </Col>
                            </FormGroup >
                        </Collapse>
                        {/* </Col>
                    <Col md={6}> */}


                        {/* </Col>
                    </FormGroup> */}
                    </Form>

                </div>
                {(this.state.loading) ? <Loader>{this.getText("lblCargando")}</Loader> : <span></span>}
            </div>
        )
    }

}

