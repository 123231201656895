// Componente base
import matchSorter from 'match-sorter';
import React from 'react';
import { Link } from 'react-router-dom';
import Base from '../../components/Base';
import Button from '../../components/Button';
import GridTable from '../../components/GridTable';
import Loader from '../../components/Loader';
import GeneralServices from '../../services/GeneralServices.js';
import './styles.css';

export default class ComerciosList extends Base {

    constructor(props) {
        super(props);
        this.servicio = new GeneralServices();

        this.state = {
            dataGridRows: [],
            dataGridColumns: []
        }
    };

    setValue(rows) {
        this.setState({ dataGridRows: rows });
    };

    New(e) {
        e.preventDefault();
        window.location.href = "/comercio/id/0";
        //this.props.history.push("/comercio/id/0");
    }

    SetColumns(categorias) {
        let tipodata = categorias;
        // console.log(tipodata);
        var columns = [
            {


                Header: this.getText("titComercios"),
                headerStyle: { textAlign: "left" },
                columns: [
                    this.ColumnaId(),

                    {
                        Header: this.getText("lblNombre"),
                        id: "nombre",
                        width: 250,
                        accessor: d => d.nombre,
                        filterMethod: (filter, rows) =>
                            matchSorter(rows, filter.value, { keys: ["nombre"] }),
                        filterAll: true
                    },
                    {
                        Header: this.getText("lblHorarioAtencion"),
                        id: "horario",
                        width: 180,
                        accessor: d => d.horario,
                        filterMethod: (filter, rows) =>
                            matchSorter(rows, filter.value, { keys: ["horario"] }),
                        filterAll: true
                    },
                    {
                        Header: this.getText("lblDireccion"),
                        id: "direccion",
                        width: 220,
                        accessor: d => d.direccion,
                        filterMethod: (filter, rows) =>
                            matchSorter(rows, filter.value, { keys: ["direccion"] }),
                        filterAll: true
                    },

                    {
                        Header: this.getText("lblCategoria"),
                        accessor: "tipo",
                        id: "tipo",
                        filterMethod: (filter, row) => {
                            if (filter.value === "all") {
                                return true;
                            }
                            else {
                                return row[filter.id] === filter.value;
                            }

                        },
                        Filter: ({ filter, onChange }) =>
                            <select
                                onChange={event => onChange(event.target.value)}
                                style={{ width: "100%" }}
                                value={filter ? filter.value : "all"}
                            >
                                <option value="all">{this.getText("lblTodos")}</option>
                                {
                                    tipodata.map(k => {
                                        return <option key={k.key} value={k.value}>{k.value}</option>
                                    })
                                }
                            </select>
                    },
                    {
                        Header: this.getText("lblSolicitudes"),
                        // id: "solicitudes",
                        width: 150,
                        accessor: "solicitud",
                        style: { textAlign: "center" },
                        // filterMethod: (filter, rows) =>
                        //     matchSorter(rows, filter.value, { keys: ["direccion"] }),
                        // filterAll: true
                    },
                    {
                        Header: this.getText("lblEstado"),
                        accessor: "estado",
                        width: 80,
                    }

                ]
            },
            {
                Header: null,
                // <Button className="tertiaryColorB" onClick={(e) => this.New(e)} value={this.getText("lblNuevo")} />,
                columns: [
                    {
                        Header: "",
                        accessor: "ver",
                        filterable: false,
                        sortable: false,
                        style: { textAlign: "center" },
                        width: 80
                    },
                    // {
                    //     Header: "",
                    //     accessor: "buscar",
                    //     filterable: false,
                    //     sortable: false,
                    //     style: { textAlign: "center" },
                    //     width: 80
                    // },
                    // {
                    //     Header: "",
                    //     accessor: "etapa",
                    //     filterable: false,
                    //     sortable: false,
                    //     width: 80,
                    //     style: { textAlign: "center" }
                    // }

                ]
            }
        ];
        this.setState({
            dataGridColumns: columns
        });
    }

    componentDidMount() {

        this.servicio.List("", 'categoriacomercio')
            .then(result => {
                if (result.codigo !== "0") {
                    alert(result.mensaje);
                    if (result.codigo === 401) {
                        this.CerarSesion();
                    }
                }
                else {
                    var rows = [];

                    for (let i = 0; i < result.data.length; i++) {
                        if (result.data[i].estado !== 'I') {
                            rows.push({
                                key: result.data[i].id.toString(),
                                value: result.data[i].nombre
                            });
                        }
                    }
                    this.SetColumns(rows);
                    this.CargaDatos(rows);
                }
            }
            )
            .catch(error => {
                console.log(error);
            });

    }

    CargaDatos(categoria) {
        // etapa/40
        let etapa = this.getEtapa();
        // this.servicio.List("", 'comercio')
        this.servicio.List(etapa, 'comercio/etapa')
            .then(result => {
                if (result.codigo !== "0") {
                    alert(result.mensaje);
                    if (result.codigo === 401) {
                        this.CerarSesion();
                    }
                }
                else {

                    // console.log("lista de comercios: " + JSON.stringify(result.data))
                    // console.log(categoria);
                    var rows = [];
                    for (let i = 0; i < result.data.length; i++) {
                        // console.log(categoria.filter(tipo => parseInt(tipo.key) === result.data[i].idCategoria));
                        rows.push({
                            id: result.data[i].id,
                            fecha: result.data[i].fechaIngreso ? new Date(result.data[i].fechaIngreso).toLocaleDateString() : "",
                            nombre: result.data[i].nombre,
                            horario: result.data[i].horario,
                            direccion: result.data[i].direccion,
                            tipo: result.data[i].idCategoria ? categoria.filter(tipo => parseInt(tipo.key) === result.data[i].idCategoria)[0].value : "",
                            estado: result.data[i].estado,
                            solicitud: (result.data[i].estado == "A") ? this.getText("lblAprobada") : (result.data[i].estado == "R") ? this.getText("lblRechazado") : this.linkRevisarSolicitud(result.data[i].id),
                            // buscar: this.link(result.data[i].id),
                            // etapa: this.linkEtapa(result.data[i].id),
                            ver: (result.data[i].estado === "S") ? null : this.linkVer(result.data[i].id)
                        });
                    }
                    this.setValue(rows.sort((a, b) => {
                        if (a.estado < b.estado) {
                            return 1;
                        }
                        if (a.estado > b.estado) {
                            return -1;
                        }
                        // a must be equal to b
                        return 0;
                    }));
                }

            }
            )
            .catch(error => {
                console.log(error);
            });
    }
    //Fucion para mostrar icono y Link
    link(id) {
        var link = [];
        link.push(
            <Link key={id} to={"/comercio/id/" + id}>
                <h6>{this.getText("lblEditar")}</h6>
            </Link>)
        return link[0]
    }

    linkVer(id) {
        var link = [];
        link.push(
            <Link key={id} to={"/comercio/id/" + id}>
                <h6>{this.getText("lblVer")}</h6>
            </Link>)
        return link[0]
    }

    //Fucion para mostrar icono y Link
    linkEtapa(id) {
        var link = [];
        link.push(
            <Link key={id} to={"/EtapasComercio/id/" + id}>
                <h6>{this.getText("lblEtapa")}</h6>
            </Link>)
        return link[0]
    }


    //Fucion para mostrar icono y Link
    linkRevisarSolicitud(id) {
        var link = [];
        link.push(
            <Link key={id} to={"/comercio/id/" + id + "/" + true}>
                <h6>{this.getText("lblRevisarSolicitud")}</h6>
            </Link>)
        return link[0]
    }

    render() {
        if (this.state.dataGridColumns.length > 0) {


            return (
                <div>
                    <div className="mainContent">
                        <div className="contentBlock">
                            <GridTable
                                data={this.state.dataGridRows}
                                columns={this.state.dataGridColumns} />
                        </div>
                    </div>
                </div>
            )
        }
        else {
            return (
                <div>
                    <Loader />
                </div>);
        }
    }
}
