// Componente base
import React from 'react';
import { Link } from 'react-router-dom'
import './style.css';
import Base from '../Base';
import { Collapse, Navbar, NavbarBrand, Nav, NavLink } from 'reactstrap';
import cookies from 'react-cookies';

export default class Menu extends Base {

    constructor(props) {
        super(props);
        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            value: this.props.value,
            collapsed: true
        }
    };

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    render() {
        var capacidades = cookies.load('capacidades');

        let rol = JSON.parse(localStorage.getItem('roles'));
        const rol_gar = rol.filter((dato) => dato.nombre === 'GARITA');
        return (
            <Navbar className="menu secondaryColorB" >
                <NavbarBrand className="primaryColor" >City2Live</NavbarBrand>
                <Collapse isOpen={true} navbar>
                    <Nav vertical navbar >
                        {rol_gar ? <div></div> :
                            <NavLink className="secondaryTextColor" key={0}
                                tag={Link} to={"/"} >{this.getText("titInicio")}</NavLink>


                        }

                        {

                            capacidades.map((cap) => {
                                if (cap.nombre == "BandejaEntrada") {
                                    return (
                                        <NavLink className="secondaryTextColor" key={cap.id}
                                            tag={Link} to={"/"} >{this.getText("tit" + cap.nombre)}</NavLink>
                                    )
                                }
                            })
                            

                        }
                        {
                            capacidades.map((cap) => {
                                if (cap.nombre != "BandejaEntrada") {
                                    return (
                                        <NavLink className="secondaryTextColor" key={cap.id}
                                            tag={Link} to={"/" + cap.nombre} >{this.getText("tit" + cap.nombre)}</NavLink>
                                    )
                                }
                            })
                        }

                    </Nav>
                </Collapse>
            </Navbar>

        )
    }
}
// Seccion de Props
Menu.propTypes = Menu.propTypes || {

}