import React from 'react';
import Base from '../Base';
import { Spinner } from 'reactstrap';

// Otros Componentes
import './styles.css';

export default class Loader extends Base {
    render() {
        return (
            <div className="loader" >
                <div className="centerloader">
                    <Spinner color="primary" style={{ width: '3.5rem', height: '3.5rem' }}  />
                    
                </div>

            </div>
        );
    }
}