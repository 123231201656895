import { Component } from 'react'
import { getText, getOpcion, validateForms } from '../../libs/TBSUtil/index.js';
import cookies from 'react-cookies';
//import "../../libs/ie"
// import 'react-app-polyfill/ie9';
// import 'react-app-polyfill/ie11';
// import "react-app-polyfill/stable";

/**
 * Clase Componente base, todos lo otros componentes deben de heredarla
 * Con funciones específicas o generales para cada Componente
 */
export default class Base extends Component {

    id;     // Id unico del objeto
    ref;    // Referencia al Componente
    components = new Map();
    constructor(props) {
        super(props);
    }


    /**
     * @description Retorna el componente instanciado previamente
     * @param {String} name Nombre del Componente a obtener
     * @return {Component} Componente
     */
    getRef(name) {
        try {
            return this.components.get(name);
        } catch (error) {

        }
        return null
    }


    /**
     * @description Label del archivo de recursos
     * @param {String} text - Id del label en String
     */
    getText(text) {
        return getText(text);
    }

    /**
     * @description Carga de variables globales
     * @param {String} opcion - Id de la variable global a obtener
     */
    getOpcion(opcion) {
        return getOpcion(opcion);
    }

    /**
     * @description Setea la referencia de un componente
     * @param {String} id - Id del componente en String
     * @param {Any} ref - Referencia al componente
     */
    setRef(id, component) {
        this.components.set(id, component);
    }

    /**
     * @description Retorna el campo value de componentes 
     * @return {String} Valor
     */
    getValue() {
        return (this.state.value);
    }

    /**
   * @description Retorna el campo texto de componentes
   * @return {String} Valor
   */
    getDescripcion() {
        return (this.state.text);
    }

    /**
     * @description Setea un valor al componente y generé el render del mismo
     * @param {Any} value - Valor a setear al componente
     */
    setValue(value) {
        try {
            if (value)
                this.setState({ value });
        } catch (error) {
            //Mensaje
        }
    }


    ColumnaId() {
        var IdColumna = {
            Header: this.getText("lblId"),
            accessor: "id",
            width: 50,
            style: { textAlign: "right" }
        };
        return IdColumna;
    }

    CerarSesion() {
        cookies.remove('usuario', { path: '/' });
        cookies.remove('etapa', { path: '/' });
        cookies.remove('token', { path: '/' });
        cookies.remove('etapaDescripcion', { path: '/' });
        cookies.remove('empresaDescripcion', { path: '/' });
        cookies.remove('idusuario', { path: '/' });
        cookies.remove('capacidades', { path: '/' });
        //sessionStorage.clear();
        localStorage.clear();

        window.location.href = '/';

    }

    getEtapa() {
        return cookies.load('etapa');
    }

    getUsuario() {
        return cookies.load('idusuario');
    }

    ValidateForm() {
        return validateForms(this.components);
    }

}
