import React from "react";
import { Editor, EditorState, RichUtils ,ContentState} from "draft-js";
import { convertToHTML, convertFromHTML } from 'draft-convert';
import {  Button, ButtonGroup, ButtonToolbar } from "reactstrap"

import './style.css';

class PageContainer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			editorState: EditorState.createEmpty()
		};

	}

	onChange = editorState => {
		this.setState({
			editorState
		});
	};


	getValue() {
		return convertToHTML(this.state.editorState.getCurrentContent());
	}

	reset(){
		const editorState = EditorState.push(this.state.editorState, ContentState.createFromText(''));
		this.setState({ editorState });
	}

	handleKeyCommand = command => {
		const newState = RichUtils.handleKeyCommand(
			this.state.editorState,
			command
		);
		if (newState) {
			this.onChange(newState);
			return "handled";
		}
		return "not-handled";
	};

	onUnderlineClick = (e) => {
		e.preventDefault();
		this.onChange(
			RichUtils.toggleInlineStyle(this.state.editorState, "UNDERLINE")
		);
	};

	onBoldClick = (e) => {
		e.preventDefault();
		this.onChange(RichUtils.toggleInlineStyle(this.state.editorState, "BOLD"));
	};

	onItalicsClick = (e) => {
		e.preventDefault();
		this.onChange(RichUtils.toggleInlineStyle(this.state.editorState, "ITALIC"));
	};


	componentDidMount() {
		const { editorState } = this.state;
		this.onChange(EditorState.push(editorState, convertFromHTML(this.props.value)));
	}

	render() {
		return (
			<div >

				<ButtonToolbar>
					<ButtonGroup>
						<Button outline color="secondary" onClick={this.onBoldClick}><b>N</b></Button>{' '}
						<Button outline color="secondary" onClick={this.onItalicsClick}><em>C</em></Button>{' '}
						<Button outline color="secondary" onClick={this.onUnderlineClick}><u>S</u></Button>{' '}
					</ButtonGroup>
				</ButtonToolbar>

				<div className="editors">
					<Editor
						editorState={this.state.editorState}
						handleKeyCommand={this.handleKeyCommand}
						onChange={this.onChange}
						readOnly={this.props.disabled}
					/>
				</div>
			</div>
		);
	}
}

export default PageContainer;
