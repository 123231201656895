// Componente base
import React from 'react';
import PropTypes from 'prop-types';
import Base from '../Base';
import { Input, Label } from "reactstrap";

// Otros Componentes
import './style.css';

export default class Text extends Base {

    constructor(props) {
        super(props);
        this.state = {
            value: this.props.value,
            label: this.props.label
        }
        this.onChange = this.onChange.bind(this);
        this.clearValue = this.clearValue.bind(this);
        this.updateValue = this.updateValue.bind(this);
        this.getLabel = this.getLabel.bind(this);
        //this.onBlur = this.onBlur.bind(this);
        this.onBlur = this.props.onBlur;
    };

    onChange(e) {
        this.setState({ value: e.target.value });
    }


    clearValue() {
        this.setState({ value: '' });
    };

    updateValue(value) {       
        this.setState({ value: value });
    };

    getLabel(label) {
        if (label !== "" && label !== undefined) {
            return (
                <Label className='inputNormal' >
                    {label}
                </Label>
            );
        }

    };


    selectInput(estilo) {
        const { type, placeholder,disabled } = this.props;

        if (this.props.onBlur !== undefined) {
            return (
                <Input
                    type={type}
                    value={this.state.value}
                    onChange={this.onChange}
                    placeholder={placeholder}
                    disabled={disabled}
                    onBlur={this.onBlur}
                    
                />
            )
        }
        else {
            return (
                <Input
                    type={type}
                    value={this.state.value}
                    onChange={this.onChange}
                    placeholder={placeholder}
                    disabled={disabled}
                    className={type==="textarea"? "areatexto": estilo?estilo:""  }
                    //bsSize="sm"
                />
            )
        }
    }

    render() {
        const { label,  className } = this.props;
        const defaultClassName = "inputNormal";
        const _className = defaultClassName ;
        return (

            <div className={_className} >
                {this.getLabel(label)}
                {this.selectInput(className)}
            </div>
        )
    }
}
// Seccion de Props
Text.propTypes = {
    value: PropTypes.string,
    label: PropTypes.string,
    type: PropTypes.string.isRequired,
    className: PropTypes.string,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    onBlur: PropTypes.func
}
