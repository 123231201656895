export default class Usuario {
    constructor(
        id,
        nombre,
        clave,
        estado,
        idEtapa,
        idPersona,
        apellidos,
        correo,
        identificacion,
        nombres,
        tipo,
        tipoIdentificacion,
        idUsuario

    ) {
        this.id = id;
        this.nombres = nombres;
        this.nombre = nombre;
        this.clave = clave;
        this.estado = estado;
        this.idEtapa = idEtapa;
        this.idPersona = idPersona;
        this.apellidos = apellidos;
        this.correo = correo;
        this.identificacion = identificacion;
        this.tipo = tipo;
        this.tipoIdentificacion = tipoIdentificacion;
        this.idUsuario = idUsuario;

    }

    route() {
        return "usuario";
    }

    routePersona() {
        return "persona";
    }
}
