import React from 'react';
import { Col, Form, FormGroup, Label, Input, CustomInput, FormText } from 'reactstrap';
import Return from '../../components/Return';
import Base from '../../components/Base';
import Text from '../../components/Text';
import Dropdown from "../../components/Dropdown";
import './style.css';
import NotificacionModel from '../../models/NotificacionModel.js';
import GeneralServices from '../../services/GeneralServices.js';
import Loader from '../../components/Loader';
import Alert from '../../components/Alert';
import Button from '../../components/Button';
import ComboModel from '../../models/ComboModel.js';
import PageContainer from '../../components/PageContainer';
import { imagenes } from '../../config/images.js';

export default class Notificacion extends Base {
    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.notificacionService = new GeneralServices();
        this.changeManzana = this.changeManzana.bind(this);
        this.changeVilla = this.changeVilla.bind(this);

        this.state =
        {
            estado: '',
            loading: false,
            alerta: false,
            mensaje: '',
            error: false,
            manzanas: [],
            villas: [],
            files: [],
            archivos: []
        };
    }

    handleChange(event) {
        this.setState({
            estado: event.target.value
        });
    }

    onChange = (editorState) => {
        this.setState({
            editorState,
        });
    }

    ShowAlert(mensaje, error) {
        this.setState({ alerta: true, mensaje: mensaje, error: error }, () => {
            window.setTimeout(() => {
                this.setState({ alerta: false, mensaje: '', error: false })
            }, 3000)
        });
    }

    changeManzana(event) {

        const mz = event.target.value
        this.getRef("cmbManzana").updateValue(mz);
        this.setVilla(mz)
        console.log(mz);
        this.setState({ departamentos: null });

    }

    changeVilla(event) {
        const vl = event.target.value
        this.getRef("cmbVilla").updateValue(vl);
        this.setDepartamento(vl)
        console.log(vl);

    }

    setVilla(mz) {
        let datos = this.state.ubicaciones;
        var vill = datos.filter(function (ubi) {
            return ubi.manzana === mz;
        });

        const villas = [...new Set(vill.map(x => x.villa))];
        const cmbVl = []
        villas.sort();
        for (const vl of villas) {
            cmbVl.push(new ComboModel(vl, vl));
        }
        this.setState({ villas: cmbVl });
    }

    setDepartamento(vl) {
        let datos = this.state.ubicaciones;

        var mz = this.getRef("cmbManzana").getValue()

        var dept = datos.filter(function (ubi) {
            return ubi.manzana === mz && ubi.villa === vl;
        });

        const departamentos = [...new Set(dept.map(x => x.departamento))];
        const cmbDept = []
        departamentos.sort();

        //console.log(dept[0] );
        if (departamentos[0] !== null && departamentos[0] !== "") {
            for (const dep of departamentos) {
                cmbDept.push(new ComboModel(dep, dep));
            }
            this.setState({ departamentos: cmbDept });
        }
        else {
            this.setState({ ubicacion: dept[0] });
        }
        //console.log(cmbDept);

    }

    cargaUbicacion() {

        this.notificacionService.List(this.getEtapa(), 'ubicacion/etapa')
            .then(result => {
                if (result.codigo !== "0") {
                    alert(result.mensaje);
                    if (result.codigo === 401) {
                        this.CerarSesion();
                    }
                }
                else {
                    //console.log("ingresa");
                    // console.log(result.data);
                    const manzanas = [...new Set(result.data.map(x => x.manzana))];
                    const cmbMz = []
                    manzanas.sort()

                    for (const mz of manzanas) {
                        cmbMz.push(new ComboModel(mz, mz));
                    }

                    this.setState({ manzanas: cmbMz, ubicaciones: result.data });

                    this.cargarDatos();

                }
            })
            .catch(error => {
                console.log(error);
            });
    }

    handleSubmit = (enviar, event) => {

        event.preventDefault();

        // return;
        console.log(enviar);
        let notificacion = new NotificacionModel();

        let validar = this.ValidateForm();

        if (validar) {
            this.ShowAlert(validar, true);
            return;
        }

        let detalle = this.getRef('txt_detalle').getValue();

        if (detalle === "<p></p>") {
            this.ShowAlert(`${this.getText("msgCampoRequerido")} ${this.getText("lblDetalle")}`, true);
            return;
        }

        this.setState({ loading: true });
        notificacion.asunto = this.getRef('txt_asunto').getValue();
        notificacion.detalle = detalle;
        notificacion.id = this.state.notificacion.id;
        notificacion.idEtapa = this.state.notificacion.idEtapa;
        notificacion.estado = this.state.estado;
        notificacion.manzana = this.getRef('cmbManzana').getValue().trim() === "" ? null : this.getRef('cmbManzana').getValue();
        notificacion.villa = this.getRef('cmbVilla').getValue().trim() === "" ? null : this.getRef('cmbVilla').getValue();
        console.log(notificacion);
        let ruta = notificacion.route();

        notificacion.usuario_modificacion = this.getUsuario();
        notificacion.usuario_ingreso = notificacion.id > 0 ? this.state.notificacion.usuario_ingreso : this.getUsuario();

        ruta = notificacion.id > 0 ? `${ruta}/${enviar}` : ruta;

        let formData = new FormData();
        for (const file of this.state.files) {
            formData.append('files', file);
        }

        this.notificacionService.Upload(formData, 'archivo/subir/attachments')
            .then(result => {
                if (result.codigo !== "0") {
                    // alert(result.mensaje);
                    this.ShowAlert(result.mensaje, true);
                }
                else {

                    var files = [];
                    var archivosServer = [];

                    archivosServer = [...this.state.archivos, ...result.data];
                    archivosServer.map((archivo, i) => { archivo.orden = i + 1 });
                    notificacion.attachments = archivosServer.length > 0 ? JSON.stringify(archivosServer) : null;

                    console.log("retorno: ", result);
                    this.notificacionService.save(notificacion, ruta)
                        .then(result => {
                            if (result.codigo !== "0") {
                                // alert(result.mensaje);
                                this.ShowAlert(result.mensaje, true);
                            }
                            else {

                                // console.log(result.data);
                                this.setState({ estado: result.data.estado, notificacion: result.data, loading: false, files: files, archivos: archivosServer });
                                alert(this.getText("msgGuardadoOk"));

                            }
                            this.setState({ loading: false });

                        }
                        )
                        .catch(error => {
                            console.log(error);
                            this.setState({ loading: false });
                        });
                }
            }
            )
            .catch(error => {
                console.log(error);
                this.setState({ loading: false });
            });
    }

    cargarDatos() {
        const id = this.props.match.params.id;
        if (id > 0) {
            this.notificacionService.Id(id, 'notificacion/id')
                .then(result => {
                    if (result.codigo !== "0") {
                        //alert(result.mensaje);
                        this.ShowAlert(result.mensaje, true);
                    }
                    else {

                        if (result.data === null) {
                            alert(this.getText("lblNoHayDatosId") + id);
                            window.location.href = "/InviNotificacionados";
                            //this.props.history.push('/Notificacion');
                            return;
                        }
                        var archivos = []

                        if (result.data.attachments != null) {
                            archivos = JSON.parse(result.data.attachments);
                        }

                        this.setState({ estado: result.data.estado, notificacion: result.data, archivos: archivos });

                        let mz = result.data.manzana;
                        if (mz) {
                            this.setVilla(mz);
                            this.getRef("cmbManzana").updateValue(mz);

                            if (result.data.villa) {

                                this.getRef("cmbVilla").updateValue(result.data.villa);
                            }

                            if (result.data.departamento) {

                                this.setDepartamento(result.data.departamento);
                                this.getRef("cmbDepartamento").updateValue(result.data.departamento);
                            }

                        }
                    }
                }
                )
                .catch(error => {
                    console.log(error);
                });
        }
        else {
            let notificacion = new NotificacionModel();
            notificacion.asunto = "";
            notificacion.detalle = "";
            notificacion.id = 0;
            notificacion.idEtapa = this.getEtapa();
            notificacion.estado = "N";
            this.setState({ estado: notificacion.estado, notificacion: notificacion });
        }
    }

    fileSelectedHandler = e => {


        console.log("archivos en target: ", e.target.files);
        let archivo = [...e.target.files];
        if (archivo.length >= 1) {
            console.log("archivo: ", archivo);

            // verificar tamaño
            const fsize = Math.round((archivo[0].size / 1024));
            // The size of the file. 
            console.log("el tamaño del archivo: ", fsize);
            if (fsize >= 15360) {
                // alert(this.getText("lblArchivoTamanio") + '15MB');
                this.ShowAlert(`${this.getText("lblArchivoTamanio") + '15MB'}`, true);
                return false;
            }

            this.setState({ files: [...this.state.files, archivo[0]] });
        }
    }

    deleteRow(orden, e) {
        e.preventDefault();
        console.log("el orden: ", orden);
        var nuevofile = []
        var nuevofotos = this.state.archivos.filter((archivo) =>
            archivo.orden !== orden);
        nuevofile = this.state.files;
        nuevofile.splice(orden, 1);
        this.setState({ files: nuevofile });
        this.setState({ archivos: nuevofotos });

    }

    componentDidMount() {
        this.cargaUbicacion();
    }
    render() {

        if (this.state.notificacion) {
            let desabilitar = this.state.estado === 'P' ? true : false;
            return (
                <div className='formTitulo' >

                    <Alert
                        open={this.state.alerta}
                        mensaje={this.state.mensaje}
                        error={this.state.error}
                    />
                    <FormGroup row >
                        <Col md={8}>
                            <h5><Return onClick={() => this.props.history.go(-1)} />{this.getText("titNotificacion")}</h5>
                        </Col>
                        <Col md={4} className="text-right">
                            {
                                this.state.notificacion.id > 0 ?
                                    <Button type="submit"
                                        onClick={this.handleSubmit.bind(this, true)}
                                        className="tertiaryColorB"
                                        disabled={this.state.estado === 'N' ? false : true}
                                        value={this.getText("lblEnviar")} /> :
                                    " "
                            }
                            {" "}
                            <Button type="submit"
                                onClick={this.handleSubmit.bind(this, false)}
                                className="tertiaryColorB"
                                disabled={desabilitar}
                                value={this.getText("lblGuardar")} />
                        </Col>

                    </FormGroup>

                    <div className='form'>
                        <Form >
                            <FormGroup row >
                                <Col md={4}>
                                    <Dropdown
                                        id="cmbManzana"
                                        label={this.getText("lblManzana")}
                                        ref={(ref) => this.setRef('cmbManzana', ref)}
                                        value={this.state.manzanas}
                                        tipo="T"
                                        disabled={desabilitar}
                                        onChange={this.changeManzana}
                                    />
                                </Col>
                                <Col md={4}>
                                    <Dropdown
                                        id="cmbVilla"
                                        label={this.getText("lblLote")}
                                        ref={(ref) => this.setRef('cmbVilla', ref)}
                                        value={this.state.villas ? this.state.villas : []}
                                        tipo="T"
                                        disabled={desabilitar}
                                        onChange={this.changeVilla}
                                    />
                                </Col>
                                <Col md={4}>
                                    {
                                        this.state.departamentos ?
                                            <Dropdown
                                                id="cmbDept"
                                                label={this.getText("lblDepartamento")}
                                                ref={(ref) => this.setRef('cmbDept', ref)}
                                                value={this.state.departamentos}
                                                disabled={desabilitar}
                                                tipo="N"
                                            /> :
                                            <div></div>
                                    }
                                </Col>
                            </FormGroup >
                            <FormGroup row>
                                <Col >
                                    <Text
                                        label={this.getText("lblAsunto")}
                                        id={'txt_asunto'}
                                        value={this.state.notificacion.asunto}
                                        type={'text'}
                                        disabled={desabilitar}
                                        ref={(ref) => this.setRef('txt_asunto', ref)}
                                        isRequired={true}
                                        menssage={`${this.getText("msgCampoRequerido")} ${this.getText("lblAsunto")}`}
                                    />
                                </Col>
                            </FormGroup>

                            <FormGroup row>
                                <Col>
                                    <PageContainer
                                        value={this.state.notificacion.detalle}
                                        id={'txt_detalle'}
                                        ref={(ref) => this.setRef('txt_detalle', ref)}
                                        disabled={desabilitar}
                                    />
                                </Col>
                            </FormGroup>

                            <FormGroup>
                                <Label for="files"  >{this.getText("lblArchivosAdjuntos")}</Label>
                                <CustomInput type="file"
                                    id="files"
                                    name="files"
                                    label={this.getText("lblSeleccionarArchivo")}
                                    accept="application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,image/*"
                                    onChange={this.fileSelectedHandler}
                                    disabled={desabilitar}
                                    />

                                <FormText color="muted">
                                    {this.getText("lblTamanioMax")}
                                </FormText>
                            </FormGroup>

                            <br></br>
                            <ul>
                                {
                                    this.state.archivos.map((repo, index) => {
                                        //console.log("lo que tiene repo: ", repo);
                                        let archivoSepSlach = repo.url.split("/");
                                        let fileName = archivoSepSlach[archivoSepSlach.length - 1]
                                        let tipo = fileName.split(".")[1];
                                        // console.log(tipo);
                                        let server = this.getOpcion('fileServer');
                                        let url = server + repo.url;
                                        return (
                                            <FormGroup row key={index}  >
                                                <Col md={3}>
                                                    {
                                                        tipo === "jpg" || tipo === "png" || tipo === "jpeg" || tipo === "gif" || tipo === "svg"
                                                            ? <img alt={index} src={url} height="42" width="42" />
                                                            : <img
                                                                src={imagenes.logo.file}
                                                                alt={index}
                                                                height="42" width="42"
                                                            />
                                                    }
                                                </Col>
                                                <Col md={7}>
                                                    {/* {tipo === "jpg" || tipo === "png" || tipo === "jpeg" || tipo === "gif" || tipo === "svg" ? */}

                                                    <a href={url}
                                                        target="_blank"
                                                    >{repo.nombreOriginal}</a>
                                                    {/* : null */}
                                                    {/* } */}
                                                </Col>
                                                <Col md={2}>
                                                    <Button
                                                        onClick={(e) => this.deleteRow(repo.orden, e)}
                                                        size="sm"
                                                        className="secondaryColorB"
                                                        value="X" />
                                                </Col>
                                            </FormGroup>
                                        )
                                    })
                                }
                                {
                                    this.state.files.map((repo, index) => {
                                        let orden = index;
                                        let tipo = repo.type.split("/");
                                        let url = URL.createObjectURL(repo);
                                        // console.log("", url, " ", typeof repo);
                                        return (
                                            <FormGroup row key={orden}  >
                                                <Col md={3}>
                                                    {tipo[0] === "image" ?
                                                        <img alt={orden} src={url} height="42" width="42" />
                                                        : <img
                                                            src={imagenes.logo.file}
                                                            alt={index}
                                                            height="42" width="42"
                                                        />
                                                    }
                                                </Col>
                                                <Col md={7}>
                                                    {/* {tipo[0] === "image" && */}
                                                        <a href={url}
                                                            target="_blank"
                                                        > {repo.name}</a>
                                                    {/* } */}
                                                </Col>
                                                <Col md={2}>
                                                    <Button
                                                        onClick={(e) => this.deleteRow(orden, e)}
                                                        size="sm"
                                                        className="secondaryColorB"
                                                        value="X" />
                                                </Col>
                                            </FormGroup>
                                        )
                                    })
                                }
                            </ul>

                        </Form>

                    </div>
                    {(this.state.loading) ? <Loader>{this.getText("lblCargando")}</Loader> : <span></span>}

                </div>
            );
        }
        else {
            return (
                <div>
                    <Loader />
                </div>);
        }
    }

}