import React from 'react';
import Base from '../../components/Base';
import { Col, Form, Row, FormGroup, Card, CardTitle, CardBody, CardFooter, Container } from 'reactstrap';
import Button from '../../components/Button';
import Text from "../../components/Text";
import './style.css';
import GeneralServices from '../../services/GeneralServices.js';
import Loader from '../../components/Loader';
import Return from '../../components/Return';
import Ubicacion from "../../components/Ubicacion";
import OpcionModel from '../../models/OpcionModel.js';
import { Decoder } from '@nuintun/qrcode';
import { faUndo, faRedo } from '@fortawesome/free-solid-svg-icons'
import Dropdown from "../../components/Dropdown";
import cookies from 'react-cookies';

import {
    Magnifier,
    GlassMagnifier,
    SideBySideMagnifier,
    PictureInPictureMagnifier,
    MOUSE_ACTIVATION,
    TOUCH_ACTIVATION
} from "react-image-magnifiers";
import { validateIdentificacion, validatePlaca } from '../../libs/TBSUtil';


export default class ControlIngresoI extends Base {

    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleOpenBar = this.handleOpenBar.bind(this);
        this.rotate = this.rotate.bind(this);
        this.rotateleft = this.rotateleft.bind(this);
        this.onMouseMove = this.onMouseMove.bind(this);
        this.Service = new GeneralServices();

        this.state =
        {
            loading: false,
            manzanas: [],
            villas: [],
            open: false,
            imgFull: false,
            rotation: 0,
            x: 0,
            y: 0,
            horizontal: true,
            theme: "",
            Barreras: []
        };
    }



    rotate() {
        let newRotation = (this.state.rotation === 360 ? 0 : this.state.rotation) + 90;
        let newTheme = newRotation > 180 ? "zoomLeft" : "zoomRight";
        //alert(newRotation);
        this.setState({
            rotation: newRotation, horizontal: !this.state.horizontal, theme: newTheme
        })
    }

    rotateleft() {
        let newRotation = (this.state.rotation === 0 ? 360 : this.state.rotation) - 90;

        let newTheme = newRotation > 180 ? "zoomLeft" : "zoomRight";
        //alert(newRotation);
        this.setState({
            rotation: newRotation, horizontal: !this.state.horizontal, theme: newTheme
        })
    }

    onMouseMove(e) {
        alert("ingresa");
        console.log(e);
        // this.setState({ x: e.nativeEvent.offsetX, y: e.nativeEvent.offsetY });
    }

    scanQR(dataQR) {

        let time = new Date();

        this.Service.List(this.getEtapa(), "control/verificarqr/" + dataQR + "/" + time.getTime())
            .then(result => {
                if (result.codigo !== "0") {
                    alert(result.mensaje);

                    if (result.codigo === 401) {
                        this.CerarSesion();
                    }
                    //window.location.href = "/ControlInvitados";
                    return;
                }
                else {

                    if (result.data === null) {
                        alert(this.getText("msgNoDatos"));
                        return;
                    }

                    let inv_resp = result.data.invitado;

                    var invitado = {
                        id: inv_resp ? inv_resp.id : 0,
                        nombres: inv_resp ? inv_resp.nombres : "",
                        apellidos: inv_resp ? inv_resp.apellidos : "",
                        identificacion: inv_resp ? inv_resp.identificacion : "",
                        correo: ""
                    }


                    this.setState({
                        invitacion: result.data.invitacion,
                        persona: result.data.persona,
                        invitado: invitado,
                        invitadoControl: result.data.invitadoControl
                    });
                    if (inv_resp) {
                        this.getRef("txt_nombre").updateValue(inv_resp.nombres + " " + inv_resp.apellidos);
                        this.getRef("txt_cedula").updateValue(inv_resp.identificacion);
                    }
                    alert(this.getText("msgQROK"));
                }
            }
            )
            .catch(error => {
                console.log(error);
            });
    }
    resizeImg = (tipo, event) => {
        event.preventDefault();

        let image;

        switch (tipo) {
            case 'F':
                image = this.state.foto;
                break;
            case 'P':
                image = this.state.placa;
                break;
            default:
                image = this.state.cedula;
        }


        this.setState({ imgFull: !this.state.imgFull, Imagen: image });
    }

    handleOpenBar(event) {
        event.preventDefault();

        let confirma = window.confirm(this.getText("msgSegundaBarrera"));
        if (confirma) {
            this.abrirBarrera(1);
            window.location.href = "/ControlInvitados";
        }
    }

    abrirBarrera(index = 0) {
        var date = new Date();
        var barreras = JSON.parse(this.getOpcion("APIBarrera_" + this.getEtapa()));
        
        this.setState({ Barreras: barreras });
        fetch(barreras[index].url + "?" + date.getTime())
            .then(res => {
                console.log(res);
            })
            .catch(error => {
                console.log(error);
            });
        if (this.state.Barreras.length == 1) {
            window.location.href = "/ControlInvitados";
        }
    }

    handleSubmit(event) {
        event.preventDefault();

        let ubicacion = this.getRef('cmbUbicacion');

        // console.log("cuando se selecciona una categoria ingreso: ", this.getRef('cmbCatIngreso'));       
        // console.log("cuando se selecciona una categoria ingreso: ", this.getRef('cmbCatIngreso').state.text);    

        let validar = ubicacion.validar();

        if (validar) {
            alert(validar);
            return;
        }

        validar = this.ValidateForm();

        if (validar) {
            alert(validar);
            return;
        }

        // validacion de que la identificacion sea correcta
        const { valido, msg } = validateIdentificacion(this.getRef('txt_cedula').getValue());
        if (!valido) {
            alert(msg);
            return;
        }
        // return;

        if (this.getRef('cmbTipoTransporte').getValue() !== "Pie" && this.getRef('cmbTipoTransporte').getValue() !== "") {
            const placaValida = validatePlaca(this.getRef('txt_placa').getValue(), this.getRef('cmbTipoTransporte').getValue());
            if (!placaValida) {
                alert(this.getText("lblPlacaNoCorrecta"));
                return;
            }
        }

        let formData = new FormData();
        var foto1 = this.state.foto1;
        var foto2 = this.state.foto2;
        var foto3 = this.state.fotoPlaca;

        if (!foto1 && !foto2) {
            alert(this.getText("msgNoFoto"));
            return;
        }

        formData.append('files', foto1);
        formData.append('files', foto2);
        if (foto3) {
            formData.append('files', foto3);
        }


        this.setState({ loading: true });

        var invitado = {
            id: this.state.invitado.id,
            nombres: this.getRef('txt_nombre').getValue(),
            apellidos: "",
            identificacion: this.getRef('txt_cedula').getValue(),
            correo: this.state.invitado.correo
        }

        let invitados = [];
        invitados.push(invitado);

        var invitacion = {
            id: 0,
            nombre: this.getRef('cmbCatIngreso').state.text,
            direccion: JSON.stringify(ubicacion.state.ubicacion),
            tipo: 'HOR',
            idEtapa: this.getEtapa(),
            estado: 'A',
            invitados: invitados,
            fecha_inicio: new Date(),
            fecha_fin: new Date()
        }

        this.Service.Upload(formData, 'archivo/subirvarios')
            .then(result => {
                if (result.codigo !== "0") {
                    alert(result.mensaje);
                    if (result.codigo === 401) {
                        this.CerarSesion();
                    }
                }
                else {


                    if (this.state.invitacion.id > 0) {

                        if (this.state.invitado.id > 0) {
                            this.guardaInvitadoControl(this.state.invitacion, this.state.invitado.id, this.state.persona.id, result.data);
                        }
                        else {

                            invitacion = this.state.invitacion;
                            invitacion.invitados = invitados;

                            this.Service.save(invitacion, "invitacion")
                                .then(result2 => {
                                    if (result2.codigo !== "0") {
                                        alert(result2.mensaje);
                                    }
                                    else {
                                        this.guardaInvitadoControl(result2.data.invitacion, result2.data.invitacion.invitados[0].id, ubicacion.state.persona, result.data);

                                    }
                                    this.setState({ loading: false });
                                }
                                )
                                .catch(error => {
                                    console.log(error);
                                    this.setState({ loading: false });
                                });
                        }



                    }
                    else {

                        this.Service.save(invitacion, "invitacion")
                            .then(result2 => {
                                if (result2.codigo !== "0") {
                                    alert(result2.mensaje);
                                }
                                else {
                                    this.guardaInvitadoControl(result2.data.invitacion, result2.data.invitacion.invitados[0].id, ubicacion.state.persona, result.data);

                                }
                                this.setState({ loading: false });
                            }
                            )
                            .catch(error => {
                                console.log(error);
                                this.setState({ loading: false });
                            });
                    }
                }
            }
            )
            .catch(error => {
                console.log(error);
                this.setState({ loading: false });
            });
    }

    guardaInvitadoControl(invitacion, invitado, persona, fotos) {
        var InvitadoControl;
        let ubicacion = this.getRef('cmbUbicacion');

        if (this.state.invitadoControl) {
            InvitadoControl = this.state.invitadoControl;
            InvitadoControl.invitado = invitado;
            InvitadoControl.placa = this.getRef('txt_placa').getValue();
            InvitadoControl.novedad = this.getRef('txt_novedad').getValue();
            InvitadoControl.categoriaTransporte = this.getRef('cmbCatTransporte').getValue();
            InvitadoControl.tipoTransporte = this.getRef('cmbTipoTransporte').getValue();
            InvitadoControl.nombreConductor = this.getRef('txt_nombre_c').getValue();
            InvitadoControl.fotosIngreso = JSON.stringify(fotos);
            InvitadoControl.idUbicacion = ubicacion.state.ubicacion.id;
            InvitadoControl.categoriaIngreso = this.getRef('cmbCatIngreso').getValue();
            InvitadoControl.usuarioIngreso = this.getUsuario();
        }
        else {
            InvitadoControl = {
                id: 0,
                persona: persona ? persona : null,
                invitacion: invitacion.id,
                invitado: invitado,
                idEtapa: this.getEtapa(),
                placa: this.getRef('txt_placa').getValue(),
                novedad: this.getRef('txt_novedad').getValue(),
                categoriaTransporte: this.getRef('cmbCatTransporte').getValue(),
                tipoTransporte: this.getRef('cmbTipoTransporte').getValue(),
                nombreConductor: this.getRef('txt_nombre_c').getValue(),
                fotosIngreso: JSON.stringify(fotos),
                fotosSalida: null,
                idUbicacion: ubicacion.state.ubicacion.id,
                categoriaIngreso: this.getRef('cmbCatIngreso').getValue(),
                usuarioIngreso: this.getUsuario(),
            }

        }

        this.Service.save(InvitadoControl, "control")
            .then(result3 => {
                if (result3.codigo !== "0") {
                    alert(result3.mensaje);
                }
                else {
                    this.setState({ invitadoControl: result3.data });

                    if (this.getRef('cmbTipoTransporte').getValue() === "Pie") {
                        window.location.href = "/ControlInvitados";
                        return;
                    }
                    let confirma = window.confirm(this.getOpcion("FraseBarrera"));

                    if (confirma) {
                        this.abrirBarrera();                        
                    }

                }
                this.setState({ loading: false });
            }
            )
            .catch(error => {
                console.log(error);
                this.setState({ loading: false });
            });
    }

    CargarCamaras() {
        let opcion = new OpcionModel();

        this.Service.Id(`camaras_${this.getEtapa()}`, opcion.route() + '/nombre')
            .then(result => {
                if (result.codigo !== "0") {
                    alert(result.mensaje);
                    if (result.codigo === 401) {
                        this.CerarSesion();
                    }
                }
                else {
                    if (result.data === null) {
                        alert(this.getText("msgConfigurarCamara"));
                        return;
                    }
                    else {

                        let cam = JSON.parse(result.data.valor);
                        const Cam01 = cam[0];
                        const Cam02 = cam[1];
                        const Cam03 = cam[2] ? cam[2] : { ip: "" };

                        if (Cam01.ip === "" || Cam02.ip === "" || Cam03.ip === "") {
                            alert(this.getText("msgConfigurarCamara"));
                            return;
                        }

                        this.iniciarCamara(Cam01, Cam02, Cam03);

                    }
                }
            }
            )
            .catch(error => {
                console.log(error);
            });
    }

    componentDidMount() {

        this.CargarCamaras();

        //console.log("la opciones nuevas: ", JSON.parse(this.getOpcion("CategoriaIngreso")));
        //const id = this.props.match.params.id;

        var persona = {
            nombres: "",
            apellidos: "",
            ubicacion: { id: 0 },
            id: 0
        }

        var invitacion = {
            id: 0
        }

        var invitado = {
            id: 0,
            nombres: "",
            apellidos: "",
            identificacion: "",
            correo: "",
            telefono: ""
        }
        this.setState({ persona: persona, invitado: invitado, invitacion: invitacion });

    }

    handleTakePicture = (tipo, event) => {
        try {

            let camara = 0;
            window.scrollTo(0, 100);

            if (tipo === 'F') {
                camara = 1;
            } else if (tipo === 'P') {
                camara = 2;
            }


            event.preventDefault();

            var arrXml = [],
                szInfo = "";

            arrXml.push("<LocalConfigInfo>");
            arrXml.push('<DownloadPath>C:\\wamp64\\www</DownloadPath>');
            arrXml.push("<CapturePath>C:\\wamp64\\www</CapturePath>");
            arrXml.push("</LocalConfigInfo>");


            var iRet = window.WebVideoCtrl.I_SetLocalCfg(arrXml.join(""));
            if (0 == iRet) {
                szInfo = "local configuration success！";
            } else {
                szInfo = "local configuration failed！";
            }

            let name = "test" + tipo + (new Date()).getTime();

            let ok = window.WebVideoCtrl.I_CapturePic(name, { iWndIndex: camara, bDateDir: false });

            fetch(this.getOpcion("APICamara") + "/" + name + ".jpg")
                .then(res => res.blob()) // Gets the response and returns it as a blob
                .then(blob => {

                    let objectURL = URL.createObjectURL(blob);
                    this.setState({ Imagen: objectURL });
                    let key_img = (new Date()).getTime();
                    if (tipo === 'F') {
                        this.setState({ foto: objectURL, foto1: blob, key1: key_img });
                    }
                    else if (tipo === 'QR') {

                        const qrcode = new Decoder();
                        qrcode.scan(objectURL)
                            .then(result => {

                                let QR = result.data;

                                if (QR) {
                                    this.scanQR(QR);
                                }
                                else {
                                    alert(this.getText("msgErrorQR"));
                                }

                            })
                            .catch(error => {
                                alert(this.getText("msgErrorQR"));
                            });

                        this.setState({ QR: objectURL, fotoQR: blob });

                    }
                    else if (tipo === 'C') {
                        this.setState({ cedula: objectURL, foto2: blob, key2: key_img });
                    }
                    else {
                        this.setState({ placa: objectURL, fotoPlaca: blob, keyPlaca: key_img });
                    }

                });

        }
        catch (error) {
            console.log("ERROR")
        }

    }

    iniciarCamara(Cam01, Cam02, Cam03) {
        const script = document.createElement("script");
        script.src = "/scripts/codebase/webVideoCtrl2.js";
        script.async = true;
        script.onload = () => this.createPluginCamera(Cam01, Cam02, Cam03);
        document.body.appendChild(script);
    }

    createPluginCamera(Cam01, Cam02, Cam03) {

        var iRet = window.WebVideoCtrl.I_CheckPluginInstall();

        if (-1 === iRet) {
            alert(this.getText("msgNoPluginCamara"));
            return;
        }



        var oPlugin = {
            iWidth: 500,             // plugin width
            iHeight: 310             // plugin height
        };

        var oLiveView = {
            iProtocol: 1,            // protocol 1��http, 2:https
            szIP: Cam01.ip,    // protocol ip
            szPort: "80",            // protocol port
            szUsername: Cam01.user,     // device username
            szPassword: Cam01.pwd, // device password
            iStreamType: 1,          // stream 1��main stream  2��sub-stream  3��third stream  4��transcode stream
            iChannelID: 1,           // channel no
            bZeroChannel: false      // zero channel
        };

        var oLiveView2 = {
            iProtocol: 1,            // protocol 1��http, 2:https
            szIP: Cam02.ip,    // protocol ip
            szPort: "80",            // protocol port
            szUsername: Cam02.user,     // device username
            szPassword: Cam02.pwd, // device password
            iStreamType: 1,          // stream 1��main stream  2��sub-stream  3��third stream  4��transcode stream
            iChannelID: 1,           // channel no
            bZeroChannel: false      // zero channel
        };

        var oLiveView3 = {
            iProtocol: 1,            // protocol 1��http, 2:https
            szIP: Cam03.ip,    // protocol ip
            szPort: "80",            // protocol port
            szUsername: Cam03.user,     // device username
            szPassword: Cam03.pwd, // device password
            iStreamType: 1,          // stream 1��main stream  2��sub-stream  3��third stream  4��transcode stream
            iChannelID: 1,           // channel no
            bZeroChannel: false      // zero channel
        };

        //Init plugin parameters and insert the plugin
        window.WebVideoCtrl.I_InitPlugin(oPlugin.iWidth, oPlugin.iHeight, {
            bWndFull: true,//Wether support doule clicking to switch the full-screen mode: it's supported by default; true:support, false:not support
            iWndowType: 2,
            cbInitPluginComplete: function () {
                window.WebVideoCtrl.I_InsertOBJECTPlugin("divPluginC");
                // Login 1
                window.WebVideoCtrl.I_Login(oLiveView.szIP, oLiveView.iProtocol, oLiveView.szPort, oLiveView.szUsername, oLiveView.szPassword, {
                    success: function (xmlDoc) {
                        // strat real play
                        var szDeviceIdentify = oLiveView.szIP + "_" + oLiveView.szPort;
                        setTimeout(function () {
                            window.WebVideoCtrl.I_StartRealPlay(szDeviceIdentify, {
                                iWndIndex: 0,
                                iStreamType: oLiveView.iStreamType,
                                iChannelID: oLiveView.iChannelID,
                                bZeroChannel: oLiveView.bZeroChannel
                            });
                        }, 1000);
                    }
                    ,
                    error: function (xmlDoc) {
                        alert("Error login Camara1");
                    }
                });

                // Login 2
                window.WebVideoCtrl.I_Login(oLiveView2.szIP, oLiveView2.iProtocol, oLiveView2.szPort, oLiveView2.szUsername, oLiveView2.szPassword, {
                    success: function (xmlDoc) {
                        // strat real play
                        var szDeviceIdentify = oLiveView2.szIP + "_" + oLiveView2.szPort;
                        setTimeout(function () {
                            window.WebVideoCtrl.I_StartRealPlay(szDeviceIdentify, {
                                iWndIndex: 1,
                                iStreamType: oLiveView2.iStreamType,
                                iChannelID: oLiveView2.iChannelID,
                                bZeroChannel: oLiveView2.bZeroChannel
                            });
                        }, 1000);
                    },
                    error: function (xmlDoc) {
                        alert("Error login Camara2");
                    }
                });

                // Login 3
                window.WebVideoCtrl.I_Login(oLiveView3.szIP, oLiveView3.iProtocol, oLiveView3.szPort, oLiveView3.szUsername, oLiveView3.szPassword, {
                    success: function (xmlDoc) {
                        // strat real play
                        var szDeviceIdentify = oLiveView3.szIP + "_" + oLiveView3.szPort;
                        setTimeout(function () {
                            window.WebVideoCtrl.I_StartRealPlay(szDeviceIdentify, {
                                iWndIndex: 2,
                                iStreamType: oLiveView3.iStreamType,
                                iChannelID: oLiveView3.iChannelID,
                                bZeroChannel: oLiveView3.bZeroChannel
                            });
                        }, 1000);
                    },
                    error: function () {
                        alert("Error login Camara3");
                    }
                });
            }
        });

    }


    render() {

        let ubicacion = this.getRef('cmbUbicacion');
        let val = this.state.persona;
        const { rotation, x, y, horizontal, theme } = this.state;
        //console.log(x, y);

        var categoria = JSON.parse(this.getOpcion("CategoriaIngreso"));
        //console.log(categoria.filter(x => x.tipo === "NORMAL"));
        //alert(rotation);
        if (this.state.persona) {

            return (
                <div className='formTitulo' >

                    <FormGroup row >
                        <Col md={7}>
                            <h5><Return onClick={() => this.props.history.go(-1)} />{this.getText("titInvitado")}</h5>
                        </Col>

                        <Col md={3} className="text-right">
                            {this.state.Barreras.length > 1 &&
                                <Button type="submit"
                                    onClick={this.handleOpenBar}
                                    className="btn-delete"
                                    value={this.getText("lblAbrirSegBarrera")} />
                            }
                        </Col>

                        <Col md={2} className="text-right">
                            <Button type="submit"
                                onClick={this.handleSubmit}
                                className="tertiaryColorB"
                                value={this.getText("lblGuardar")} />
                        </Col>

                    </FormGroup>
                    <Card >

                        <FormGroup row>
                            <Col>
                                <FormGroup row>
                                    <Col md='2'  >
                                        <b>{this.getText("lblFotos")}</b>
                                    </Col>
                                    <Col md='10'>
                                        <Button type="submit"
                                            onClick={(e) => this.handleTakePicture("QR", e)}
                                            className="tertiaryColorA"
                                            size="sm"
                                            value={this.getText("lblLeerQR")} />

                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Col>
                                        <div ref={(ref) => this.video = ref} id="divPluginC" key="divPluginC" className="plugin"></div>
                                    </Col>
                                </FormGroup>
                            </Col>

                            <Col>
                                <FormGroup row>


                                    <Col sm='4' >
                                        <Button type="button"
                                            onClick={(e) => this.handleTakePicture("F", e)}
                                            className="tertiaryColorB"
                                            size="sm"
                                            value={this.getText("lblFotoCara")} />

                                    </Col>
                                    <Col sm='4' >
                                        <Button type="button"
                                            onClick={(e) => this.handleTakePicture("C", e)}
                                            className="tertiaryColorB"
                                            size="sm"
                                            value={this.getText("lblFotoCedula")} />

                                    </Col>
                                    <Col sm='4' >
                                        <Button type="button"
                                            onClick={(e) => this.handleTakePicture("P", e)}
                                            className="tertiaryColorA"
                                            size="sm"
                                            value={this.getText("lblFotoPlaca")} />

                                    </Col>
                                </FormGroup>

                                {this.state.imgFull ?
                                    <Container>
                                        <FormGroup row>
                                            <Col >

                                                {horizontal ?
                                                    <Magnifier
                                                        imageSrc={this.state.Imagen}
                                                        mouseActivation={MOUSE_ACTIVATION.DOUBLE_CLICK} // Optional
                                                        dragToMove={false}
                                                        className="imageZoom"
                                                        style={{
                                                            transform: `rotate(${rotation}deg)`
                                                        }}
                                                    /> :
                                                    <img src={this.state.Imagen}
                                                        className={theme}
                                                        //style={{ transform: `rotate(${rotation}deg)` }}
                                                        width="316"
                                                        height="200"
                                                        onClick={(e) => this.resizeImg("C", e)} ></img>
                                                }

                                                <Button className="botonCerrar"
                                                    value={"X"}
                                                    size={"sm"}
                                                    onClick={(e) => this.resizeImg("C", e)} />
                                                <Button className="botonRight"
                                                    onClick={this.rotate}
                                                    size={"sm"}
                                                    icono={faRedo} />
                                                <Button className="botonLeft" onClick={this.rotateleft}
                                                    size={"sm"}
                                                    icono={faUndo} />

                                            </Col>
                                        </FormGroup>
                                    </Container> :
                                    <div>

                                        <FormGroup row>
                                            <Col sm='6'>
                                                <img src={this.state.foto} width="90%"
                                                    onClick={(e) => this.resizeImg("F", e)}
                                                    key={this.state.key1}
                                                    id={this.state.key1}
                                                    height="150"></img>
                                            </Col>
                                            <Col sm='6'>
                                                <img src={this.state.placa} width="90%"
                                                    onClick={(e) => this.resizeImg("P", e)}
                                                    key={this.state.keyPlaca}
                                                    id={this.state.keyPlaca}
                                                    height="150"></img>
                                            </Col>

                                        </FormGroup>
                                        <FormGroup row>
                                            <Col sm='6'>
                                                <img src={this.state.cedula}
                                                    width="90%"
                                                    height="150"
                                                    key={this.state.key2}
                                                    id={this.state.key2}
                                                    onClick={(e) => this.resizeImg("C", e)} ></img>
                                            </Col>
                                        </FormGroup>
                                    </div>

                                }

                            </Col>
                        </FormGroup>

                    </Card>

                    <FormGroup row >
                        <Col>

                            <CardTitle  >
                                <b>{this.getText("titResidente")}</b>
                            </CardTitle>
                            <Card >

                                <CardBody>
                                    <Ubicacion
                                        ref={(ref) => this.setRef('cmbUbicacion', ref)}
                                        residente={true}
                                        persona={val.id}
                                        ubicacion={val.ubicacion.id}
                                        telefono={true}
                                        disabled={val.id > 0 ? true : false}
                                        inactivos={true}

                                    />


                                </CardBody>
                            </Card>
                        </Col>
                        <Col>

                            {this.state.invitado ?
                                <div>
                                    <CardTitle  >
                                        <b>{this.getText("titInvitado")}</b>
                                    </CardTitle>
                                    <Card >
                                        <CardBody>

                                            {/* el nuevo categoria ingreso */}
                                            <FormGroup row >
                                                <Col>
                                                    <Dropdown
                                                        id="cmbCatIngreso"
                                                        label={this.getText("lblCategoriaIngreso")}
                                                        ref={(ref) => this.setRef('cmbCatIngreso', ref)}
                                                        //tipo="N"
                                                        value={categoria.filter(x => x.tipo === "NORMAL")}
                                                    />
                                                </Col>
                                            </FormGroup>

                                            <FormGroup row >
                                                <Col>
                                                    <Text
                                                        label={this.getText("lblNombres")}
                                                        ref={(ref) => this.setRef('txt_nombre', ref)}
                                                        id={'txt_nombre'}
                                                        value={this.state.invitado.nombres + ' ' + this.state.invitado.apellidos}
                                                        type={'text'}
                                                        isRequired={true}
                                                        disabled={this.state.invitado.nombres.length > 0 ? true : false}
                                                        menssage={`${this.getText("msgCampoRequerido")} ${this.getText("lblNombres")}`}
                                                    />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row >
                                                <Col>
                                                    <Text
                                                        label={this.getText("lblIdentificacion")}
                                                        id={'txt_cedula'}
                                                        ref={(ref) => this.setRef('txt_cedula', ref)}
                                                        value={this.state.invitado.identificacion}
                                                        type={'number'}
                                                        isRequired={true}
                                                        disabled={this.state.invitado.nombres.length > 0 ? true : false}
                                                        menssage={`${this.getText("msgCampoRequerido")} ${this.getText("lblIdentificacion")}`}
                                                    />
                                                </Col>
                                                <Col>
                                                    <Text
                                                        label={this.getText("lblPlaca")}
                                                        id={'txt_placa'}
                                                        ref={(ref) => this.setRef('txt_placa', ref)}
                                                        value={""}
                                                        type={'text'}

                                                    />
                                                    <div className="secondaryLightColor textPasswordCorrecta" >
                                                        <p>{this.getText("lblEjemploPlaca")}</p>
                                                    </div>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row >
                                                <Col>

                                                    <Dropdown
                                                        id="cmbCatTransporte"
                                                        label={this.getText("lblCatTransporte")}
                                                        ref={(ref) => this.setRef('cmbCatTransporte', ref)}
                                                        tipo="N"
                                                        value={JSON.parse(this.getOpcion("CategoriaTransporte"))}
                                                    // isRequired={true}
                                                    // menssage={`${this.getText("msgCampoRequerido")} ${this.getText("lblManzana")}`}
                                                    />
                                                </Col>
                                                <Col>
                                                    <Dropdown
                                                        id="cmbTipoTransporte"
                                                        tipo="N"
                                                        ref={(ref) => this.setRef('cmbTipoTransporte', ref)}
                                                        label={this.getText("lblTipoTransporte")}
                                                        value={JSON.parse(this.getOpcion("TipoTransporte"))}
                                                    />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row >
                                                <Col>
                                                    <Text
                                                        label={this.getText("lblNombreConductor")}
                                                        ref={(ref) => this.setRef('txt_nombre_c', ref)}
                                                        id={'txt_nombre_c'}
                                                        value={""}
                                                        type={'text'}
                                                    //isRequired={true}
                                                    //menssage={`${this.getText("msgCampoRequerido")} ${this.getText("lblNombreConductor")}`}
                                                    />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row >
                                                <Col>
                                                    <Text
                                                        label={this.getText("lblNovedad")}
                                                        ref={(ref) => this.setRef('txt_novedad', ref)}
                                                        id={'txt_novedad'}
                                                        value={""}
                                                        type={'textarea'}
                                                    //isRequired={true}
                                                    // menssage={`${this.getText("msgCampoRequerido")} ${this.getText("lblNovedad")}`}
                                                    />
                                                </Col>
                                            </FormGroup>

                                        </CardBody>
                                    </Card>
                                    <FormGroup row />
                                </div> : ""

                            }
                        </Col>
                    </FormGroup>
                    {(this.state.loading) ? <Loader /> : <span />}
                </div >
            );
        }
        else {
            return (
                <div>
                    <Loader />
                </div>);
        }
    }
}