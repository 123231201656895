import React from 'react';
import { Col, Form, FormGroup, Input, Label } from 'reactstrap';
import Alert from '../../components/Alert';
import Base from '../../components/Base';
import Button from '../../components/Button';
import Dropdown from "../../components/Dropdown";
import Loader from '../../components/Loader';
import Return from '../../components/Return';
import Text from "../../components/Text";
import { validateIdentificacion, validatePassword } from '../../libs/TBSUtil';
import { default as UsuarioModel } from '../../models/Usuario.js';
import GeneralServices from '../../services/GeneralServices.js';
import './style.css';

export default class Usuario extends Base {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.UsuarioService = new GeneralServices();

        this.state =
        {
            estado: '',
            loading: false,
            alerta: false,
            mensaje: '',
            error: false
        };
    }

    handleChange(event) {
        this.setState({
            estado: event.target.value
        });
    }

    ShowAlert(mensaje, error) {
        this.setState({ alerta: true, mensaje: mensaje, error: error }, () => {
            window.setTimeout(() => {
                this.setState({ alerta: false, mensaje: '', error: false })
            }, 3000)
        });
    }

    handleSubmit(event) {
        event.preventDefault();

        let validar = this.ValidateForm();

        if (validar) {
            this.ShowAlert(validar, true);
            return;
        }

        // validacion de que la identificacion sea correcta
        if (this.getRef("cmbTipoIdentificacion").getValue() === "CED") {
            const { valido, msg } = validateIdentificacion(this.getRef('txt_cedula').getValue());
            if (!valido) {
                this.ShowAlert(msg, true);
                return;
            }
        }
        // return;

        if (this.state.Usuario.id == 0) {
            var pass = validatePassword(this.getRef('txt_clave').getValue());
            //console.log(pass);
            if (!pass[1]) {
                this.ShowAlert(pass[0], true);
                return;
            }
        }
        //return;

        this.setState({ loading: true });

        let person = new UsuarioModel();
        person.apellidos = this.getRef('txt_apellidos').getValue();
        person.correo = this.getRef('txt_correo').getValue();
        person.nombre = this.getRef('txt_correo').getValue();
        person.id = this.state.Usuario.id;

        person.identificacion = this.getRef('txt_cedula').getValue();
        person.nombres = this.getRef('txt_nombres').getValue();
        person.clave = this.getRef('txt_clave').getValue();
        person.tipoIdentificacion = this.getRef("cmbTipoIdentificacion").getValue();
        person.idUsuario = this.state.Usuario.idUsuario;

        if (this.getEtapa() !== "null") {
            person.tipo = this.getRef("cmbTipo").getValue();
            person.idEtapa = this.getEtapa();
        }
        else {
            person.idEtapa = this.getRef("cmbTipo").getValue();
            person.tipo = 'ADM';
        }

        person.estado = this.state.estado;
        console.log("la data de persona que se envia: " + JSON.stringify(person.idUsuario));
        // return;
        this.UsuarioService.save(person, `${person.routePersona()}${person.id > 0 ? '' : '/user'}`)
            .then(result => {
                this.setState({ loading: false });
                console.log(result);
                if (result.codigo !== "0") {
                    this.ShowAlert(result.mensaje, true);

                    if (result.codigo === 401) {
                        this.CerarSesion();
                    }
                }
                else {
                    alert(this.getText("msgGuardadoOk"));
                    window.location.href = "/Usuarios";
                    //this.props.history.push('/Usuarios');
                }

            }
            )
            .catch(error => {
                console.log(error);
                this.setState({ loading: false });
            });
    }

    componentDidMount() {
        const id = this.props.match.params.id;
        let person = new UsuarioModel();
        let etapa = this.getEtapa();
        if (id > 0) {
            this.UsuarioService.Id(id, `${person.routePersona()}/id`)
                .then(result => {
                    if (result.codigo !== "0") {
                        alert(result.mensaje);
                        if (result.codigo === 401) {
                            this.CerarSesion();
                        }
                    }
                    else {
                        if (result.data === null) {
                            alert(this.getText('lblNoHayDatosId') + id);
                            window.location.href = "/Usuarios";
                            //this.props.history.push('/Usuarios');
                            return;
                        }

                        this.setState({ estado: result.data.persona.estado, Usuario: result.data.persona });
                        if (etapa !== 'null') {
                            this.getRef("cmbTipo").updateValue(result.data.persona.tipo);
                        }
                        else {
                            this.getRef("cmbTipo").updateValue(result.data.persona.idEtapa);
                        }
                    }
                }
                )
                .catch(error => {
                    console.log(error);
                });
        }
        else {

            person.apellidos = "";
            person.nombres = "";
            person.correo = "";
            person.id = id;
            person.idEtapa = "";
            person.tipo = "";
            person.identificacion = "";
            person.estado = "A";
            person.tipoIdentificacion = "CED"
            person.idUsuario = 0

            this.setState({ estado: person.estado, Usuario: person });

        }
    }

    render() {
        let etapa = this.getEtapa();
        let cmbTipoIdentificacion = JSON.parse(this.getOpcion("TipoIdentificacion"))
        if (this.state.Usuario) {
            return (
                <div className='formTitulo' >
                    <Alert
                        open={this.state.alerta}
                        mensaje={this.state.mensaje}
                        error={this.state.error}
                    />
                    <FormGroup row >
                        <Col md={9}>
                            <h5><Return onClick={() => this.props.history.go(-1)} />
                                {this.getText("titUsuarios")}</h5>
                        </Col>
                        <Col md={3} className="text-right">
                            <Button type="submit"
                                onClick={this.handleSubmit}
                                className="tertiaryColorB"
                                value={this.getText("lblGuardar")} />
                        </Col>
                    </FormGroup>
                    <div className='form'>
                        <Form  >
                            <FormGroup row >
                                <Col md={8}>
                                    <div className='inputNormal' >
                                        <Label className='inputNormal' >{this.getText("lblEstado")}</Label>
                                    </div>
                                </Col>
                            </FormGroup >
                            <FormGroup row>
                                <Col md={4}>
                                    <FormGroup check inline >
                                        <Label check>
                                            <Input
                                                type='radio'
                                                name="estado"
                                                value='A'
                                                checked={this.state.estado === 'A'}
                                                onChange={this.handleChange}
                                            />{this.getText("lblActivo")}</Label>

                                    </FormGroup>
                                    <FormGroup check inline  >
                                        <Label check>
                                            <Input
                                                type='radio'
                                                name="estado"
                                                value='I'
                                                checked={this.state.estado === 'I'}
                                                onChange={this.handleChange}
                                            />{this.getText("lblInactivo")}</Label>
                                    </FormGroup>
                                </Col>
                            </FormGroup>
                            <FormGroup row >
                                <Col >
                                    <Dropdown
                                        id="cmbTipo"
                                        ref={(ref) => this.setRef('cmbTipo', ref)}
                                        label={this.getText(etapa !== 'null' ? "lblTipo" : "lblUrbanizacion")}
                                        value={JSON.parse(etapa !== 'null' ? this.getOpcion("RolesAdmin") : localStorage.getItem('etapas'))}
                                    //disabled={etapa!=='null'?true:false}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Col >
                                    <Text
                                        label={this.getText("lblNombres")}
                                        id={'txt_nombres'}
                                        value={this.state.Usuario.nombres}
                                        type={'text'}
                                        isRequired={true}
                                        menssage={`${this.getText("msgCampoRequerido")} ${this.getText("lblNombres")}`}
                                        ref={(ref) => this.setRef('txt_nombres', ref)}
                                    />
                                </Col>
                            </FormGroup>

                            <FormGroup row>
                                <Col >
                                    <Text
                                        label={this.getText("lblApellidos")}
                                        id={'txt_apellidos'}
                                        value={this.state.Usuario.apellidos}
                                        type={'text'}
                                        isRequired={true}
                                        menssage={`${this.getText("msgCampoRequerido")} ${this.getText("lblApellidos")}`}
                                        ref={(ref) => this.setRef('txt_apellidos', ref)}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup row >
                                <Col>
                                    <Dropdown
                                        label={this.getText("lblIdentificacion")}
                                        id="cmbTipoIdentificacion"
                                        ref={(ref) => this.setRef('cmbTipoIdentificacion', ref)}
                                        value={cmbTipoIdentificacion}
                                        default={this.state.Usuario.tipoIdentificacion ? this.state.Usuario.tipoIdentificacion : "CED"}
                                    // tipo="N"
                                    />

                                </Col>

                            </FormGroup>
                            <FormGroup row >
                                <Col >
                                    <Text
                                        //label={this.getText("lblIdentificacion")}
                                        id={'txt_cedula'}
                                        value={this.state.Usuario.identificacion}
                                        type={'number'}
                                        isRequired={true}
                                        menssage={`${this.getText("msgCampoRequerido")} ${this.getText("lblIdentificacion")}`}
                                        ref={(ref) => this.setRef('txt_cedula', ref)}
                                    />
                                </Col>
                            </FormGroup>

                            <FormGroup row >
                                <Col >
                                    <Text
                                        label={this.getText("lblEmail")}
                                        id={'txt_correo'}
                                        value={this.state.Usuario.correo}
                                        type={'email'}
                                        isRequired={true}
                                        menssage={`${this.getText("msgCampoRequerido")} ${this.getText("lblEmail")}`}
                                        ref={(ref) => this.setRef('txt_correo', ref)}
                                    />
                                </Col>
                            </FormGroup>

                            <FormGroup row >
                                <Col >
                                    <Text
                                        label={this.getText("lblPassword")}
                                        id={'txt_clave'}
                                        value={""}
                                        type={'password'}
                                        isRequired={this.state.Usuario.id === '0' ? true : false}
                                        menssage={`${this.getText("msgCampoRequerido")} ${this.getText("lblPassword")}`}
                                        ref={(ref) => this.setRef('txt_clave', ref)}
                                    />
                                    <div className="secondaryLightColor textPasswordCorrecta" >
                                        <p>{this.getText("lblPasswordCorrecta")}</p>
                                    </div>
                                </Col>
                            </FormGroup>

                        </Form>
                    </div>
                    {(this.state.loading) ? <Loader /> : <span />}
                </div>
            );
        }
        else {
            return (
                <div>
                    <Loader />
                </div>);
        }
    }
    // }
}