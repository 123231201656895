import Services from './Services'


export default class LoginService extends Services {

    login(data, route) {
        try {
            return this.post(data, route);
        } catch (error) {
            console.log(error)
        }
    }

    list(data, route) {
        try {
            return this.get(data, route);
        } catch (error) {
            console.log(error)
        }
    }

    reset(data, route) {
        try {
            return this.post(data, route);
        } catch (error) {
            console.log(error)
        }
    }

}

