export default class CategoriaModel {
    constructor(
        nombre,        
        estado,    
        id
    ) {
        this.nombre = nombre;
       // this.valor = valor;
        this.estado = estado;
        this.id = id;

    }

    route() {
        return "categoriacomercio";
    }
}