import React from 'react';
import Base from '../../components/Base';
import {
    Col, Form, FormGroup, Label, Input,
    ModalHeader, Modal, ModalBody,
    ModalFooter,  Button
} from 'reactstrap';
import Text from "../../components/Text";
import './style.css';
import GeneralServices from '../../services/GeneralServices.js';
import VehiculoModel from '../../models/VehiculoModel.js';

export default class Vehiculo extends Base {
    constructor(props) {
        super(props);
        this.handleAgregar= this.handleAgregar.bind(this);
        this.personaService = new GeneralServices();
        this.ChangeEstado = this.ChangeEstado.bind(this);
        this.state = {
            open: this.props.open,
            vehiculo:null
        };

    }

    handleAgregar(event){
        event.preventDefault();
        let vehi = new VehiculoModel();
        let tipo = typeof this.props.value ;

        let validar = this.ValidateForm();

        if (validar) {
           alert(validar);
            return;
        }

        if (tipo==='object')
        {
            vehi.id=this.props.value.id;
        }
        else
        {
            vehi.id = 0;
        }
        
        vehi.anio=this.getRef('txt_anio').getValue();
        vehi.color = this.getRef('txt_color').getValue();
        vehi.estado = this.state.estado?this.state.estado:this.props.value.estado?this.props.value.estado:'A';
        vehi.garaje = this.getRef('txt_estacionamiento').getValue();
        vehi.garajeNo = this.getRef('txt_garage').getValue();
        //vehi.idPersona = idPersona;
        vehi.marca = this.getRef('txt_marca').getValue();
        vehi.matricula = this.getRef('txt_matricula').getValue();
        vehi.modelo = this.getRef('txt_modelo').getValue();
        vehi.unidad = this.getRef('txt_unidad').getValue();
        vehi.numeroTag = this.getRef('txt_numero_tag').getValue();
       // console.log(vehi);

        this.setState({ vehiculo: vehi });
        this.props.addVehiculo(vehi);

    }

    ChangeEstado(event) {
        console.log(event.target.value);
        this.setState({
            estado: event.target.value
        });
    }

    render() {
        const val = this.props.value;
        var status='A'

        if (val.estado){
            if (this.state.estado){
                status=this.state.estado;
            }
            else{
                status=val.estado;
            }
        }
        else{
            if (this.state.estado){
                status=this.state.estado;
            }

        }

        const closeBtn = <button className="close" onClick={this.props.Close}>&times;</button>;
        return (
            <div>
                <Modal isOpen={this.props.open} centered >
                    <ModalHeader close={closeBtn}> {this.getText("titVehiculo")}</ModalHeader>
                    <ModalBody>
                        <Form  >
                            <FormGroup row>
                            <Col >
                                    <Label for="estados" className='inputNormal' >{this.getText("lblEstado")}</Label>
                                    <FormGroup check inline id="estados" >
                                        <Label check>
                                            <Input
                                                type='radio'
                                                name="estado"
                                                value='A'
                                                checked={status === 'A'}
                                                onChange={this.ChangeEstado}
                                            />{this.getText("lblActivo")}</Label>

                                    </FormGroup>
                                    <FormGroup check inline  >
                                        <Label check>
                                            <Input
                                                type='radio'
                                                name="estado"
                                                value='I'
                                                checked={status === 'I'}
                                                onChange={this.ChangeEstado}
                                            />{this.getText("lblInactivo")}</Label>
                                    </FormGroup>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Col sm={6}>
                                    <Text
                                        // label={this.getText("lblUnidad")}
                                        label={this.getText("lblAlias")}
                                        id={'txt_unidad'}
                                        value={val.unidad?val.unidad:""}
                                        type={'text'}
                                        ref={(ref) => this.setRef('txt_unidad', ref)}
                                        isRequired={true}
                                        menssage={`${this.getText("msgCampoRequerido")} ${this.getText("lblUnidad")}`}
                                    />
                                </Col>
                                <Col sm={6}>
                                    <Text
                                        label={this.getText("lblMatricula")}
                                        id={'txt_matricula'}
                                        value={val.matricula?val.matricula:""}
                                        type={'text'}
                                        ref={(ref) => this.setRef('txt_matricula', ref)}
                                        isRequired={true}
                                        menssage={`${this.getText("msgCampoRequerido")} ${this.getText("lblMatricula")}`}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Col sm={6}>
                                    <Text
                                        label={this.getText("lblAnio")}
                                        id={'txt_anio'}
                                        value={val.anio?""+val.anio:""}
                                        type={'number'}
                                        ref={(ref) => this.setRef('txt_anio', ref)}
                                        isRequired={true}
                                        menssage={`${this.getText("msgCampoRequerido")} ${this.getText("lblAnio")}`}
                                    />
                                </Col>
                                <Col sm={6}>
                                    <Text
                                        label={this.getText("lblColor")}
                                        id={'txt_color'}
                                        value={val.color?val.color:""}
                                        type={'text'}
                                        ref={(ref) => this.setRef('txt_color', ref)}
                                        isRequired={true}
                                        menssage={`${this.getText("msgCampoRequerido")} ${this.getText("lblColor")}`}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Col sm={6}>
                                    <Text
                                        label={this.getText("lblMarca")}
                                        id={'txt_marca'}
                                        value={val.marca?val.marca:""}
                                        type={'text'}
                                        ref={(ref) => this.setRef('txt_marca', ref)}
                                        isRequired={true}
                                        menssage={`${this.getText("msgCampoRequerido")} ${this.getText("lblMarca")}`}
                                    />
                                </Col>
                                <Col sm={6}>
                                    <Text
                                        label={this.getText("lblModelo")}
                                        id={'txt_modelo'}
                                        value={val.modelo?val.modelo:""}
                                        type={'text'}
                                        ref={(ref) => this.setRef('txt_modelo', ref)}
                                        isRequired={true}
                                        menssage={`${this.getText("msgCampoRequerido")} ${this.getText("lblModelo")}`}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Col sm={6}>
                                    <Text
                                        label={this.getText("lblEstacionamiento")}
                                        id={'txt_estacionamiento'}
                                        value={val.garaje?val.garaje:""}
                                        type={'text'}
                                        ref={(ref) => this.setRef('txt_estacionamiento', ref)}
                                    />
                                </Col>
                                <Col sm={6}>
                                    <Text
                                        label={this.getText("lblGarage")}
                                        id={'txt_garage'}
                                        value={val.garajeNo?val.garajeNo:""}
                                        type={'text'}
                                        ref={(ref) => this.setRef('txt_garage', ref)}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Col sm={6}>
                                    <Text
                                        label={this.getText("lblNumeroTag")}
                                        id={'txt_numero_tag'}
                                        value={val.numeroTag?val.numeroTag:""}
                                        type={'text'}
                                        ref={(ref) => this.setRef('txt_numero_tag', ref)}
                                    />
                                </Col>
                            </FormGroup>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={this.handleAgregar}>{this.getText("lblAceptar")}</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}