export default class PAlicuotaModel {
    constructor(
        idEtapa,
        valor,
        diaMaximoPago, 
        diasMorosidad,  
        id
    ) {
        this.idEtapa = idEtapa;
        this.valor = valor;
        this.id = id;
        this.diaMaximoPago=diaMaximoPago;
        this.diasMorosidad=diasMorosidad;
    }

    route() {
        return "configalicuota";
    }
}