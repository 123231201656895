import React from 'react';
import Base from '../Base';
import { Alert } from 'reactstrap';

// Otros Componentes
import './styles.css';

export default class Alerta extends Base {



    render() {
        return (
            //  <div className="myAlert-top" >
            <Alert
                color={this.props.error ? "danger" : "success"}
                isOpen={this.props.open} >

                {this.props.mensaje.split("\n").map(function (item,index) {
                    return (<p key={index} className="mb-0">
                        {item}</p>

                    )
                })}

            </Alert>
            //  </div>
        );
    }
}