import React from 'react';
import Base from '../../components/Base';
import { Col, Form, FormGroup, Label, Input } from 'reactstrap';
import Button from '../../components/Button';
import Text from "../../components/Text";
import Tag from "../../components/Tag";
import './style.css';
import PAlicuotaModel from '../../models/PAlicuotaModel.js';
import GeneralServices from '../../services/GeneralServices.js';
import Loader from '../../components/Loader';
import Return from '../../components/Return';

export default class ParametroAlicuota extends Base {


    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.ParametroService = new GeneralServices();

        this.state =
        {
            loading: false,
            diasMorosidad: []
        };
    }


    changeDia(e) {
        let parametro = this.state.parametro;
        parametro.diaMaximoPago = e.target.value
        this.setState({
            parametro: parametro
        })
    }

    handleSubmit(event) {
        event.preventDefault();
        this.setState({ loading: true });

        let opcion = new PAlicuotaModel();

        opcion.idEtapa = this.getEtapa();
        opcion.valor = this.getRef('txt_valor').getValue();
        opcion.diaMaximoPago = this.cmbDia.value;
        opcion.id = this.state.parametro.id;
        opcion.diasMorosidad = JSON.stringify(this.getRef('txt_tag').getTags());

        console.log(opcion.diasMorosidad);

        this.ParametroService.save(opcion, opcion.route())
            .then(result => {
                if (result.codigo !== "0") {
                    alert(result.mensaje);

                    if (result.codigo === 401) {
                        this.CerarSesion();
                    }
                }
                else {
                    alert(this.getText("msgGuardadoOk"));
                    this.setState({ loading: false });
                    window.location.href="/Cobros";
                    //this.props.history.push('/Cobros');
                }

            }
            )
            .catch(error => {
                console.log(error);
                this.setState({ loading: false });
            });
    }

    componentDidMount() {

        //console.log("ingresa");
        let opcion = new PAlicuotaModel();
        let opcionDefault = this.getOpcion("alicuotaDefault");


        if (opcionDefault !== "") {
            opcion.valor = opcionDefault;
            opcion.idEtapa = this.getEtapa();
            opcion.diaMaximoPago = this.getOpcion("DiaMaximoPago");
            opcion.id = 0;
        }


        this.ParametroService.Id(this.getEtapa(), opcion.route() + '/etapa')
            .then(result => {
                //console.log(result);
                if (result.codigo !== "0") {
                    alert(result.mensaje);
                    if (result.codigo === 401) {
                        this.CerarSesion();
                    }
                }
                else {
                    if (result.data === null) {

                        this.setState({ parametro: opcion });
                    }
                    else {
                        this.setState({ parametro: result.data, diasMorosidad: JSON.parse(result.data.diasMorosidad === null ? '[]' : result.data.diasMorosidad) });
                    }
                }
            }
            )
            .catch(error => {
                console.log(error);
            });
    }

    render() {

        let dias = [];

        for (let index = 1; index < 29; index++) {
            dias[index] = index;
        }

        if (this.state.parametro) {
            return (
                <div className='formTitulo' >

                    <FormGroup row >
                        <Col md={9}>
                            <h5><Return onClick={() => this.props.history.go(-1)} />
                                {this.getText("titValorAlicuotas")}</h5>
                        </Col>
                        <Col md={3} className="text-right">
                            <Button type="submit"
                                onClick={this.handleSubmit}
                                className="tertiaryColorB"
                                value={this.getText("lblGuardar")} />
                        </Col>
                    </FormGroup>
                    <div className='form'>
                        <Form  >
                            <FormGroup row>
                                <Col>
                                    <Text
                                        label={this.getText("lblValor")}
                                        id={'txt_valor'}
                                        value={"" + this.state.parametro.valor}
                                        type="number"
                                        ref={(ref) => this.setRef('txt_valor', ref)}
                                    />
                                </Col>
                            </FormGroup>

                            <FormGroup row>
                                <Col>
                                    <div className="dropdown">
                                        <Label for="cmbDia" >{this.getText("lblDia")}</Label>
                                        <Input type="select" id="cmbDia"
                                            innerRef={(c) => this.cmbDia = c}
                                            onChange={this.changeDia.bind(this)}
                                            value={"" + this.state.parametro.diaMaximoPago} >
                                            {dias.map((dia) => { return <option key={dia}  >{dia}</option>; })}
                                        </Input>
                                    </div>
                                </Col>
                            </FormGroup>


                            <Tag
                                type={"number"}
                                label={this.getText("lblControlMorosidad")}
                                ref={(ref) => this.setRef('txt_tag', ref)}
                                value={this.state.diasMorosidad}
                            />


                        </Form>
                    </div>
                    {(this.state.loading) ? <Loader /> : <span />}
                </div>
            );
        }
        else {
            return (
                <div>
                    <Loader />
                </div>);
        }
    }
}