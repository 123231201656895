import React from 'react';
import Base from '../../components/Base';
import cookies from 'react-cookies';
import { Col, Form, FormGroup, Label } from 'reactstrap';
import Button from '../../components/Button';
import Text from "../../components/Text";
import './style.css';
import OpcionModel from '../../models/OpcionModel.js';
import GeneralServices from '../../services/GeneralServices.js';
import Loader from '../../components/Loader';
import Return from '../../components/Return';

export default class Parametro extends Base {


    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.ParametroService = new GeneralServices();

        this.state =
        {
            loading: false
        };
    }


    handleChange(event) {
        this.setState({
            estado: event.target.value
        });
    }

    handleSubmit(event) {
        event.preventDefault();
        this.setState({ loading: true });

        let opcion = new OpcionModel();

        let valor = `{ "horas":"${this.getRef('txt_horas').getValue()}","dias":"${this.getRef('txt_dias').getValue()}"}`;

        opcion.nombre = `vigencia_qr_${cookies.load('etapa')}`;
        opcion.valor = valor;
        opcion.id = this.state.parametro.id;
        opcion.estado = this.state.parametro.estado;


        this.ParametroService.save(opcion, opcion.route())
            .then(result => {
                if (result.codigo !== "0") {
                    alert(result.mensaje);

                    if (result.codigo === 401) {
                        this.CerarSesion();
                    }
                }
                else {
                    alert(this.getText("msgGuardadoOk"));
                    window.location.href="/Invitados";
                    //this.props.history.push('/Invitados');
                }
                this.setState({ loading: false });
            }
            )
            .catch(error => {
                console.log(error);
                this.setState({ loading: false });
            });
    }

    componentDidMount() {
        let opcion = new OpcionModel();
        let opcionDefault = this.getOpcion("vigencia_qr");
        if (opcionDefault !== "") {
            opcion.valor = opcionDefault;
            opcion.estado = 'A';
            opcion.id = 0;
        }

        this.ParametroService.Id(`vigencia_qr_${cookies.load('etapa')}`, opcion.route() + '/nombre')
            .then(result => {
                //  console.log(result);
                if (result.codigo !== "0") {
                    alert(result.mensaje);
                    if (result.codigo === 401) {
                        this.CerarSesion();
                    }
                }
                else {
                    if (result.data === null) {
                        this.setState({ parametro: opcion });
                    }
                    else {
                        this.setState({ parametro: result.data });
                    }
                }
            }
            )
            .catch(error => {
                console.log(error);
            });
    }

    render() {
        if (this.state.parametro) {
            const data = JSON.parse(this.state.parametro.valor);
            return (
                <div className='formTitulo' >

                    <FormGroup row >
                        <Col md={9}>
                            <h5><Return onClick={() => this.props.history.go(-1)} />
                                {this.getText("titInvitacion")}</h5>
                        </Col>
                        <Col md={3} className="text-right">
                            <Button type="submit"
                                onClick={this.handleSubmit}
                                className="tertiaryColorB"
                                value={this.getText("lblGuardar")} />
                        </Col>
                    </FormGroup>
                    <div className='form'>
                        <Form  >
                            <FormGroup row >
                                <Col >
                                    <Label className='inputNormal' >{this.getText("titInvitacionQR")}</Label>
                                </Col>
                            </FormGroup >
                            <FormGroup row>
                                <Col>
                                    <Text
                                        label={this.getText("lblInvitacionHora")}
                                        id={'txt_horas'}
                                        value={data.horas}
                                        type="number"
                                        ref={(ref) => this.setRef('txt_horas', ref)}
                                    />
                                </Col>
                            </FormGroup>

                            <FormGroup row>
                                <Col>
                                    <Text
                                        label={this.getText("lblInvitacionDias")}
                                        id={'txt_dias'}
                                        value={data.dias}
                                        type="number"
                                        ref={(ref) => this.setRef('txt_dias', ref)}
                                    />
                                </Col>
                            </FormGroup>
                        </Form>
                    </div>
                    {(this.state.loading) ? <Loader /> : <span />}
                </div>
            );
        }
        else {
            return (
                <div>
                    <Loader />
                </div>);
        }
    }
}