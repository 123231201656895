import React from 'react';
import Base from '../../components/Base';
import { Col, Form, FormGroup, Label, CustomInput, Input } from 'reactstrap';
import Button from '../../components/Button';
import Text from "../../components/Text";
import './style.css';
import CobroModel from '../../models/CobroModel.js';
import GeneralServices from '../../services/GeneralServices.js';
import Loader from '../../components/Loader';
import moment from 'moment';
import Dropdown from "../../components/Dropdown";
import ComboModel from '../../models/ComboModel.js';
import PAlicuotaModel from '../../models/PAlicuotaModel.js';
import { pad } from '../../libs/TBSUtil/index.js';
import Return from '../../components/Return';
import Pago from "../../components/Pago";

export default class Cobro extends Base {

    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.changeMesesPago = this.changeMesesPago.bind(this);
        this.changeManzana = this.changeManzana.bind(this);
        this.changeVilla = this.changeVilla.bind(this);
        this.changeDepartamento = this.changeDepartamento.bind(this);
        this.CobroService = new GeneralServices();


        this.state =
        {
            loading: false,
            manzanas: [],
            villas: []
        };
    }

    changeMesesPago(event) {

        const val = event.target.value
        this.getRef("cmbMesesPago").updateValue(val);
        this.getRef("txt_valor").updateValue(val * this.state.cobro.valor === 0 ? this.state.valorDefault : this.state.cobro.valor);

    }

    changeManzana(event) {

        const mz = event.target.value
        this.getRef("cmbManzana").updateValue(mz);
        this.setVilla(mz);
        this.setState({ departamentos: null, ubicacion: null });

    }

    changeVilla(event) {
        const vl = event.target.value
        this.getRef("cmbVilla").updateValue(vl);
        this.setDepartamento(vl);

    }

    changeDepartamento(event) {
        const dpt = event.target.value
        this.getRef("cmbDept").updateValue(dpt);

        let datos = this.state.ubicaciones;
        var mz = this.getRef("cmbManzana").getValue();
        var vl = this.getRef("cmbVilla").getValue();

        var dept = datos.filter(function (ubi) {
            return ubi.manzana === mz && ubi.villa === vl && ubi.departamento === dpt;
        });

        this.setState({ ubicacion: dept[0] });
    }

    setVilla(mz) {
        let datos = this.state.ubicaciones;
        var vill = datos.filter(function (ubi) {
            return ubi.manzana === mz;
        });

        const villas = [...new Set(vill.map(x => x.villa))];
        const cmbVl = []
        villas.sort();
        for (const vl of villas) {
            cmbVl.push(new ComboModel(vl, vl));
        }
        this.setState({ villas: cmbVl });
    }

    setDepartamento(vl) {
        let datos = this.state.ubicaciones;

        var mz = this.getRef("cmbManzana").getValue();

        var dept = datos.filter(function (ubi) {
            return ubi.manzana === mz && ubi.villa === vl;
        });

        const departamentos = [...new Set(dept.map(x => x.departamento))];
        const cmbDept = []
        departamentos.sort();
        if (departamentos[0] !== null && departamentos[0] !== "") {
            for (const dep of departamentos) {
                cmbDept.push(new ComboModel(dep, dep));
            }
            this.setState({ departamentos: cmbDept });
        }
        else {
            this.setState({ ubicacion: dept[0], departamentos: null });
        }
    }

    cargaUbicacion() {
        this.CobroService.List(this.getEtapa(), 'ubicacion/etapa')
            .then(result => {
                if (result.codigo !== "0") {
                    alert(result.mensaje);
                    if (result.codigo === 401) {
                        this.CerarSesion();
                    }
                }
                else {
                    //  console.log(result.data);
                    const manzanas = [...new Set(result.data.map(x => x.manzana))];
                    const cmbMz = []
                    manzanas.sort()

                    for (const mz of manzanas) {
                        cmbMz.push(new ComboModel(mz, mz));
                    }

                    this.setState({ manzanas: cmbMz, ubicaciones: result.data });
                    this.cargarDatosAlicuota();
                }

            })
            //.then(resultado2 =>)
            .catch(error => {
                console.log(error);
            });
    }

    cargarDatosAlicuota() {
        let cobro = new CobroModel();
        const id = this.props.match.params.id;

        cobro.id = id;

        if (id > 0) {

            this.CobroService.Id(id, cobro.route() + '/id')
                .then(result => {
                    if (result.codigo !== "0") {
                        alert(result.mensaje);
                        if (result.codigo === 401) {
                            this.CerarSesion();
                        }
                    }
                    else {

                        if (result.data === null) {
                            alert(this.getText("lblNoHayDatosId") + id);
                            window.location.href = '/Cobros';
                            //this.props.history.push('/Cobros');
                            return;
                        }
                        let ubi = result.data.ubicacion;
                        this.setState({ ubicacion: ubi });

                        if (result.data.fechaPago === null) {
                            result.data.fechaPago = new Date();
                        }

                        if (ubi) {
                            this.setVilla(ubi.manzana);
                            if (!ubi.departamento) {
                                result.data.ubicacion.departamento = "";
                            }
                        }

                        this.setState({ cobro: result.data });
                        this.getRef("cmbManzana").updateValue(ubi.manzana);
                        this.getRef("cmbVilla").updateValue(ubi.villa);
                        if (ubi.departamento) {
                            this.setDepartamento(ubi.villa);
                            this.getRef("cmbDept").updateValue(ubi.departamento);
                        }

                        if (result.data.formaPago != null) {
                            this.getRef("cmbFormaPago").updateValue(result.data.formaPago);
                        }

                        this.getRef("cmbTipoCobro").updateValue(result.data.tipoCobro);
                    }
                }
                )
                .catch(error => {
                    console.log(error);
                });

        }
        else {
            cobro.mes = moment().format("MM");
            cobro.anio = moment().year();
            cobro.valor = 0;
            cobro.fechaIngreso = new Date();
            cobro.fechaPago = new Date();
            cobro.usuario_ingreso = this.getUsuario();
            this.CargarAlicuota();
            this.setState({ cobro: cobro });
        }
    }

    CargarAlicuota() {
        let opcion = new PAlicuotaModel();
        let opcionDefault = this.getOpcion("alicuotaDefault");

        this.CobroService.Id(this.getEtapa(), opcion.route() + '/etapa')
            .then(result => {
                if (result.codigo !== "0") {
                    alert(result.mensaje);
                    if (result.codigo === 401) {
                        this.CerarSesion();
                    }
                }
                else {
                    if (result.data === null) {
                        if (opcionDefault === "") {
                            console.log("Debe configurar un valor de cobro");
                            return;
                        }
                        this.setState({ valorDefault: opcionDefault });
                        this.getRef("txt_valor").updateValue(opcionDefault);
                    }
                    else {
                        this.setState({ valorDefault: result.data.valor });
                        this.getRef("txt_valor").updateValue(result.data.valor);
                    }
                }
            }
            )
            .catch(error => {
                console.log(error);
            });
    }

    handleSubmit(event) {
        event.preventDefault();

        if (!this.state.ubicacion) {
            alert(this.getText("msgSelUbicacion"));
            return;
        }

        let file = this.getRef('cmbArchivoPago').state.file;

        this.setState({ loading: true });

        if (typeof file =='string') {
            this.guardarCobro(file);
        }
        else {
            let formData = new FormData();
            formData.append('file', file);
            this.CobroService.Upload(formData, 'archivo/subir')
                .then(result => {
                    console.log(result);
                    if (result.codigo !== "0") {
                        alert(result.mensaje);
                        if (result.codigo === 401) {
                            this.CerarSesion();
                        }
                        this.setState({ loading: false });
                    }
                    else {
                        this.guardarCobro(result.data.url);
                    }
                }
                )
                .catch(error => {
                    console.log(error);
                    this.setState({ loading: false });
                });
        }

    }

    guardarCobro(file) {
        let cobro = new CobroModel();
        var fecha = this.fecha.value
        cobro.archivo = file;
        cobro.idEtapa = this.getEtapa();
        cobro.valor = this.state.cobro.valor === 0 ? this.state.valorDefault : this.state.cobro.valor;
        cobro.id = this.state.cobro.id;
        cobro.usuario_modificacion = this.getUsuario();
        cobro.usuario_ingreso = cobro.id > 0 ? this.state.cobro.usuario_ingreso : this.getUsuario();
        cobro.anio = this.cmbAnio.value;
        cobro.mes = this.cmbMes.selectedIndex + 1;
        cobro.fechaIngreso = this.state.cobro.fechaIngreso;
        let _fecha = moment(fecha);

        cobro.fechaPago = _fecha.toDate();
        cobro.pagado = true;
        cobro.formaPago = this.getRef('cmbFormaPago').getValue();
        cobro.tipoCobro = this.getRef('cmbTipoCobro').getValue();
        cobro.banco = this.getRef('txt_banco').getValue();
        cobro.referencia = this.getRef('txt_referencia').getValue();
        cobro.ubicacion = this.state.ubicacion;

        let meses = 1;// this.getRef("cmbMesesPago").getValue();
        this.CobroService.save(cobro, `${cobro.route()}/${meses}`)
            .then(result => {
                if (result.codigo !== "0") {
                    alert(result.mensaje);

                    if (result.codigo === 401) {
                        this.CerarSesion();
                    }
                    this.setState({ loading: false });
                }
                else {
                    alert(this.getText("msgGuardadoOk"));
                    this.setState({ loading: false, cobro: result.data });
                    //this.props.history.push('/Cobros');
                }
            })
            .catch(error => {
                console.log(error);
                this.setState({ loading: false });
            });
    }

    componentDidMount() {
        this.cargaUbicacion();
    }

    render() {
        if (this.state.cobro) {

            let anio_actual = this.state.cobro.id > 0 ? this.state.cobro.anio : moment().year() + 1;
            let anios = [];

            for (let index = 0; index < 2; index++) {
                anios[index] = anio_actual - index;
            }
            const desabilitar = this.state.cobro.id > 0 ? true : false;

            let meses = moment.months();
            return (
                <div className='formTitulo' >

                    <FormGroup row >
                        <Col md={9}>
                            <h5><Return onClick={() => this.props.history.go(-1)} />{this.getText("titCobro")}</h5>
                        </Col>
                        <Col md={3} className="text-right">
                            <Button type="submit"
                                onClick={this.handleSubmit}
                                className="tertiaryColorB"
                                value={this.getText("lblGuardar")} />
                        </Col>
                    </FormGroup>
                    <div className='form'>
                        <Form encType="multipart/form-data" >
                            <FormGroup row>
                                <Col className="text-right">
                                    <Label className="inputNormal">{this.getText("lblComprobante") + ' #: '}{pad(this.state.cobro.id, this.getOpcion("TamañoComprobante"))}</Label>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Col>
                                    <div className="dropdown">
                                        <Label for="cmbMes" className="inputNormal">{this.getText("lblAnio")}</Label>
                                        <CustomInput type="select"
                                            id="cmbAnio"
                                            disabled={desabilitar}
                                            innerRef={(c) => this.cmbAnio = c}
                                            defaultValue={this.state.cobro.anio} >
                                            {anios.map((anio) => { return <option key={anio}  >{anio}</option>; })}
                                        </CustomInput>
                                    </div>
                                </Col>
                                <Col>
                                    <div className="dropdown">
                                        <Label for="cmbMes" className="inputNormal">{this.getText("lblMes")}</Label>
                                        <CustomInput type="select"
                                            id="cmbMes"
                                            //disabled={true}
                                            innerRef={(c) => this.cmbMes = c}
                                            disabled={desabilitar}
                                            defaultValue={moment.months(this.state.cobro.mes - 1)}>
                                            {meses.map((mes, index) => { return <option key={index} >{mes}</option>; })}
                                        </CustomInput>
                                    </div>
                                </Col>
                            </FormGroup>

                            <FormGroup row >
                                <Col >
                                    <Dropdown
                                        id="cmbManzana"
                                        label={this.getText("lblManzana")}
                                        ref={(ref) => this.setRef('cmbManzana', ref)}
                                        value={this.state.manzanas}
                                        tipo="N"
                                        onChange={this.changeManzana}
                                        disabled={desabilitar}
                                        isRequired={true}
                                        menssage={`${this.getText("msgCampoRequerido")} ${this.getText("lblManzana")}`}
                                    />
                                </Col>
                                <Col >
                                    <Dropdown
                                        id="cmbVilla"
                                        label={this.getText("lblLote")}
                                        ref={(ref) => this.setRef('cmbVilla', ref)}
                                        value={this.state.villas ? this.state.villas : []}
                                        tipo="N"
                                        onChange={this.changeVilla}
                                        disabled={desabilitar}
                                        isRequired={true}
                                        menssage={`${this.getText("msgCampoRequerido")} ${this.getText("lblLote")}`}
                                    />
                                </Col>

                                {
                                    this.state.departamentos ?
                                        <Col >
                                            <Dropdown
                                                id="cmbDept"
                                                label={this.getText("lblDepartamento")}
                                                ref={(ref) => this.setRef('cmbDept', ref)}
                                                value={this.state.departamentos}
                                                onChange={this.changeDepartamento}
                                                tipo="N"
                                                disabled={desabilitar}
                                            />
                                        </Col> :
                                        ""
                                }

                            </FormGroup >
                            <FormGroup row>
                                <Col >
                                    <Dropdown
                                        id="cmbTipoCobro"
                                        ref={(ref) => this.setRef('cmbTipoCobro', ref)}
                                        label={this.getText("lblTipoCobro")}
                                        value={JSON.parse(this.getOpcion("TipoCobro"))}
                                        disabled={true}
                                    />
                                </Col>
                                <Col >

                                    {/* // !this.state.cobro.pagado ?
                                        //     <Dropdown
                                        //         id="cmbMesesPago"
                                        //         ref={(ref) => this.setRef('cmbMesesPago', ref)}
                                        //         label={this.getText("lblMesesPagar")}
                                        //         onChange={this.changeMesesPago}
                                        //         value={JSON.parse(this.getOpcion("MesesPago"))}
                                        //     />
                                        //     :  */}
                                    <div className="dropdown">

                                        <Label for="dtpfecha" className="inputNormal">{this.getText("lblFecha")}</Label>
                                        <Input
                                            type="date"
                                            name="date"
                                            id="dtpfecha"
                                            defaultValue={moment(this.state.cobro.fechaPago).format('YYYY-MM-DD')}
                                            min="2000-01-01"
                                            max="2500-01-01"
                                            innerRef={(c) => this.fecha = c}
                                        />
                                    </div>
                                </Col>

                            </FormGroup>

                            <FormGroup row>
                                <Col >
                                    <Dropdown
                                        id="cmbFormaPago"
                                        ref={(ref) => this.setRef('cmbFormaPago', ref)}
                                        label={this.getText("lblFormaPago")}
                                        value={JSON.parse(this.getOpcion("FormaPago"))}
                                    //disabled={this.state.cobro.pagado}
                                    />
                                </Col>
                                <Col >
                                    <Text
                                        label={this.getText("lblBanco")}
                                        id={'txt_banco'}
                                        value={this.state.cobro.banco ? this.state.cobro.banco : ""}
                                        type={'text'}
                                        ref={(ref) => this.setRef('txt_banco', ref)}
                                    //disabled={this.state.cobro.pagado}
                                    />
                                </Col>

                            </FormGroup>

                            <FormGroup row>
                                <Col >
                                    <Text
                                        label={this.getText("lblReferencia")}
                                        id={'txt_referencia'}
                                        value={this.state.cobro.referencia ? this.state.cobro.referencia : ""}
                                        type={'text'}
                                        ref={(ref) => this.setRef('txt_referencia', ref)}
                                    //disabled={this.state.cobro.pagado}
                                    />
                                </Col>
                                <Col>
                                    <Text
                                        label={this.getText("lblValor")}
                                        id={'txt_valor'}
                                        value={"" + this.state.cobro.valor}
                                        type="number"
                                        ref={(ref) => this.setRef('txt_valor', ref)}
                                        disabled={true}
                                    />
                                </Col>
                            </FormGroup>
                            <Pago
                                ref={(ref) => this.setRef('cmbArchivoPago', ref)}
                                file={"" + this.state.cobro.archivo}
                            />

                        </Form>
                    </div>
                    {(this.state.loading) ? <Loader /> : <span />}
                </div>
            );
        }
        else {
            return (
                <div>
                    <Loader />
                </div>);
        }
    }
}