export default class VehiculoModel {
    constructor(
        id,
        anio,
        color,
        detalle,
        //fecha_ingreso,
       // fecha_modificacion,
        garaje,
        garajeNo,
        estado,
        marca,
        matricula,
        modelo,
        unidad,
        numeroTag
        
    ) {
        this.id = id;
        this.anio=anio;
        this.color = color;
        this.detalle = detalle;
        this.garaje = garaje;
        this.garajeNo = garajeNo;
        this.estado = estado;
        this.marca = marca;
        this.matricula = matricula;
        this.modelo = modelo;
        this.unidad = unidad;
        this.numeroTag = numeroTag;
    }

    route() {
        return "usuario";
    }

    routePersona() {
        return "persona";
    }
}
