// Componente base
import React from 'react';
import PropTypes from 'prop-types';
import Base from '../Base';
// Otros Componentes
import './styles.css';
import { Label, Input } from 'reactstrap';

export default class Dropdown extends Base {

    constructor(props) {
        super(props);

        this.state = {
            value: this.props.tipo ? "" : (this.props.default ? this.props.default : this.props.value[0].key),
            text: this.props.value.length > 0 ? this.props.value[0].value : "",
            data: this.props.value
        };
        this.handleChange = this.handleChange.bind(this);
        this.clearValue = this.clearValue.bind(this);
        this.updateValue = this.updateValue.bind(this);
    };

    createItems(data, tipo) {
        var options = [];
        if (tipo === "T") {
            options.push(<option key={tipo} value={""}  >{this.getText("lblTodos")}</option>);
        }
        if (tipo === "N") {
            options.push(<option key={tipo} value={""}  >{this.getText("lblSeleccionar")}</option>);
        }
        //valida si contiene grupos
        var grupos = data.length > 1 ? data.filter((grupo) => {
            return grupo.key.indexOf("GR") > -1
        }) : [];
        if (grupos.length > 0) {
            for (var i = 0; i < grupos.length; i++) {
                const id = +new Date() + i;
                var grps = data.filter((grupo) => { return (grupo.key.indexOf(grupos[i].key.slice(3)) > -1 && grupo.key !== grupos[i].key) });
                var opt = [];

                for (var j = 0; j < grps.length; j++) {

                    opt.push(<option key={grps[j].key} value={grps[j].key} label={grps[j].value}>{grps[j].value}</option>);
                }

                options.push(<optgroup key={id} label={grupos[i].value}>{opt}</optgroup>);
            }

        }
        else {
            for (var i = 0; i < data.length; i++) {

                options.push(<option key={data[i].key} value={data[i].key} label={data[i].value}>{data[i].value}</option>);
            }

        }
        return options;
    };

    getLabel(label, id) {
        if (label !== "" && label !== undefined) {
            return (
                <Label for={id}
                    className='inputNormal'  >

                    {label}
                </Label>
            );
        }

    };

    getTexto(key) {
        for (var c of this.state.data) {
            if (c.key === key) {
                this.setState({ text: c.value })
            }
        }
    };

    handleChange(event) {
        this.getTexto(event.target.value);
        this.setState({
            value: event.target.value
        });
    };
    clearValue() {
        this.setState({ value: this.props.value[0].key });
    };

    updateValue(value) {
        this.setState({ value: value });
    };


    render() {
        const { label, id, tipo } = this.props;
        const data = this.state.data !== this.props.value ? this.props.value : this.state.data;
        const valDefault = this.state.value;
        const defaultClassName = "dropdown";
        const _className = defaultClassName;
        return (
            <div className={_className}>
                {this.getLabel(label, id)}
                <Input
                    type="select"
                    name="select"
                    id={id}
                    onChange={this.props.onChange ? this.props.onChange : this.handleChange}
                    disabled={this.props.disabled}
                    value={valDefault}
                >
                    {this.createItems(data, tipo)}
                </Input>
            </div>

        )
    }
}
// Seccion de Props
Dropdown.propTypes = Dropdown.propTypes || {
    label: PropTypes.string,
    value: PropTypes.array,
    id: PropTypes.string
}
