// Componente base
import React from 'react';
import Base from '../../components/Base';
import { Link } from 'react-router-dom';
import matchSorter from 'match-sorter';
import { Input, FormGroup, Label, Col, Card, CardHeader, CardBody, Row, Form } from 'reactstrap';
import Button from '../../components/Button';
import GridTable from '../../components/GridTable';
import GeneralServices from '../../services/GeneralServices.js';
import './style.css';
import moment from 'moment';
import ComboModel from '../../models/ComboModel.js';
import { pad } from '../../libs/TBSUtil/index.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'


export default class CobroList extends Base {

    constructor(props) {
        super(props);
        this.servicio = new GeneralServices();

        let cmbTipo = this.getOpcion("FormaPago");
        var tipodata = cmbTipo === "" ? [] : JSON.parse(cmbTipo);

        var columns = [
            {
                columns: [
                    {
                        Header: this.getText("lblComprobante"),
                        id: "id",
                        accessor: d => d.id,
                        filterMethod: (filter, rows) =>
                            matchSorter(rows, filter.value, { keys: ["id"] }),
                        filterAll: true,
                        style: { textAlign: "right" }
                    },
                    {
                        Header: this.getText("lblManzana"),
                        id: "manzana",
                        width: 50,
                        accessor: d => d.manzana,
                        filterMethod: (filter, rows) =>
                            matchSorter(rows, filter.value, { keys: ["manzana"] }),
                        filterAll: true,
                        style: { textAlign: "right" }
                    },

                    {
                        Header: this.getText("lblLote"),
                        id: "villa",
                        accessor: d => d.villa,
                        filterMethod: (filter, rows) =>
                            matchSorter(rows, filter.value, { keys: ["villa"] }),
                        filterAll: true,
                        width: 80,
                        style: { textAlign: "right" }
                    },

                    {
                        Header: this.getText("lblDepartamento"),
                        id: "departamento",
                        accessor: d => d.departamento,
                        filterMethod: (filter, rows) =>
                            matchSorter(rows, filter.value, { keys: ["departamento"] }),
                        filterAll: true,
                        width: 90,
                        style: { textAlign: "right" }
                    },


                    {
                        Header: this.getText("lblEstado"),
                        id: "estado",
                        accessor: d => d.estado,
                        filterMethod: (filter, rows) =>
                            matchSorter(rows, filter.value, { keys: ["estado"] }),
                        filterAll: true,
                        width: 100,
                    },
                    {
                        Header: this.getText("lblFechaPago"),
                        id: "fecha",
                        width: 150,
                        accessor: d => d.fecha,
                        filterMethod: (filter, rows) =>
                            matchSorter(rows, filter.value, { keys: ["fecha"] }),
                        filterAll: true,
                        style: { textAlign: "right" }
                    },
                    {
                        Header: this.getText("lblFormaPago"),
                        accessor: "tipo",
                        id: "tipo",
                        filterMethod: (filter, row) => {
                            if (filter.value === "all") {
                                return true;
                            }
                            else {
                                return row[filter.id] === filter.value;
                            }

                        },
                        Filter: ({ filter, onChange }) =>
                            <select
                                onChange={event => onChange(event.target.value)}
                                style={{ width: "100%" }}
                                value={filter ? filter.value : "all"}
                            >
                                <option value="all">{this.getText("lblTodos")}</option>
                                {
                                    tipodata.map(k => {
                                        return <option key={k.key} value={k.value}>{k.value}</option>
                                    })
                                }
                            </select>
                    },
                    {
                        Header: this.getText("lblReferenciaPago"),
                        id: "ref",
                        accessor: d => d.ref,
                        filterMethod: (filter, rows) =>
                            matchSorter(rows, filter.value, { keys: ["ref"] }),
                        filterAll: true,
                        style: { textAlign: "right" }
                    },
                ]
            },
            {
                Header: "",
                columns: [
                    {
                        Header: "",
                        accessor: "buscar",
                        filterable: false,
                        sortable: false,
                        style: { textAlign: "center" }
                    }
                ]
            }
        ];

        this.state = {
            dataGridRows: [],
            dataGridColumns: columns,
            manzanas: [],
            loading: false,
            formaPago: tipodata
        }
    };

    setValue(rows) {
        this.setState({ dataGridRows: rows });
    };

    Parametros(e) {
        e.preventDefault();
        window.location.href='/parametroAlicuota';
        //this.props.history.push("/parametroAlicuota");
    }

    Generar(e) {
        e.preventDefault();
        window.location.href='/cobro/id/0';
        //this.props.history.push("/cobro/id/0");
    }

    Buscar(e) {
        if (e!==undefined)
            e.preventDefault();

        let mes = this.cmbMes.selectedIndex;
        let mz = this.cmbMz.selectedIndex;
        let etapa = this.getEtapa();
        if (mes === 0) {
            alert(this.getText("lblSeleccionar") + this.getText("lblMes"));
            return;
        }


        this.setState({ loading: true });

        this.servicio.List(mz !== 0 ? this.cmbMz.value : "", `cobro/etapa/${etapa}/${this.cmbAnio.value}/${mes}/${this.cmbTipoCobro.value}`)
            .then(result => {
                if (result.codigo !== "0") {
                    alert(result.mensaje);
                    if (result.codigo === 401) {
                        this.CerarSesion();
                    }
                }
                else {
                    var rows = [];
                    result.data.map(cob =>
                        rows.push({
                            id: pad(cob.id, this.getOpcion("TamañoComprobante")),
                            manzana: cob.ubicacion.manzana,
                            villa: cob.ubicacion.villa,
                            departamento: cob.ubicacion.departamento,
                            estado: cob.pagado ? this.getText("lblPagado") : this.getText("lblPendiente"),
                            fecha: cob.fechaPago ? new Date(cob.fechaPago).toLocaleDateString() : "",
                            tipo: cob.formaPago ? this.state.formaPago.filter(tipo => tipo.key === cob.formaPago)[0].value : "",
                            ref: cob.banco,
                            buscar: this.link(cob.id)
                        }),
                    )
                    this.setValue(rows);
                }
                this.setState({ loading: false });

            }
            )
            .catch(error => {
                console.log(error);
                this.setState({ loading: false });
            });

    }

    componentDidMount() {
       this.cargaUbicacion();

        this.Buscar();
    }

    cargaUbicacion() {
        this.servicio.List(this.getEtapa(), 'ubicacion/etapa')
            .then(result => {
                if (result.codigo !== "0") {
                    alert(result.mensaje);
                    if (result.codigo === 401) {
                        this.CerarSesion();
                    }
                }
                else {
                    const manzanas = [...new Set(result.data.map(x => x.manzana))];
                    const cmbMz = []
                    manzanas.sort()

                    for (const mz of manzanas) {
                        cmbMz.push(new ComboModel(mz, mz));
                    }

                    this.setState({ manzanas: cmbMz, ubicaciones: result.data });
                }
            })
            .catch(error => {
                console.log(error);
            });
    }

    //Fucion para mostrar icono y Link
    link(id) {
        var link = [];
        link.push(
            <Link key={id} to={"/cobro/id/" + id}>
                <h6>{this.getText("lblVer")}</h6>
            </Link>)
        return link[0]
    }

    render() {

        let meses = moment.months();
        let anio_actual = moment().year() + 1;
        let anios = [];

        let TipoCobro = JSON.parse(this.getOpcion("TipoCobro"));

        for (let index = 0; index < 5; index++) {
            anios[index] = anio_actual - index;
        }
        return (
            <div>
                <div className="mainContent">
                    <div className="contentBlock">
                        <Card >
                            <CardHeader tag="h5">
                                <Row  >
                                    <Col sm={8}>
                                        {this.getText("titCobros")}
                                    </Col>
                                    <Col sm={2} >
                                        <Button className="float-right"
                                            onClick={(e) => this.Generar(e)}
                                            disabled={this.state.loading}
                                            value={this.getText("lblGenerar")} />
                                    </Col>
                                    <Col sm={2} >
                                        <Button className="float-right"
                                            onClick={(e) => this.Parametros(e)}
                                            disabled={this.state.loading}
                                            value={this.getText("lblParametros")} />
                                    </Col>

                                </Row>

                            </CardHeader>
                            <CardBody>
                                <Form inline>
                                    <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                        <Label for="cmbTipoCobro" className="mr-sm-2">{this.getText("lblTipoCobro")}</Label>
                                        <Input type="select" id="cmbTipoCobro"
                                            innerRef={(c) => this.cmbTipoCobro = c} >
                                            {TipoCobro.map((dato) => { return <option key={dato.key} value={dato.key} >{dato.value}</option>; })}
                                        </Input>

                                    </FormGroup>{" "}
                                    <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                        <Label for="cmbAnio" className="mr-sm-2">{this.getText("lblAnio")}</Label>
                                        <Input type="select" id="cmbAnio"
                                            innerRef={(c) => this.cmbAnio = c} defaultValue={moment().year()} >
                   
                                            {anios.map((anio) => { return <option key={anio}  >{anio}</option>; })}
                                        </Input>
                                    </FormGroup>
                                    <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                        <Label for="cmbMes" className="mr-sm-2">{this.getText("lblMes")}</Label>
                                        <Input type="select" id="cmbMes" innerRef={(c) => this.cmbMes = c} defaultValue={moment().format("MMMM")}>
                                            <option key={-1} >{this.getText("lblSeleccionar")}</option>
                                            {meses.map((mes, index) => { return <option key={index} >{mes}</option>; })}
                                        </Input>
                                    </FormGroup>
                                    <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                        <Label for="cmbManzana" className="mr-sm-2">{this.getText("lblManzana")}</Label>
                                        <Input type="select" id="cmbManzana" innerRef={(c) => this.cmbMz = c} >
                                            <option key={-1} >{this.getText("lblTodos")}</option>
                                            {this.state.manzanas.map((mz) => { return <option key={mz.key} >{mz.value}</option>; })}
                                        </Input>

                                    </FormGroup>


                                    <span className="css_buscar">
                                        <FontAwesomeIcon icon={faSearch} size="lg" onClick={(e) => this.Buscar(e)} />
                                    </span>
                                </Form>
                            </CardBody>
                        </Card>
                        <GridTable
                            data={this.state.dataGridRows}
                            columns={this.state.dataGridColumns}
                            loading={this.state.loading} />
                    </div>
                </div>
            </div >
        )
    }
}
