// Componente base
import React from 'react';
import Base from '../Base';
import { Col, FormGroup } from "reactstrap";
import Dropdown from "../../components/Dropdown";
import Text from "../Text";
import GeneralServices from '../../services/GeneralServices.js';
import ComboModel from '../../models/ComboModel.js';
// Otros Componentes
import './style.css';

export default class Ubicacion extends Base {

    constructor(props) {
        super(props);
        this.state = {
            manzanas: [],
            villas: [],
            residente: this.props.residente,
            telefono: ""
        }
        this.changeManzana = this.changeManzana.bind(this);
        this.changeVilla = this.changeVilla.bind(this);
        this.changePersona = this.changePersona.bind(this);
        this.service = new GeneralServices();

    };

    changeManzana(event) {
        const mz = event.target.value
        this.getRef("cmbManzana").updateValue(mz);
        this.setVilla(mz)
        this.setState({ departamentos: null, personas: null });
        this.getRef("cmbVilla").updateValue(0);

    }

    changePersona(event) {
        const per = event.target.value
        this.getRef("cmbPers").updateValue(per);
        this.setState({ persona: per });

    }

    changeVilla(event) {
        const vl = event.target.value
        this.getRef("cmbVilla").updateValue(vl);
        this.setDepartamento(vl)
    }

    validar() {
        return this.ValidateForm()
    }

    setVilla(mz) {
        let datos = this.state.ubicaciones;
        var vill = datos.filter(function (ubi) {
            return ubi.manzana === mz;
        });

        const villas = [...new Set(vill.map(x => x.villa))];
        const cmbVl = []
        villas.sort();
        for (const vl of villas) {
            cmbVl.push(new ComboModel(vl, vl));
        }
        this.setState({ villas: cmbVl });
    }

    setDepartamento(vl) {
        let datos = this.state.ubicaciones;

        var mz = this.getRef("cmbManzana").getValue();

        var dept = datos.filter(function (ubi) {
            return ubi.manzana === mz && ubi.villa === vl;
        });
        const departamentos = [...new Set(dept.map(x => x.departamento))];
        const cmbDept = [];

        departamentos.sort();
        if (departamentos[0] !== null && departamentos[0] !== "" && departamentos[0] !== undefined) {
            for (const dep of departamentos) {
                cmbDept.push(new ComboModel(dep, dep));
            }
            this.setState({ departamentos: cmbDept });

        }
        else {
            this.cargarPersona(dept[0].id);
            this.setState({ ubicacion: dept[0] });
        }
    }

    componentDidMount() {
        //alert(this.props.persona);
        this.cargaUbicaciones();

    }

    componentDidUpdate(prevProps, prevState) {

        if (prevProps.persona !== this.props.persona) {
            if (!this.state.residente)
                return;
            this.cargaDatosPersona(this.props.persona);
        }
    }

    getUbicacion() {
        return this.state.ubicacion;
    }

    getPersona() {
        return this.state.persona;
    }

    cargarPersona(ubicacion) {

        if (!this.state.residente)
            return;

        var ruta = this.props.inactivos ? 'persona/todos/ubicacion' : 'persona/ubicacion';;
        this.service.List(ubicacion, ruta)
            .then(result => {
                if (result.codigo !== "0") {
                    alert(result.mensaje);
                    if (result.codigo === 401) {
                        this.CerarSesion();
                    }
                }
                else {
                    const cmbPers = []

                    for (const per of result.data) {
                        cmbPers.push(new ComboModel(per[0].toString(), per[1] + ' ' + per[2]));
                    }
                    if (cmbPers.length > 0) {
                        let person = result.data[0];
                        let telefono = person[4] ? person[4].toString() : "";
                        this.setState({ personas: cmbPers, telefono: telefono });
                        if (this.props.persona) {
                            this.getRef("cmbPers").updateValue(this.props.persona);
                            this.setState({ persona: this.props.persona });
                        }
                        else {
                            //this.getRef("cmbPers").updateValue(this.props.persona);

                            this.setState({ persona: cmbPers[0].key });
                        }
                        if (this.props.telefono) {
                            this.getRef("txt_telefono").updateValue(telefono);
                        }

                    }
                    else {
                        this.setState({ personas: null });
                    }

                }
            })
            .catch(error => {
                console.log(error);
            });
    }

    /*
    *Carga las ubicaciones mz, vll y departamento de una etapa
    */
    cargaUbicaciones() {

        this.service.List(this.getEtapa(), 'ubicacion/etapa')
            .then(result => {
                if (result.codigo !== "0") {
                    alert(result.mensaje);
                    if (result.codigo === 401) {
                        this.CerarSesion();
                    }
                }
                else {
                    const manzanas = [...new Set(result.data.map(x => x.manzana))];
                    const cmbMz = []
                    manzanas.sort()

                    for (const mz of manzanas) {
                        cmbMz.push(new ComboModel(mz, mz));
                    }

                    this.setState({ manzanas: cmbMz, ubicaciones: result.data });

                    if (this.props.ubicacion) {
                        this.cargaDatosUbicacion(this.props.ubicacion);
                    }

                    // if (this.props.persona) {
                    //     this.cargaDatosPersona(this.props.persona);
                    // }


                }
            })
            .catch(error => {
                console.log(error);
            });
    }

    /*
    * Carga los datos de una ubicacion y selecciona por defecto su mz, vl y dept
    */
    cargaDatosUbicacion(id) {
        this.service.Id(id, 'ubicacion/id')
            .then(result => {
                if (result.codigo !== "0") {
                    alert(result.mensaje);
                    if (result.codigo === 401) {
                        this.CerarSesion();
                    }
                }
                else {
                    let ubi = result.data;

                    if (ubi) {
                        this.setVilla(ubi.manzana);
                        this.getRef("cmbManzana").updateValue(ubi.manzana);
                        this.setDepartamento(ubi.villa);
                        this.getRef("cmbVilla").updateValue(ubi.villa);

                    }
                }
            }
            )
            .catch(error => {
                console.log(error);
            });
    }

    /*
    * Carga los datos de una persona y selecciona por defecto su ubicación
    */
    cargaDatosPersona(id) {
        this.service.Id(id, 'persona/id')
            .then(result => {
                if (result.codigo !== "0") {
                    alert(result.mensaje);
                    if (result.codigo === 401) {
                        this.CerarSesion();
                    }
                }
                else {
                    let ubi = result.data.persona.ubicacion
                    if (ubi) {
                        this.setVilla(ubi.manzana);
                        this.getRef("cmbManzana").updateValue(ubi.manzana);
                        this.setDepartamento(ubi.villa);
                        this.getRef("cmbVilla").updateValue(ubi.villa);

                    }
                }
            }
            )
            .catch(error => {
                console.log(error);
            });
    }


    render() {
        return (
            <div>
                <FormGroup row >
                    <Col >
                        <Dropdown
                            id="cmbManzana"
                            label={this.getText("lblManzana")}
                            ref={(ref) => this.setRef('cmbManzana', ref)}
                            value={this.state.manzanas}
                            tipo="N"
                            onChange={this.changeManzana}
                            disabled={this.props.disabled}
                            isRequired={true}
                            menssage={`${this.getText("msgCampoRequerido")} ${this.getText("lblManzana")}`}
                        />
                    </Col>
                    <Col >
                        <Dropdown
                            id="cmbVilla"
                            label={this.getText("lblLote")}
                            ref={(ref) => this.setRef('cmbVilla', ref)}
                            value={this.state.villas ? this.state.villas : []}
                            tipo="N"
                            onChange={this.changeVilla}
                            disabled={this.props.disabled}
                            isRequired={true}
                            menssage={`${this.getText("msgCampoRequerido")} ${this.getText("lblLote")}`}
                        />
                    </Col>

                    {
                        this.state.departamentos ?
                            <Col >
                                <Dropdown
                                    id="cmbDept"
                                    label={this.getText("lblDepartamento")}
                                    ref={(ref) => this.setRef('cmbDept', ref)}
                                    value={this.state.departamentos}
                                    disabled={this.props.disabled}
                                    tipo="N"
                                />
                            </Col> :
                            <div></div>
                    }

                </FormGroup >
                {
                    this.state.personas ?
                        <div>
                            <FormGroup row >
                                <Col >
                                    <Dropdown
                                        id="cmbPers"
                                        label={this.getText("lblResidente")}
                                        ref={(ref) => this.setRef('cmbPers', ref)}
                                        value={this.state.personas}
                                        onChange={this.changePersona}
                                        disabled={this.props.disabled}
                                    />
                                </Col>

                            </FormGroup>
                            {this.props.telefono ?
                                <FormGroup row >
                                    <Col >
                                        <Text
                                            label={this.getText("lblTelefono")}
                                            ref={(ref) => this.setRef('txt_telefono', ref)}
                                            id={'txt_telefono'}
                                            value={this.state.telefono}
                                            type={'text'}
                                            disabled={true}
                                        />
                                    </Col>

                                </FormGroup> :
                                <div></div>

                            }


                        </div> :
                        <div></div>

                }

            </div>
        );
    }


}
