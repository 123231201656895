import React from 'react';
import { Col, Form, FormGroup, Label, Input, CustomInput } from 'reactstrap';
import Return from '../../components/Return';
import moment from 'moment';
import Base from '../../components/Base';
import Text from '../../components/Text';
import Dropdown from "../../components/Dropdown";
import './style.css';
import PizarraModel from '../../models/PizarraModel.js';
import GeneralServices from '../../services/GeneralServices.js';
import Loader from '../../components/Loader';
import Alert from '../../components/Alert';
import Button from '../../components/Button';
import { dateFnsLocalizer } from 'react-big-calendar';

const Compress = require('compress.js');

export default class Pizarra extends Base {
    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.pizarraService = new GeneralServices();
        this.state =
        {
            estado: '',
            files: [],
            fotos: [],
            loading: false,
            alerta: false,
            mensaje: '',
            error: false,
            caducidad: false,
            fechaCaducidad: moment(new Date()).format('YYYY-MM-DD')
        };
    }

    handleChange(event) {
        this.setState({
            estado: event.target.value
        });
    }

    ShowAlert(mensaje, error) {
        this.setState({ alerta: true, mensaje: mensaje, error: error }, () => {
            window.setTimeout(() => {
                this.setState({ alerta: false, mensaje: '', error: false })
            }, 5000)
        });
    }

    handleSubmit(event) {
        event.preventDefault();
        // console.log("fecha: ", this.fecha.value)
        //this.setState({fechaCaducidad: this.fecha.value})
        //return;

        let pizarra = new PizarraModel();
        let formData = new FormData();
        var imagedata = this.state.files;
        let validar = this.ValidateForm();

        if (validar) {
            this.ShowAlert(validar, true);
            return;
        }

        this.setState({ loading: true });

        pizarra.titulo = this.getRef('txt_titulo').getValue();
        pizarra.detalle = this.getRef('txt_detalle').getValue();;
        pizarra.id = this.state.pizarra.id;
        pizarra.idEtapa = this.state.pizarra.idEtapa;
        pizarra.tipo = this.getRef("cmbTipo").getValue();
        pizarra.estado = this.state.estado;
        if(this.state.caducidad){
            let _fecha = moment( this.fecha.value);
            pizarra.fecha_caducidad = _fecha ;
        }
        // else{
            // pizarra.fecha_caducidad = null;
        // }

        for (const file of imagedata) {
            formData.append('files', file);
        }

        this.pizarraService.Upload(formData, 'archivo/subirvarios')
            .then(result => {
                if (result.codigo !== "0") {
                    alert(result.mensaje);
                    if (result.codigo === 401) {
                        this.CerarSesion();
                    }
                }
                else {
                    var archivos = [];
                    var fotoServer = [];

                    fotoServer = [...this.state.fotos, ...result.data];
                    fotoServer.map((foto, i) => { foto.orden = i + 1 });
                    pizarra.fotos = JSON.stringify(fotoServer);

                    this.pizarraService.save(pizarra, pizarra.route())
                        .then(result2 => {
                            if (result2.codigo !== "0") {
                                alert(result2.mensaje);
                            }
                            else {
                                this.setState({ estado: result2.data.estado, pizarra: result2.data, files: archivos, fotos: fotoServer, loading: false });
                                this.ShowAlert(this.getText("msgGuardadoOk"), false);
                                //this.props.history.push('/Pizarra');
                            }
                            this.setState({ loading: false });
                        }
                        )
                        .catch(error => {
                            console.log(error);
                            this.setState({ loading: false });
                        });
                }
            }
            )
            .catch(error => {
                console.log(error);
                this.setState({ loading: false });
            });
    }

    validaImagenes = (file) => {
        const CantImagen = parseInt(this.getOpcion("NoImgPizarra"));
        const types = ['image/png', 'image/jpeg'];

        if (types.every(type => file.type !== type)) {
            this.ShowAlert(this.getText("msgImagenNoPermitido"), true);
            window.scrollTo(0, 0);
            return false;
        }

        let CantRegistro = this.state.files.length + this.state.fotos.length;

        if (CantRegistro >= CantImagen) {
            this.ShowAlert(this.getText("msgImagenMaximo") + CantImagen, true);
            window.scrollTo(0, 0);
            return false;
        }
        return true;
    }

    deleteRow(orden, e) {
        e.preventDefault();
        console.log("orden en pizarra: ", orden);
        var nuevofile = []
        var nuevofotos = this.state.fotos.filter((foto) =>
            foto.orden !== orden);
        nuevofile = this.state.files;
        nuevofile.splice(orden, 1);
        this.setState({ files: nuevofile });
        this.setState({ fotos: nuevofotos });

    }

    fileSelectedHandler = e => {
        let imagen = [...e.target.files];
        if (this.validaImagenes(imagen[0])) {
            let compress = new Compress();

            compress.compress(imagen, {
                size: 8, // the max size in MB, defaults to 2MB
                quality: 0.40, // the quality of the image, max is 1,
            }).then((results) => {

                let img1 = results[0];
                let base64str = img1.data;
                let imgExt = img1.ext;
                let name = img1.alt;
                let file = Compress.convertBase64ToFile(base64str, imgExt);
                let archivo = new File([file], name, { type: file.type, });
                this.setState({ files: [...this.state.files, archivo] });
            });
        }
        e.target.value = null;
        setTimeout(() => {
            
            console.log("el state desde handler file: ", this.state); 
        }, 3000);
    }

    componentDidMount() {
        const id = this.props.match.params.id;
        if (id > 0) {
            this.pizarraService.Id(id, 'pizarra/id')
                .then(result => {
                    if (result.codigo !== "0") {
                        alert(result.mensaje);
                    }
                    else {
                        var fotos = []

                        if (result.data === null) {
                            alert(this.getText("lblNoHayDatosId") + id);
                            window.location.href = "/Pizarra";
                            //this.props.history.push('/Pizarra');
                            return;
                        }

                        if (result.data.fotos != null) {
                            fotos = JSON.parse(result.data.fotos);
                        }
                        //console.log(result.data);
                        this.setState({ estado: result.data.estado, pizarra: result.data, fotos: fotos, caducidad: result.data.fecha_caducidad ? true : false, fechaCaducidad: result.data.fecha_caducidad ? Date.parse(result.data.fecha_caducidad) : new Date().getTime() });
                        this.getRef("cmbTipo").updateValue(result.data.tipo);
                    }
                }
                )
                .catch(error => {
                    console.log(error);
                });
        }
        else {
            let pizarra = new PizarraModel();
            pizarra.titulo = "";
            pizarra.detalle = "";
            pizarra.fotos = "";
            pizarra.id = id;
            pizarra.idEtapa = this.getEtapa();
            pizarra.tipo = 0;
            pizarra.estado = "N";
            this.setState({ estado: pizarra.estado, pizarra: pizarra });
        }
    }
    render() {

        if (this.state.pizarra) {
            return (
                <div className='formTitulo' >

                    <Alert
                        open={this.state.alerta}
                        mensaje={this.state.mensaje}
                        error={this.state.error}
                    />
                    <FormGroup row >
                        <Col md={9}>
                            <h5><Return onClick={() => this.props.history.go(-1)} />{this.getText("titPizarra")}</h5>
                        </Col>
                        <Col md={3} className="text-right">
                            <Button type="submit" onClick={this.handleSubmit} className="tertiaryColorB" value={this.getText("lblGuardar")} />
                        </Col>
                    </FormGroup>

                    <div className='form'>
                        <Form encType="multipart/form-data" >
                            <FormGroup row>
                                <Col md={4}>
                                    <FormGroup check inline >
                                        <Label check>
                                            <Input
                                                type='radio'
                                                name="rbtpublicado"
                                                value='P'
                                                checked={this.state.estado === 'P'}
                                                onChange={this.handleChange}
                                            />{this.getText("lblActivo")}</Label>

                                    </FormGroup>
                                    <FormGroup check inline  >
                                        <Label check>
                                            <Input
                                                type='radio'
                                                name="rbtpublicado"
                                                value='N'
                                                checked={this.state.estado === 'N'}
                                                onChange={this.handleChange}
                                            />{this.getText("lblInactivo")}</Label>
                                    </FormGroup>
                                </Col>
                                <Col md={4}>
                                    <FormGroup row  >
                                        <Col >
                                            <CustomInput type="checkbox" id={"Expira"}
                                                label={this.getText("lblExpiracion")}
                                                //checked={this.state.caducidad}
                                                defaultChecked={this.state.caducidad}
                                                onClick={() => this.setState({ caducidad: !this.state.caducidad })} />
                                        </Col>
                                        {this.state.caducidad &&
                                            <Col >
                                                <Input
                                                    type="date"
                                                    name="date"
                                                    id="dtpfecha"
                                                    defaultValue={moment(this.state.fechaCaducidad).format('YYYY-MM-DD')}
                                                    min={moment(new Date()).format('YYYY-MM-DD')}
                                                    max="2500-01-01"
                                                    innerRef={(c) => this.fecha = c}
                                                />
                                            </Col>
                                        }
                                    </FormGroup>
                                </Col>
                                <Col md={4} className="text-right"  >
                                    <Label>
                                        {this.state.pizarra.fechaModificacion ?
                                            this.getText("lblFechaModificacion") + ": " + new Date(this.state.pizarra.fechaModificacion).toLocaleString()
                                            : ""
                                        }
                                    </Label>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Col >
                                    <Text
                                        label={this.getText("lblTitulo")}
                                        id={'txt_titulo'}
                                        value={this.state.pizarra.titulo}
                                        type={'text'}
                                        ref={(ref) => this.setRef('txt_titulo', ref)}
                                        isRequired={true}
                                        menssage={`${this.getText("msgCampoRequerido")} ${this.getText("lblTitulo")}`}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup row >
                                <Col >
                                    <Dropdown
                                        id="cmbTipo"
                                        ref={(ref) => this.setRef('cmbTipo', ref)}
                                        label={this.getText("lblTipo")}
                                        value={JSON.parse(this.getOpcion("TipoPizarra"))}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Col >
                                    <Text
                                        label={this.getText("lblDetalle")}
                                        id={'txt_detalle'}
                                        value={this.state.pizarra.detalle}
                                        type={'textarea'}
                                        height="200px"
                                        ref={(ref) => this.setRef('txt_detalle', ref)}
                                        isRequired={true}
                                        menssage={`${this.getText("msgCampoRequerido")} ${this.getText("lblDetalle")}`}
                                    />
                                </Col>
                            </FormGroup>

                            <FormGroup>
                                <Label for="files"  >{this.getText("lblMultimedia")}</Label>
                                <CustomInput type="file"
                                    id="files"
                                    name="files"
                                    label={this.getText("lblSeleccionarImages")}
                                    accept="image/*"
                                    onChange={this.fileSelectedHandler}
                                    disabled={this.state.loading} />
                            </FormGroup>
                            <br></br>
                            <ul>
                                {
                                    this.state.fotos.map((repo, index) => {
                                        let server = this.getOpcion('fileServer');
                                        let url = server + repo.url;
                                        return (
                                            <FormGroup row key={index}  >
                                                <Col md={3}>
                                                    <img alt={index} src={url} height="42" width="42" />
                                                </Col>
                                                <Col md={7}>
                                                    <a
                                                        href={url}
                                                        target="_blank"
                                                    >{this.getText("lblImagen") + " " + repo.orden}

                                                    </a></Col>
                                                <Col md={2}>
                                                    <Button
                                                        onClick={(e) => this.deleteRow(repo.orden, e)}
                                                        size="sm"
                                                        className="secondaryColorB"
                                                        value="X" />
                                                </Col>
                                            </FormGroup>
                                        )
                                    })
                                }
                                {
                                    this.state.files.map((repo, index) => {
                                        let orden = index;
                                        let url = URL.createObjectURL(repo);
                                        return (
                                            <FormGroup row key={orden}  >
                                                <Col md={3}>
                                                    <img alt={orden} src={url} height="42" width="42" />
                                                </Col>
                                                <Col md={7}>
                                                    <a href={url}
                                                        target="_blank"
                                                    > {this.getText("lblImagen") + " " + (this.state.fotos.length + index + 1)}</a>
                                                </Col>
                                                <Col md={2}>
                                                    <Button
                                                        onClick={(e) => this.deleteRow(orden, e)}
                                                        size="sm"
                                                        className="secondaryColorB"
                                                        value="X" />
                                                </Col>
                                            </FormGroup>
                                        )
                                    })
                                }
                            </ul>
                        </Form>

                    </div>
                    {(this.state.loading) ? <Loader>{this.getText("lblCargando")}</Loader> : <span></span>}

                </div>
            );
        }
        else {
            return (
                <div>
                    <Loader />
                </div>);
        }
    }

}