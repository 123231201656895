export default class EspacioModel {
    constructor(
        nombre,
        tipoPeriodo,
        periodo,
        estado,
        detalle,
        horario,
        idEtapa,  
        reservaApp,
        reservasPermitidas,
        aprobar,      
        id,
        color,
        color2,
        valor
    ) {
        this.nombre = nombre;
        this.detalle = detalle;
        this.estado = estado;
        this.horario = horario;
        this.periodo = periodo;
        this.id = id;
        this.idEtapa = idEtapa;
        this.aprobar = aprobar;
        this.reservaApp = reservaApp;
        this.tipoPeriodo = tipoPeriodo;
        this.reservasPermitidas=reservasPermitidas;
        this.color=color;
        this.color2=color2;
        this.valor=valor;
    }

    route() {
        return "espacio";
    }
}