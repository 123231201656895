import './global';

const resFileES = require("../res/app_es.json")

export function loadSettings() {
    //CONFIGURACIONES INICIALES
    
    //SI SE MANEJA IDIOMA HAY QUE LEER LA VARIABLE LENGUAJE DE ALGUN REPORSITORIO
    let lang = "es";
    if (lang === "es") {
        global.resFile = resFileES;
    }
    global.lang = lang

}
