// Componente base
import React from 'react';
import Base from '../../components/Base';
import { Link } from 'react-router-dom';
import matchSorter from 'match-sorter';
import { Input, FormGroup, Label, Col, Card, CardHeader, CardBody, Row, Form, Modal, ModalHeader, ModalBody, } from 'reactstrap';
import GridTable from '../../components/GridTable';
import GeneralServices from '../../services/GeneralServices.js';
import './style.css';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import Button from '../../components/Button';
import Dropdown from '../../components/Dropdown';

export default class ControlIngresoList extends Base {

    constructor(props) {
        super(props);
        this.servicio = new GeneralServices();


        var columns = [
            {
                columns: [

                    {
                        Header: this.getText("lblManzana"),
                        id: "manzana",
                        width: 50,
                        accessor: d => d.manzana,
                        filterMethod: (filter, rows) =>
                            matchSorter(rows, filter.value, { keys: ["manzana"] }),
                        filterAll: true,
                        style: { textAlign: "right" }
                    },

                    {
                        Header: this.getText("lblLote"),
                        id: "villa",
                        accessor: d => d.villa,
                        filterMethod: (filter, rows) =>
                            matchSorter(rows, filter.value, { keys: ["villa"] }),
                        filterAll: true,
                        width: 80,
                        style: { textAlign: "right" }
                    },

                    {
                        Header: this.getText("lblDepartamento"),
                        id: "departamento",
                        accessor: d => d.departamento,
                        filterMethod: (filter, rows) =>
                            matchSorter(rows, filter.value, { keys: ["departamento"] }),
                        filterAll: true,
                        width: 90,
                        style: { textAlign: "right" }
                    },

                    {
                        Header: this.getText("lblHoraIngreso"),
                        id: "horaDesde",
                        accessor: d => d.horaDesde,
                        filterMethod: (filter, rows) =>
                            matchSorter(rows, filter.value, { keys: ["horaDesde"] }),
                        width: 190,
                        filterAll: true,
                        style: { textAlign: "right" }
                    },

                    {
                        Header: this.getText("lblHoraSalida"),
                        id: "horaHasta",
                        accessor: d => d.horaHasta,
                        filterMethod: (filter, rows) =>
                            matchSorter(rows, filter.value, { keys: ["horaHasta"] }),
                        width: 190,
                        filterAll: true,
                        style: { textAlign: "right" }
                    },
                    {
                        Header: this.getText("lblPlaca"),
                        id: "placa",
                        accessor: d => d.placa,
                        filterMethod: (filter, rows) =>
                            matchSorter(rows, filter.value, { keys: ["placa"] }),
                        filterAll: true,
                        style: { textAlign: "right" }
                    }
                ]
            },
            {
                Header: "",
                columns: [
                    {
                        Header: "",
                        accessor: "buscar",
                        filterable: false,
                        sortable: false,
                        style: { textAlign: "center" }
                    }
                ]
            },
            {
                Header: "",
                columns: [
                    {
                        Header: "",
                        accessor: "salida",
                        filterable: false,
                        sortable: false,
                        style: { textAlign: "center" }
                    }
                ]
            }
        ];

        this.state = {
            modalEmergencia: false,
            dataGridRows: [],
            dataGridColumns: columns,
            manzanas: [],
            loading: false
        }
        this.toggle = this.toggle.bind(this);
        this.guardaInvitadoControl = this.guardaInvitadoControl.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    };

    setValue(rows) {
        this.setState({ dataGridRows: rows });
    };

    New(e) {
        e.preventDefault();
        window.location.href = "/ControlIngreso/id/0";
        //this.props.history.push("/comercio/id/0");
    }

    Parametros(e) {
        e.preventDefault();
        window.location.href = "/parametrosCamaras";
    }

    Buscar(e) {

        if (e) {
            e.preventDefault();
        }


        var fecha = this.fecha.value
        var fecha_hasta = this.fecha_hasta.value

        if (fecha === "" || fecha_hasta === "") {
            alert(this.getText("msgValidaFechaVacia"));
            return;
        }
        let _fecha = moment(fecha);
        let _fecha_hasta = moment(fecha_hasta);

        if (_fecha > _fecha_hasta) {
            alert(this.getText("msgValidaFechas"));
            return;
        }

        let etapa = this.getEtapa();

        this.setState({ loading: true });

        this.servicio.List("", `control/etapa/${etapa}/${_fecha.toDate().getTime()}/${_fecha_hasta.toDate().getTime()}`)
            .then(result => {
                if (result.codigo !== "0") {
                    alert(result.mensaje);
                    if (result.codigo === 401) {
                        this.CerarSesion();
                    }
                }
                else {
                    var rows = [];

                    for (let i = 0; i < result.data.length; i++) {
                        let invitacion = result.data[i];
                        let dir;
                        if (invitacion[7] != null) {
                            dir = JSON.parse(invitacion[7]);
                        }
                        rows.push({

                            manzana: dir ? dir.mz ? dir.mz : dir.manzana : "",
                            villa: dir ? dir.vl ? dir.vl : dir.villa : "",
                            departamento: dir ? dir.dp ? dir.dp : dir.departamento : "",
                            horaDesde: invitacion[3] ? new Date(invitacion[3].replace("+0000", "Z")).toLocaleString() : "",
                            horaHasta: invitacion[4] ? new Date(invitacion[4].replace("+0000", "Z")).toLocaleString() : "",
                            placa: invitacion[12] ? invitacion[12] : "",
                            buscar: this.link(invitacion[0]),
                            salida: this.linkSalida(invitacion[0])

                        });
                    }

                    this.setValue(rows);
                }
                this.setState({ loading: false });

            }
            )
            .catch(error => {
                console.log(error);
                this.setState({ loading: false });
            });

    }

    componentDidMount() {
        this.Buscar();
        //console.log("las emergencias papu: ", this.getOpcion("CategoriaIngreso"));
    }

    //Fucion para mostrar icono y Link
    link(id) {
        var link = [];
        link.push(
            <Link key={id} to={"/controlInvitado/id/" + id}>
                <h6>{this.getText("lblVer")}</h6>
            </Link>)
        return link[0]
    }

    //Fucion para mostrar icono y Link
    linkSalida(id) {
        var link = [];
        link.push(
            <Link key={id} to={"#"} onClick={(e) => this.registrarSalida(id, e)}>
                <h6>{this.getText("lblRegistrarSalida")}</h6>
            </Link>);

        return link[0]
    }

    abrirBarrera() {
        var date = new Date();
        var barreras = JSON.parse(this.getOpcion("APIBarrera_" + this.getEtapa()));
        barreras.forEach(b => {
            // console.log(b);
            fetch(b.url + "?" + date.getTime())
                .then(res => {
                     console.log(res);
                })
                .catch(error => {
                    console.log(error);
                });
        });

    }

    handleSubmit() {
        var invitacion = {
            id: 0,
            nombre: this.getRef('cmbCatIngreso').state.text,
            //direccion: JSON.stringify(ubicacion.state.ubicacion),
            tipo: 'HOR',
            idEtapa: this.getEtapa(),
            estado: 'A',
            //invitados: invitados,
            fecha_inicio: new Date(),
            fecha_fin: new Date()
        }

        this.servicio.save(invitacion, "invitacion")
            .then(result2 => {
                if (result2.codigo !== "0") {
                    alert(result2.mensaje);
                }
                else {
                    this.guardaInvitadoControl(result2.data.invitacion);

                }
            }
            )
            .catch(error => {
                console.log(error);
            }
            );
    }


    guardaInvitadoControl(invitacion) {
        var InvitadoControl;
        //let ubicacion = this.getRef('cmbUbicacion');

        InvitadoControl = {
            id: 0,
            persona: this.getUsuario(),
            invitacion: invitacion.id,
            //invitado: invitado,
            idEtapa: this.getEtapa(),
            //placa: this.getRef('txt_placa').getValue(),
            novedad: "Emergencia",
            categoriaTransporte: "PRO",
            tipoTransporte: "Aut",
            nombreConductor: "Emergencia",
            //fotosIngreso: JSON.stringify(fotos),
            //fotosSalida: null,
            //idUbicacion: ubicacion.state.ubicacion.id,
            categoriaIngreso: this.getRef('cmbCatIngreso').getValue(),
            usuarioIngreso: this.getUsuario(),
        }

        this.servicio.save(InvitadoControl, "control")
            .then(result3 => {
                if (result3.codigo !== "0") {
                    alert(result3.mensaje);
                }
                else {
                    //let confirma = window.confirm(this.getOpcion("FraseBarrera"));
                    //if (confirma) {
                    //}
                    this.toggle();
                    //window.location.href = "/ControlInvitados";
                    this.Buscar();
                }
                this.abrirBarrera();
                this.setState({ loading: false });
            }
            )
            .catch(error => {
                console.log(error);
                this.setState({ loading: false });
            });
    }

    registrarSalida = (id, e) => {
        e.preventDefault();

        var InvitadoControl;


        InvitadoControl = {
            id: id,
            usuario_modificacion: this.getUsuario(),
            usuarioSalida: this.getUsuario()
        }

        this.servicio.save(InvitadoControl, "control/fechasalida")
            .then(result3 => {
                if (result3.codigo !== "0") {
                    alert(result3.mensaje);
                }
                else {
                    alert(this.getText("msgGuardadoOk"));
                    this.Buscar();
                }
                this.setState({ loading: false });
            }
            )
            .catch(error => {
                console.log(error);
                this.setState({ loading: false });
            });
    }

    toggle() {
        this.setState({
            modalEmergencia: !this.state.modalEmergencia,
        });

    }

    render() {

        var date = moment().format('YYYY-MM-DD')
        var categoria = JSON.parse(this.getOpcion("CategoriaIngreso"));
        //console.log(date);
        return (
            <div>
                <div className="mainContent">
                    <div className="contentBlock">
                        <Card >
                            <CardHeader tag="h5">
                                <Row  >
                                    <Col sm={6}>
                                        {this.getText("titControlInvitados")}
                                    </Col>
                                    <Col md={2} className="text-right">
                                        <Button type="submit"
                                            onClick={this.toggle}
                                            className="btn-delete"
                                            value={this.getText("lblEmergencia")} />
                                    </Col>

                                    <Col sm={2} >
                                        <Button className="float-right"
                                            onClick={(e) => this.New(e)}
                                            disabled={this.state.loading}
                                            value={this.getText("lblNuevo")} />
                                    </Col>
                                    <Col sm={2} >
                                        <Button className="float-right"
                                            onClick={(e) => this.Parametros(e)}
                                            disabled={this.state.loading}
                                            value={this.getText("lblParametros")} />
                                    </Col>


                                </Row>

                            </CardHeader>
                            <CardBody>
                                <Form inline>
                                    <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                        <Label for="dtpfecha" className="mr-sm-2">{this.getText("lblFechaDesde")}</Label>
                                        <Input
                                            type="date"
                                            name="date"
                                            id="dtpfecha"
                                            defaultValue={date}
                                            min="2000-01-01"
                                            max="2500-01-01"
                                            innerRef={(c) => this.fecha = c}
                                        />
                                    </FormGroup>
                                    <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                        <Label for="dtpfecha2" className="mr-sm-2">{this.getText("lblFechaHasta")}</Label>
                                        <Input
                                            type="date"
                                            name="date2"
                                            id="dtpfecha2"
                                            defaultValue={date}
                                            min="2000-01-01"
                                            max="2500-01-01"
                                            innerRef={(c) => this.fecha_hasta = c}
                                        />
                                    </FormGroup>
                                    <span className="css_buscar">
                                        <FontAwesomeIcon icon={faSearch} size="lg" onClick={(e) => this.Buscar(e)} />
                                    </span>

                                </Form>
                            </CardBody>
                        </Card>
                        <GridTable
                            data={this.state.dataGridRows}
                            columns={this.state.dataGridColumns}
                            loading={this.state.loading} />
                    </div>

                    {this.state.modalEmergencia &&
                        <Modal isOpen={this.state.modalEmergencia} toggle={this.toggle}>
                            <ModalHeader toggle={this.toggle}>{this.getText("lblEmergencia")}</ModalHeader>
                            <ModalBody>
                                {/* {this.body(this.state.Invitados, this.state.index)} */}
                                <div>
                                    <FormGroup row >
                                        <Col>
                                            <Dropdown
                                                id="cmbCatIngreso"
                                                label={this.getText("lblMotivo")}
                                                ref={(ref) => this.setRef('cmbCatIngreso', ref)}
                                                //tipo="N"
                                                value={categoria.filter(x => x.tipo === "SOS")}
                                            />
                                        </Col>
                                    </FormGroup >
                                    <FormGroup row >
                                        <Col>
                                            <Button
                                                type="button"
                                                onClick={this.handleSubmit}
                                                className="btn-delete"
                                                value={this.getText("lblAbrirBarrera")} />

                                            <Button
                                                type="button"
                                                onClick={this.toggle}
                                                className="btn-cancelar"
                                                value={this.getText("lblCancelar")} />
                                        </Col>


                                    </FormGroup >
                                </div>
                            </ModalBody>
                        </Modal>
                    }

                </div>
            </div >
        )
    }
}
