import React from 'react';
import Base from '../../components/Base';
import { Col, Form, FormGroup, Label, Input, CustomInput, Row } from 'reactstrap';
import Button from '../../components/Button';
import Text from "../../components/Text";
import './style.css';
import GeneralServices from '../../services/GeneralServices.js';
import Loader from '../../components/Loader';
import Dropdown from "../../components/Dropdown";
import Return from '../../components/Return';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight, faAngleLeft } from '@fortawesome/free-solid-svg-icons'

export default class EtapasComercio extends Base {

    constructor(props) {
        super(props);
        this.lstDisponible = React.createRef();
        this.lstAsociadas = React.createRef();
        this.handleSubmit = this.handleSubmit.bind(this);
        this.changeEmpresa = this.changeEmpresa.bind(this);
        this.EtapasComercioService = new GeneralServices();

        this.state =
        {
            Etapas: [],
            Asignadas: [],
            Empresas: JSON.parse(localStorage.getItem('empresas')),
            loading: false,
            cambios: false
        };
    }

    handleSubmit(event) {
        event.preventDefault();
        this.setState({ loading: true });

        var rows = [];
        let asignado = this.state.Asignadas;
        let empresa = this.getRef("cmbEmp").getValue();

        for (let i = 0; i < asignado.length; i++) {
            rows.push({
                id: 0,
                idEtapa: asignado[i].key,
                idComercio: this.props.match.params.id,
                idEmpresa: empresa

            });
        }

        this.EtapasComercioService.update(rows, 'comercio/etapas/' + this.props.match.params.id + '/' + empresa)
            .then(result => {
                this.setState({ loading: false, cambios: false });
                if (result.codigo !== "0") {
                    alert(result.mensaje);

                    if (result.codigo === 401) {
                        this.CerarSesion();
                    }

                }
                else {
                    alert(this.getText("msgGuardadoOk"));
                }

            }
            )
            .catch(error => {
                console.log(error);
                this.setState({ loading: false });
            });
    }

    add(e) {
        if (e !== undefined)
            e.preventDefault();

        var options = this.lstDisponible.current.options;
        var value = [];
        var etapas_actuales = this.state.Etapas;

        for (var i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
                let nuevo = {
                    key: options[i].id,
                    value: options[i].value,
                    keyPadre: options[i].getAttribute('padre')
                };

                value.push(nuevo);
                etapas_actuales = etapas_actuales.filter((etp) => etp.key !== nuevo.key);

            }

        }

        if (value.length > 0) {
            this.setState({ Etapas: etapas_actuales, Asignadas: [...this.state.Asignadas, ...value], cambios: true });
        }


    }

    del(e) {
        if (e !== undefined)
            e.preventDefault();

        var options = this.lstAsociadas.current.options;
        var value = [];
        var etapas_actuales = this.state.Asignadas;

        for (var i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
                let nuevo = {
                    key: options[i].id,
                    value: options[i].value,
                    keyPadre: options[i].getAttribute('padre')
                };

                value.push(nuevo);
                etapas_actuales = etapas_actuales.filter((etp) => etp.key !== nuevo.key);

            }

        }

        if (value.length > 0) {
            this.setState({ Asignadas: etapas_actuales, Etapas: [...this.state.Etapas, ...value], cambios: true });
        }

    }


    componentDidMount() {

        console.log("ingresa");
        const idEmpresa = this.state.Empresas[0].key;
        this.CargarDatos(idEmpresa);

    }


    CargarDatos(idEmpresa) {

        const id = this.props.match.params.id;

        this.setState({ loading: true });
        this.EtapasComercioService.Id(idEmpresa, 'comercio/etapas/' + id)
            .then(result => {
                if (result.codigo !== "0") {
                    alert(result.mensaje);
                    if (result.codigo === 401) {
                        this.CerarSesion();
                    }
                    else {
                        window.location.href="/Comercios";
                        //this.props.history.push('/Comercios');
                    }
                }
                else {
                    if (result.data !== null) {
                        //console.log(result.data);
                        this.filtraEmpresa(idEmpresa, result.data.EtapaComercio);
                        this.setState({ Comercio: result.data.comercio, Asignadas: result.data.EtapaComercio, loading: false, cambios: false });
                    }

                }
            }
            )
            .catch(error => {
                this.setState({ loading: false });
                console.log(error);
            });
    }

    changeEmpresa(event) {

        if (this.state.cambios) {
            if (!window.confirm(this.getText("msgDescartarCambios"))) {
                return;
            }
        }
        const emp = event.target.value;
        this.getRef("cmbEmp").updateValue(emp);
        this.CargarDatos(emp);
        this.filtraEmpresa(emp, this.state.Asignadas);


    }

    filtraEmpresa(emp, etapas_actuales) {
        let Etapas = JSON.parse(localStorage.getItem('etapas'));
        var etap = Etapas.filter((etp) => etp.keyPadre === emp);
        etap.sort((a, b) => (a.value > b.value) ? 1 : -1)

        for (var i = 0, l = etapas_actuales.length; i < l; i++) {
            etap = etap.filter((etp) => etp.key !== etapas_actuales[i].key);

        }
        this.setState({ Etapas: etap });
    }

    render() {
        if (this.state.Comercio) {

            return (
                <div className='formTitulo' >

                    <FormGroup row >
                        <Col md={9}>
                        <h5><Return onClick={() => this.props.history.go(-1)}/>
                        {this.getText("titComerciosComercio")}</h5>
                        </Col>
                        <Col md={3} className="text-right">
                            <Button type="submit"
                                onClick={this.handleSubmit}
                                className="tertiaryColorB"
                                value={this.getText("lblGuardar")} />

                        </Col>
                    </FormGroup>
                    <div className='form'>
                        <Form  >
                            <FormGroup row>
                                <Col md={6}>
                                    <FormGroup row>
                                        <Col sm={11}>
                                            <Dropdown
                                                id="cmbEmp"
                                                ref={(ref) => this.setRef('cmbEmp', ref)}
                                                label={this.getText("lblEmpresa")}
                                                value={this.state.Empresas}
                                                onChange={this.changeEmpresa}

                                            />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col sm={11}>
                                            <Label for="disponible">{this.getText("lblUrbanizacion") + " " + this.getText("lblDisponible")}</Label>
                                            <CustomInput
                                                className="Lista"
                                                innerRef={this.lstDisponible}
                                                type="select"
                                                id="disponible"
                                                name="disponible" multiple>
                                                {this.state.Etapas.map((etp) => { return <option key={etp.key} id={etp.key} padre={etp.keyPadre} >{etp.value}</option>; })}

                                            </CustomInput>
                                        </Col>
                                        <Col sm={1}>
                                            <br></br>
                                            <FormGroup row>
                                                <span className="css_control_add">
                                                    <Row>
                                                        <Col className="css_control">
                                                            <FontAwesomeIcon icon={faAngleRight}
                                                                size="3x" onClick={(e) => this.add(e)} />
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col className="css_control">
                                                            <FontAwesomeIcon icon={faAngleLeft} size="3x" onClick={(e) => this.del(e)} />
                                                        </Col>
                                                    </Row>
                                                </span>
                                            </FormGroup>

                                        </Col>
                                    </FormGroup>
                                </Col>

                                <Col md={6}>
                                    <FormGroup row>
                                        <Col>
                                            <Text
                                                id="txt_comercio"
                                                ref={(ref) => this.setRef('txt_comercio', ref)}
                                                label={this.getText("titComercio")}
                                                value={this.state.Comercio}
                                                type="text"
                                                disabled={true}
                                            />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col>
                                            <Label for="asociado">{this.getText("lblUrbanizacion") + " " + this.getText("lblAsociado")}</Label>
                                            <CustomInput
                                                className="Lista"
                                                innerRef={this.lstAsociadas}
                                                type="select"
                                                id="asociado"
                                                name="asociado" multiple>
                                                {this.state.Asignadas.map((etp) => { return <option key={etp.key} id={etp.key} padre={etp.keyPadre} >{etp.value}</option>; })}
                                            </CustomInput>
                                        </Col>
                                    </FormGroup>

                                </Col>
                            </FormGroup>

                        </Form>
                    </div>
                    {(this.state.loading) ? <Loader /> : <span />}
                </div>
            );
        }
        else {
            return (
                <div>
                    <Loader />
                </div>);
        }
    }
}