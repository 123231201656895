import React from 'react';
import Base from '../Base';

import ReactTable from "react-table";
import "react-table/react-table.css";

class GridTable extends Base {

  constructor(props, context) {
    super(props, context);
    this.state = {
      data: this.props.data,
      columns: this.props.columns
    };

  };



 
  render() {
    return (
      <div className='gridwith' >
        <ReactTable
          data={this.props.data}
          loading={this.props.loading}
          filterable
          columns={this.state.columns}

          defaultPageSize={10}
          // style={{
          //   height: "400px" // This will force the table body to overflow and scroll, since there is not enough room
          // }}
          className="-striped -highlight"

          previousText={this.getText("lblAnterior")}
          nextText={this.getText("lblSiguiente")}
          loadingText={this.getText("lblCargando")}
          noDataText={this.getText("lblNoHayDatos")}
          pageText={this.getText("lblPagina")}
          ofText={this.getText("lblDe")}
          rowsText={this.getText("lblFila")}
          ref={(r)=>this.gridTable=r}
          getTrProps={this.props.getTrProps}
        />
        <br />
      </div>
    );
  }
}

export default GridTable;

