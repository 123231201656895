import React from 'react';
import Base from '../Base';
import { Col, Form, FormGroup, Label, CustomInput } from 'reactstrap';

// Otros Componentes
import './styles.css';
const Compress = require('compress.js');

export default class Pago extends Base {

    constructor(props) {
        super(props);
        this.state =
        {    file: this.props.file?this.props.file:""  };
    }

    fileSelectedHandler = e => {
        let file_upload = [...e.target.files];
         if (this.validaImagenes(file_upload[0])) {
            this.setState({ file: file_upload[0] });
        }
        e.target.value = null;
    }

    validaImagenes = (file) => {
        // const CantImagen = 1;
        const types = ['image/png', 'image/jpeg', 'application/msword', 'application/pdf','application/vnd.openxmlformats-officedocument.wordprocessingml.document'];

        if (types.every(type => file.type !== type)) {
            alert(this.getText("msgArchivoNoPermitido"));
            return false;
        }

        const fsize = Math.round((file.size / 1024));
        // The size of the file. 
        if (fsize >= 2048) {
            alert(this.getText("lblArchivoTamanio") + '2MB');
            return false;
        }

        // let CantRegistro = this.state.files.length + this.state.fotos.length;

        // if (CantRegistro >= CantImagen) {
        //     this.ShowAlert(this.getText("msgImagenMaximo") + CantImagen, true);
        //     window.scrollTo(0, 0);
        //     return false;
        // }
        return true;
    }

    render() {
        console.log(this.state.file);
        let tipo= typeof this.state.file;
        console.log(tipo);
        let server = this.getOpcion('fileServer');
        return (
            <div >
                <FormGroup row>
                    <Col>
                        <Label for="files" className='inputNormal' >{this.getText("lblFileComprobante")}</Label>
                        <CustomInput type="file"
                            id="files"
                            name="files"
                            label={this.getText("lblSeleccionarArchivo")}
                            accept="application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf,image/*"
                            onChange={this.fileSelectedHandler}
                        />
                        {/* <br></br> */}
                        {(this.state.file != "" && this.state.file != 'null')?
                            tipo=='string'?
                            <a target="_blank"
                                href={server + this.state.file}>{this.getText("lblComprobante")} </a>
                            :
                            <a target="_blank"
                                href={URL.createObjectURL(this.state.file)}>{this.state.file.name} </a>
                            : <div />}

                    </Col>
                </FormGroup>

            </div>
        );
    }
}


