import React from 'react';
import Base from '../../components/Base';
import cookies from 'react-cookies';
import { Col, Form, FormGroup, Label, Card, CardTitle, CardBody } from 'reactstrap';
import Button from '../../components/Button';
import Text from "../../components/Text";
import './style.css';
import OpcionModel from '../../models/OpcionModel.js';
import GeneralServices from '../../services/GeneralServices.js';
import Loader from '../../components/Loader';
import Return from '../../components/Return';

export default class ParametroCamaras extends Base {


    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.ParametroService = new GeneralServices();

        this.state =
        {
            loading: false
        };
    }


    handleChange(event) {
        this.setState({
            estado: event.target.value
        });
    }

    handleSubmit(event) {
        event.preventDefault();

        if (this.getRef('txt_barrera_1').getValue() === "") {
            alert(this.getText('msgErrorBarrera'));
            return;
        }

        this.setState({ loading: true });

        let opcion = new OpcionModel();

        opcion.nombre = `camaras_${cookies.load('etapa')}`;
        opcion.valor = JSON.stringify([
            {
                ip: this.getRef('txt_ip').getValue(),
                user: this.getRef('txt_user').getValue(),
                pwd: this.getRef('txt_pwd').getValue()
            },
            {
                ip: this.getRef('txt_ip2').getValue(),
                user: this.getRef('txt_user2').getValue(),
                pwd: this.getRef('txt_pwd2').getValue()
            },
            {
                ip: this.getRef('txt_ip3').getValue(),
                user: this.getRef('txt_user3').getValue(),
                pwd: this.getRef('txt_pwd3').getValue()
            }
        ]);;
        opcion.id = this.state.parametro.id;
        opcion.estado = this.state.parametro.estado;


        this.ParametroService.save(opcion, opcion.route())
            .then(result => {
                if (result.codigo !== "0") {
                    alert(result.mensaje);
                    if (result.codigo === 401) {
                        this.CerarSesion();
                    }
                    return;
                }
                else {
                    // alert(this.getText("msgGuardadoOk"));
                    // window.location.href = "/ControlInvitados";
                }
                // this.setState({ loading: false });
            }
            )
            .catch(error => {
                console.log(error);
                this.setState({ loading: false });
            });

        // barrera
        let opcionBarrera = new OpcionModel();
        opcionBarrera.nombre = `APIBarrera_${cookies.load('etapa')}`;
        opcionBarrera.valor = JSON.stringify([
            {
                url: this.getRef('txt_barrera_1').getValue()
            }

        ]);
        if (this.getRef('txt_barrera_2').getValue() !== "") {

            opcionBarrera.valor = JSON.stringify([
                {
                    url: this.getRef('txt_barrera_1').getValue()
                },
                {
                    url: this.getRef('txt_barrera_2').getValue()
                }

            ]);
        }

        opcionBarrera.id = this.state.barrera.id;
        opcionBarrera.estado = this.state.barrera.estado;

        this.ParametroService.save(opcionBarrera, opcionBarrera.route())
            .then(result => {
                if (result.codigo !== "0") {
                    alert(result.mensaje);

                    if (result.codigo === 401) {
                        this.CerarSesion();
                    }
                }
                else {
                    alert(this.getText("msgGuardadoOk"));
                    window.location.href = "/ControlInvitados";
                }
                this.setState({ loading: false });
            }
            )
            .catch(error => {
                console.log(error);
                this.setState({ loading: false });
            });

    }


    componentDidMount() {
        let opcion = new OpcionModel();
        opcion.nombre = `camaras_${cookies.load('etapa')}`;
        opcion.valor = JSON.stringify([{ ip: "", user: "", pwd: "" }, { ip: "", user: "", pwd: "" }, { ip: "", user: "", pwd: "" }]);
        opcion.estado = 'A';
        opcion.id = 0;

        this.ParametroService.Id(opcion.nombre, opcion.route() + '/nombre')
            .then(result => {
                //  console.log(result);
                if (result.codigo !== "0") {
                    alert(result.mensaje);
                    if (result.codigo === 401) {
                        this.CerarSesion();
                    }
                }
                else {
                    if (result.data === null) {
                        this.setState({ parametro: opcion });
                    }
                    else {
                        this.setState({ parametro: result.data });
                    }
                }
            }
            )
            .catch(error => {
                console.log(error);
            });
        // barrera

        let opcionBarrera = new OpcionModel();
        opcionBarrera.nombre = `APIBarrera_${cookies.load('etapa')}`;
        opcionBarrera.valor = JSON.stringify([{ url: "" }]);
        opcionBarrera.estado = 'A';
        opcionBarrera.id = 0;

        this.ParametroService.Id(opcionBarrera.nombre, opcionBarrera.route() + '/nombre')
            .then(result => {
                //  console.log(result);
                if (result.codigo !== "0") {
                    alert(result.mensaje);
                    if (result.codigo === 401) {
                        this.CerarSesion();
                    }
                }
                else {
                    if (result.data === null) {
                        this.setState({ barrera: opcionBarrera });
                    }
                    else {
                        this.setState({ barrera: result.data });
                    }
                }
            }
            )
            .catch(error => {
                console.log(error);
            });
    }

    render() {
        if (this.state.parametro && this.state.barrera) {
            let cam = JSON.parse(this.state.parametro.valor);
            const Cam01 = cam[0];
            const Cam02 = cam[1];
            const Cam03 = cam[2] ? cam[2] : { ip: "", user: "", pwd: "" };

            let barrera = JSON.parse(this.state.barrera.valor);
            const bar1 = barrera[0];
            const bar2 = barrera[1] ? barrera[1] : { url: "" };

            return (
                <div className='formTitulo' >

                    <FormGroup row >
                        <Col md={9}>
                            <h5><Return onClick={() => this.props.history.go(-1)} />
                                {this.getText("titCamaras")}</h5>
                        </Col>
                        <Col md={3} className="text-right">
                            <Button type="submit"
                                onClick={this.handleSubmit}
                                className="tertiaryColorB"
                                value={this.getText("lblGuardar")} />
                        </Col>
                    </FormGroup>
                    <div className='form'>
                        <Form  >
                            <FormGroup row>
                                <Col sm={6}>
                                    <Card>
                                        <b>{this.getText("lblCam01")}</b>
                                        <CardBody>
                                            <FormGroup row>
                                                <Col>
                                                    <Text
                                                        label={this.getText("lblIp")}
                                                        id={'txt_ip'}
                                                        value={Cam01.ip}
                                                        type="text"
                                                        ref={(ref) => this.setRef('txt_ip', ref)}
                                                    />
                                                </Col>
                                            </FormGroup>

                                            <FormGroup row>
                                                <Col>
                                                    <Text
                                                        label={this.getText("lblUsuario")}
                                                        id={'txt_user'}
                                                        value={Cam01.user}
                                                        type="text"
                                                        ref={(ref) => this.setRef('txt_user', ref)}
                                                    />
                                                </Col>
                                            </FormGroup>

                                            <FormGroup row>
                                                <Col>
                                                    <Text
                                                        label={this.getText("lblPassword")}
                                                        id={'txt_pwd'}
                                                        value={Cam01.pwd}
                                                        type="password"
                                                        ref={(ref) => this.setRef('txt_pwd', ref)}
                                                    />
                                                </Col>
                                            </FormGroup>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col sm={6}>
                                    <Card>

                                        <b>{this.getText("lblCam02")}</b>

                                        <CardBody>
                                            <FormGroup row>
                                                <Col>
                                                    <Text
                                                        label={this.getText("lblIp")}
                                                        id={'txt_ip2'}
                                                        value={Cam02.ip}
                                                        type="text"
                                                        ref={(ref) => this.setRef('txt_ip2', ref)}
                                                    />
                                                </Col>
                                            </FormGroup>

                                            <FormGroup row>
                                                <Col>
                                                    <Text
                                                        label={this.getText("lblUsuario")}
                                                        id={'txt_user2'}
                                                        value={Cam02.user}
                                                        type="text"
                                                        ref={(ref) => this.setRef('txt_user2', ref)}
                                                    />
                                                </Col>
                                            </FormGroup>

                                            <FormGroup row>
                                                <Col>
                                                    <Text
                                                        label={this.getText("lblPassword")}
                                                        id={'txt_pwd2'}
                                                        value={Cam02.pwd}
                                                        type="password"
                                                        ref={(ref) => this.setRef('txt_pwd2', ref)}
                                                    />
                                                </Col>
                                            </FormGroup>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Col sm={6}>
                                    <Card>

                                        <b >{this.getText("lblCam03")}</b>

                                        <CardBody>
                                            <FormGroup row>
                                                <Col>
                                                    <Text
                                                        label={this.getText("lblIp")}
                                                        id={'txt_ip3'}
                                                        value={Cam03.ip}
                                                        type="text"
                                                        ref={(ref) => this.setRef('txt_ip3', ref)}
                                                    />
                                                </Col>
                                            </FormGroup>

                                            <FormGroup row>
                                                <Col>
                                                    <Text
                                                        label={this.getText("lblUsuario")}
                                                        id={'txt_user3'}
                                                        value={Cam03.user}
                                                        type="text"
                                                        ref={(ref) => this.setRef('txt_user3', ref)}
                                                    />
                                                </Col>
                                            </FormGroup>

                                            <FormGroup row>
                                                <Col>
                                                    <Text
                                                        label={this.getText("lblPassword")}
                                                        id={'txt_pwd3'}
                                                        value={Cam03.pwd}
                                                        type="password"
                                                        ref={(ref) => this.setRef('txt_pwd3', ref)}
                                                    />
                                                </Col>
                                            </FormGroup>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col sm={6}>
                                    <Card>
                                        <b >{this.getText("lblBarreras")}</b>

                                        <CardBody>
                                            <FormGroup row>
                                                <Col>
                                                    <Text
                                                        label={this.getText("lblBarr1")}
                                                        id={'txt_barrera_1'}
                                                        value={bar1.url}
                                                        type="text"
                                                        ref={(ref) => this.setRef('txt_barrera_1', ref)}
                                                    />
                                                </Col>
                                            </FormGroup>

                                            <FormGroup row>
                                                <Col>
                                                    <Text
                                                        label={this.getText("lblBarr2")}
                                                        id={'txt_barrera_2'}
                                                        value={bar2.url}
                                                        type="text"
                                                        ref={(ref) => this.setRef('txt_barrera_2', ref)}
                                                    />
                                                </Col>
                                            </FormGroup>

                                        </CardBody>
                                    </Card>
                                </Col>
                            </FormGroup>
                        </Form>
                    </div>
                    {(this.state.loading) ? <Loader /> : <span />}
                </div>
            );
        }
        else {
            return (
                <div>
                    <Loader />
                </div>);
        }
    }
}