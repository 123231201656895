import React, {Component} from 'react';
import './App.css';
import Login from './screens/Login';
import { loadSettings } from "./config/settings.js"
import Base from './components/Base';

require('moment/locale/es.js');
export default class App extends Base {
  
	constructor(props) {
		super(props);
		loadSettings();
	}

	render() {
		const {children}  = this.props;
		//const { username } = this.state;
		return (
			<Login body ={children}/>
		);
	}
}
