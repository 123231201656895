// Componente base
import React from 'react';
import Base from '../../components/Base';
import { Link } from 'react-router-dom';
import matchSorter from 'match-sorter';
import Button from '../../components/Button';

import GridTable from '../../components/GridTable';
import GeneralServices from '../../services/GeneralServices.js';
import './styles.css';
import cookies from 'react-cookies';

export default class EspacioList extends Base {

    constructor(props) {
        super(props);
        this.servicio = new GeneralServices();

        let cmbTipo = this.getOpcion("TipoEspacio");
        var tipoPeriododata = cmbTipo===""?[]:JSON.parse(cmbTipo);

        var columns = [
            {
                Header: this.getText("titEspacios"),
                headerStyle: { textAlign: "left" },
                columns: [
                    this.ColumnaId(),
                    {
                        Header: this.getText("lblNombre"),
                        id: "nombre",
                        width: 200,
                        accessor: d => d.nombre,
                        filterMethod: (filter, rows) =>
                            matchSorter(rows, filter.value, { keys: ["nombre"] }),
                        filterAll: true
                    },
                    {
                        Header: this.getText("lblDetalle"),
                        id: "detalle",
                        width: 300,
                        accessor: d => d.detalle,
                        filterMethod: (filter, rows) =>
                            matchSorter(rows, filter.value, { keys: ["detalle"] }),
                        filterAll: true
                    },
                    {
                        Header: this.getText("lblPeriodo"),
                        accessor: "periodo",
                        width: 100,
                        style: { textAlign: "center" }
                    },
                    
                    {
                        Header: this.getText("lblTipo"),
                        accessor: "tipoPeriodo",
                        id: "tipoPeriodo",
                        filterMethod: (filter, row) => {
                            if (filter.value === "all") {
                                return true;
                            }
                            else {
                                return row[filter.id] === filter.value;
                            }

                        },
                        Filter: ({ filter, onChange }) =>
                            <select
                                onChange={event => onChange(event.target.value)}
                                style={{ width: "100%" }}
                                value={filter ? filter.value : "all"}
                            >
                                <option value="all">{this.getText("lblTodos")}</option>
                                {
                                    tipoPeriododata.map(k => {
                                        return <option key={k.key} value={k.key}>{k.value}</option>
                                    })
                                }
                            </select>
                    }
                    

                ]
            },
            {
                Header: <Button className="tertiaryColorB" onClick={(e) => this.New(e)} value={this.getText("lblNuevo")}  />,
                columns: [
                    {
                        Header: "",
                        accessor: "buscar",
                        filterable: false,
                        sortable: false,
                        style: { textAlign: "center" }
                    }]
            }
        ];

        this.state = {
            dataGridRows: [],
            dataGridColumns: columns
        }
    };


    setValue(rows) {
        this.setState({
            dataGridRows: rows
        });
    };

    New(e) {
        e.preventDefault();
        window.location.href="/espacio/id/0";
        //this.props.history.push("/espacio/id/0");
    }

    componentDidMount() {
        let etapa = cookies.load('etapa');
        this.servicio.List(etapa, 'espacio/etapa')
            .then(result => {
                if (result.codigo !== "0") {
                    alert(result.mensaje);
                    if(result.codigo===401){
                        this.CerarSesion();                        
                    }
                }
                else {
                    var rows = [];
                    for (let i = 0; i < result.data.length; i++) {
                        rows.push({
                            id: result.data[i].id,
                            nombre: result.data[i].nombre,
                            detalle: result.data[i].detalle,
                            periodo: result.data[i].periodo,
                            tipoPeriodo: result.data[i].tipoPeriodo,
                            buscar: this.link(result.data[i].id)

                        });
                    }
                    this.setValue(rows);
                }

            }
            )
            .catch(error => {
                console.log(error);
            });
    }
    //Fucion para mostrar icono y Link
    link(id) {
        var link = [];
        link.push(
            <Link key={id} to={"/espacio/id/" + id}>
                <h6>{this.getText("lblEditar")}</h6>
            </Link>)
        return link[0]
    }

    render() {
        return (
            <div>
                <div className="mainContent">                    
                    <div className="contentBlock">
                        <GridTable
                            data={this.state.dataGridRows}
                            columns={this.state.dataGridColumns} />
                    </div>
                </div>
            </div>
        )
    }
}
