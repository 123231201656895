import React from 'react';
import Base from '../../components/Base';
import GridTable from '../../components/GridTable';
import GeneralServices from '../../services/GeneralServices.js';
import './style.css';
import matchSorter from 'match-sorter';
import { Link } from 'react-router-dom';
import Button from '../../components/Button';

export default class PersonaList extends Base {


    constructor(props) {
        super(props);
        this.personaService = new GeneralServices();

        var columns = [
            {
                Header: this.getText("titResidentes"),
                headerStyle: { textAlign: "left" },
                columns: [this.ColumnaId()
                    ,
                {
                    Header: this.getText("lblNombres"),
                    id: "nombres",
                    width: 180,
                    accessor: d => d.nombres,
                    filterMethod: (filter, rows) =>
                        matchSorter(rows, filter.value, { keys: ["nombres"] }),
                    filterAll: true
                },
                {
                    Header: this.getText("lblApellidos"),
                    id: "apellidos",
                    width: 180,
                    accessor: d => d.apellidos,
                    filterMethod: (filter, rows) =>
                        matchSorter(rows, filter.value, { keys: ["apellidos"] }),
                    filterAll: true
                },

                {
                    Header: this.getText("lblManzana"),
                    id: "manzana",
                    width: 50,
                    accessor: d => d.manzana,
                    filterMethod: (filter, rows) =>
                        matchSorter(rows, filter.value, { keys: ["manzana"] }),
                    filterAll: true,
                    style: { textAlign: "right" }
                },

                {
                    Header: this.getText("lblLote"),
                    id: "villa",
                    accessor: d => d.villa,
                    filterMethod: (filter, rows) =>
                        matchSorter(rows, filter.value, { keys: ["villa"] }),
                    filterAll: true,
                    width: 80,
                    style: { textAlign: "right" }
                },

                {
                    Header: this.getText("lblDepartamento"),
                    id: "departamento",
                    accessor: d => d.departamento,
                    filterMethod: (filter, rows) =>
                        matchSorter(rows, filter.value, { keys: ["departamento"] }),
                    filterAll: true,
                    style: { textAlign: "right" }
                }


                ]
            },
            {
                Header: <Button className="tertiaryColorB" onClick={(e) => this.Importar(e)} value={this.getText("lblImportar")} />,
                columns: [
                    {
                        Header: this.getText("lblEstado"),
                        accessor: "estado",
                        style: { textAlign: "center" }
                    }]
            },

            {
                Header: <Button className="tertiaryColorB" onClick={(e) => this.New(e)} value={this.getText("lblNuevo")} />,
                columns: [
                    {
                        Header: "",
                        accessor: "buscar",
                        filterable: false,
                        sortable: false,
                        style: { textAlign: "center" }
                    }]
            }
        ];

        this.state = {
            dataGridRows: [],
            dataGridColumns: columns,
            loading: false
        }
    };

    New(e) {
        e.preventDefault();
        console.log(this.props);
        window.location.href="/persona/id/0";
        //this.props.history.push("/persona/id/0");

    }

    Importar(e) {
        e.preventDefault();
        window.location.href="/personaImport";
        //this.props.history.push("/personaImport");
    }

    setValue(rows) {
        this.setState({
            dataGridRows: rows
        });
    };

    componentDidMount() {
        let etapa = this.getEtapa();
        this.setState({ loading: true });
        this.personaService.List(etapa, 'persona/etapa')
            .then(result => {
                if (result.codigo !== "0") {
                    alert(result.mensaje);
                    if (result.codigo === 401) {
                        this.CerarSesion();
                    }
                }
                else {

                    var rows = [];
                    var count = 1;
                    //  console.log(result.data);

                    result.data.map(persona =>
                        rows.push({
                            n: count,
                            id: persona.id,
                            nombres: persona.nombres,
                            apellidos: persona.apellidos,
                            manzana: persona.ubicacion ? persona.ubicacion.manzana : "",
                            villa: persona.ubicacion ? persona.ubicacion.villa : "",
                            departamento: persona.ubicacion ? persona.ubicacion.departamento : "",
                            estado: persona.estado,
                            buscar: this.link(persona.id)
                        }),
                        count++

                    )
                    this.setValue(rows);
                }
                this.setState({ loading: false });
            }
            )
            .catch(error => {
                console.log(error);
                this.setState({ loading: false });
            });
    }

    //Fucion para mostrar icono y Link
    link(id) {
        var link = [];
        link.push(
            <Link key={id} to={"/persona/id/" + id}>
                <h6>{this.getText("lblEditar")}</h6>
            </Link>)
        return link[0]
    }

    render() {

        return (
            <div>
                <div className="mainContent">
                    <div className="contentBlock">
                        <GridTable
                            data={this.state.dataGridRows}
                            loading={this.state.loading}
                            columns={this.state.dataGridColumns} />
                    </div>
                </div>               

            </div>
        )
    }

}

