import React from 'react';
import Base from '../Base';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { UncontrolledTooltip } from 'reactstrap';

// Otros Componentes
import './styles.css';


export default class Return extends Base {

    render() {
        return (
            <div className="css_return">
                <FontAwesomeIcon icon={faArrowLeft}  onClick={this.props.onClick} id="regresar" />
                <UncontrolledTooltip placement="top" target="regresar">
                    {this.getText("lblRegresar")}
                </UncontrolledTooltip>
            </div>
        );
    }
}
