// Componente base
import React from 'react';
import Base from '../../components/Base';
import { Link } from 'react-router-dom';
import matchSorter from 'match-sorter';
import { Input, FormGroup, Label, Col, Card, CardHeader, CardBody, Row, Form } from 'reactstrap';
import GridTable from '../../components/GridTable';
import GeneralServices from '../../services/GeneralServices.js';
import Button from '../../components/Button';
import './style.css';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'


export default class SolicitudReclamoList extends Base {

    constructor(props) {
        super(props);
        this.servicio = new GeneralServices();

        let cmbTipo = this.getOpcion("CasoCategoria");
        var tipodata = cmbTipo === "" ? [] : JSON.parse(cmbTipo);

        var columns = [
            {
                columns: [

                    {
                        Header: this.getText("lblManzana"),
                        id: "manzana",
                        width: 50,
                        accessor: d => d.manzana,
                        filterMethod: (filter, rows) =>
                            matchSorter(rows, filter.value, { keys: ["manzana"] }),
                        filterAll: true,
                        style: { textAlign: "right" }
                    },

                    {
                        Header: this.getText("lblLote"),
                        id: "villa",
                        accessor: d => d.villa,
                        filterMethod: (filter, rows) =>
                            matchSorter(rows, filter.value, { keys: ["villa"] }),
                        filterAll: true,
                        width: 80,
                        style: { textAlign: "right" }
                    },

                    {
                        Header: this.getText("lblDepartamento"),
                        id: "departamento",
                        accessor: d => d.departamento,
                        filterMethod: (filter, rows) =>
                            matchSorter(rows, filter.value, { keys: ["departamento"] }),
                        filterAll: true,
                        width: 90,
                        style: { textAlign: "right" }
                    },

                    {
                        Header: this.getText("lblCategoria"),
                        accessor: "categoria",
                        id: "categoria",
                        filterMethod: (filter, row) => {
                            if (filter.value === "all") {
                                return true;
                            }
                            else {
                                return row[filter.id] === filter.value;
                            }

                        },
                        Filter: ({ filter, onChange }) =>
                            <select
                                onChange={event => onChange(event.target.value)}
                                style={{ width: "100%" }}
                                value={filter ? filter.value : "all"}
                            >
                                <option value="all">{this.getText("lblTodos")}</option>
                                {
                                    tipodata.map(k => {
                                        return <option key={k.key} value={k.value}>{k.value}</option>
                                    })
                                }
                            </select>
                    },


                    {
                        Header: this.getText("lblAsunto"),
                        id: "titulo",
                        accessor: d => d.titulo,
                        filterMethod: (filter, rows) =>
                            matchSorter(rows, filter.value, { keys: ["titulo"] }),
                        filterAll: true,
                        style: { textAlign: "right" }
                    },

                    {
                        Header: this.getText("lblFechaInicio"),
                        id: "fechaIngreso",
                        accessor: d => d.fechaIngreso,
                        filterMethod: (filter, rows) =>
                            matchSorter(rows, filter.value, { keys: ["fechaIngreso"] }),
                        filterAll: true,
                        style: { textAlign: "right" }
                    }
                ]
            },
            {
                Header: "",
                columns: [
                    {
                        Header: "",
                        accessor: "buscar",
                        filterable: false,
                        sortable: false,
                        style: { textAlign: "center" }
                    }
                ]
            }
        ];

        this.state = {
            dataGridRows: [],
            dataGridColumns: columns,
            manzanas: [],
            loading: false,
            CasoCategoria: tipodata
        }
    };

    setValue(rows) {
        this.setState({ dataGridRows: rows });
    };



    Buscar(e) {
        e.preventDefault();

        var fecha = this.fecha.value
        var fecha_hasta = this.fecha_hasta.value

        if(fecha ==="" || fecha_hasta ===""){
            alert(this.getText("msgValidaFechaVacia"));
            return;
        }
        let _fecha = moment(fecha);
        let _fecha_hasta = moment(fecha_hasta);

        if(_fecha > _fecha_hasta){
            alert(this.getText("msgValidaFechas"));
            return;
        }

        let etapa = this.getEtapa();

        this.setState({ loading: true });

        this.servicio.List("", `caso/etapa/${etapa}/${this.cmbTipoSol.value}/${_fecha.toDate().getTime()}/${_fecha_hasta.toDate().getTime()}`)
            .then(result => {
                if (result.codigo !== "0") {
                    alert(result.mensaje);
                    if (result.codigo === 401) {
                        this.CerarSesion();
                    }
                }
                else {
                    var rows = [];
                    console.log(result.data);

                    for (let i = 0; i < result.data.length; i++) {
                        let caso = result.data[i];

                        rows.push({
                            tipo: caso[1] ? caso[1] : "",
                            categoria: caso[2] ? this.state.CasoCategoria.filter(tipo => tipo.key === caso[2])[0].value: "",
                            titulo: caso[3] ? caso[3] : "",
                            manzana: caso[4] ? caso[4] : "",
                            villa: caso[5] ? caso[5] : "",
                            departamento: caso[6] ? caso[6] : "",
                            fechaIngreso: caso[7] ? new Date(caso[7]).toLocaleString() : "",
                            buscar: this.link(caso[0])

                        });
                    }

                    this.setValue(rows);
                }
                this.setState({ loading: false });

            }
            )
            .catch(error => {
                console.log(error);
                this.setState({ loading: false });
            });

    }

    componentDidMount() {
    }

    //Fucion para mostrar icono y Link
    link(id) {
        var link = [];
        link.push(
            <Link key={id} to={"/caso/id/" + id}>
                <h6>{this.getText("lblVer")}</h6>
            </Link>)
        return link[0]
    }

    New(e) {
        e.preventDefault();
        window.location.href="/caso/id/0";
    }

    render() {

        const date_ini=moment().startOf('month').format('YYYY-MM-DD');
        var date = moment().format('YYYY-MM-DD')

        let TipoSolicitud = JSON.parse(this.getOpcion("CasoTipo"));

        console.log(TipoSolicitud);
        return (
            <div>
                <div className="mainContent">
                    <div className="contentBlock">
                        <Card >
                            <CardHeader tag="h5">
                                <Row  >
                                    <Col sm={10} >
                                        {this.getText("titSolicitudReclamos")}
                                    </Col>
                                    <Col sm={2} >
                                        <Button className="float-right"
                                            onClick={(e) => this.New(e)}
                                            disabled={this.state.loading}
                                            value={this.getText("lblNuevo")} />
                                    </Col>

                                </Row>

                            </CardHeader>
                            <CardBody>
                                <Form inline>
                                <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                        <Label for="cmbTipoSol" className="mr-sm-2">{this.getText("lblTipoSolicitud")}</Label>
                                        <Input type="select" id="cmbTipoSol"
                                            innerRef={(c) => this.cmbTipoSol = c} >
                                            {TipoSolicitud.map((dato) => { return <option key={dato.key} value={dato.key} >{dato.value}</option>; })}
                                        </Input>
                                    </FormGroup>{" "}
                                    <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                        <Label for="dtpfecha" className="mr-sm-2">{this.getText("lblFechaDesde")}</Label>
                                        <Input
                                            type="date"
                                            name="date"
                                            id="dtpfecha"
                                            defaultValue={date_ini}
                                            min="2000-01-01"
                                            max="2500-01-01"
                                            innerRef={(c) => this.fecha = c}
                                        />
                                    </FormGroup>
                                    <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                        <Label for="dtpfecha2" className="mr-sm-2">{this.getText("lblFechaHasta")}</Label>
                                        <Input
                                            type="date"
                                            name="date2"
                                            id="dtpfecha2"
                                            defaultValue={date}
                                            min="2000-01-01"
                                            max="2500-01-01"
                                            innerRef={(c) => this.fecha_hasta = c}
                                        />
                                    </FormGroup>
                                    <span className="css_buscar">
                                        <FontAwesomeIcon icon={faSearch} size="lg" onClick={(e) => this.Buscar(e)} />
                                    </span>

                                </Form>
                            </CardBody>
                        </Card>
                        <GridTable
                            data={this.state.dataGridRows}
                            columns={this.state.dataGridColumns}
                            loading={this.state.loading} />
                    </div>
                </div>
            </div >
        )
    }
}
