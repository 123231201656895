// Componente base
import React from 'react';
import PropTypes from 'prop-types';
import Base from '../Base';



// Otros Componentes
import './style.css';

export default class Img extends Base {

    constructor (props) {
        super(props);
        this.state = {
            value: this.props.value,
        }
    };


    render () {
        const {src,className,alt} = this.props;
        
        const _className =  className;
        return (
            <div className={_className}>
                <img src={src} alt={alt}/>
            </div>
        )
    }
}
// Seccion de Props
Img.propTypes = Img.propTypes || {
    src: PropTypes.string,    
    alt: PropTypes.string
}
