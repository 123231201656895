import Services from './Services'


export default class GeneralServices extends Services {

    List(data, route) {
        try {
            return this.get(data, route);
        } catch (error) {
            console.log(error)
        }
    }

    Id(data, route) {
        try {
            return this.get(data, route);
        } catch (error) {
            console.log(error)
        }
    }

    save(data, route) {
        console.log("la ruta: " + route)
        try {
            if (data.id > 0) {
                return this.put(data, route);
            }
            else {
                return this.post(data, route);
            }

        } catch (error) {
            console.log(error)
        }
    }

    update(data, route) {
        return this.put(data, route);
    }

    Upload(data, route) {
        try {
            return this.post2(data, route);
        } catch (error) {
            console.log(error)
        }
    }
}