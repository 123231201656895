// Componente base
import React from 'react';
import { Switch, Route } from 'react-router-dom'
import App from './App';

import Persona from './screens/Persona';
import PersonaImport from './screens/PersonaImport';
import Pizarra from './screens/Pizarra';
import Parametro from './screens/Parametro';
import ParametroCamaras from './screens/ParametroCamaras';

import Usuario from './screens/Usuario';
import Invitado from './screens/Invitado';
import Espacio from './screens/Espacio';
import ReservaEspacio from './screens/ReservaEspacio';
import ParametroAlicuota from './screens/ParametroAlicuota';
import Cobro from './screens/Cobro';
import ControlIngreso from './screens/ControlIngreso';
import ControlIngresoI from './screens/ControlIngresoI';
import Notificacion from './screens/Notificacion';
import CategoriaComercio from './screens/CategoriaComercio';
import Comercio from './screens/Comercio';
import EtapasComercio from './screens/EtapasComercio';
import Buzon from './screens/Buzon';
import SolicitudReclamo from './screens/SolicitudReclamo';
import ControlIngresoReport from './screens/ControlIngresoReport';
// import CambiarClave from './screens/CambiarClave';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import "react-app-polyfill/stable";

const Routes = () => (
 
  <App>
    {/* <main> */}
      <Switch>
        {/* <Route exact path='/' component={BuzonList} /> */}
        <Route exact path='/buzon/id/:id' component={Buzon} />       
        <Route exact path='/persona/id/:id' component={Persona} />
        <Route exact path='/personaImport' component={PersonaImport} />        
        <Route exact path='/pizarra/id/:id' component={Pizarra} />
        <Route exact path='/invitado/id/:id' component={Invitado} />
        <Route exact path='/parametros' component={Parametro} />
        <Route exact path='/parametrosCamaras' component={ParametroCamaras} />
        

        <Route exact path='/usuario/id/:id' component={Usuario} />
        <Route exact path='/parametroAlicuota' component={ParametroAlicuota} />
        <Route exact path='/cobro/id/:id' component={Cobro} />
        <Route exact path='/usuario/id/:id' component={Usuario} />
        
        <Route exact path='/controlInvitado/id/:id' component={ControlIngreso} />
        {/* el nuevo componente */}
        <Route exact path='/controlInvitadoR/id/:id' component={ControlIngresoReport} />


        <Route exact path='/ControlIngreso/id/:id' component={ControlIngresoI} />
        <Route exact path='/espacio/id/:id' component={Espacio} />
        <Route exact path='/reservaespacio/id/:id/:title?' component={ReservaEspacio} />
        <Route exact path='/notificacion/id/:id' component={Notificacion} />
        <Route exact path='/caso/id/:id' component={SolicitudReclamo} />
        {/* <Route exact path='/Notificacion' component={NotificacionList} />
        <Route exact path='/Espacios' component={EspacioList} />
        <Route exact path='/Comercios' component={ComerciosList} />
        <Route exact path='/EspaciosReserva' component={ReservaEspacioList} />        
        <Route exact path='/CategoriasComercio' component={CategoriasComercioList} />
        <Route exact path='/ControlInvitados' component={ControlIngresoList} />   */}

        <Route exact path='/CategoriaComercio/id/:id' component={CategoriaComercio} />        
        <Route exact path='/Comercio/id/:id' component={Comercio} />
        <Route exact path='/Comercio/id/:id/:reserva' component={Comercio} />
        <Route exact path='/EtapasComercio/id/:id' component={EtapasComercio} />
        {/* <Route component={BuzonList} /> } */}
        {/* <Route exact path='/CambiarClave/id/:id' component={CambiarClave} /> */}


      </Switch>
    {/* </main> */}
  </App>
)

export default Routes;