// Componente base
import React from 'react';
import Base from '../../components/Base';
import { Link } from 'react-router-dom';
import matchSorter from 'match-sorter';
import Button from '../../components/Button';

import GridTable from '../../components/GridTable';
import GeneralServices from '../../services/GeneralServices.js';
import './styles.css';
import cookies from 'react-cookies';

export default class InvitadoList extends Base {

    constructor(props) {
        super(props);
        this.servicio = new GeneralServices();
        let cmbTipo = this.getOpcion("vigencia_tipo");
        var tipodata = cmbTipo === "" ? [] : JSON.parse(cmbTipo);

        var columns = [
            {
                Header: this.getText("titInvitados"),
                headerStyle: { textAlign: "left" },
                columns: [
                    this.ColumnaId(),
                    {
                        Header: this.getText("lblManzana"),
                        id: "manzana",
                        width: 50,
                        accessor: d => d.manzana,
                        filterMethod: (filter, rows) =>
                            matchSorter(rows, filter.value, { keys: ["manzana"] }),
                        filterAll: true,
                        style: { textAlign: "right" }
                    },

                    {
                        Header: this.getText("lblLote"),
                        id: "villa",
                        accessor: d => d.villa,
                        filterMethod: (filter, rows) =>
                            matchSorter(rows, filter.value, { keys: ["villa"] }),
                        filterAll: true,
                        width: 80,
                        style: { textAlign: "right" }
                    },

                    // {
                    //     Header: this.getText("lblDepartamento"),
                    //     id: "departamento",
                    //     accessor: d => d.departamento,
                    //     filterMethod: (filter, rows) =>
                    //         matchSorter(rows, filter.value, { keys: ["departamento"] }),
                    //     filterAll: true,
                    //     style: { textAlign: "right" }
                    // },
                    {
                       Header: this.getText("lblPropietario"),
                       id: "propietario",
                       accessor: d => d.propietario,
                       filterMethod: (filter, rows) =>
                           matchSorter(rows, filter.value, { keys: ["propietario"] }),
                       filterAll: true,
                       style: { textAlign: "right" }
                    },              
                    // {
                    //     Header: this.getText("lblTipo"),
                    //     accessor: "tipo",
                    //     id: "tipo",
                    //     filterMethod: (filter, row) => {
                    //         if (filter.value === "all") {
                    //             return true;
                    //         }
                    //         else {
                    //             return row[filter.id] === filter.value;
                    //         }

                    //     },
                    //     Filter: ({ filter, onChange }) =>
                    //         <select
                    //             onChange={event => onChange(event.target.value)}
                    //             style={{ width: "100%" }}
                    //             value={filter ? filter.value : "all"}
                    //         >
                    //             <option value="all">{this.getText("lblTodos")}</option>
                    //             {
                    //                 tipodata.map(k => {
                    //                     return <option key={k.key} value={k.key}>{k.value}</option>
                    //                 })
                    //             }
                    //         </select>
                    // },
                    {
                        Header: this.getText("lblFechaDesde"),
                        accessor: "fechaDesde",
                        width: 180,
                        style: { textAlign: "center" }
                    },
                    {
                        Header: this.getText("lblFechaHasta"),
                        accessor: "fechaHasta",
                        width: 180,
                        style: { textAlign: "center" }
                    }

                ]
            },
            {
                Header: <Button className="tertiaryColorB" onClick={(e) => this.Parametros(e)} value={this.getText("lblParametros")} />,
                columns: [
                    {
                        Header: "",
                        accessor: "buscar",
                        filterable: false,
                        sortable: false,
                        style: { textAlign: "center" }
                    }]
            }
        ];

        this.state = {
            dataGridRows: [],
            dataGridColumns: columns
        }
    };

    setValue(rows) {
        this.setState({
            dataGridRows: rows
        });
    };

    Parametros(e) {
        e.preventDefault();
        window.location.href = "/parametros";
        //this.props.history.push("/parametros");
    }

    componentDidMount() {
        let etapa = cookies.load('etapa');
        this.servicio.List(etapa, 'invitacion/etapa')
            .then(result => {
                // console.log("resultados de lista de invitados: ", result);
                if (result.codigo !== "0") {
                    alert(result.mensaje);
                    if (result.codigo === 401) {
                        this.CerarSesion();
                    }
                }
                else {
                    var rows = [];
                    for (let i = 0; i < result.data.length; i++) {
                        let invitacion = result.data[i];
                        let dir;
                        if (result.data[i].direccion != null) {
                            dir = JSON.parse(result.data[i].direccion);
                        }
                        rows.push({

                            id: invitacion.id,
                            manzana: dir ? dir.mz : "",
                            villa: dir ? dir.vl : "",
                            departamento: dir ? dir.dp : "",
                            fechaDesde: invitacion.fecha_inicio ? new Date(invitacion.fecha_inicio).toLocaleString() : "",
                            fechaHasta: invitacion.fecha_fin ? new Date(invitacion.fecha_fin).toLocaleString() : "",
                            tipo: invitacion.tipo,
                            buscar: this.link(invitacion.id),
                            propietario: invitacion.nombres ? invitacion.nombres + " " + invitacion.apellidos : ""

                        });

                    }
                    this.setValue(rows);
                }

            }
            )
            .catch(error => {
                console.log(error);
            });
    }
    //Fucion para mostrar icono y Link
    link(id) {
        var link = [];
        link.push(
            <Link key={id} to={"/invitado/id/" + id}>
                <h6>{this.getText("lblVer")}</h6>
            </Link>)
        return link[0]
    }

    render() {
        return (
            <div>
                <div className="mainContent">
                    <div className="contentBlock">
                        <GridTable
                            data={this.state.dataGridRows}
                            columns={this.state.dataGridColumns} />
                    </div>
                </div>
            </div>
        )
    }
}
