import React from 'react';
import Base from '../../components/Base';
import {
    Col, Form, FormGroup, Label, Input,
    ModalHeader, Modal, ModalBody,
    ModalFooter, Button
} from 'reactstrap';
import Text from "../../components/Text";
import Ubicacion from "../../components/Ubicacion";
import GeneralServices from '../../services/GeneralServices.js';
import ReservaModel from '../../models/ReservaModel.js';
import Loader from '../../components/Loader';
import Dropdown from "../../components/Dropdown";
import CobroModel from '../../models/CobroModel.js';
//import Reserva from '../../components/Reserva';
import Pago from "../../components/Pago";
import moment from 'moment';

export default class Espacio extends Base {
    constructor(props) {
        super(props);
        this.handleAgregar = this.handleAgregar.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.reservaService = new GeneralServices();
        this.ChangeEstado = this.ChangeEstado.bind(this);
        this.state = {
            open: this.props.open,
            loading: false
        };

    }

    handleClose(event) {
        this.setState({ estado: null });
    }

    handleAgregar(event) {
        event.preventDefault();

        let ubicacion = this.getRef('cmbUbicacion');

        let validar = ubicacion.validar();

        if (validar) {
            alert(validar);
            return;
        }

        validar = this.ValidateForm();

        if (validar) {
            alert(validar);
            return;
        }

        this.setState({ loading: true });

        let file = this.props.value.valor>0?this.getRef('cmbArchivoPago').state.file:"";

        if (typeof file =='string') {
            this.guardarCobro(ubicacion,file);
        }
        else {
            let formData = new FormData();
            formData.append('file', file);
            this.reservaService.Upload(formData, 'archivo/subir')
                .then(result => {
                    //console.log(result);
                    if (result.codigo !== "0") {
                        alert(result.mensaje);
                        if (result.codigo === 401) {
                            this.CerarSesion();
                        }
                        this.setState({ loading: false });
                    }
                    else {
                        this.guardarCobro(ubicacion,result.data.url);
                    }
                }
                )
                .catch(error => {
                    console.log(error);
                    this.setState({ loading: false });
                });
        }
    }

    guardarCobro(ubicacion,file) {
        //console.log(ubicacion.state);
        let reserva = new ReservaModel();
        reserva.id = this.props.value.id;
        reserva.estado = this.state.estado ? this.state.estado : this.props.value.estado;
        reserva.fechaInicio = moment(this.getRef('txt_inicio').getValue(), "DD/MM/YYYY HH:mm:ss").toDate();
        reserva.fechaFin = moment(this.getRef('txt_fin').getValue(), "DD/MM/YYYY HH:mm:sss").toDate();
        reserva.persona = ubicacion.state.persona ? ubicacion.state.persona : null;
        reserva.detalle = this.getRef('txt_detalle').getValue();
        reserva.espacio = this.espacio.value;
        reserva.ubicacion = ubicacion.state.ubicacion.id;
        reserva.usuario_ingreso = reserva.id === 0 ? this.getUsuario() : this.props.value.usuario_ingreso;
        reserva.usuario_modificacion = reserva.id === 0 ? null : this.getUsuario();

        let formaPago = this.props.value.valor>0?this.getRef('cmbFormaPago').getValue():'';

        if (formaPago !== '') {

            let cobro = new CobroModel();
            cobro.archivo = file;

            if (this.props.cobro !== undefined && this.props.cobro !== null) {

                cobro.idEtapa = this.props.cobro.idEtapa;
                cobro.id = this.props.cobro.id;
                cobro.anio = this.props.cobro.anio;
                cobro.mes = this.props.cobro.mes;
                cobro.fechaIngreso = this.props.cobro.fechaIngreso;
                cobro.usuario_ingreso = this.props.cobro.usuario_ingreso;
                cobro.tipoCobro = this.props.cobro.tipoCobro;
                cobro.pagado = this.props.cobro.pagado;
                cobro.fechaPago = this.props.cobro.fechaPago;
                cobro.usuario_modificacion = this.getUsuario();
            }
            else {
                cobro.idEtapa = this.getEtapa();
                cobro.id = 0;
                cobro.anio = moment().year();
                cobro.mes = moment().format("MM");
                cobro.fechaIngreso = new Date();
                cobro.usuario_ingreso = this.getUsuario(); //cobro.id > 0 ? this.state.cobro.usuario_ingreso : this.getUsuario();
                cobro.tipoCobro = 'RES';
                cobro.pagado = true;
                cobro.fechaPago = new Date();//_fecha.toDate(); 

            }

            cobro.ubicacion = ubicacion.state.ubicacion;
            cobro.valor = this.getRef('txt_valor').getValue();

            //let _fecha = moment(fecha);                       
            cobro.formaPago = this.getRef('cmbFormaPago').getValue();
            cobro.banco = this.getRef('txt_banco').getValue();
            cobro.referencia = this.getRef('txt_referencia').getValue();

            this.reservaService.save(cobro, cobro.route())
                .then(result => {
                    //console.log(result);
                    if (result.codigo !== "0") {
                        alert(result.mensaje);

                        if (result.codigo === 401) {
                            this.CerarSesion();
                        }
                        this.setState({ loading: false });
                    }
                    else {
                        reserva.idCobro = result.data.id;
                        this.guardaReserva(reserva);
                    }
                })
                .catch(error => {
                    console.log(error);
                    this.setState({ loading: false });
                });
        }
        else {
            this.guardaReserva(reserva);
        }
    }

    guardaReserva(reserva) {
        this.reservaService.save(reserva, reserva.route())
            .then(result => {
                this.setState({ loading: false });
                if (result.codigo !== "0") {
                    alert(result.mensaje);
                    if (result.codigo === 401) {
                        this.CerarSesion();
                    }
                }
                else {
                    alert(this.getText("msgGuardadoOk"));
                    reserva.id = result.data.id;
                    this.props.addEvent(reserva);
                }
            }
            )
            .catch(error => {
                console.log(error);
                this.setState({ loading: false });
            });
    }

    ChangeEstado(event) {
        this.setState({
            estado: event.target.value
        });
    }

    render() {
        const val = this.props.value;
        const cobro = this.props.cobro;
        //console.log(this.getRef("cmbFormaPago"));
        if (!val) {
            return (<div></div>);
        }
        //  console.log(val);
        var status = val.estado;

        if (this.state.estado) {
            status = this.state.estado;
        }

        const closeBtn = <button className="close" onClick={this.props.Close}>&times;</button>;
        return (
            <div>
                <Modal isOpen={this.props.open} centered onClosed={this.handleClose} >
                    <ModalHeader close={closeBtn}> {val.espacioNombre}</ModalHeader>
                    <ModalBody>
                        <Form encType="multipart/form-data" >
                            <Input type="hidden" id="espacio" innerRef={(c) => this.espacio = c} value={val.espacio} />
                            <FormGroup row>
                                <Col >
                                    <Label for="estados" className='inputNormal' >{this.getText("lblEstado")}</Label>
                                    <FormGroup check inline id="estados" >
                                        <Label check>
                                            <Input
                                                type='radio'
                                                name="estado"
                                                value='A'
                                                checked={status === 'A'}
                                                onChange={this.ChangeEstado}
                                            />{this.getText("lblReservado")}</Label>

                                    </FormGroup>
                                    <FormGroup check inline  >
                                        <Label check>
                                            <Input
                                                type='radio'
                                                name="estado"
                                                value='P'
                                                checked={status === 'P'}
                                                onChange={this.ChangeEstado}
                                            />{this.getText("lblPendiente")}</Label>
                                    </FormGroup>
                                    <FormGroup check inline  >
                                        <Label check>
                                            <Input
                                                type='radio'
                                                name="estado"
                                                value='I'
                                                checked={status === 'I'}
                                                onChange={this.ChangeEstado}
                                            />{this.getText("lblCancelado")}</Label>
                                    </FormGroup>
                                </Col>
                            </FormGroup>

                            <Ubicacion
                                ref={(ref) => this.setRef('cmbUbicacion', ref)}
                                residente={true}
                                persona={val.persona}
                                ubicacion={val.ubicacion}
                            />

                            <FormGroup row>
                                <Col >
                                    <Text
                                        label={this.getText("lblDetalle")}
                                        id={'txt_detalle'}
                                        value={val.detalle}
                                        type={'textarea'}
                                        height="150px"
                                        ref={(ref) => this.setRef('txt_detalle', ref)}
                                        isRequired={true}
                                        menssage={`${this.getText("msgCampoRequerido")} ${this.getText("lblDetalle")}`}
                                    />
                                </Col>
                            </FormGroup>
                            {val.valor > 0 ?
                                <div>
                                    <FormGroup row>
                                        <Col >
                                            <Dropdown
                                                id="cmbFormaPago"
                                                ref={(ref) => this.setRef('cmbFormaPago', ref)}
                                                label={this.getText("lblFormaPago")}
                                                value={JSON.parse(this.getOpcion("FormaPago"))}
                                                tipo={cobro !== null ? "" : "N"}
                                                default={cobro !== null ? cobro.formaPago : undefined}

                                            />
                                        </Col>
                                        <Col >
                                            <Text
                                                label={this.getText("lblBanco")}
                                                id={'txt_banco'}
                                                value={cobro !== null ? cobro.banco : ""}
                                                type={'text'}
                                                ref={(ref) => this.setRef('txt_banco', ref)}
                                            />
                                        </Col>

                                    </FormGroup>
                                    <FormGroup row>
                                        <Col >
                                            <Text
                                                label={this.getText("lblReferencia")}
                                                id={'txt_referencia'}
                                                value={cobro !== null ? cobro.referencia : ""}
                                                type={'text'}
                                                ref={(ref) => this.setRef('txt_referencia', ref)}
                                            />
                                        </Col>
                                        <Col>
                                            <Text
                                                label={this.getText("lblValor")}
                                                id={'txt_valor'}
                                                value={"" + val.valor}
                                                type="number"
                                                ref={(ref) => this.setRef('txt_valor', ref)}
                                                disabled={true}
                                            />
                                        </Col>
                                    </FormGroup>
                                    <Pago
                                        ref={(ref) => this.setRef('cmbArchivoPago', ref)}
                                        file={cobro !== null ? cobro.archivo : ""}
                                    />

                                </div>
                                : <div />

                            }
                            <FormGroup row>
                                <Col sm={6}>
                                    <Text
                                        label={this.getText("lblHoraInicio")}
                                        id={'txt_inicio'}
                                        value={val.fechaInicio ? new Date(val.fechaInicio).toLocaleString() : ""}
                                        type={'text'}
                                        ref={(ref) => this.setRef('txt_inicio', ref)}
                                        disabled={true}
                                    />
                                </Col>
                                <Col sm={6}>
                                    <Text
                                        label={this.getText("lblHoraFin")}
                                        id={'txt_fin'}
                                        type={'text'}
                                        value={val.fechaFin ? new Date(val.fechaFin).toLocaleString() : ""}
                                        ref={(ref) => this.setRef('txt_fin', ref)}
                                        disabled={true}
                                    />
                                </Col>
                            </FormGroup>
                        </Form>
                        {/* <Reserva
                        value={val}
                        cobro={cobro}
                        ></Reserva> */}
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={this.handleAgregar}>{this.getText("lblGuardar")}</Button>
                    </ModalFooter>
                    {(this.state.loading) ? <Loader /> : <span />}
                </Modal>
            </div>

        )
    }
}