import React from 'react';
import {
    Col, Form, FormGroup, Label, Input,
    CustomInput, Card, Collapse,
    Dropdown as DR, DropdownItem, DropdownMenu, DropdownToggle
} from 'reactstrap';

import Base from '../../components/Base';
import Text from '../../components/Text';
import Dropdown from "../../components/Dropdown";
import './style.css';
import EspacioModel from '../../models/EspacioModel.js';
import GeneralServices from '../../services/GeneralServices.js';
import cookies from 'react-cookies';
import Loader from '../../components/Loader';
import Alert from '../../components/Alert';
import Button from '../../components/Button';
import Return from '../../components/Return';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';

export default class Espacio extends Base {
    constructor(props) {
        super(props);

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.espacioService = new GeneralServices();
        this.toggle = this.toggle.bind(this);
        //const [startDate, setStartDate] = React.useState(new Date());
        this.state =
        {
            estado: '',
            horario: [],
            loading: false,
            alerta: false,
            mensaje: '',
            open: false,
            open2: false,
            error: false,
            startDateIni: new Date(),
            startDateFin: new Date(),
            collapse: [
                { id: 'lun', val: false, horarios: [{ id: 1, ini: "", fin: "", periodo: "15", tipoPeriodo: "MIN", numeroFamilias: "1" }] },
                { id: 'mar', val: false, horarios: [{ id: 1, ini: "", fin: "", periodo: "15", tipoPeriodo: "MIN", numeroFamilias: "1" }] },
                { id: 'mié', val: false, horarios: [{ id: 1, ini: "", fin: "", periodo: "15", tipoPeriodo: "MIN", numeroFamilias: "1" }] },
                { id: 'jue', val: false, horarios: [{ id: 1, ini: "", fin: "", periodo: "15", tipoPeriodo: "MIN", numeroFamilias: "1" }] },
                { id: 'vie', val: false, horarios: [{ id: 1, ini: "", fin: "", periodo: "15", tipoPeriodo: "MIN", numeroFamilias: "1" }] },
                { id: 'sáb', val: false, horarios: [{ id: 1, ini: "", fin: "", periodo: "15", tipoPeriodo: "MIN", numeroFamilias: "1" }] },
                { id: 'dom', val: false, horarios: [{ id: 1, ini: "", fin: "", periodo: "15", tipoPeriodo: "MIN", numeroFamilias: "1" }] },
            ]
        };

        console.log("state: ", this.state);
    }

    handleChange(event) {
        this.setState({
            estado: event.target.value
        });
    }

    toggle_button = () => {
        this.setState({
            open: !this.state.open
        });
    }

    toggle_button2 = () => {
        this.setState({
            open2: !this.state.open2
        });
    }

    ShowAlert(mensaje, error) {
        this.setState({ alerta: true, mensaje: mensaje, error: error }, () => {
            window.setTimeout(() => {
                this.setState({ alerta: false, mensaje: '', error: false })
            }, 3000)
        });
    }

    handleSubmit(event) {
        event.preventDefault();

        let espacio = new EspacioModel();
        let validar = this.ValidateForm();

        if (validar) {
            this.ShowAlert(validar, true);
            return;
        }

        let validarH = this.validaHorario();
        if (validarH) {
            this.ShowAlert(validarH, true);
            return;
        }

        this.setState({ loading: true });
        espacio.nombre = this.getRef('txt_nombre').getValue();
        espacio.detalle = this.getRef('txt_detalle').getValue();;
        espacio.id = this.state.espacio.id;
        espacio.idEtapa = this.state.espacio.idEtapa;
        // espacio.tipoPeriodo = this.getRef("cmbTipoPeriodo").getValue();
        espacio.reservaApp = this.reserva.checked;
        //espacio.reservasPermitidas = this.reserva.checked ? this.getRef('txt_reservapermitida').getValue() : "";
        espacio.estado = this.state.estado;
        espacio.aprobar = this.aprobar.checked;
        //espacio.periodo = this.getRef('txt_periodo').getValue();
        espacio.horario = JSON.stringify(this.state.collapse);
        espacio.color = this.state.espacio.color;
        espacio.color2 = this.state.espacio.color2;
        espacio.valor = this.getRef('txt_valor').getValue();

        // console.log(espacio);
        this.espacioService.save(espacio, espacio.route())
            .then(result => {
                if (result.codigo !== "0") {
                    alert(result.mensaje);
                }
                else {
                    this.setState({ estado: result.data.estado, espacio: result.data, loading: false });
                    this.ShowAlert(this.getText("msgGuardadoOk"), false);
                    //this.props.history.push('/Espacio');
                }
                this.setState({ loading: false });
            }
            )
            .catch(error => {
                console.log(error);
                this.setState({ loading: false });
            });
    }



    changeColor2(e) {
        let espacio = this.state.espacio;
        espacio.color2 = e;
        this.setState({
            espacio: espacio
        })
    }

    changeColor(e) {
        let espacio = this.state.espacio;
        espacio.color = e;
        this.setState({
            espacio: espacio
        })
    }

    changeDatePicker(date, id, dias) {
        const _hora = new Date(date).getHours();
        const _minutos = new Date(date).getMinutes();

        const _time = ((_hora < 10) ? `0${_hora}` : _hora) + ":" + ((_minutos == 0) ? "00" : _minutos);

        var res = id.split("_");
        var dia = dias.id;

        const lista = this.state.collapse.map((item) => {
            if (item.id === dia) {
                // console.log(item);
                item.horarios.map((hor) => {
                    if (hor.id == res[1]) {
                        // console.log(hor);
                        if (res[0] === "ini") {
                            hor.ini = _time;
                        }
                        if (res[0] === "fin") {
                            hor.fin = _time;
                        }
                    } else {
                        return hor;
                    }
                });
                return item;
            } else {
                return item;
            }
        });
        this.setState({ collapse: lista });
    }

    changeHour = (e, dias) => {
        var res = e.target.id.split("_");
        var dia = dias.id;
        // console.log(dia);
        // console.log(res);
        const lista = this.state.collapse.map((item) => {
            if (item.id === dia) {
                // console.log(item);
                const horario = item.horarios.map((hor) => {
                    if (hor.id == res[2]) {
                        // console.log("id de hor: ", hor);
                        if (res[1] === "ini") {
                            hor.ini = e.target.value;
                        }
                        if (res[1] === "fin") {
                            hor.fin = e.target.value;
                        }
                        if (res[1] === "TiempoPeriodo") {
                            hor.periodo = e.target.value;
                        }
                        if (res[1] === "TipoPeriodo") {
                            hor.tipoPeriodo = e.target.value;
                        }
                        if(res[1] === "Familias"){
                            hor.numeroFamilias = e.target.value;
                        }
                    } else {
                        return hor;
                    }
                    //             return hor
                })
                // console.log("lo que devuelve horarios: ", horario);
                return item;
            } else {
                return item;
            }
        });
        // console.log("la nueva lista: ", lista);
        this.setState({ collapse: lista });
    }

    agregarHorario(dias) {
        var dia = dias.id;

        const lista = this.state.collapse.map((item) => {
            if (item.id === dia) {
                var data = item.horarios;
                data.push({ id: data.length + 1, ini: "", fin: "", periodo: "15", tipoPeriodo: "MIN", numeroFamilias: "1" });
                item.horarios = data
                return item;
            } else {
                return item;
            }
        });

        // console.log(lista);
        this.setState({ collapse: lista });
        //console.log(this.state.collapse);

    }

    eliminarHorario(dias, horarioSeleccionado) {
        var dia = dias.id;

        // console.log("id de dias seleccionado: ", dias);
        // console.log('horario seleccionado de ese dia de ese dia', horario);
        const lista = this.state.collapse.map((item) => {
            if (item.id === dia) {
                var data = item.horarios;
                data.forEach((horario, index) => {
                    if (horario.id === horarioSeleccionado.id) {
                        // console.log("horario: ", horario, " index: ", index);
                        data.splice(index, 1);
                        let newData = [];
                        if (data.length > 0) {
                            data.forEach((dat, index) => {
                                newData.push({ ...dat, id: index + 1 })
                                item.horarios = newData;
                                // console.log("data nuevamente: ", newData);
                            })
                        } else {
                            data.push({ id: data.length + 1, ini: "", fin: "", periodo: "15", tipoPeriodo: "MIN", numeroFamilias: "1" })
                            // item.horarios = data;
                            // item.val = !item.val;
                            const event = {
                                target: { id: dia }
                            }
                            this.toggle(event);
                            // return item;
                        }
                    }
                })
                // data.push({ id: data.length + 1, ini: "", fin: "", periodo: "15", tipoPeriodo: "MIN" });
                return item;
            } else {
                return item;
            }
        });

        // console.log(lista);
        this.setState({ collapse: lista });
        //console.log(this.state.collapse);
    }

    toggle(event) {
        const lista = this.state.collapse.map((item) => {
            if (item.id === event.target.id) {
                item.val = !item.val;
                return item
            } else {
                return item;
            }
        });

        this.setState({ collapse: lista });
    }

    validaHorario() {
        let menssage = null;
        const lista = this.state.collapse.filter(dato => { return dato.val });

        if (lista.length === 0) {
            menssage = this.getText("msgIngreseHorario");
        }

        for (let item of lista) {
            if (item.val && (item.ini !== "" || item.fin !== "")) {
                var startTime = new Date('2000/01/01 ' + item.ini);
                var endTime = new Date('2000/01/01 ' + item.fin);

                if (startTime >= endTime) {
                    menssage = this.getText("lbl" + item.id) + ": " + this.getText("msgValidaHora");
                    break;
                }

            }
            if (item.val && (item.ini === "" || item.fin === "")) {
                menssage = this.getText("lbl" + item.id) + ": " + this.getText("msgValidaHoraVacia");
                break;
            }
        }
        return menssage;


    }

    reservar = e => {
        //console.log(e.target);
        const area = this.state.espacio;
        area.reservaApp = e.target.checked;
        this.setState({ espacio: area });
    }



    componentDidMount() {
        const id = this.props.match.params.id;
        if (id > 0) {
            this.espacioService.Id(id, 'espacio/id')
                .then(result => {
                    if (result.codigo !== "0") {
                        alert(result.mensaje);
                    }
                    else {
                        var horario = []

                        if (result.data === null) {
                            alert(this.getText("lblNoHayDatosId") + id);
                            window.location.href = "/Espacio";
                            //this.props.history.push('/Espacio');
                            return;
                        }

                        if (result.data.horario != null) {
                            horario = JSON.parse(result.data.horario);
                        }
                        this.setState({ estado: result.data.estado, espacio: result.data, collapse: horario });
                        this.getRef("cmbTipoPeriodo").updateValue(result.data.tipoPeriodo)
                    }
                }
                )
                .catch(error => {
                    console.log(error);
                });
        }
        else {
            let espacio = new EspacioModel();
            let etapa = cookies.load('etapa');
            espacio.nombre = "";
            espacio.detalle = "";
            espacio.horario = "";
            espacio.id = id;
            espacio.idEtapa = etapa;
            espacio.tipoPeriodo = 0;
            espacio.periodo = "1";
            espacio.estado = "A";
            espacio.aprobar = false;
            espacio.reservaApp = true;
            espacio.color = '#B077FE';
            espacio.color2 = '#B077FE';
            this.setState({ estado: espacio.estado, espacio: espacio });
        }
    }
    render() {
        let Colors = JSON.parse(this.getOpcion("Colores"));
        var minutosEspacio = JSON.parse(this.getOpcion("MinutosEspacio"));
        var tipoSpacio = JSON.parse(this.getOpcion("TipoEspacio"));
        if (this.state.espacio) {
            return (
                <div className='formTitulo' >

                    <Alert
                        open={this.state.alerta}
                        mensaje={this.state.mensaje}
                        error={this.state.error}
                    />
                    <FormGroup row >
                        <Col md={9}>
                            <h5><Return onClick={() => this.props.history.go(-1)} />{this.getText("titEspacio")}</h5>
                        </Col>
                        <Col md={3} className="text-right">
                            <Button type="submit" onClick={this.handleSubmit} className="tertiaryColorB" value={this.getText("lblGuardar")} />
                        </Col>
                    </FormGroup>

                    <div className='form'>
                        <Form encType="multipart/form-data" >
                            <FormGroup row>
                                <Col md={8}>
                                    <FormGroup row>
                                        <Col>
                                            <Label for="estados" className='inputNormal' >{this.getText("lblEstado")}</Label>
                                            <FormGroup check inline id="estados" >
                                                <Label check>
                                                    <Input
                                                        type='radio'
                                                        name="estado"
                                                        value='A'
                                                        checked={this.state.estado === 'A'}
                                                        onChange={this.handleChange}
                                                    />{this.getText("lblActivo")}</Label>

                                            </FormGroup>
                                            <FormGroup check inline  >
                                                <Label check>
                                                    <Input
                                                        type='radio'
                                                        name="estado"
                                                        value='I'
                                                        checked={this.state.estado === 'I'}
                                                        onChange={this.handleChange}
                                                    />{this.getText("lblInactivo")}</Label>
                                            </FormGroup>
                                        </Col>

                                    </FormGroup>
                                    <FormGroup row>
                                        <Col >
                                            <Text
                                                label={this.getText("lblNombre")}
                                                id={'txt_nombre'}
                                                value={this.state.espacio.nombre}
                                                type={'text'}
                                                ref={(ref) => this.setRef('txt_nombre', ref)}
                                                isRequired={true}
                                                menssage={`${this.getText("msgCampoRequerido")} ${this.getText("lblNombre")}`}
                                            />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col >
                                            <Text
                                                label={this.getText("lblDetalle")}
                                                id={'txt_detalle'}
                                                value={this.state.espacio.detalle}
                                                type={'textarea'}
                                                ref={(ref) => this.setRef('txt_detalle', ref)}
                                                isRequired={true}
                                                menssage={`${this.getText("msgCampoRequerido")} ${this.getText("lblDetalle")}`}
                                            />
                                        </Col>
                                    </FormGroup>

                                    <FormGroup row >
                                        <Col md={3}>
                                            <div className="dropdown">
                                                <Label className='inputNormal' >
                                                    {this.getText("lblColor") + " 1"}
                                                </Label>
                                                <DR isOpen={this.state.open} toggle={this.toggle_button}
                                                >
                                                    <DropdownToggle caret className="btn2"
                                                        style={{ backgroundColor: this.state.espacio.color, width: "100%" }}
                                                        color="primary">
                                                        {this.getText("lblElegir")}</DropdownToggle>
                                                    <DropdownMenu right>
                                                        {Colors.map((dato) => {
                                                            return <DropdownItem key={dato.key}
                                                                style={{ backgroundColor: dato.key, width: "100%", height: "20px" }}
                                                                value={dato.key}
                                                                onClick={this.changeColor.bind(this, dato.key)} > {" "}</DropdownItem>;
                                                        })}
                                                    </DropdownMenu>
                                                </DR>
                                            </div>
                                        </Col>
                                        <Col md={3}>
                                            <div className="dropdown">
                                                <Label className='inputNormal' >
                                                    {this.getText("lblColor") + " 2"}
                                                </Label>

                                                <DR isOpen={this.state.open2} toggle={this.toggle_button2} >
                                                    <DropdownToggle caret className="btn2"
                                                        style={{ backgroundColor: this.state.espacio.color2, width: "100%" }}
                                                        color="primary">
                                                        {this.getText("lblElegir")}</DropdownToggle>
                                                    <DropdownMenu right>
                                                        {Colors.map((dato) => {
                                                            return <DropdownItem key={dato.key}
                                                                style={{ backgroundColor: dato.key, width: "100%", height: "20px" }}
                                                                value={dato.key}
                                                                onClick={this.changeColor2.bind(this, dato.key)} > {" "}</DropdownItem>;
                                                        })}
                                                    </DropdownMenu>
                                                </DR>
                                            </div>
                                        </Col>
                                        <Col md={6} >
                                            <div className="inputNormal">
                                                <Label for="txt_color" className='inputNormal' >
                                                    {this.getText("lblColor") + " " + this.getText("titEspacio")}
                                                </Label>
                                                <Input id="txt_color"
                                                    disabled={true}
                                                    type={"text"}
                                                    style={{ backgroundImage: `linear-gradient(to right, ${this.state.espacio.color}, ${this.state.espacio.color2})`, }} />
                                            </div>


                                        </Col>
                                    </FormGroup>


                                    <FormGroup row>
                                        <Col  >
                                            <CustomInput
                                                // type="switch"
                                                id="reserva"
                                                innerRef={(c) => this.reserva = c}
                                                //defaultChecked={this.state.espacio.reservaApp}
                                                defaultChecked={true}
                                                type="hidden"
                                                onChange={this.reservar}
                                                label={this.getText("lblReservaApp")} />
                                        </Col>
                                        <Col  >
                                            {
                                                this.state.espacio.reservaApp ?
                                                    <Text
                                                        id={'txt_reservapermitida'}
                                                        value={""}//+ this.state.espacio.reservasPermitidas}
                                                        // type={'number'}
                                                        isRequired={false}
                                                        type="hidden"
                                                        ref={(ref) => this.setRef('txt_reservapermitida', ref)}
                                                        placeholder={this.getText("lblReservaPermitidas")}
                                                    />
                                                    : <div></div>
                                            }
                                        </Col>

                                    </FormGroup>
                                    <FormGroup row>
                                        <Col >
                                            <CustomInput type="switch" id="aprobar"
                                                innerRef={(c) => this.aprobar = c}
                                                defaultChecked={this.state.espacio.aprobar}
                                                label={this.getText("lblAprobacion")} />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col>
                                            <Text
                                                label={this.getText("lblValor")}
                                                id={'txt_valor'}
                                                value={"" + (this.state.espacio.valor == null ? "0.00" : (Math.round(this.state.espacio.valor * 100) / 100).toFixed(2))}
                                                type="number"
                                                ref={(ref) => this.setRef('txt_valor', ref)}
                                            />
                                        </Col>
                                    </FormGroup>

                                </Col>

                                <Col md={4} >
                                    <Label>{this.getText("lblDiasDisponible")}</Label>
                                    <Card body>
                                        {
                                            this.state.collapse.map((dias, index) => {

                                                return (
                                                    <div key={index}>

                                                        <FormGroup row  >
                                                            <Col >
                                                                <CustomInput type="checkbox" id={dias.id}
                                                                    label={this.getText("lbl" + dias.id)}
                                                                    checked={dias.val}
                                                                    // defaultChecked={dias.val}
                                                                    onClick={this.toggle} />
                                                            </Col>
                                                        </FormGroup>
                                                        <Collapse isOpen={this.state.collapse[index].val}>
                                                            {dias.horarios.map((horario, index) => {
                                                                return (
                                                                    <Card className="card-style" body key={index} color="light">
                                                                        <FormGroup row>
                                                                            <Col sm={10}>
                                                                                <Label>{this.getText("lblTiempoReserva")}</Label>
                                                                            </Col>
                                                                            <Col sm={2}>
                                                                                <FontAwesomeIcon icon={faTimes} size="lg" onClick={(e) => this.eliminarHorario(dias, horario)} />
                                                                            </Col>
                                                                        </FormGroup>
                                                                        <FormGroup row  >
                                                                            <Col >
                                                                                <Input
                                                                                    type="select"
                                                                                    id={"cmb_TiempoPeriodo_" + horario.id}
                                                                                    onChange={(e) => this.changeHour(e, dias)}
                                                                                    placeholder={this.getText("lblSeleccionar")}
                                                                                    value={horario.periodo}
                                                                                >
                                                                                    {/* <option>{this.getText("lblSeleccionar")}</option> */}
                                                                                    {minutosEspacio.map(opcion => (
                                                                                        <option key={opcion.key} value={opcion.key} label={opcion.value} >{opcion.value}</option>
                                                                                    ))}
                                                                                </Input>


                                                                            </Col>
                                                                            <Col >
                                                                                <Input
                                                                                    type="select"
                                                                                    id={"cmb_TipoPeriodo_" + horario.id}
                                                                                    onChange={(e) => this.changeHour(e, dias)}
                                                                                    placeholder={this.getText("lblSeleccionar")}
                                                                                    value={horario.tipoPeriodo}
                                                                                >
                                                                                    {/* <option>{this.getText("lblSeleccionar")}</option> */}
                                                                                    {tipoSpacio.map(opcion => (
                                                                                        <option key={opcion.key} value={opcion.key} label={opcion.value} >{opcion.value}</option>
                                                                                    ))}
                                                                                </Input>
                                                                            </Col>
                                                                        </FormGroup>
                                                                        <FormGroup row>

                                                                            <Col >
                                                                                <div
                                                                                    className="customDatePickerWidth"
                                                                                >
                                                                                    <DatePicker
                                                                                        // selected={new Date(horario.ini)}
                                                                                        onChange={(date) => this.changeDatePicker(date, "ini_" + horario.id, dias)}
                                                                                        locale={es}
                                                                                        dateFormat="HH:mm"
                                                                                        showTimeSelect
                                                                                        timeIntervals={horario.periodo}
                                                                                        showTimeSelectOnly={true}
                                                                                        placeholderText={(horario.ini == "") ? 'Hora Inicio' : horario.ini}
                                                                                    />

                                                                                </div>

                                                                            </Col>

                                                                            <Col >
                                                                                <div
                                                                                    className="customDatePickerWidth"
                                                                                >
                                                                                    <DatePicker
                                                                                        // selected={horario.fin}
                                                                                        onChange={(date) => this.changeDatePicker(date, "fin_" + horario.id, dias)}
                                                                                        locale={es}
                                                                                        dateFormat="HH:mm"
                                                                                        showTimeSelect
                                                                                        timeIntervals={horario.periodo}
                                                                                        showTimeSelectOnly={true}
                                                                                        placeholderText={(horario.fin == "") ? 'Hora Fin' : horario.fin}
                                                                                    />

                                                                                </div>
                                                                            </Col>
                                                                        </FormGroup>

                                                                        <FormGroup>
                                                                            <Col>
                                                                                {/* <Text
                                                                                    label={this.getText("lblNumeroFamilias")}
                                                                                    id={'txt_familias'}
                                                                                    value={horario.numeroFamilias ? horario.numeroFamilias : "1"}
                                                                                    type="number"
                                                                                    ref={(ref) => this.setRef('txt_familias', ref)}
                                                                                    onChange={(e) => this.changeHour(e, dias)}
                                                                                /> */}
                                                                                <Label className='inputNormal' >{this.getText("lblNumeroFamilias")}</Label>
                                                                                <Input
                                                                                    type="number"
                                                                                    id={"txt_Familias_" + horario.id}
                                                                                    onChange={(e) => this.changeHour(e, dias)}
                                                                                    // placeholder={this.getText("lblSeleccionar")}
                                                                                    value={horario.numeroFamilias ? horario.numeroFamilias : "1"}
                                                                                ></Input>
                                                                            </Col>
                                                                        </FormGroup>

                                                                    </Card>)
                                                            })
                                                            }
                                                            <FormGroup row  >
                                                                <Col >
                                                                    <p className="css_anadirnuevo"
                                                                        onClick={() => this.agregarHorario(dias)}
                                                                    >
                                                                        <FontAwesomeIcon
                                                                            icon={faPlusCircle}
                                                                            size="sm"
                                                                        /> {this.getText("lblNuevoHorario")}
                                                                    </p>
                                                                </Col>
                                                            </FormGroup>
                                                        </Collapse>

                                                    </div>
                                                )
                                            })
                                        }
                                    </Card>
                                </Col>
                            </FormGroup>
                        </Form>

                    </div>
                    {(this.state.loading) ? <Loader>Cargando</Loader> : <span></span>}

                </div >
            );
        }
        else {
            return (
                <div>
                    <Loader />
                </div>);
        }
    }

}