export default class CobroModel {
    constructor(
        idEtapa,
        anio,
        mes,
        fechaIngreso,
        fechaPago,
        pagado,
        formaPago,
        tipoCobro,
        banco,
        referencia,
        valor,   
        ubicacion ,
        usuario_ingreso,
        id,
        archivo
    ) {
        this.idEtapa = idEtapa;
        this.anio=anio;
        this.mes=mes;
        this.valor = valor;
        this.id = id;
        this.fechaIngreso=fechaIngreso;
        this.pagado=pagado;
        this.banco=banco;
        this.formaPago=formaPago;
        this.fechaPago=fechaPago;
        this.referencia=referencia;
        this.ubicacion = ubicacion;
        this.tipoCobro = tipoCobro;
        this.usuario_ingreso=usuario_ingreso;
        this.archivo=archivo;
    }

    route() {
        return "cobro";
    }
}