// Componente base
import React from 'react';
import Base from '../../components/Base';
import { Link } from 'react-router-dom';
import matchSorter from 'match-sorter';
import Button from '../../components/Button';
import GridTable from '../../components/GridTable';
import GeneralServices from '../../services/GeneralServices.js';
import './styles.css';

export default class PizarraList extends Base {

    constructor(props) {
        super(props);
        this.servicio = new GeneralServices();

        let cmbTipo = this.getOpcion("TipoPizarra");
        var tipodata = cmbTipo===""?[]:JSON.parse(cmbTipo);

        var columns = [
            {
                Header: this.getText("titPizarra"),
                headerStyle: { textAlign: "left" },
                columns: [
                    this.ColumnaId(),
                    {
                        Header: this.getText("lblTitulo"),
                        id: "usuario",
                        width: 200,
                        accessor: d => d.usuario,
                        filterMethod: (filter, rows) =>
                            matchSorter(rows, filter.value, { keys: ["usuario"] }),
                        filterAll: true
                    },
                    {
                        Header: this.getText("lblDetalle"),
                        id: "detalle",
                        width: 200,
                        accessor: d => d.detalle,
                        filterMethod: (filter, rows) =>
                            matchSorter(rows, filter.value, { keys: ["detalle"] }),
                        filterAll: true
                    },
                    {
                        Header: this.getText("lblFechaModificacion"),
                        accessor: "fecha",
                        style: { textAlign: "center" }
                    },
                    {
                        Header: this.getText("lblTipo"),
                        accessor: "tipo",
                        id: "tipo",
                        filterMethod: (filter, row) => {
                            if (filter.value === "all") {
                                return true;
                            }
                            else {
                                return row[filter.id] === filter.value;
                            }

                        },
                        Filter: ({ filter, onChange }) =>
                            <select
                                onChange={event => onChange(event.target.value)}
                                style={{ width: "100%" }}
                                value={filter ? filter.value : "all"}
                            >
                                <option value="all">{this.getText("lblTodos")}</option>
                                {
                                    tipodata.map(k => {
                                        return <option key={k.key} value={k.key}>{k.value}</option>
                                    })
                                }
                            </select>
                    },
                    {
                        Header: this.getText("lblCaducidad"),
                        accessor: "fecha_caducidad",
                        style: { textAlign: "center" }
                    },

                ]
            },
            {
                Header: <Button className="tertiaryColorB" onClick={(e) => this.New(e)} value={this.getText("lblNuevo")}  />,
                columns: [
                    {
                        Header: "",
                        accessor: "buscar",
                        filterable: false,
                        sortable: false,
                        style: { textAlign: "center" }
                    }]
            }
        ];

        this.state = {
            dataGridRows: [],
            dataGridColumns: columns
        }
    };


    setValue(rows) {
        this.setState({
            dataGridRows: rows
        });
    };

    New(e) {
        e.preventDefault();
        window.location.href="/pizarra/id/0";
        //this.props.history.push("/pizarra/id/0");
    }

    componentDidMount() {
        this.servicio.List(this.getEtapa(), 'pizarra/etapa')
            .then(result => {
                console.log("resultado obtenido: ", result);
                if (result.codigo !== "0") {
                    alert(result.mensaje);
                    if(result.codigo===401){
                        this.CerarSesion();                        
                    }
                }
                else {
                    var rows = [];
                    for (let i = 0; i < result.data.length; i++) {
                        rows.push({
                            id: result.data[i].id,
                            usuario: result.data[i].titulo,
                            detalle: result.data[i].detalle,
                            fecha: result.data[i].fechaModificacion ? new Date(result.data[i].fechaModificacion).toLocaleDateString() : "",
                            tipo: result.data[i].tipo,
                            buscar: this.link(result.data[i].id),
                            fecha_caducidad: result.data[i].fecha_caducidad ? new Date(result.data[i].fecha_caducidad).toLocaleDateString() : ""

                        });
                    }
                    this.setValue(rows);
                }

            }
            )
            .catch(error => {
                console.log(error);
            });
    }
    //Fucion para mostrar icono y Link
    link(id) {
        var link = [];
        link.push(
            <Link key={id} to={"/pizarra/id/" + id}>
                <h6>{this.getText("lblEditar")}</h6>
            </Link>)
        return link[0]
    }

    render() {
        return (
            <div>
                <div className="mainContent">                    
                    <div className="contentBlock">
                        <GridTable
                            data={this.state.dataGridRows}
                            columns={this.state.dataGridColumns} />
                    </div>
                </div>
            </div>
        )
    }
}
