// Componente base
import React from 'react';
import Base from '../../components/Base';
import { Form,FormGroup,Spinner } from 'reactstrap';
import LoginService from '../../services/LoginService.js';
import {imagenes} from '../../config/images.js';
import Text from "../../components/Text";
import Button from '../../components/Button';
import Usuario from '../../models/Usuario.js';
import './styles.css';

export default class CambiarClave extends Base {

    constructor(props) {
        super(props);
        this.loginService = new LoginService();
        this.login = this.login.bind(this);

        this.state = {
            isSubmitting: false
        }
    }


    login(event) {
        event.preventDefault();
        console.log("ingresa");
        let validar = this.ValidateForm();
        if (validar) {
            alert(validar);
            return;
        }
        let usuario = new Usuario();
        //Ejecuta Login contra el servidor
        usuario.nombre = this.getRef('txt_usuario').getValue();
        usuario.clave="";


        this.setState({ isSubmitting: true });
        this.loginService.login(usuario, `${usuario.route()}/restaurar-clave-admin`)
            .then(result => {
                this.setState({ isSubmitting: false });
                if (result.codigo !== "0") {
                    alert(result.mensaje);
                   
                }
                else {
                    alert(this.getText("msgReviseCorreo"));
                    window.location.reload();
                }
            })
            .catch(error => {
                console.log(error);
                this.setState({ isSubmitting: false });
            });
    }

    render() {
        return (
            <div className="limiter">
                <div className="container-login">
                    <div className="wrap-login p-t-85 p-b-20">
                        <Form className="login-form100" onSubmit={this.login} >
                            <img
                                src={imagenes.logo.logoCity2Live}
                                alt={'City2Live'}
                                id={''}
                                className={'login-logo'}
                                ref={(ref) => this.setRef('logo_login', ref)}
                            />
                            <FormGroup row>
                                <Text
                                    label={''}
                                    id={'txt_usuario'}
                                    value=''
                                    type={'text'}
                                    className={'inputLogin'}
                                    placeholder={this.getText("msgCorreoRecuperacion")}
                                    isRequired={true}
                                    menssage={this.getText("msgCampoRequerido")+this.getText("lblEmail") }
                                    ref={(ref) => this.setRef('txt_usuario', ref)}
                                />
                            </FormGroup>
                            <FormGroup row>
                                    <div className="container-login-form-btn">
                                        <Button
                                            id={'btn_login'}
                                            ref={(ref) => this.setRef('btn_login', ref)}
                                            value={this.getText("lblResetPassword")}
                                            type={'submit'}
                                            disabled={this.state.isSubmitting}
                                            className={'login-btn'}

                                        />
                                    </div>
                                    {
                                        this.state.isSubmitting ? <Spinner color="primary" /> : <span></span>
                                    }
                                </FormGroup>
                        </Form>
                    </div>
                </div>
            </div>

        )

    }



}

