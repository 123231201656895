import cookies from 'react-cookies';

const devServer = "http://localhost:8080";
const proServer = "http://localhost:8080";
//const server = "https://city2live.com:2053";
//const server = "https://8854ca788913.ngrok.io";
const server = "https://city2live-342502.uc.r.appspot.com";

export default class Services {
    
    post(data, route) {
        return new Promise((resolve, reject) => {
            let token=cookies.load('token');
            try {
                fetch(server + "/" + route,
                    {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',  
                            'Authorization':  `Bearer ${token}`                                     
                        },
                        body: JSON.stringify(data)
                    }
                )
                .then(response => response.json())
                .then(response => {
                    console.log("OK");
                    console.log(server + "/" + route );
                    //console.log(response);
                    if (response.codigo) {
                        resolve(response);    
                    }
                    else {
                        let resp = {};
                        resp = {
                            codigo: response.status,
                            mensaje: response.error + " - (" + response.status + ") - " + response.message
                        };
                        resolve(resp);
                    }
                    
                }).catch((error) => {
                    console.log("ERROR AL CONCETARSE AL SERVICIO");
                    console.log(server + "/" + route + '/' + data);
                    reject(error)
                });
            } catch (error) {
                console.log("err");
                reject(error)
            }
        })
    }

    put(data, route) {
        return new Promise((resolve, reject) => {
            let token=cookies.load('token');
            try {
                fetch(server + "/" + route,
                    {
                        method: 'PUT',
                        headers: {
                            'Content-Type': 'application/json',  
                            'Authorization':  `Bearer ${token}`                                     
                        },
                        body: JSON.stringify(data)
                    }
                )
                .then(response => response.json())
                .then(response => {
                    console.log("OK");
                    //console.log(response);
                    if (response.codigo) {
                        resolve(response);    
                    }
                    else {
                        let resp = {};
                        resp = {
                            codigo: response.status,
                            mensaje: response.error + " - (" + response.status + ") - " + response.message
                        };
                        resolve(resp);
                    }
                    
                }).catch((error) => {
                    console.log("ERROR AL CONCETARSE AL SERVICIO");
                    console.log(error)
                    reject(error)
                });
            } catch (error) {
                reject(error)
            }
        })
    }

    save(data, route) {
        try {
            return this.post(data, route);
        } catch (error) {
            console.log(error)
        }
    }

    get(data, route){  
        // console.log("la ruta: " + server + "/" + route + '/' + data);
            return new Promise((resolve, reject) => {
                let token=cookies.load('token');
                try {
                    fetch(server + "/" + route + '/' + data,
                        {
                            method: 'GET',
                            headers: {
                                        'Content-Type': 'application/json',  
                                        'Authorization':  `Bearer ${token}`                                     
                                    },
                        }
                        
                    )
                    .then(response => response.json())
                    .then(response => {
                       //console.log(server + "/" + route + '/' + data);
                        if (response.codigo) {
                            resolve(response);                              
                        }
                        else {
                            let resp = {};
                            resp = {
                                codigo: response.status,
                                mensaje: response.error + " - (" + response.status + ") - " + response.message
                            };
                            resolve(resp);
                        }
                        
                    }).catch((error) => {
                        console.log("ERROR AL CONCETARSE AL SERVICIO");
                        console.log(error)
                        reject(error)
                    });
                } catch (error) {
                    reject(error)
                }
            })  

    }
    //para archivos e imagenes
    post2(data, route) {
        return new Promise((resolve, reject) => {
            let token=cookies.load('token');
            try {
                fetch(server + "/" + route,
                    {
                        
                        method: 'POST',
                        headers: {
                             
                            'Authorization':  `Bearer ${token}`                                     
                        },
                        body: data
                    }
                )
                .then(response => response.json())
                .then(response => {
                    console.log("OK");
                   // console.log(response);
                    if (response.codigo) {
                        resolve(response);    
                    }
                    else {
                        let resp = {};
                        resp = {
                            codigo: response.status,
                            mensaje: response.error + " - (" + response.status + ") - " + response.message
                        };
                        resolve(resp);
                    }
                    
                }).catch((error) => {
                    console.log("ERROR AL CONCETARSE AL SERVICIO");
                    console.log(error)
                    reject(error)
                });
            } catch (error) {
                reject(error)
            }
        })
    }
    

   

}

 