import '../../config/global';

export function validateForms(refs) {


    let menssage = null;
    for (let it of refs) {

        // console.log(it[1]);

        if (it[1] === null) {
            continue;
        }

        let props = it[1].props;
        let state = it[1].state;

        if (props === undefined) {
            continue;
        }

        try {
            if (props.isRequired) {
                if (!state.value || state.value.toString().trim() === "") {
                    menssage = props.menssage;
                    break;

                }
            }
        } catch (error) {
            console.log(error);
        }


    }
    return menssage;
}

export function getText(key) {
    try {
        var value = global.resFile[key];
        if (value !== "")
            return value;
        else
            return "";
    }
    catch (error) {
        console.log('Error al cargar el archivo de recursos: ' + error);
    }
}


export function getOpcion(key) {
    try {
        var value = global.opciones.filter((opcion) =>
            opcion.nombre === key);
        if (value[0].valor !== "")
            return value[0].valor;
        else
            return '';
    }
    catch (error) {
        console.log('Error al cargar el archivo de opciones: ' + error);
        return '';
    }
}

export function pad(str, max) {
    str = str.toString();
    return str.length < max ? pad("0" + str, max) : str;

}

export function validatePassword(contrasenna) {
    if (contrasenna.length >= 8) {
        var mayuscula = false;
        var minuscula = false;
        var numero = false;
        var caracter_raro = false;

        for (var i = 0; i < contrasenna.length; i++) {
            if (contrasenna.charCodeAt(i) >= 65 && contrasenna.charCodeAt(i) <= 90) {
                mayuscula = true;
            }
            else if (contrasenna.charCodeAt(i) >= 97 && contrasenna.charCodeAt(i) <= 122) {
                minuscula = true;
            }
            else if (contrasenna.charCodeAt(i) >= 48 && contrasenna.charCodeAt(i) <= 57) {
                numero = true;
            }
            else {
                caracter_raro = true;
            }
        }
        if (mayuscula == true && minuscula == true && caracter_raro == true && numero == true) {
            //if (mayuscula == true && minuscula == true && numero == true) {
            return ["", true];
        } else {
            return ["La contraseña debe contener mayúscula, minúscula, números y símbolos(.-/(),)", false];
        }
    } else {

        return ["La contraseña debe tener mínimo 8 caracteres", false];
    }
}

export function validatePlaca(placa, tipo) {
    // regex para carro /^([A-Z]{3}-\d{3,4})$/
    // regex para moto /^([A-Z]{2}\d{3}[A-Z]{1})$/
    // const placaCarro = /^([A-Z]{3}-\d{3,4})$/.test(placa);
    // const placaMoto = /^([A-Z]{2}\d{3}[A-Z]{1})$/.test(placa);

    const regex = (tipo === "MOT") ? /^([A-Z]{2}\d{3}[A-Z]{1})$/ : /^([A-Z]{3}-\d{3,4})$/
    const valida = regex.test(placa);

    return valida;
}

export function validateIdentificacion(identificacion) {
    //console.log("limite: " , getOpcion("LimiteIdentificacion"));
    // if (identificacion.length == getOpcion("LimiteIdentificacion")) {
    //     //console.log("el numero es valido");
    //     return true;
    // }
    // return false;
    var cad = identificacion.trim();
    var total = 0;
    var longitud = cad.length;
    var longcheck = longitud - 1;

    //if (cad !== "" && longitud == getOpcion("LimiteIdentificacion")) {
    if (cad !== "" && longitud === 10) {
        for (var i = 0; i < longcheck; i++) {
            if (i % 2 === 0) {
                var aux = cad.charAt(i) * 2;
                if (aux > 9) aux -= 9;
                total += aux;
            } else {
                total += parseInt(cad.charAt(i)); // parseInt o concatenará en lugar de sumar
            }
        }

        total = total % 10 ? 10 - total % 10 : 0;

        if (cad.charAt(longitud - 1) == total) {
            //console.log("Cedula Válida");
            return { valido: true };
        } else {
            //console.log("Cedula Inválida", getText("msgIdenNoValida"));
            return { valido: false, msg: getText("msgCedulaInvalida") };
        }
    }
    //console.log(getText("msgIdenNoValida"));
    return { valido: false, msg: getText("msgNumIncompletos") };

}