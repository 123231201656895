import React from 'react';
import Base from '../../components/Base';
import { Col, Form, FormGroup, Label } from 'reactstrap';
import Button from '../../components/Button';
import Text from "../../components/Text";
import './style.css';
import BuzonModel from '../../models/BuzonModel.js';
import GeneralServices from '../../services/GeneralServices.js';
import Loader from '../../components/Loader';
import Return from '../../components/Return';


export default class Buzon extends Base {

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.BuzonService = new GeneralServices();

        this.state =
        {
            loading: false
        };
    }


    handleChange(event) {
        this.setState({
            estado: event.target.value
        });
    }

    handleSubmit(event) {
        event.preventDefault();
        this.setState({ loading: true });

        let buzon = new BuzonModel();

       
        buzon.id = this.state.Buzon.id;
        buzon.usuario_modificacion=this.getUsuario();
        // buzon.estado = 'REV';
        // buzon.detalle=this.state.Buzon.detalle;
        // buzon.idEtapa=this.state.Buzon.idEtapa;
        // buzon.usuario_ingreso=this.state.Buzon.usuario_ingreso;
        // buzon.titulo = this.state.Buzon.titulo;        
        // buzon.tipo=this.state.Buzon.tipo;
        // buzon.url=this.state.Buzon.url;

        console.log(buzon);

        this.BuzonService.save(buzon, buzon.route())
            .then(result => {
                this.setState({ loading: false });
                if (result.codigo !== "0") {
                    alert(result.mensaje);

                    if (result.codigo === 401) {
                        this.CerarSesion();
                    }
                }
                else {
                    //alert(this.getText("msgGuardadoOk"));
                    window.location.href=this.state.Buzon.url;
                    //this.props.history.push(this.state.Buzon.url);
                }

            }
            )
            .catch(error => {
                console.log(error);
                this.setState({ loading: false });
            });
    }


    componentDidMount() {

        const id = this.props.match.params.id;

        let buzon = new BuzonModel();

        if (id > 0) {
            this.BuzonService.Id(id, buzon.route()+'/id')
                .then(result => {
                      console.log(result);
                    if (result.codigo !== "0") {
                        alert(result.mensaje);
                        if (result.codigo === 401) {
                            this.CerarSesion();
                        }
                    }
                    else {
                        if (result.data !== null) {
                            this.setState({ Buzon: result.data });
                        }
                    }
                }
                )
                .catch(error => {
                    console.log(error);
                });

        }
    }

    render() {
        if (this.state.Buzon) {
            const data = this.state.Buzon;
            return (
                <div className='formTitulo' >

                    <FormGroup row >
                        <Col md={9}>
                            <h5><Return onClick={() => this.props.history.go(-1)}/>{this.getText("titBuzon")}</h5>
                        </Col>
                        <Col md={3} className="text-right">
                            <Button type="submit"
                                onClick={this.handleSubmit}
                                className="tertiaryColorB"
                                value={this.getText("lblIr")} />                                
                        </Col>
                    </FormGroup>
                    <div className='form'>
                        <Form  >
                            <FormGroup row>

                            </FormGroup>
                            <FormGroup row>
                                <Col>
                                    <Text
                                        label={this.getText("lblTitulo")}
                                        id={'txt_titulo'}
                                        value={data.titulo}
                                        type={'text'}
                                        disabled
                                       
                                    />
                                </Col>
                            </FormGroup>

                            <FormGroup row>
                                <Col >
                                    <Text
                                        label={this.getText("lblDetalle")}
                                        id={'txt_detalle'}
                                        value={data.detalle}
                                        type={'textarea'}
                                        height="200px"
                                        disabled                                    
                                    />
                                </Col>
                            </FormGroup>

                            <FormGroup row>
                                <Col >
                                <Label>{this.getText("lblEstado")+': '+this.getText("lbl"+data.estado)}</Label>
                                </Col>
                            </FormGroup>


                        </Form>
                    </div>
                    {(this.state.loading) ? <Loader /> : <span />}
                </div>
            );
        }
        else {
            return (
                <div>
                    <Loader />
                </div>);
        }
    }
}