import React from 'react';
import Base from '../../components/Base';
import {
    Col, Form, FormGroup, Label, Input,
    Table, Card, CardHeader,
    CardBody, Row, CardLink, CustomInput
} from 'reactstrap';
import Button from '../../components/Button';
import Dropdown from "../../components/Dropdown";
import Text from "../../components/Text";
import './style.css';
import Residente from '../../models/Residente.js';
import GeneralServices from '../../services/GeneralServices.js';
import Loader from '../../components/Loader';
import Vehiculo from './Vehiculo.js';
import Alert from '../../components/Alert';
import Return from '../../components/Return';
import cookies from 'react-cookies';
import { validateIdentificacion } from '../../libs/TBSUtil';

export default class Persona extends Base {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.personaService = new GeneralServices();
        this.Close = this.Close.bind(this);
        this.addVehiculo = this.addVehiculo.bind(this);
        this.toggle = this.toggle.bind(this);

        // let estadoVivienda = [
        //     {
        //         key: "HABI",
        //         value: "Habitada"
        //     },
        //     {
        //         key: "DESH",
        //         value: "Deshabitada"
        //     },
        //     {
        //         key: "DEVA",
        //         value: "De vacaciones"
        //     }
        // ]

        this.state =
        {
            estado: '',
            loading: false,
            open: false,
            idvehiculo: 0,
            alerta: false,
            mensaje: '',
            error: false,
            manzanas: [],
            villas: [],
            familiares: [],
            capacidad: [],
            obligatorio: false,
            noCobrar: false
        };
    }

    handleChange(event) {
        this.setState({
            estado: event.target.value
        });
    }


    addVehiculo(dato) {

        var listVehiculos = this.state.persona.vehiculos;
        var newVehiculos = [];


        for (let vehiculo of listVehiculos) {
            if (vehiculo.id === dato.id) {
                vehiculo = dato;
            }
            newVehiculos.push(vehiculo);
        }
        if (dato.id === 0) {
            newVehiculos = [...newVehiculos, dato];
        }

        var per = this.state.persona;
        per.vehiculos = newVehiculos;

        this.setState({ persona: per });
        this.Close();
        this.savePersona();
    }


    handleSubmit(event) {
        event.preventDefault();
        this.cedulaCorreoObligatorio();
        //imprime una pantalla completa
        //window.print();
        window.setTimeout(() => {
            this.savePersona();
        }, 500);
    }

    ShowAlert(mensaje, error) {
        this.setState({ alerta: true, mensaje: mensaje, error: error }, () => {
            window.setTimeout(() => {
                this.setState({ alerta: false, mensaje: '', error: false });
            }, 10000)
        });
    }

    cedulaCorreoObligatorio() {
        var tipo = this.getRef("cmbTipo").getValue();
        if (tipo === 'PROPRI' || tipo === 'PROCON' || tipo === 'INQPRI' || tipo === 'INQCON') {
            //console.log('respuesta tipo: ', true);
            this.setState({ obligatorio: true })
        } else {
            //console.log('respuesta tipo: ', false);
            this.setState({ obligatorio: false })
        }
    }

    savePersona() {
        //console.log("estado de vivienda: ", this.getRef("cmbEstadoVivienda").getValue());
        console.log("valor no cobrar: ", this.state.noCobrar);
        //return;
        if (this.state.estado !== 'I' && this.getRef("cmbTipo").getValue() === '') {
            this.ShowAlert(this.getText("msgSelTipoOcupante"), true);
            return;
        }

        let validar = this.ValidateForm();
        if (validar) {
            this.ShowAlert(validar, true);
            return;
        }

        // validacion de que la identificacion sea correcta
        if (this.state.obligatorio && this.getRef("cmbTipoIdentificacion").getValue() === "CED") {
            const { valido, msg } = validateIdentificacion(this.getRef('txt_cedula').getValue());
            if (!valido) {
                this.ShowAlert(msg, true);
                return;
            }
        }
        // return;

        this.setState({ loading: true });
        let person = new Residente();
        person.apellidos = this.getRef('txt_apellidos').getValue();
        person.correo = this.getRef('txt_correo').getValue();
        person.contactoEmergencia = this.getRef('txt_contacto').getValue();
        person.estado_civil = this.state.persona.estado_civil;
        person.fecha_nacimiento = this.state.persona.fecha_nacimiento;
        person.foto = this.state.persona.foto;
        person.genero = this.state.persona.genero;
        person.id = this.state.persona.id;
        person.idEtapa = this.state.persona.idEtapa;
        person.identificacion = this.getRef('txt_cedula').getValue();
        person.tipoIdentificacion = this.getRef("cmbTipoIdentificacion").getValue();
        person.nombres = this.getRef('txt_nombres').getValue();
        person.telefono = this.getRef('txt_telefono').getValue();//this.state.persona.telefono;
        person.tipo = this.state.persona.tipo;
        person.estado = person.id === "0" ? this.state.persona.estado : this.state.estado;
        person.fechaIngreso = new Date(this.state.persona.fechaIngreso);
        person.tipoOcupante = this.getRef("cmbTipo").getValue();
        person.capacidad = JSON.stringify(this.state.capacidad);
        person.idUsuario = this.state.persona.idUsuario;
        person.estadoVivienda = this.getRef("cmbEstadoVivienda").getValue();
        person.noCobro = this.state.noCobrar ? "S" : null;
        //eliminar campo nombre
        let x = JSON.parse(person.capacidad);
        x.forEach(function (v) { delete v.nombre });
        person.capacidad = JSON.stringify(x);


        let ubic = {
            manzana: this.getRef('txt_manzana').getValue(),
            villa: this.getRef('txt_villa').getValue(),
            departamento: this.getRef('txt_dept').getValue()
        }

        person.ubicacion = ubic;
        person.vehiculos = this.state.persona.vehiculos;
        person.usuario_ingreso = this.state.persona.usuario_ingreso;
        person.usuario_modificacion = this.getUsuario();
        //console.log(person);
        //return;
        this.personaService.save(person, person.route())
            .then(result => {
                this.setState({ loading: false });
                if (result.codigo !== "0") {
                    console.log(result);
                    let error_mesage = this.getText(result.codigo);
                    if (error_mesage !== undefined) {
                        this.ShowAlert(error_mesage === "" ? result.mensaje : error_mesage, true);
                    }
                    else {
                        this.ShowAlert(result.mensaje, true);
                    }
                    if (result.codigo === 401) {
                        this.CerarSesion();
                    }
                }
                else {
                    this.setState({
                        estado: result.data.persona.estado,
                        persona: result.data.persona,
                    });

                    this.ShowAlert(this.getText("msgGuardadoOk"), false);
                }
            })
            .catch(error => {
                console.log(error);
                this.setState({ loading: false });
            });
    }



    addRow(e, id) {
        e.preventDefault();
        this.setState({ open: true, idvehiculo: id });
    }


    toggle(event) {

        //console.log(typeof event.target.id);
        const lista = this.state.capacidad.map((item) => {
            //console.log(typeof item.id );
            if (item.id == event.target.id) {
                item.val = !item.val;
                return item
            } else {
                return item;
            }
        });
        //console.log(lista);
        this.setState({ capacidad: lista });
    }


    Close() {
        this.setState(({ open }) => ({ open: !open }));
    }


    deleteRow(id, e) {
        e.preventDefault();

        var person = this.state.persona;
        var nuevovehiculos = this.state.persona.vehiculos.filter((vehi) =>
            vehi.id !== id);
        person.vehiculos = nuevovehiculos;
        this.setState({ persona: person });

    }


    cargarDatosPersona() {
        const id = this.props.match.params.id;
        console.log(id);
        let person = new Residente();

        if (id > 0) {

            this.personaService.Id(id, 'persona/id')
                .then(result => {
                    // console.log(result);
                    if (result.codigo !== "0") {
                        alert(result.mensaje);
                        if (result.codigo === 401) {
                            this.CerarSesion();
                        }
                    }
                    else {
                        //console.log(result.data);
                        if (result.data.persona === null) {
                            alert(this.getText("lblNoHayDatosId") + id);
                            window.location.href = "/Residentes";
                            //this.props.history.push('/Residentes');
                            return;
                        }
                        let ubi = result.data.persona.ubicacion


                        if (ubi) {
                            if (!ubi.departamento) {
                                result.data.persona.ubicacion.departamento = "";
                            }
                        }

                        this.setState({
                            estado: result.data.persona.estado,
                            persona: result.data.persona,
                            familiares: result.data.familiares,
                            capacidad: result.data.capacidad,
                            noCobrar: result.data.persona.noCobro !== 'S' ? false : true
                        });
                        this.getRef("cmbTipo").updateValue(result.data.persona.tipoOcupante === null ? '' : result.data.persona.tipoOcupante);
                        this.getRef("cmbEstadoVivienda").updateValue(result.data.estadoVivienda === null ? 'HABI' : result.data.persona.estadoVivienda);
                    }
                }
                )
                .catch(error => {
                    console.log(error);
                });
        }
        else {

            person.apellidos = "";
            person.nombres = "";
            person.correo = "";
            person.telefono = "";
            person.id = id;
            person.idEtapa = this.getEtapa();
            person.tipo = "RES";
            person.identificacion = "";
            person.tipoIdentificacion = "CED"
            person.vehiculos = [];
            person.estado = "A";
            person.fechaIngreso = new Date();
            person.usuario_ingreso = this.getUsuario();

            let ubic = { manzana: "", villa: "", departamento: "" }
            person.ubicacion = ubic;

            this.personaService.List("capacidadesidente", "usuario")
                .then(result => {
                    if (result.codigo !== "0") {
                        alert(result.mensaje);
                        if (result.codigo === 401) {
                            this.CerarSesion();
                        }
                    }
                    else {
                        // console.log(result);
                        this.setState({ capacidad: result.data });
                    }
                }
                )
                .catch(error => {
                    console.log(error);
                });

            this.setState({ estado: person.estado, persona: person });

        }
    }

    componentDidMount() {
        this.cargarDatosPersona();
    }

    handleDelete() {
        // console.log("this.props: ", this.props);
        if (!window.confirm(this.getText("msgEliminarPersona"))) {
            return;
        }
        this.setState({ loading: true });
        const id = this.props.match.params.id;
        // console.log("id de usuario: ", id);

        this.personaService.Id(id, 'persona/delete').then(result => {
            // console.log("el resultado: " , result);
            if (result.codigo !== "0") {
                alert(result.mensaje);
                if (result.codigo === 401) {
                    this.CerarSesion();
                }
                this.setState({ loading: false });
            }
            else {
                // console.log(result);
                this.props.history.go(-1);
                this.setState({ loading: false });
            }
        }).catch(err => {
            console.log("error: ", err);
        })

        this.setState({ loading: false });
    }

    render() {
        let cmbTipoOcupante = JSON.parse(this.getOpcion("TipoOcupante"));
        let cmbTipoIdentificacio = JSON.parse(this.getOpcion("TipoIdentificacion"))
        let cmbEstadoVivienda = JSON.parse(this.getOpcion("EstadoVivienda"));
        const id = this.props.match.params.id;
        //console.log(this.props);
        if (this.state.persona) {
            return (
                <div className='formTitulo' >
                    <Alert
                        open={this.state.alerta}
                        mensaje={this.state.mensaje}
                        error={this.state.error}
                    />
                    <FormGroup row >
                        <Col md={9}>
                            <h5><Return onClick={() => this.props.history.go(-1)} />
                                {this.getText("titResidentes")}</h5>
                        </Col>
                        <Col className="text-right" >
                            <Button type="submit"
                                onClick={this.handleSubmit}
                                className="tertiaryColorB"
                                value={this.getText("lblGuardar")} />
                        </Col>
                    </FormGroup>
                    <div className='form'>
                        <Form  >
                            <FormGroup row>
                                {
                                    this.state.persona.id !== "0" ?
                                        <Col md={5}>
                                            <Label for="estados" className='inputNormal' >{this.getText("lblEstado")}</Label>
                                            <FormGroup check inline id="estados" >
                                                <Label check>
                                                    <Input
                                                        type='radio'
                                                        name="estado"
                                                        value='A'
                                                        checked={this.state.estado === 'A'}
                                                        onChange={this.handleChange}
                                                    />{this.getText("lblActivo")}</Label>

                                            </FormGroup>
                                            <FormGroup check inline  >
                                                <Label check>
                                                    <Input
                                                        type='radio'
                                                        name="estado"
                                                        value='I'
                                                        checked={this.state.estado === 'I'}
                                                        onChange={this.handleChange}
                                                    />{this.getText("lblInactivo")}</Label>
                                            </FormGroup>
                                        </Col> :
                                        <div></div>
                                }
                                <Col md={3}>
                                    <Dropdown
                                        id="cmbTipo"
                                        label={this.getText("lblTipoResidente")}
                                        ref={(ref) => this.setRef('cmbTipo', ref)}
                                        value={cmbTipoOcupante}
                                        tipo="N"
                                    />
                                </Col>
                                <Col md={4} className="text-right"  >
                                    <Label>
                                        {this.state.persona.fechaModificacion ?
                                            this.getText("lblFechaModificacion") + ": " + new Date(this.state.persona.fechaModificacion).toLocaleString()
                                            : ""
                                        }
                                    </Label>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Col md={4}>
                                    <CustomInput type="checkbox" id={"noCobro"}
                                        label={this.getText("lblNoCobro")}
                                        //checked={this.state.caducidad}
                                        defaultChecked={this.state.noCobrar}
                                        onClick={() => this.setState({ noCobrar: !this.state.noCobrar })}
                                    />
                                </Col>
                                <Col md={4}>
                                    <Dropdown
                                        id="cmbEstadoVivienda"
                                        label={this.getText("lblEstadoVivienda")}
                                        ref={(ref) => this.setRef('cmbEstadoVivienda', ref)}
                                        value={cmbEstadoVivienda}
                                    // tipo="N"
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Col md={8}>
                                    <Text
                                        label={this.getText("lblNombres")}
                                        id={'txt_nombres'}
                                        value={this.state.persona.nombres}
                                        type={'text'}
                                        ref={(ref) => this.setRef('txt_nombres', ref)}
                                        isRequired={true}
                                        menssage={`${this.getText("msgCampoRequerido")} ${this.getText("lblNombres")}`}
                                    />
                                </Col>
                            </FormGroup>

                            <FormGroup row>
                                <Col md={8}>
                                    <Text
                                        label={this.getText("lblApellidos")}
                                        id={'txt_apellidos'}
                                        value={this.state.persona.apellidos}
                                        type={'text'}
                                        ref={(ref) => this.setRef('txt_apellidos', ref)}
                                        isRequired={true}
                                        menssage={`${this.getText("msgCampoRequerido")} ${this.getText("lblApellidos")}`}
                                    />
                                </Col>
                            </FormGroup>

                            <FormGroup row >
                                <Col md={8}>
                                    <Dropdown
                                        label={this.getText("lblIdentificacion")}
                                        id="cmbTipoIdentificacion"
                                        ref={(ref) => this.setRef('cmbTipoIdentificacion', ref)}
                                        value={cmbTipoIdentificacio}
                                        default={this.state.persona.tipoIdentificacion}
                                    // tipo="N"
                                    />

                                </Col>

                            </FormGroup>
                            <FormGroup row>
                                <Col md={8}>
                                    <Text
                                        id={'txt_cedula'}
                                        value={this.state.persona.identificacion}
                                        type={'number'}
                                        ref={(ref) => this.setRef('txt_cedula', ref)}
                                        isRequired={this.state.obligatorio}
                                        menssage={`${this.getText("msgCampoRequerido")} ${this.getText("lblIdentificacion")}`}
                                    />
                                </Col>
                            </FormGroup>

                            <FormGroup row >
                                <Col md={8}>
                                    <Text
                                        label={this.getText("lblEmail")}
                                        id={'txt_correo'}
                                        value={this.state.persona.correo}
                                        type={'text'}
                                        ref={(ref) => this.setRef('txt_correo', ref)}
                                        isRequired={this.state.obligatorio}
                                        menssage={`${this.getText("msgCampoRequerido")} ${this.getText("lblEmail")}`}
                                    />
                                </Col>
                            </FormGroup>

                            <FormGroup row >
                                <Col md={8}>
                                    <Text
                                        label={this.getText("lblTelefono")}
                                        id={'txt_telefono'}
                                        value={this.state.persona.telefono}
                                        type={'text'}
                                        ref={(ref) => this.setRef('txt_telefono', ref)}
                                    // isRequired={true}
                                    // menssage={`${this.getText("msgCampoRequerido")} ${this.getText("lblTelefono")}`}
                                    />
                                </Col>
                            </FormGroup>

                            <FormGroup row >
                                <Col md={8}>
                                    <Text
                                        label={this.getText("lblContacto")}
                                        id={'txt_contacto'}
                                        value={this.state.persona.contactoEmergencia}
                                        type={'text'}
                                        ref={(ref) => this.setRef('txt_contacto', ref)}
                                    // isRequired={true}
                                    // menssage={`${this.getText("msgCampoRequerido")} ${this.getText("lblEmail")}`}
                                    />
                                </Col>
                            </FormGroup>

                            <FormGroup row >
                                <Col md={8}>
                                    <Text
                                        label={this.getText("lblFechaRegistro")}
                                        id={'txt_fec_ingreso'}
                                        value={this.state.persona.fechaIngreso ?
                                            new Date(this.state.persona.fechaIngreso).toLocaleString() : ""
                                        }
                                        disabled={true}
                                        type={'text'}
                                    />
                                </Col>
                            </FormGroup>

                            <FormGroup row >
                                <Col md={4}>

                                    <Text
                                        label={this.getText("lblManzana")}
                                        id="txt_manzana"
                                        ref={(ref) => this.setRef('txt_manzana', ref)}
                                        value={this.state.persona.ubicacion.manzana}
                                        isRequired={true}
                                        menssage={`${this.getText("msgCampoRequerido")} ${this.getText("lblManzana")}`}
                                        type={'text'}
                                    />

                                </Col>
                                <Col md={4}>
                                    <Text
                                        label={this.getText("lblLote")}
                                        id="txt_villa"
                                        value={this.state.persona.ubicacion.villa}
                                        isRequired={true}
                                        ref={(ref) => this.setRef('txt_villa', ref)}
                                        menssage={`${this.getText("msgCampoRequerido")} ${this.getText("lblLote")}`}
                                        type={'text'}
                                    />
                                </Col>
                                <Col md={4}>
                                    <Text
                                        label={this.getText("lblDepartamento")}
                                        id="txt_dept"
                                        ref={(ref) => this.setRef('txt_dept', ref)}
                                        value={this.state.persona.ubicacion.departamento}
                                        type={'text'}
                                    />
                                </Col>
                            </FormGroup >
                            <FormGroup row >
                                <Col md={4}>
                                    <Text
                                        label={this.getText("lblProyecto")}
                                        id={'txt_proy'}
                                        value={cookies.load('empresaDescripcion')}
                                        type={'text'}
                                        disabled={true}
                                    />
                                </Col>
                                <Col md={8}>
                                    <Text
                                        label={this.getText("lblUrbanizacion")}
                                        id={'txt_urb'}
                                        value={cookies.load('etapaDescripcion')}
                                        type={'text'}
                                        disabled={true}
                                    />
                                </Col>
                            </FormGroup>
                            <Alert
                                open={this.state.alerta}
                                mensaje={this.state.mensaje}
                                error={this.state.error}
                            />
                            {id > 0 ?
                                <Card >
                                    <CardHeader tag="h5" >
                                        <Row>
                                            <Col sm='10'>
                                                {this.getText("titFamiliares")}
                                            </Col>
                                        </Row>
                                    </CardHeader>
                                    <CardBody>
                                        <FormGroup row >
                                            <Col>
                                                <Table size="sm" responsive  >
                                                    <thead>
                                                        <tr>
                                                            <th>{this.getText("lblNombres")}</th>
                                                            <th>{this.getText("lblApellidos")}</th>
                                                            <th>{this.getText("lblParentesco")}</th>
                                                            <th>{this.getText("lblEstado")}</th>
                                                            <th></th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            this.state.familiares.map((repo, index) => {
                                                                return (
                                                                    <tr key={index} >
                                                                        <td>{repo[1]}</td>
                                                                        <td>{repo[2]}</td>
                                                                        <td>{(repo[3] !== null && repo[3] !== '') ? cmbTipoOcupante.filter(tipo => tipo.key === repo[3])[0].value : ""}</td>
                                                                        <td>{repo[4]}</td>
                                                                        <td>
                                                                            <CardLink href={"/persona/id/" + repo[0]}
                                                                            // onClick={(e) => this.EditFam(e, repo[0])}
                                                                            >
                                                                                {this.getText("lblEditar")}
                                                                            </CardLink>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })

                                                        }
                                                    </tbody>
                                                </Table>
                                            </Col>
                                        </FormGroup >
                                    </CardBody>
                                </Card> : ""
                            }
                            <br></br>

                            <Card >
                                <CardHeader tag="h5" >
                                    <Row>
                                        <Col sm='10'>
                                            {this.getText("titCapacidades")}
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    {
                                        this.state.capacidad.map((cap, index) => {
                                            return (
                                                <div key={index}>
                                                    <FormGroup row  >                                                            <Col >
                                                        <CustomInput type="checkbox" id={cap.id}
                                                            label={cap.nombre}
                                                            defaultChecked={cap.val}
                                                            onClick={this.toggle} />
                                                    </Col>
                                                    </FormGroup>
                                                </div>
                                            )
                                        })

                                    }

                                </CardBody>
                            </Card>
                            <br></br>
                            {id > 0 ?
                                <Card >
                                    <CardHeader tag="h5" >
                                        <Row>
                                            <Col sm='10'>
                                                {this.getText("titVehiculos")}
                                            </Col>
                                            <Button size="sm"
                                                onClick={(e) => this.addRow(e, 0)}
                                                className="tertiaryColorB"
                                                value={this.getText("lblAgregar")} />
                                        </Row>

                                    </CardHeader>
                                    <CardBody>
                                        <FormGroup row >
                                            <Col>
                                                <Table size="sm" responsive  >
                                                    <thead>
                                                        <tr>
                                                            <th>{this.getText("lblAlias")}</th>
                                                            {/* <th>{this.getText("lblUnidad")}</th> */}
                                                            <th>{this.getText("lblColor")}</th>
                                                            <th>{this.getText("lblMarca")}</th>
                                                            <th>{this.getText("lblModelo")}</th>
                                                            <th>{this.getText("lblEstado")}</th>
                                                            <th></th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            this.state.persona.vehiculos.map((repo, index) => {
                                                                return (
                                                                    <tr key={index} >
                                                                        <td>{repo.unidad}</td>
                                                                        <td>{repo.color}</td>
                                                                        <td>{repo.marca}</td>
                                                                        <td>{repo.modelo}</td>
                                                                        <td>{repo.estado}</td>
                                                                        <td>
                                                                            <CardLink href="#" onClick={(e) => this.addRow(e, repo)}>
                                                                                {this.getText("lblEditar")}
                                                                            </CardLink>
                                                                        </td>
                                                                        <td>
                                                                            <Button
                                                                                onClick={(e) => this.deleteRow(repo.id, e)}
                                                                                size="sm"
                                                                                className="btn-delete"
                                                                                value="X" />
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })

                                                        }
                                                    </tbody>
                                                </Table>
                                            </Col>
                                        </FormGroup >

                                        <Vehiculo
                                            open={this.state.open}
                                            Close={this.Close}
                                            addVehiculo={this.addVehiculo}
                                            value={this.state.idvehiculo}
                                        />
                                    </CardBody>
                                </Card>
                                : ""
                            }
                            <br />
                            {
                                this.state.persona.id !== "0" ?
                                    <FormGroup row>
                                        <Col md={5} className="text-left" >
                                            <Label>{this.getText("lblPrecaucion")}</Label>
                                            <Button type="submit"
                                                onClick={this.handleDelete}
                                                className="btn-delete"
                                                value={this.getText("lblEliminar")} />
                                            <br />
                                        </Col>
                                        {/* <Col md={10}>
                                        </Col> */}
                                    </FormGroup>
                                    : null
                            }
                        </Form>

                    </div>
                    {(this.state.loading) ? <Loader /> : <span />}

                </div >
            );
        }
        else {
            return (
                <div>
                    <Loader />
                </div>);
        }
    }
    // }
}