export default class PizarraModel {
    constructor(
        titulo,
        tipo,
        estado,
        detalle,
        fotos,
        idEtapa,        
        id,
        fecha_caducidad
    ) {
        this.titulo = titulo;
        this.detalle = detalle;
        this.estado = estado;
        this.fotos = fotos;
        this.id = id;
        this.idEtapa = idEtapa;
        this.tipo = tipo;
        this.fecha_caducidad = fecha_caducidad;
    }

    route() {
        return "pizarra";
    }
}