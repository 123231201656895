import React from 'react';
import Base from '../Base';
import { DropdownItem } from "reactstrap";
import * as XLSX from 'xlsx';
//import * as FileSaver from 'file-saver';

// Otros Componentes
import './styles.css';

export default class ExportExcel extends Base {

    constructor(props) {
        super(props);

        this.state = {
            data: this.props.data
        };

    };

    

    exportFile(csvData, fileName) {

        const wb = XLSX.utils.book_new();
        const wsAll = XLSX.utils.aoa_to_sheet(csvData);
        XLSX.utils.book_append_sheet(wb, wsAll, fileName);
        XLSX.writeFile(wb, fileName + ".xlsx");
    }

    render() {
        return (
            <DropdownItem
                onClick={(e) => this.exportFile(this.props.data, this.props.filename)}>
                {this.props.title}
            </DropdownItem>
        );
    }
}