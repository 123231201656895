import React from 'react';
import { Col, Form, FormGroup, Label, Input, CustomInput } from 'reactstrap';
import Base from '../../components/Base';
import Text from '../../components/Text';
import Ubicacion from "../../components/Ubicacion";
import './style.css';
import ReservaModel from '../../models/ReservaModel.js';
import GeneralServices from '../../services/GeneralServices.js';
import Loader from '../../components/Loader';
import Button from '../../components/Button';
import Return from '../../components/Return';
import Dropdown from "../../components/Dropdown";
import CobroModel from '../../models/CobroModel.js';
import Pago from "../../components/Pago";
import moment from 'moment';

export default class ReservaEspacio extends Base {
    constructor(props) {
        super(props);

        this.handleAgregar = this.handleAgregar.bind(this);
        this.reservaService = new GeneralServices();
        this.ChangeEstado = this.ChangeEstado.bind(this);
        this.state = {
            open: this.props.open,
            cobro: null
        };
    }

    ChangeEstado(event) {
        this.setState({
            estado: event.target.value
        });
    }

    ShowAlert(mensaje, error) {
        this.setState({ alerta: true, mensaje: mensaje, error: error }, () => {
            window.setTimeout(() => {
                this.setState({ alerta: false, mensaje: '', error: false })
            }, 3000)
        });
    }


    handleAgregar(event) {

        event.preventDefault();

        let file = this.state.espacio.valor>0?this.getRef('cmbArchivoPago').state.file:"";

        if (typeof file =='string') {
            this.guardarCobro(file);
        }
        else {
            let formData = new FormData();
            formData.append('file', file);
            this.reservaService.Upload(formData, 'archivo/subir')
                .then(result => {
                    //console.log(result);
                    if (result.codigo !== "0") {
                        alert(result.mensaje);
                        if (result.codigo === 401) {
                            this.CerarSesion();
                        }
                        this.setState({ loading: false });
                    }
                    else {
                        this.guardarCobro(result.data.url);
                    }
                }
                )
                .catch(error => {
                    console.log(error);
                    this.setState({ loading: false });
                });
        }
    }

    guardarCobro(file) {
        let ubicacion = this.getRef('cmbUbicacion');

        let reserva = new ReservaModel();

        reserva.id = this.props.match.params.id;
        reserva.estado = this.state.estado ? this.state.estado : this.props.value.estado;
        reserva.usuario_modificacion = this.getUsuario();
        reserva.idCobro = this.state.espacio.idCobro;

       let formaPago = this.state.espacio.valor>0?this.getRef('cmbFormaPago').getValue():'';

        this.setState({ loading: true });

        if (formaPago !== '') {
            let cobro = new CobroModel();
            cobro.archivo = file;
            if (reserva.idCobro !== undefined && reserva.idCobro !== null) {

                cobro.idEtapa = this.state.cobro.idEtapa;
                cobro.id = reserva.idCobro;
                cobro.anio = this.state.cobro.anio;
                cobro.mes = this.state.cobro.mes;
                cobro.fechaIngreso = this.state.cobro.fechaIngreso;
                cobro.usuario_ingreso = this.state.cobro.usuario_ingreso;
                cobro.tipoCobro = this.state.cobro.tipoCobro;
                cobro.pagado = this.state.cobro.pagado;
                cobro.fechaPago = this.state.cobro.fechaPago;
                cobro.usuario_modificacion = this.getUsuario();

            }
            else {
                cobro.idEtapa = this.getEtapa();
                cobro.id = 0;
                cobro.anio = moment().year();
                cobro.mes = moment().format("MM");
                cobro.fechaIngreso = new Date();
                cobro.usuario_ingreso = this.getUsuario(); //cobro.id > 0 ? this.state.cobro.usuario_ingreso : this.getUsuario();
                cobro.tipoCobro = 'RES';
                cobro.pagado = true;
                cobro.fechaPago = new Date();//_fecha.toDate(); 

            }

            cobro.ubicacion = ubicacion.state.ubicacion;
            cobro.valor = this.getRef('txt_valor').getValue();                   
            cobro.formaPago = this.getRef('cmbFormaPago').getValue();
            cobro.banco = this.getRef('txt_banco').getValue();
            cobro.referencia = this.getRef('txt_referencia').getValue();

            this.reservaService.save(cobro, cobro.route())
                .then(result => {
                    if (result.codigo !== "0") {
                        alert(result.mensaje);
                        if (result.codigo === 401) {
                            this.CerarSesion();
                        }
                        this.setState({ loading: false });
                    }
                    else {
                        reserva.idCobro = result.data.id;
                        this.guardaReserva(reserva);
                        this.setState({ cobro: cobro });
                    }
                })
                .catch(error => {
                    console.log(error);
                    this.setState({ loading: false });
                });
        }
        else {
            this.guardaReserva(reserva);
        }
    }

    guardaReserva(reserva) {
        this.reservaService.save(reserva, reserva.route() + '/estado')
            .then(result => {
                this.setState({ loading: false });
                if (result.codigo !== "0") {
                    alert(result.mensaje);

                    if (result.codigo === 401) {
                        this.CerarSesion();
                    }
                }
                else {
                    alert(this.getText("msgGuardadoOk"));
                    let espacio = this.state.espacio;
                    espacio.idCobro = reserva.idCobro;
                    this.setState({ loading: false, espacio: espacio });
                    //console.log(result.data);
                }

            }
            )
            .catch(error => {
                console.log(error);
                this.setState({ loading: false });
            });
    }

    cargarDatos() {
        const id = this.props.match.params.id;

        let reserva = new ReservaModel();
        reserva.id = id;
        this.setState({ loading: true });
        this.reservaService.Id(reserva.id, reserva.route() + '/id')
            .then(result => {
                this.setState({ loading: false });
                if (result.codigo !== "0") {
                    alert(result.mensaje);
                    if (result.codigo === 401) {
                        this.CerarSesion();
                    }
                }
                else {
                    //console.log(result.data);

                    reserva.estado = result.data.reserva.estado;
                    reserva.fechaIngreso = result.data.reserva.fechaIngreso;
                    reserva.persona = result.data.reserva.persona;
                    reserva.ubicacion = result.data.reserva.ubicacion;
                    reserva.detalle = result.data.reserva.detalle;
                    reserva.fechaInicio = result.data.reserva.fechaInicio;
                    reserva.fechaFin = result.data.reserva.fechaFin;
                    reserva.usuario_ingreso = result.data.reserva.usuario_ingreso;
                    reserva.espacioNombre = result.data.espacio.nombre;
                    reserva.valor = result.data.espacio.valor;
                    reserva.idCobro = result.data.reserva.idCobro;
                    //console.log(reserva);
                    this.setState({ estado: result.data.reserva.estado, espacio: reserva, cobro: result.data.cobro ? result.data.cobro : null });
                }
            }
            )
            .catch(error => {
                this.setState({ loading: false });
                console.log(error);
            });
    }

    componentDidMount() {
        this.cargarDatos();
    }
    render() {
        const val = this.state.espacio;
        const cobro = this.state.cobro;

        if (this.state.espacio) {
            return (
                <div className='formTitulo' >

                    {/* <Alert
                        open={this.state.alerta}
                        mensaje={this.state.mensaje}
                        error={this.state.error}
                    /> */}
                    <FormGroup row >
                        <Col md={9}>
                            <h5><Return onClick={() => this.props.history.go(-1)} />
                                {val.espacioNombre}</h5>
                        </Col>
                        <Col md={3} className="text-right">
                            <Button type="submit"
                                onClick={this.handleAgregar}
                                className="tertiaryColorB"
                                value={this.getText("lblGuardar")} />
                        </Col>

                    </FormGroup>

                    <div className='form'>
                        <Form >
                            {/* <Input type="hidden" id="espacio" innerRef={(c) => this.espacio = c} value={val.espacio} /> */}
                            <FormGroup row>
                                <Col >
                                    <Label for="estados" className='inputNormal' >{this.getText("lblEstado")}</Label>
                                    <FormGroup check inline id="estados" >
                                        <Label check>
                                            <Input
                                                type='radio'
                                                name="estado"
                                                value='A'
                                                checked={this.state.estado === 'A'}
                                                onChange={this.ChangeEstado}
                                            />{this.getText("lblReservado")}</Label>

                                    </FormGroup>
                                    <FormGroup check inline  >
                                        <Label check>
                                            <Input
                                                type='radio'
                                                name="estado"
                                                value='P'
                                                checked={this.state.estado === 'P'}
                                                onChange={this.ChangeEstado}
                                            />{this.getText("lblPendiente")}</Label>
                                    </FormGroup>
                                    <FormGroup check inline  >
                                        <Label check>
                                            <Input
                                                type='radio'
                                                name="estado"
                                                value='I'
                                                checked={this.state.estado === 'I'}
                                                onChange={this.ChangeEstado}
                                            />{this.getText("lblCancelado")}</Label>
                                    </FormGroup>
                                </Col>
                            </FormGroup>

                            <Ubicacion
                                ref={(ref) => this.setRef('cmbUbicacion', ref)}
                                residente={true}
                                persona={val.persona}
                                ubicacion={val.ubicacion}
                                disabled={true}
                            />

                            <FormGroup row>
                                <Col >
                                    <Text
                                        label={this.getText("lblDetalle")}
                                        id={'txt_detalle'}
                                        value={val.detalle}
                                        type={'textarea'}
                                        height="150px"
                                        ref={(ref) => this.setRef('txt_detalle', ref)}
                                        disabled={true}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Col sm={6}>
                                    <Text
                                        label={this.getText("lblHoraInicio")}
                                        id={'txt_inicio'}
                                        value={val.fechaInicio ? new Date(val.fechaInicio).toLocaleString() : ""}
                                        type={'text'}
                                        ref={(ref) => this.setRef('txt_inicio', ref)}
                                        disabled={true}
                                    />
                                </Col>
                                <Col sm={6}>
                                    <Text
                                        label={this.getText("lblHoraFin")}
                                        id={'txt_fin'}
                                        type={'text'}
                                        value={val.fechaFin ? new Date(val.fechaFin).toLocaleString() : ""}
                                        ref={(ref) => this.setRef('txt_fin', ref)}
                                        disabled={true}
                                    />
                                </Col>
                            </FormGroup>

                            {val.valor > 0 ?
                                <div>
                                    <FormGroup row>
                                        <Col >
                                            <Dropdown
                                                id="cmbFormaPago"
                                                ref={(ref) => this.setRef('cmbFormaPago', ref)}
                                                label={this.getText("lblFormaPago")}
                                                value={JSON.parse(this.getOpcion("FormaPago"))}
                                                tipo={cobro !== null ? "" : "N"}
                                                default={cobro !== null ? cobro.formaPago : undefined}

                                            />
                                        </Col>
                                        <Col >
                                            <Text
                                                label={this.getText("lblBanco")}
                                                id={'txt_banco'}
                                                value={cobro !== null ? cobro.banco : ""}
                                                type={'text'}
                                                ref={(ref) => this.setRef('txt_banco', ref)}
                                            />
                                        </Col>

                                    </FormGroup>
                                    <FormGroup row>
                                        <Col >
                                            <Text
                                                label={this.getText("lblReferencia")}
                                                id={'txt_referencia'}
                                                value={cobro !== null ? cobro.referencia : ""}
                                                type={'text'}
                                                ref={(ref) => this.setRef('txt_referencia', ref)}
                                            />
                                        </Col>
                                        <Col>
                                            <Text
                                                label={this.getText("lblValor")}
                                                id={'txt_valor'}
                                                value={"" + val.valor}
                                                type="number"
                                                ref={(ref) => this.setRef('txt_valor', ref)}
                                                disabled={true}
                                            />
                                        </Col>
                                    </FormGroup>
                                    <Pago
                                        ref={(ref) => this.setRef('cmbArchivoPago', ref)}
                                        file={cobro !== null ? cobro.archivo : ""}
                                    />

                                </div>
                                : <div />

                            }
                        </Form>

                    </div>
                    {(this.state.loading) ? <Loader>{this.getText("lblCargando")}</Loader> : <span></span>}

                </div>
            );
        }
        else {
            return (
                <div>
                    <Loader />
                </div>);
        }
    }

}