import React from 'react';
import Base from '../../components/Base';
import {
    Col, Form, FormGroup, Label, Input,
    Dropdown as DR, DropdownItem, DropdownMenu, DropdownToggle
} from 'reactstrap';
import Button from '../../components/Button';
import Text from "../../components/Text";
import './style.css';
import CategoriaModel from '../../models/CategoriaModel.js';
import GeneralServices from '../../services/GeneralServices.js';
import Loader from '../../components/Loader';
import Return from '../../components/Return';

export default class CategoriaComercio extends Base {

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.CategoriaComercioService = new GeneralServices();

        this.state =
        {
            loading: false,
            open: false,
            open2: false,
        };
    }



    handleChange(event) {
        let cat = this.state.Categoria;
        cat.estado = event.target.value;
        this.setState({
            Categoria: cat, estado: event.target.value
        });
    }
    toggle_button = () => {
        this.setState({
            open: !this.state.open
        });
    }

    toggle_button2 = () => {
        this.setState({
            open2: !this.state.open2
        });
    }

    handleSubmit(event) {
        event.preventDefault();

        let validar = this.ValidateForm();

        if (validar) {
            alert(validar);
            return;
        }

        this.setState({ loading: true });

        let categoria = new CategoriaModel();

        categoria.nombre = this.getRef('txt_nombre').getValue();
        categoria.id = this.state.Categoria.id;
        categoria.estado = this.state.Categoria.estado;

        categoria.color = this.state.Categoria.color;
        categoria.color2 = this.state.Categoria.color2;


        this.CategoriaComercioService.save(categoria, categoria.route())
            .then(result => {
                this.setState({ loading: false });
                if (result.codigo !== "0") {
                    alert(result.mensaje);

                    if (result.codigo === 401) {
                        this.CerarSesion();
                    }
                }
                else {
                    alert(this.getText("msgGuardadoOk"));
                    window.location.href='/CategoriasComercio';
                    //this.props.history.push('/CategoriasComercio');
                }

            }
            )
            .catch(error => {
                console.log(error);
                this.setState({ loading: false });
            });
    }



    changeColor2(e) {
        let Categoria = this.state.Categoria;
        Categoria.color2 = e;
        this.setState({
            Categoria: Categoria
        })
    }

    changeColor(e) {
        let Categoria = this.state.Categoria;
        Categoria.color = e;
        this.setState({
            Categoria: Categoria
        })
    }

    componentDidMount() {

        const id = this.props.match.params.id;

        let categoria = new CategoriaModel();

        if (id > 0) {


            this.CategoriaComercioService.Id(id, categoria.route())
                .then(result => {
                    //  console.log(result);
                    if (result.codigo !== "0") {
                        alert(result.mensaje);
                        if (result.codigo === 401) {
                            this.CerarSesion();
                        }
                    }
                    else {
                        if (result.data !== null) {
                            this.setState({ Categoria: result.data });
                        }
                    }
                }
                )
                .catch(error => {
                    console.log(error);
                });

        }
        else {

            categoria.nombre = "";
            categoria.id = id;
            categoria.estado = "A";
            categoria.color = '#B077FE';
            categoria.color2 = '#B077FE';

            this.setState({ Categoria: categoria });

        }
    }

    render() {
        let Colors = JSON.parse(this.getOpcion("Colores"));

        if (this.state.Categoria) {
            const data = this.state.Categoria;
            return (
                <div className='formTitulo' >

                    <FormGroup row >
                        <Col md={9}>
                        <h5><Return onClick={() => this.props.history.go(-1)}/>{this.getText("titCategoriasComercio")}</h5>
                        </Col>
                        <Col md={3} className="text-right">
                            <Button type="submit"
                                onClick={this.handleSubmit}
                                className="tertiaryColorB"
                                value={this.getText("lblGuardar")} />
                        </Col>
                    </FormGroup>
                    <div className='form'>
                        <Form  >
                            {/* <Alert
                        open={this.state.alerta}
                        mensaje={this.state.mensaje}
                        error={this.state.error}
                    /> */}
                            <FormGroup row>
                                <Col md={4}>
                                    <FormGroup check inline >
                                        <Label check>
                                            <Input
                                                type='radio'
                                                name="estado"
                                                value='A'
                                                checked={data.estado === 'A'}
                                                onChange={this.handleChange}
                                            />{this.getText("lblActivo")}</Label>

                                    </FormGroup>
                                    <FormGroup check inline  >
                                        <Label check>
                                            <Input
                                                type='radio'
                                                name="estado"
                                                value='I'
                                                checked={data.estado === 'I'}
                                                onChange={this.handleChange}
                                            />{this.getText("lblInactivo")}</Label>
                                    </FormGroup>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Col>
                                    <Text
                                        label={this.getText("lblNombre")}
                                        id={'txt_nombre'}
                                        value={data.nombre}
                                        type={'text'}
                                        ref={(ref) => this.setRef('txt_nombre', ref)}
                                        isRequired={true}
                                        menssage={`${this.getText("msgCampoRequerido")} ${this.getText("lblNombres")}`}
                                    />
                                </Col>
                            </FormGroup>

                            <FormGroup row >
                                <Col md={6}>
                                    <div className="dropdown">
                                        <Label className='inputNormal' >
                                            {this.getText("lblColor") + " 1"}
                                        </Label>
                                        <DR isOpen={this.state.open} toggle={this.toggle_button}
                                        >
                                            <DropdownToggle caret className="btn2"
                                                style={{ backgroundColor: this.state.Categoria.color, width: "100%" }}
                                                color="primary">
                                                {this.getText("lblElegir")}</DropdownToggle>
                                            <DropdownMenu right>
                                                {Colors.map((dato) => {
                                                    return <DropdownItem key={dato.key}
                                                        style={{ backgroundColor: dato.key, width: "100%", height: "20px" }}
                                                        value={dato.key}
                                                        onClick={this.changeColor.bind(this, dato.key)} > {" "}</DropdownItem>;
                                                })}
                                            </DropdownMenu>
                                        </DR>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="dropdown">
                                        <Label className='inputNormal' >
                                            {this.getText("lblColor") + " 2"}
                                        </Label>

                                        <DR isOpen={this.state.open2} toggle={this.toggle_button2} >                                                   <DropdownToggle caret className="btn2"
                                            style={{ backgroundColor: this.state.Categoria.color2, width: "100%" }}
                                            color="primary">
                                            {this.getText("lblElegir")}</DropdownToggle>
                                            <DropdownMenu right>
                                                {Colors.map((dato) => {
                                                    return <DropdownItem key={dato.key}
                                                        style={{ backgroundColor: dato.key, width: "100%", height: "20px" }}
                                                        value={dato.key}
                                                        onClick={this.changeColor2.bind(this, dato.key)} > {" "}</DropdownItem>;
                                                })}
                                            </DropdownMenu>
                                        </DR>
                                    </div>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Col  >
                                    <div className="inputNormal">
                                        <Label for="txt_color" className='inputNormal' >
                                            {this.getText("lblColor") + " " + this.getText("titEspacio")}
                                        </Label>
                                        <Input id="txt_color"
                                            disabled={true}
                                            type={"text"}
                                            style={{ backgroundImage: `linear-gradient(to right, ${this.state.Categoria.color}, ${this.state.Categoria.color2})`, }} />
                                    </div>
                                </Col>
                            </FormGroup>


                        </Form>
                    </div>
                    {(this.state.loading) ? <Loader /> : <span />}
                </div>
            );
        }
        else {
            return (
                <div>
                    <Loader />
                </div>);
        }
    }
}