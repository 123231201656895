// Componente base
import React from 'react';
import Base from '../../components/Base';
import { Link } from 'react-router-dom';
import { UncontrolledTooltip } from 'reactstrap';
import matchSorter from 'match-sorter';
import BuzonModel from '../../models/BuzonModel.js';
import Loader from '../../components/Loader';
import GridTable from '../../components/GridTable';
import GeneralServices from '../../services/GeneralServices.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faEnvelopeOpen } from '@fortawesome/free-solid-svg-icons'
import './styles.css';

export default class BuzonList extends Base {

    constructor(props) {
        super(props);
        this.servicio = new GeneralServices();
        this.handleClick = this.handleClick.bind(this);


        this.state = {
            dataGridRows: [],
            dataGridColumns: [],
            time: 0,
        }

    };

    setValue(rows) {
        this.setState({ dataGridRows: rows });
    };

    New(e) {
        e.preventDefault();
        window.location.href = "/comercio/id/0";
        //this.props.history.push("/comercio/id/0");
    }

    SetColumns() {

        var columns = [
            {
                Header: this.getText("titBandeja"),
                headerStyle: { textAlign: "left" },
                columns: [
                    this.ColumnaId(),

                    {
                        Header: this.getText("lblTitulo"),
                        id: "nombre",
                        width: 250,
                        accessor: d => d.nombre,
                        filterMethod: (filter, rows) =>
                            matchSorter(rows, filter.value, { keys: ["nombre"] }),
                        filterAll: true
                    },
                    {
                        Header: this.getText("lblDetalle"),
                        id: "detalle",
                        width: 350,
                        accessor: d => d.detalle,
                        filterMethod: (filter, rows) =>
                            matchSorter(rows, filter.value, { keys: ["detalle"] }),
                        filterAll: true
                    },
                    {
                        Header: this.getText("lblFechaIngreso"),
                        accessor: "fecha",
                        style: { textAlign: "center" },
                        width: 100
                    },

                    {
                        Header: this.getText("lblEstado"),
                        accessor: "estado",
                        width: 80,
                    }

                ]
            },
            {
                Header: "",
                columns: [
                    {
                        Header: this.getText("lblVer"),
                        accessor: "buscar",
                        filterable: false,
                        sortable: false,
                        style: { textAlign: "center" },
                        width: 70
                    },
                    {
                        Header: "",
                        accessor: "btn_ir",
                        filterable: false,
                        sortable: false,
                        width: 70,
                        style: { textAlign: "center" }
                    }

                ]
            }
        ];
        this.setState({
            dataGridColumns: columns
        });
    }

    componentDidMount() {

        let rol = JSON.parse(localStorage.getItem('roles'));
        const rol_admin = rol.filter((dato) => dato.nombre === 'ADMIN');

        if (rol_admin.length === 0) {

            const rol_gar = rol.filter((dato) => dato.nombre === 'GARITA');

            if (rol_gar.length !== 0) {

                window.location.href = "/ControlInvitados";
            }
            return;
        }       

        this.SetColumns();
        this.CargaDatos();
        this.interval = setInterval(() => { this.CargaDatos(); }, 30000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    CargaDatos() {
        let etapa = this.getEtapa();
        this.servicio.List(etapa, 'buzon')
            .then(result => {
                if (result.codigo !== "0") {
                    alert(result.mensaje);
                    if (result.codigo === 401) {
                        this.CerarSesion();
                    }
                }
                else {

                    var rows = [];
                    for (let i = 0; i < result.data.length; i++) {
                        rows.push({
                            id: result.data[i].id,
                            fecha: result.data[i].fechaIngreso ? new Date(result.data[i].fechaIngreso).toLocaleDateString() : "",
                            nombre: result.data[i].titulo,
                            detalle: result.data[i].detalle,
                            estado: result.data[i].estado,
                            buscar: this.link(result.data[i].id, result.data[i].estado),
                            btn_ir: this.linkIR(result.data[i].id, result.data[i].url)

                        });
                    }
                    this.setValue(rows);
                }

            }
            )
            .catch(error => {
                console.log(error);
            });
    }
    //Fucion para mostrar icono y Link
    link(id, estado) {
        var link = [];
        link.push(
            <Link key={id} to={"/buzon/id/" + id}>
                <h6 id={"idVer" + id}><FontAwesomeIcon color='white' icon={estado === "ING" ? faEnvelope : faEnvelopeOpen} /></h6>
                <UncontrolledTooltip placement="right" target={"idVer" + id}>
                    {this.getText("lblVer")}
                </UncontrolledTooltip>
            </Link>)
        return link[0]
    }

    //Fucion para mostrar icono y Link
    linkIR(id, url) {
        var link = [];
        link.push(
            <Link key={id} to={url} onClick={(e) => this.handleClick(id, url, e)}>
                <h6>{this.getText("lblIr")}</h6>
            </Link>)
        return link[0]
    }

    handleClick(id, url, event) {
        event.preventDefault();
        let buzon = new BuzonModel();
        buzon.id = id;
        buzon.usuario_modificacion = this.getUsuario();

        this.servicio.save(buzon, buzon.route())
            .then(result => {
                this.setState({ loading: false });
                if (result.codigo !== "0") {
                    alert(result.mensaje);

                    if (result.codigo === 401) {
                        this.CerarSesion();
                    }
                }
                else {
                    window.location.href = url;
                    //this.props.history.push(url);
                }

            }
            )
            .catch(error => {
                console.log(error);
                this.setState({ loading: false });
            });


    }

    getTrProps = (state, rowInfo, instance) => {
        if (rowInfo) {
            return {
                style: {
                    //background: rowInfo.row.estado,
                    fontWeight: rowInfo.row.estado === "ING" ? 'bold' : 'normal',
                    //color: 'white'
                }
            }
        }
        return {};
    }

    render() {

        let rol = JSON.parse(localStorage.getItem('roles'));
        const rol_admin = rol.filter((dato) => dato.nombre === 'ADMIN');

        if (rol_admin.length === 0) {
            return (<div></div>);
        }

        if (this.state.dataGridColumns.length > 0) {
            return (
                <div>
                    <div className="mainContent">
                        <div className="contentBlock">
                            <GridTable
                                data={this.state.dataGridRows}
                                getTrProps={this.getTrProps}
                                columns={this.state.dataGridColumns} />
                        </div>
                    </div>
                </div>
            )
        }
        else {
            return (
                <div>
                    <Loader />
                </div>);
        }
    }
}
