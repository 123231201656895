import React from 'react';
import { Col, CustomInput, Form, FormGroup, Input, Label } from 'reactstrap';
import Alert from '../../components/Alert';
import Base from '../../components/Base';
import Button from '../../components/Button';
import Dropdown from "../../components/Dropdown";
import Loader from '../../components/Loader';
import Return from '../../components/Return';
import Tag from "../../components/Tag";
import Text from "../../components/Text";
import ComercioModel from '../../models/ComercioModel.js';
import GeneralServices from '../../services/GeneralServices.js';
import './style.css';
const Compress = require('compress.js');

export default class Comercio extends Base {

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.ComercioService = new GeneralServices();
        this.changeComercio = this.changeComercio.bind(this);
        let reserva = null;
        this.state =
        {
            loading: false,
            categoria: null,
            files: [],
            fotos: [],
            alerta: false,
            mensaje: '',
            error: false,
            productos: [], 
            estado:''
        };
    }


    handleChange(event) {
        let commer = this.state.Comercio;
        commer.estado = event.target.value;
        this.setState({
            Comercio: commer, estado: event.target.value
        });
    }

    changeComercio(event) {
        const val = event.target.value
        this.getRef("cmbCategoriaComercio").updateValue(val);
    }

    validaImagenes = (file) => {
        const CantImagen = parseInt(this.getOpcion("NoImgPizarra"));
        const types = ['image/png', 'image/jpeg'];

        if (types.every(type => file.type !== type)) {
            this.ShowAlert(this.getText("msgImagenNoPermitido"), true);
            window.scrollTo(0, 0);
            return false;
        }

        let CantRegistro = this.state.files.length + this.state.fotos.length;

        if (CantRegistro >= CantImagen) {
            this.ShowAlert(this.getText("msgImagenMaximo") + CantImagen, true);
            window.scrollTo(0, 0);
            return false;
        }
        return true;
    }

    deleteRow(orden, e) {
        e.preventDefault();
        var nuevofile = []
        var nuevofotos = this.state.fotos.filter((foto) =>
            foto.orden !== orden);
        nuevofile = this.state.files;
        nuevofile.splice(orden, 1);
        this.setState({ files: nuevofile });
        this.setState({ fotos: nuevofotos });

    }

    fileSelectedHandler = e => {
        let imagen = [...e.target.files];
        if (this.validaImagenes(imagen[0])) {
            let compress = new Compress();

            compress.compress(imagen, {
                size: 8, // the max size in MB, defaults to 2MB
                quality: 0.40, // the quality of the image, max is 1,
            }).then((results) => {

                let img1 = results[0];
                let base64str = img1.data;
                let imgExt = img1.ext;
                let name = img1.alt;
                let file = Compress.convertBase64ToFile(base64str, imgExt);
                let archivo = new File([file], name);

                this.setState({ files: [...this.state.files, archivo] });
            });
        }
        e.target.value = null;
    }

    ShowAlert(mensaje, error) {
        this.setState({ alerta: true, mensaje: mensaje, error: error }, () => {
            window.setTimeout(() => {
                this.setState({ alerta: false, mensaje: '', error: false })
            }, 5000)
        });
    }

    submitAceptarRechazar(e, estado) {
        e.preventDefault();
        // console.log("estado recibido: " + estado)
        let commer = this.state.Comercio;
        commer.estado = estado;
        this.setState({
            Comercio: commer
        });
        setTimeout(() => {
            this.save();
        }, 500);
    }

    handleSubmit(event) {
        event.preventDefault();
        this.save()
    }

    save() {
        let comercio = new ComercioModel();
        let formData = new FormData();
        var imagedata = this.state.files;
        let validar = this.ValidateForm();

        // console.log("el usuario de getUsuario: " + this.getUsuario());
        // return;

        if (validar) {
            this.ShowAlert(validar, true);
            return;
        }
        this.setState({ loading: true });
        comercio.nombre = this.getRef('txt_nombre').getValue();
        comercio.direccion = this.getRef('txt_direccion').getValue();
        comercio.horario = this.getRef('txt_horario').getValue();
        comercio.detalle = this.getRef('txt_detalle').getValue();
        comercio.id = this.state.Comercio.id;
        comercio.estado = this.state.Comercio.estado;
        comercio.valor = this.getRef('txt_tag').getTags();
        comercio.idCategoria = this.getRef("cmbCategoriaComercio").getValue();
        comercio.usuario_ingreso = this.state.Comercio.usuario_ingreso
        comercio.usuario_modificacion = this.getUsuario();
        comercio.telefono = this.state.Comercio.telefono
        for (const file of imagedata) {
            formData.append('files', file);
        }

        this.ComercioService.Upload(formData, 'archivo/subirvarios')
            .then(result => {
                if (result.codigo !== "0") {
                    alert(result.mensaje);
                    if (result.codigo === 401) {
                        this.CerarSesion();
                    }
                }
                else {
                    var archivos = [];
                    var fotoServer = [];

                    fotoServer = [...this.state.fotos, ...result.data];
                    fotoServer.map((foto, i) => { foto.orden = i + 1 });
                    comercio.fotos = JSON.stringify(fotoServer);

                    this.ComercioService.save(comercio, comercio.route())
                        .then(result2 => {
                            if (result2.codigo !== "0") {
                                alert(result2.mensaje);
                            }
                            else {
                                this.setState({ estado: result2.data.estado, Comercio: result2.data, files: archivos, fotos: fotoServer, loading: false });
                                this.ShowAlert(this.getText("msgGuardadoOk"), false);
                                //this.props.history.push('/Pizarra');
                            }
                            this.setState({ loading: false });
                        }
                        )
                        .catch(error => {
                            console.log(error);
                            this.setState({ loading: false });
                        });
                }
            }
            )
            .catch(error => {
                console.log(error);
                this.setState({ loading: false });
            });
    }

    componentDidMount() {

        const id = this.props.match.params.id;

        this.reserva = this.props.match.params.reserva;


        this.ComercioService.List("", 'categoriacomercio')
            .then(result => {
                if (result.codigo !== "0") {
                    alert(result.mensaje);
                    if (result.codigo === 401) {
                        this.CerarSesion();
                    }
                }
                else {
                    var rows = [];

                    for (let i = 0; i < result.data.length; i++) {
                        if (result.data[i].estado !== 'I') {
                            rows.push({
                                key: result.data[i].id.toString(),
                                value: result.data[i].nombre

                            });
                        }
                    }
                    this.setState({ categoria: rows });
                    this.CargaDatos(id);

                }
            }
            )
            .catch(error => {
                console.log(error);
            });


    }

    CargaDatos(id) {
        let comercio = new ComercioModel();

        if (id > 0) {
            this.ComercioService.Id(id, comercio.route())
                .then(result => {
                    if (result.codigo !== "0") {
                        alert(result.mensaje);
                        if (result.codigo === 401) {
                            this.CerarSesion();
                        }
                    }
                    else {
                        var fotos = [];

                        // console.log(result.data);

                        if (result.data.comercio === null) {
                            alert(this.getText("lblNoHayDatosId") + id);
                            window.location.href = '/Comercios';
                            //this.props.history.push('/Comercios');
                            return;
                        }

                        if (result.data.comercio.fotos != null) {
                            fotos = JSON.parse(result.data.comercio.fotos);
                        }

                        this.ComercioService.Id(id, "producto")
                            .then(resultado => {
                                if (resultado.codigo !== "0") {
                                    alert(resultado.mensaje);
                                    if (resultado.codigo === 401) {
                                        this.CerarSesion();
                                    }
                                }

                                if (resultado.data === null) {
                                    alert(this.getText("lblNoHayDatosId") + id);
                                    //window.location.href = '/Comercios';
                                    //this.props.history.push('/Comercios');
                                    // this.setState({productos:[]})
                                    return;
                                }

                                this.setState({ productos: resultado.data })

                                this.setState({ estado: result.data.comercio.estado, Comercio: result.data.comercio, fotos: fotos, tags: result.data.tagComercio });
                                this.getRef("cmbCategoriaComercio").updateValue(result.data.comercio.idCategoria);
                            }).catch(error => {
                                console.log(error);
                            });
                    }

                }
                )
                .catch(error => {
                    console.log(error);
                });
        }
        else {

            comercio.nombre = "";
            comercio.id = id;
            comercio.estado = "A";
            comercio.fotos = "";
            comercio.direccion = "";
            comercio.detalle = "";
            comercio.horario = "";

            this.setState({ Comercio: comercio, tags: [] });

        }
    }

    render() {
        if (this.state.Comercio) {
            const data = this.state.Comercio;
            const estado = this.state.estado;
            // console.log("el estado general: " + estado);
            // const ingreso = vehi.fotosIngreso === null ? [] : JSON.parse(vehi.fotosIngreso);

            const ingreso = [];
            return (
                <div className='formTitulo' >
                    <Alert
                        open={this.state.alerta}
                        mensaje={this.state.mensaje}
                        error={this.state.error}
                    />
                    <FormGroup row >
                        <Col md={9}>
                            <h5><Return onClick={() => this.props.history.go(-1)} />{this.getText("titComercios")}</h5>
                        </Col>
                        {/* <Col md={3} className="text-right">
                            <Button type="submit"
                                onClick={this.handleSubmit}
                                className="tertiaryColorB"
                                value={this.getText("lblGuardar")} />
                        </Col> */}
                    </FormGroup>
                    <div className='form' >
                        <FormGroup row>
                            <Col sm={12} md={6}>
                                <Form encType="multipart/form-data" >
                                    {(this.reserva && estado == "S" ) &&
                                        <FormGroup row>
                                            <Col md={12}>
                                                <Label for="solicitud" className='inputNormal' >{this.getText("lblSolicitud")}</Label>
                                                {/* <FormGroup row> */}
                                                {/* <Col> */}
                                                <Button
                                                    type="button"
                                                    onClick={(e) => this.submitAceptarRechazar(e, 'A')}
                                                    className="btn-aprobar"
                                                    value={this.getText("lblAprobar")} />

                                                <Button
                                                    type="button"
                                                    onClick={(e) => this.submitAceptarRechazar(e, 'R')}
                                                    className="btn-rechazar"
                                                    value={this.getText("lblRechazar")} />
                                                {/* </Col> */}
                                                {/* </FormGroup> */}

                                            </Col>
                                        </FormGroup>
                                    }
                                    <FormGroup row>
                                        <Col md={12}>
                                            <Label for="estados" className='inputNormal' >{this.getText("lblEstado")}</Label>
                                            <FormGroup check inline id="estados">
                                                <Label check>
                                                    <Input
                                                        type='radio'
                                                        name="estado"
                                                        value='A'
                                                        checked={data.estado === 'A'}
                                                        onChange={this.handleChange}
                                                        disabled={true}
                                                    />{this.getText("lblActivo")}</Label>

                                            </FormGroup>
                                            <FormGroup check inline  >
                                                <Label check>
                                                    <Input
                                                        type='radio'
                                                        name="estado"
                                                        value='I'
                                                        checked={data.estado !== 'A'}
                                                        onChange={this.handleChange}
                                                        disabled={true}
                                                    />{this.getText("lblInactivo")}</Label>
                                            </FormGroup>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col>
                                            <Text
                                                label={this.getText("lblNombre")}
                                                id={'txt_nombre'}
                                                value={(estado == 'R') ? data.nombre + ' ' + this.getText('lblRechazada'): data.nombre}
                                                type={'text'}
                                                ref={(ref) => this.setRef('txt_nombre', ref)}
                                                isRequired={true}
                                                menssage={`${this.getText("msgCampoRequerido")} ${this.getText("lblNombres")}`}
                                                disabled={true}
                                            />
                                        </Col>
                                    </FormGroup>

                                    <FormGroup row >
                                        <Col>
                                            <Dropdown
                                                id="cmbCategoriaComercio"
                                                ref={(ref) => this.setRef('cmbCategoriaComercio', ref)}
                                                value={this.state.categoria}
                                                onChange={this.changeComercio}
                                                label={this.getText("lblCategoria")}
                                                disabled={true}
                                            />
                                        </Col>
                                    </FormGroup>

                                    <FormGroup row>
                                        <Col>
                                            <Text
                                                label={this.getText("lblDireccion")}
                                                id={'txt_direccion'}
                                                value={data.direccion}
                                                type={'text'}
                                                ref={(ref) => this.setRef('txt_direccion', ref)}
                                                isRequired={true}
                                                menssage={`${this.getText("msgCampoRequerido")} ${this.getText("lblDireccion")}`}
                                                disabled={true}
                                            />
                                        </Col>
                                    </FormGroup>

                                    <FormGroup row>
                                        <Col>
                                            <Text
                                                label={this.getText("lblHorarioAtencion")}
                                                id={'txt_horario'}
                                                value={data.horario}
                                                type={'text'}
                                                ref={(ref) => this.setRef('txt_horario', ref)}
                                                isRequired={true}
                                                menssage={`${this.getText("msgCampoRequerido")} ${this.getText("lblHorarioAtencion")}`}
                                                disabled={true}
                                            />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col>
                                            <Text
                                                label={this.getText("lblDescripcion")}
                                                id={'txt_detalle'}
                                                value={data.detalle}
                                                type={'textarea'}
                                                ref={(ref) => this.setRef('txt_detalle', ref)}
                                                isRequired={true}
                                                menssage={`${this.getText("msgCampoRequerido")} ${this.getText("lblDescripcion")}`}
                                                disabled={true}
                                            />
                                        </Col>
                                    </FormGroup>

                                    <Tag
                                        ref={(ref) => this.setRef('txt_tag', ref)}
                                        value={this.state.tags}
                                        disabled={true}
                                    />

                                    <FormGroup row>
                                        <Col>
                                            <Label for="files" className='inputNormal' >{this.getText("lblFotos")}</Label>
                                            <CustomInput type="file"
                                                id="files"
                                                name="files"
                                                label={this.getText("lblSeleccionarImages")}
                                                accept="image/*"
                                                onChange={this.fileSelectedHandler}
                                                disabled={this.state.loading}
                                                disabled={true} />

                                        </Col>

                                    </FormGroup>
                                    <br></br>
                                    <ul>
                                        {
                                            this.state.fotos.map((repo, index) => {
                                                let server = this.getOpcion('fileServer');
                                                let url = server + repo.url;
                                                return (
                                                    <FormGroup row key={index}  >
                                                        <Col md={3}>
                                                            <img alt={index} src={url} height="42" width="42" />
                                                        </Col>
                                                        <Col md={7}>
                                                            <a href={url}>Imagen {repo.orden}</a>
                                                        </Col>
                                                        <Col md={2}>
                                                            {/* <Button
                                                                onClick={(e) => this.deleteRow(repo.orden, e)}
                                                                size="sm"
                                                                className="secondaryColorB"
                                                                value="X" 
                                                                disabled = {true}/> */}
                                                        </Col>
                                                    </FormGroup>
                                                )
                                            })
                                        }
                                        {
                                            this.state.files.map((repo, index) => {
                                                let orden = index;
                                                let url = URL.createObjectURL(repo);
                                                return (
                                                    <FormGroup row key={orden}  >
                                                        <Col md={3}>
                                                            <img alt={orden} src={url} height="42" width="42" />
                                                        </Col>
                                                        <Col md={7}>
                                                            <a href={url}>Imagen {this.state.fotos.length + index + 1}</a>
                                                        </Col>
                                                        <Col md={2}>
                                                            <Button
                                                                onClick={(e) => this.deleteRow(repo.orden, e)}
                                                                size="sm"
                                                                className="secondaryColorB"
                                                                value="X" />
                                                        </Col>
                                                    </FormGroup> 
                                                )
                                            })
                                        }
                                    </ul>
                                    {(!this.reserva && estado != "R") &&
                                        <FormGroup row>
                                            <br />
                                            <Col>
                                                <Label className='inputNormal' >{this.getText("lblEliminarNegocio")}</Label>
                                                <Button
                                                    type="button"
                                                    onClick={(e) => this.submitAceptarRechazar(e, 'R')}
                                                    className="btn-rechazar"
                                                    value={this.getText("lblElNegocioDef")} />
                                            </Col>

                                        </FormGroup>
                                    }

                                </Form>
                            </Col>
                            <Col sm={12} md={6}>
                                {
                                    this.state.productos.length < 1 ?
                                        <FormGroup row>
                                            <Col>
                                                <p>{this.getText('lblNoProductos')}</p>
                                            </Col>
                                        </FormGroup> :
                                        <div>

                                            <FormGroup row>
                                                <Col>
                                                    <Label className='inputNormal' >{this.getText('lblProductos')}</Label>
                                                </Col>
                                            </FormGroup>
                                            {this.state.productos.map(prod => {
                                                let imagenes = [];
                                                imagenes = JSON.parse(prod.fotos)
                                                return (
                                                    <FormGroup row key={prod.id}>
                                                        <Col>
                                                            <div className="form altura">
                                                                <FormGroup row>
                                                                    <Col sm={4}>
                                                                        {/* <h1>{prod.nombre}</h1> */}
                                                                        {/* <Carrusel value={JSON.parse(prod.fotos)} /> */}
                                                                        <img src={this.getOpcion('fileServer') + imagenes[0].url} alt={imagenes[0].url}
                                                                            width="100%"
                                                                        // height='70%'
                                                                        />

                                                                    </Col>
                                                                    <Col>
                                                                        <Label className='inputNormal' ><Label style={{
                                                                            fontWeight: "bold",
                                                                        }}>Nombre: </Label> {prod.nombre}</Label>
                                                                        <Label className='inputNormal' ><Label style={{
                                                                            fontWeight: "bold",
                                                                        }}>Precio: </Label> ${prod.precio}</Label>
                                                                        <Label className='inputNormal' ><Label style={{
                                                                            fontWeight: "bold",
                                                                        }}>Descripcion: </Label> {prod.descripcion}</Label>
                                                                    </Col>
                                                                </FormGroup>
                                                            </div>
                                                        </Col>
                                                    </FormGroup>
                                                )
                                            })}
                                        </div>
                                }
                            </Col>
                        </FormGroup>
                    </div>
                    {(this.state.loading) ? <Loader /> : <span />}
                </div>
            );
        }
        else {
            return (
                <div>
                    <Loader />
                </div>);
        }
    }
}