export default class Residente {
    constructor(
        apellidos,
        correo,
        estado,
        estado_civil,
        fecha_nacimiento,
        foto,
        genero,
        id,
        idEtapa,
        identificacion,
        tipoIdentificacion,
        ubicacion ,
        nombres,
        telefono,
        tipo,
        fechaIngreso,
        tipoOcupante,
        vehiculos,
        usuario_modificacion,
        usuario_ingreso,
        capacidad, 
        idUsuario,
        estadoVivienda,
        noCobro
    ) {
        this.apellidos = apellidos;
        this.correo = correo;
        this.estado = estado;
        this.estado_civil = estado_civil;
        this.fecha_nacimiento = fecha_nacimiento;
        this.foto = foto;
        this.genero = genero;
        this.id = id;
        this.idEtapa = idEtapa;
        this.identificacion = identificacion;
        this.tipoIdentificacion = tipoIdentificacion;
        this.ubicacion = ubicacion;
        this.nombres = nombres;
        this.telefono = telefono;
        this.tipo = tipo;
        this.fechaIngreso=fechaIngreso;
        this.tipoOcupante=tipoOcupante;
        this.vehiculos=vehiculos;
        this.usuario_ingreso=usuario_ingreso;
        this.usuario_modificacion=usuario_modificacion;
        this.capacidad=capacidad;
        this.idUsuario = idUsuario;
        this.estadoVivienda = estadoVivienda;
        this.noCobro = noCobro;

    }

    route() {
        return "persona";
    }
}