import React from 'react';
import Base from '../../components/Base';
import { Col, Form, Row, FormGroup, Card, CardTitle, CardBody, CardFooter } from 'reactstrap';
import Dropdown from '../../components/Dropdown';
import Text from "../../components/Text";
import './style.css';
import GeneralServices from '../../services/GeneralServices.js';
import Loader from '../../components/Loader';
import Carrusel from '../../components/Carrusel';
import ControlIngresoFoto from './ControlIngresoFoto';
import Return from '../../components/Return';

export default class ControlIngreso extends Base {

    constructor(props) {
        super(props);

        this.ControlIngresoService = new GeneralServices();
        // this.Close = this.Close.bind(this);

        this.state =
        {
            loading: false,
            manzanas: [],
            villas: [],
            open: false
        };
    }



    componentDidMount() {
        const id = this.props.match.params.id;

        if (id > 0) {

            this.ControlIngresoService.Id(id, "control/id")
                .then(result => {
                    //console.log(result);
                    if (result.codigo !== "0") {
                        alert(result.mensaje);
                        if (result.codigo === 401) {
                            this.CerarSesion();
                        }
                    }
                    else {

                        if (result.data === null) {
                            alert(this.getText("lblNoHayDatosId") + id);
                            window.location.href = "/ControlInvitados";
                            return;
                        }

                        //console.log(result.data);

                        this.setState({
                            persona: result.data.persona,
                            invitado: result.data.invitado,
                            ubicacion: result.data.persona.ubicacion,
                            vehiculo: result.data.invitadoControl,
                        });
                        //     invitacion: result.data.invitacion

                    }
                }
                )
                .catch(error => {
                    console.log(error);
                });

        }
        else {
            this.setState({ controlIngreso: "" });
        }
    }



    render() {
        if (this.state.persona) {
            const control = this.state.vehiculo;

            const ingreso = control.fotosIngreso === null || control.fotosIngreso === "" ? [] : JSON.parse(control.fotosIngreso);
            const salida = control.fotosSalida === null || control.fotosSalida === "" ? [] : JSON.parse(control.fotosSalida);
            const desabilitar = true;

            //console.log(control);

            return (
                <div className='formTitulo' >

                    {/* <div className='form'>
                    <Form> */}
                    <FormGroup row >
                        <Col md={9}>
                            <h5><Return onClick={() => this.props.history.go(-1)} />{this.getText("titInvitado")}</h5>
                        </Col>
                    </FormGroup>

                    <CardTitle  >
                        <b>{this.getText("titResidente")}</b>
                    </CardTitle>



                    <Card >
                        <CardBody>
                            <FormGroup row >
                                <Col>
                                    <Text
                                        label={this.getText("lblNombres")}
                                        id={'txt_nombres'}
                                        value={this.state.persona.nombres + ' ' + this.state.persona.apellidos}
                                        type={'text'}
                                        disabled={true}
                                    />
                                </Col>
                            </FormGroup>

                            <FormGroup row >
                                {this.state.ubicacion &&
                                    <>
                                        <Col >
                                            <Text
                                                label={this.getText("lblManzana")}
                                                id={'txt_nombres'}
                                                value={this.state.ubicacion.manzana}
                                                type={'text'}
                                                disabled={true}
                                            />
                                        </Col>
                                        <Col >
                                            <Text
                                                label={this.getText("lblLote")}
                                                id={'txt_nombres'}
                                                value={this.state.ubicacion.villa}
                                                type={'text'}
                                                disabled={true}
                                            />

                                        </Col>
                                        {
                                            this.state.ubicacion.departamento ?
                                                <Col >
                                                    <Text
                                                        label={this.getText("lblDepartamento")}
                                                        id={'txt_nombres'}
                                                        value={this.state.ubicacion.departamento}
                                                        type={'text'}
                                                        disabled={desabilitar}
                                                    />
                                                </Col> :
                                                ""
                                        }
                                    </>
                                }
                            </FormGroup >
                        </CardBody>
                    </Card>
                    <FormGroup row />
                    {/* {this.state.invitado ? */}
                    <div>
                        <CardTitle  >
                            <b>{this.getText("titInvitado")}</b>
                        </CardTitle>
                        <Card >
                            <CardBody>
                                {control.categoriaIngreso &&
                                    <FormGroup row >
                                        {/* categoria ingreso */}
                                        <Col>
                                            <Dropdown
                                                id="cmbCatIngreso"
                                                label={this.getText("lblCategoriaIngreso")}
                                                ref={(ref) => this.setRef('cmbCatIngreso', ref)}
                                                disabled={true}
                                                value={JSON.parse(this.getOpcion("CategoriaIngreso"))}
                                                default={control.categoriaIngreso}
                                            />
                                        </Col>
                                    </FormGroup>

                                }
                                {this.state.invitado &&
                                    <>
                                        <FormGroup row >
                                            <Col>
                                                <Text
                                                    label={this.getText("lblNombres")}
                                                    id={'txt_nombres_invitado'}
                                                    value={this.state.invitado.nombres + ' ' + this.state.invitado.apellidos}
                                                    type={'text'}
                                                    disabled={true}
                                                />
                                            </Col>
                                        </FormGroup>

                                        <FormGroup row >
                                            <Col>
                                                <Text
                                                    label={this.getText("lblIdentificacion")}
                                                    id={'txt_id_invitado'}
                                                    value={this.state.invitado.identificacion}
                                                    type={'text'}
                                                    disabled={true}
                                                />
                                            </Col>
                                            <Col>
                                                <Text
                                                    label={this.getText("lblPlaca")}
                                                    id={'txt_placa'}
                                                    value={control.placa}
                                                    type={'text'}
                                                    disabled={true}
                                                />
                                            </Col>
                                        </FormGroup>
                                    </>
                                }
                                {control.categoriaTransporte ?
                                    <FormGroup row >
                                        <Col>

                                            <Dropdown
                                                id="cmbCatTransporte"
                                                label={this.getText("lblCatTransporte")}
                                                ref={(ref) => this.setRef('cmbCatTransporte', ref)}
                                                value={JSON.parse(this.getOpcion("CategoriaTransporte"))}
                                                disabled={true}
                                                default={control.categoriaTransporte}
                                            />
                                        </Col>
                                        <Col>
                                            <Dropdown
                                                id="cmbTipoTransporte"
                                                ref={(ref) => this.setRef('cmbTipoTransporte', ref)}
                                                label={this.getText("lblTipoTransporte")}
                                                value={JSON.parse(this.getOpcion("TipoTransporte"))}
                                                disabled={true}
                                                default={control.tipoTransporte ? control.tipoTransporte : ""}
                                            />
                                        </Col>
                                    </FormGroup>
                                    : <div></div>}
                                {control.nombreConductor ?
                                    <FormGroup row >
                                        <Col>
                                            <Text
                                                label={this.getText("lblNombreConductor")}
                                                id={'txt_nombre_c'}
                                                value={control.nombreConductor}
                                                type={'text'}
                                                disabled={true}
                                            />
                                        </Col>
                                    </FormGroup> : <div></div>

                                }


                                <FormGroup row >
                                    <Col>
                                        <Text
                                            label={this.getText("lblNovedad")}
                                            id={'txt_novedad'}
                                            value={control.novedad ? control.novedad : ""}
                                            type={'textarea'}
                                            disabled={true}
                                        />
                                    </Col>
                                </FormGroup>
                            </CardBody>
                        </Card>
                        <FormGroup row />
                    </div>

                    {/* } */}

                    <CardTitle  >
                        <b>{`${this.getText("titVehiculo")}  (${control.placa})`} </b>
                    </CardTitle>
                    <FormGroup row>
                        <Col>
                            <Card >
                                <CardBody>
                                    <CardTitle  >
                                        <Row>
                                            <Col sm='6'>
                                                {`${this.getText("lblFechaIngreso")} : ${new Date(control.fechaIngreso.replace("+0000", "Z")).toLocaleString()}`}
                                            </Col>
                                            <Col sm='6'>
                                                {control.fechaSalida ?
                                                    `${this.getText("lblFechaSalida")} : ${new Date(control.fechaSalida.replace("+0000", "Z")).toLocaleString()}` :
                                                    ""}
                                            </Col>
                                            {/*<Button size="sm"
                                            onClick={(e) => this.tomarFoto(e, 0)}
                                            className="tertiaryColorB"
                                    value={this.getText("lblFotos")} />*/}
                                        </Row>
                                    </CardTitle>
                                    <Carrusel value={ingreso} />
                                    {/* <CardFooter>
  
                                        {this.state.open ?

                                            <ControlIngresoFoto
                                                open={this.state.open}
                                                Close={this.Close}
                                            /> : <div></div>
                                        }
                                    </CardFooter> */}
                                </CardBody>
                            </Card>
                        </Col>
                        {/* <Col>
                            <Card >
                                <CardBody>
                                    <CardTitle  >
                                        {`${this.getText("lblFechaSalida")} : ${new Date(this.state.invitacion.fecha_fin.replace("+0000","Z")).toLocaleString() }`}
                                    </CardTitle>
                                    <Carrusel value={salida} />
                                </CardBody>
                            </Card>
                        </Col> */}
                    </FormGroup>
                    {/* </Form>
                </div> */}
                    { (this.state.loading) ? <Loader /> : <span />}
                </div >
            );
        }
        else {
            return (
                <div>
                    <Loader />
                </div>);
        }
    }
}