import React from 'react';
import Base from '../../components/Base';
import Menu from "../../components/Menu";
import {
    Row, Col,
    DropdownMenu,
    DropdownItem,
    Navbar,
    Nav,
    UncontrolledDropdown,
    //NavbarBrand,
    DropdownToggle
} from 'reactstrap';
import cookies from 'react-cookies';
import * as Routes from '../../routes/index';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './style.css';


export default class Home extends Base {

    constructor(props) {
        super(props);
        this.body = this.body.bind(this);
        this.onLogout = this.onLogout.bind(this)
        this.state = {
            title: "Home"
        }
    }

    onLogout() {
        this.CerarSesion();
    }


    body() {
        const body = this.props.body;
        this.setRef("body", body);
        return (
            body
        );
    }


    render() {
        var capacidades=cookies.load('capacidades');
        //console.log(this.props);
        return (
            <div className="App">
                {/* <Container>                   */}
                <Router>
                <Row>
                    <Col md='2' >
                        <Menu />
                    </Col>
                    <Col md="10">

                        <Navbar color="light" light expand="md">
                            <Nav className="mr-auto navbar-title" >{cookies.load('empresaDescripcion') === undefined ? this.getText("titPrincipal") : cookies.load('empresaDescripcion') + '-' + cookies.load('etapaDescripcion')}</Nav>
                            <Nav className="ml-auto" navbar>
                                <UncontrolledDropdown nav inNavbar direction="left" >
                                    <DropdownToggle nav caret>
                                        {cookies.load('usuario')}
                                    </DropdownToggle>
                                    {<DropdownMenu right>
                                        {/* <DropdownItem>
                                                    Cambiar Clave
                                                </DropdownItem>
                                                <DropdownItem divider /> */}
                                        <DropdownItem onClick={this.onLogout} >
                                            {this.getText("lblCerrarSesion")}
                                        </DropdownItem>
                                    </DropdownMenu>}
                                </UncontrolledDropdown>
                            </Nav>

                        </Navbar>

                        <div   >

                        <Switch>
                            {capacidades?capacidades.map((route) => (
                                <Route
                                    exact
                                    key={route.id}
                                    component={Routes[route.nombre]}
                                    path={`/${route.nombre}`}
                                    props={this.props}
                                />
                            )):""}
                            <Route exact 
                            component={Routes['Home']} 
                            path ='/'
                            />

                        </Switch>

                            {this.props.body}
                        </div>
                    </Col>
                </Row>
                </Router>
            </div>

        )
    };
}

